import React, { useRef } from "react";
import FooterPrescription from "./FooterPrescription";
import HeaderPrescription from "./HeaderPrescription";
import PharmacyPrescription from "./PharmacyPrescription";
import Lotus from "../../../assets/images/sriclinic.png";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const PrescriptionTemplate = () => {
    const contentRef = useRef();

    const download = () => {
        const input = contentRef.current;
        html2canvas(input, {
          scale: 2,
          useCORS: true,
          logging: true,
          onclone: (clonedDoc) => {
            const imgElement = clonedDoc.querySelector("img");
            imgElement.crossOrigin = "anonymous";
          },
        })
          .then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF({
              orientation: "portrait",
              unit: "px",
              format: "a4",
              userUnit: "px",
            });
    
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
    
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
    
            const width = imgWidth * ratio;
            const height = imgHeight * ratio;
    
            pdf.addImage(imgData, "PNG", 0, 0, width, height);
            pdf.save(
              "Prescription-" + ".pdf"
            );
          })
          .catch((error) => {
            console.error("Error capturing the content:", error);
          });
      };
  return (
    <section>
    <div 
    ref={contentRef}
    className="p-4 max-w-screen-lg mx-auto border-2 border-gray-300">
      {/* Header Section */}
      <div className="border-b-2 border-gray-300 flex justify-between items-start">
        <div>
          <div className="flex">
            <img src={Lotus} alt="Clinic Logo" className="w-24 h-24 mx-4" />
            <div className="text-center mt-3">
              <h1 className="text-4xl font-serif font-medium text-[#154A6B]">
                SRITHAILA{" "}
              </h1>
              <h1 className="text-2xl  text-[#154A6B]">CLINIC</h1>
            </div>
          </div>
          <p className="text-sm text-[#E49DC4] mt-4">
            Morning: <span className="text-[#184B67]">8:00 am - 11:00 am</span>{" "}
            Evening: <span className="text-[#184B67]">5:30 pm - 9:00 pm </span>
          </p>
        </div>
        <div className="text-right text-[#184B67]">
          <h2 className="text-2xl font-bold text-[#154A6B]">Dr. S. SHWETHA</h2>
          <p className=" text-gray-600 ">
            <span className="text-xs font-medium">
              M.S(OG), FMAS, DMAS, FRM{" "}
            </span>
            <br />
            <span className="text-xs font-medium">
              Dip in Cosmetic Gynecology{" "}
            </span>{" "}
            <br />
            Consultant Obstetrician & Gynecologist <br />
            Reg.no. 112630
          </p>
        </div>
      </div>
      {/* Patient Information */}
      {/* Review Section */}
      <div className="h-full">
        <PharmacyPrescription />
      </div>
      {/* Footer with Services and Contact */}
      <div className="p-6 bg-white border-t-2 border-gray-300 w-full mx-auto">
        <div className="text-center mb-6">
          <h2 className="text-xl font-semibold text-gray-800 ">Services</h2>
          <div className="mt-2 flex justify-center space-x-3 text-pink-600">
            <span>General Medicine</span>
            <span className="text-blue-800">|</span>
            <span>Maternity</span>
            <span className="text-blue-800">|</span>
            <span>Fertility</span>
            <span className="text-blue-800">|</span>
            <span>General & Cosmetic Gynecology</span>
            <span className="text-blue-800">|</span>
            <span>Well Women Services</span>
            <span className="text-blue-800">|</span>
            <span>Lab & Scans</span>
          </div>
        </div>

        <div className="flex justify-between text-gray-600">
          <div>
            <p className="text-[#184B67]">
              Plot no : 1-A-1, Dr.RP Road, Gayathri Nagar,
              <br />
              Hasthinapuram, Chrompet, Chennai - 44
            </p>
          </div>
          <div className="text-[#184B67]">
            <a href="tel:9894060645" className=" ">
              <i className="fas fa-phone-alt"></i>
              <span className="ml-2">98940 60645</span>
            </a>
            <br />
            <a href="mailto:srithailahealthcare@gmail.com" className=" ">
              <i className="fas fa-envelope"></i>
              <span className="ml-2">srithailahealthcare@gmail.com</span>
            </a>
          </div>
        </div>
      </div>{" "}
    </div>
          {/* <div className="flex mx-8 mb-6 items-center justify-end gap-2">
        <button
          onClick={() => download()}
          className="text-white bg-brand-primaryColor w-44 h-12  rounded-lg "
        >
          Download Prescription
        </button>
      </div> */}
    </section>
  );
};

export default PrescriptionTemplate;
