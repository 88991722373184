import React from 'react'
import Layout from '../../Layout'
import ChangePassword from './ChangePassword'

const Settings = () => {
    return (
        <Layout>
            <div className='w-full flex justify-center items-center my-2'>
                <div className='w-2/4'>
                    <div className="bg-[#002F72] rounded-md p-2">
                        <p className="font-medium text-center md:text-2xl text-White">Change Password</p>
                    </div>
                    <ChangePassword />
                </div>
            </div>
        </Layout>
    )
}

export default Settings