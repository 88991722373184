import React, { useEffect, useRef, useState } from 'react';
import doxzonLogo from '../assets/images/doxzonnewlogo.png';
import { Link, useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Avatar, Dropdown, Menu, Modal, Radio } from 'antd';
import DoctorSelfEnrollment from '../Pages/Enrollment/doctor';
import InProgress from "../assets/images/WorkInProgess.jpg";
import { APP_ROUTES } from "../PageRouting/pageRoutes";
import PatientReg from '../Pages/Enrollment/patientReg';
import Login from '../Authentication/Login';

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showsideMenuDropdown, setShowsideMenuDropdown] = useState(false);
  const [regModal, setRegModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('doctor');
  const [loginModal, setLoginModal] = useState(false);
  const sideMenudropdownRef = useRef(null);
  const headerdropdownRef = useRef(null);
  const navigate = useNavigate();
  const loggedUserJson = localStorage.getItem("patientData");
  const loggedUser = loggedUserJson ? JSON.parse(loggedUserJson) : null;

  console.log(loggedUser);

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  const handleRadioSelect = (e) => {
    console.log(e.target.value);
    setSelectedOption(e.target.value);
  }

  const handleClickOutside = (event) => {
    if (headerdropdownRef.current && !headerdropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  const handleSignUpClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSideMenuSignuUp = () => {
    setShowsideMenuDropdown(!showsideMenuDropdown);
  }

  const handleLoginModal = () => {
    setLoginModal(!loginModal);
  }

  const handleSignUpFromLogin = () => {
    setLoginModal(!loginModal);
    setRegModal(true);
  }

  const handleReg = () => {
    setRegModal(true);
    // toggleMobileMenu()
  }

  const handleRegClose = () => {
    setRegModal(false);
  }

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };


  const handleRoute = (route) => {
    navigate(route);
  };

  const handleLogOut = () => {
    localStorage.clear();
    handleRoute(APP_ROUTES.LOGIN);
  }

  const menu = (
    <Menu className='w-full'>
      <Menu.Item key="1" onClick={() => handleRoute(APP_ROUTES.MYPROFILE)}>My Profile</Menu.Item>
      <Menu.Item key="2" onClick={() => handleRoute(APP_ROUTES.MYAPPOINMENTS)}>Appointments</Menu.Item>
      <Menu.SubMenu key="medicalRecords" title="Medical Records">
        <Menu.Item key="3-1" onClick={() => handleRoute(APP_ROUTES.MEDICALHISTORY)}>Medical History</Menu.Item>
        <Menu.Item key="3-2" onClick={() => handleRoute(APP_ROUTES.MYPRESCRIPTION)}>My Prescription</Menu.Item>
        <Menu.Item key="3-3" onClick={() => handleRoute(APP_ROUTES.MYREPORTS)}>My Reports</Menu.Item>
        <Menu.Item key="3-4" onClick={() => handleRoute(APP_ROUTES.MYLABREPORTS)}>Lab Reports</Menu.Item>
        <Menu.Item key="3-5" onClick={() => handleRoute(APP_ROUTES.MYVITALS)}>Vitals</Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key="4" onClick={() => handleRoute(APP_ROUTES.SETTINGS)}>Settings</Menu.Item>
      <Menu.Item key="5" onClick={handleLogOut}>Logout</Menu.Item>
    </Menu>
  );

  return (
    <div className='w-full bg-[#002F72] fixed z-50 top-0 py-4 px-5'>
      <div className='flex justify-between'>
        <div className='cursor-pointer' onClick={() => handleRoute(APP_ROUTES.LOGIN)}><img className='pt-1 w-32' src={doxzonLogo} alt="Logo" /></div>

        <div className='flex my-auto'>
          {/* Desktop Menu */}
          <ul className="nav flex items-center font-Poppins text-white xl:gap-12 gap-x-4 md:hidden sm:hidden xs:hidden m:hidden">
            <Link to="/#providers" className="leading-normal hover:text-[#C1F2F5] no-underline uppercase xl:text-[17px] lg:text-[14px] cursor-pointer">
              For Providers
            </Link>
            <Link to="/#patients" className="leading-normal hover:text-[#C1F2F5] no-underline uppercase xl:text-[17px] lg:text-[14px] cursor-pointer">
              For Patients
            </Link>
            <p className="leading-normal hover:text-[#C1F2F5] no-underline uppercase xl:text-[17px] lg:text-[14px] cursor-pointer" onClick={() =>
              handleRoute(APP_ROUTES.CONSULTDOCTOR)
            }>
              Consult Doctor
            </p>
            <Link to="/#clinic" className="leading-normal hover:text-[#C1F2F5] no-underline uppercase xl:text-[17px] lg:text-[14px] cursor-pointer">
              For Clinic
            </Link>
            {!loggedUser ?
              (<div className="flex gap-6 items-center">
                <button
                  className="leading-normal hover:text-[#C1F2F5] no-underline uppercase text-[17px] cursor-pointer"
                  onClick={handleReg}
                >
                  Sign Up
                </button>
                <button
                  className="leading-normal hover:text-[#C1F2F5] no-underline uppercase text-[17px] cursor-pointer"
                  onClick={handleLoginModal}
                >
                  Login
                </button>
              </div>)
              :
              (
                <Dropdown overlay={menu} trigger={['click','hover']}>
                  <Avatar className='bg-White text-iconBlue text-lg font-medium cursor-pointer'>
                   {loggedUser?.firstName.charAt(0)}
                  </Avatar>
                </Dropdown>
              )
            }

          </ul>

          {/* Hamburger Icon for Mobile */}
          <div className='max-lg:flex xl:hidden x:hidden l:hidden lg:hidden text-white'>
            <button onClick={toggleMobileMenu}>
              {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu (Sliding In) */}
      <div
        className={`fixed top-16 left-0 w-3/4 h-full bg-[#002F72] text-white z-40 transform ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out lg:hidden`}
      >
        <ul className="flex flex-col items-start font-Poppins text-white gap-6 p-6">
          <Link to="/#providers" onClick={toggleMobileMenu} className="hover:text-[#C1F2F5] no-underline uppercase xl:text-[17px] lg:text-[15px]">
            For Providers
          </Link>
          <Link to="/#patients" onClick={toggleMobileMenu} className="hover:text-[#C1F2F5] no-underline uppercase xl:text-[17px] lg:text-[15px]">
            For Patients
          </Link>
          <p className="leading-normal hover:text-[#C1F2F5] no-underline uppercase xl:text-[17px] lg:text-[14px] cursor-pointer" onClick={() =>
            handleRoute(APP_ROUTES.CONSULTDOCTOR)
          }>
            Consult Doctor
          </p>
          <Link to="/#clinic" onClick={toggleMobileMenu} className="hover:text-[#C1F2F5] no-underline uppercase xl:text-[17px] lg:text-[15px]">
            For Clinic
          </Link>
          <div>
            <button
              className="leading-normal hover:text-[#C1F2F5] no-underline uppercase text-[17px] cursor-pointer"
              onClick={handleSideMenuSignuUp}
            >
              Sign Up
            </button>
            <button
              className="leading-normal hover:text-[#C1F2F5] no-underline uppercase text-[17px] cursor-pointer"
              onClick={handleLoginModal}
            >
              Login
            </button>
            {showsideMenuDropdown && (
              <ul ref={sideMenudropdownRef} className='list-disc mx-6'>
                <li className="leading-normal hover:text-[#C1F2F5] no-underline uppercase text-[17px] cursor-pointer" onClick={handleReg}>Doctor</li>
                <li className="leading-normal hover:text-[#C1F2F5] no-underline uppercase text-[17px] cursor-pointer">Patient</li>
              </ul>
            )}
          </div>
        </ul>
      </div>
      {/* doctor/patient registration */}
      <Modal
        open={regModal}
        onCancel={handleRegClose}
        destroyOnClose
        footer={[]}
        width={'60%'}
        style={{
          height: 'max-content',
          padding: '0px',
        }}
      >
        <div className='m-6'>
          <Radio.Group onChange={handleRadioSelect} value={selectedOption}>
            <Radio className="font-medium" value="doctor">Doctor</Radio>
            <Radio className="font-medium" value="patient">Patient</Radio>
          </Radio.Group>

        </div>
        {
          selectedOption === 'doctor' ?
            (<DoctorSelfEnrollment handleDoctorClose={handleRegClose} />)
            :
            (
              // <div className='flex justify-center items-center'>
              //   <img src={InProgress} alt='In Progress'/>
              // </div>
              <PatientReg handleCancel={handleRegClose} />
            )
        }
      </Modal>

      {/* Patient Login */}
      <Modal
        open={loginModal}
        footer={[]}
        width={'38%'}
        onCancel={handleLoginModal}
        destroyOnClose
      >
        <Login handleLoginModal={handleLoginModal} handleSignup={handleSignUpFromLogin}/>
      </Modal>
    </div>
  );
}

export default Navbar;
