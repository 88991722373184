import http from "./http_common";

class AppointmentService {
     queryString(payload) {
          const QueryString = Object.keys(payload).map((key) => (key && payload[key] && key + "=" + payload[key])).join('&');
          return QueryString;
     }


     UserSavedTemplates(data) {
          const queryString = this.queryString(data);
          return http.get(`/appointmentTemplate/consultation/list/filter?${queryString}`);
     }

     AppointmentUpdate(data) {
          return http.put(`/invitationDetails/update-status/${data.id}`, data);
     }

     getAppointmentId(id) {
          return http.get(`/invitationDetails/list/filter?appointmentId=${id}`);
     }

     getPatientAppointment(payload) {
          var queryString = this.queryString(payload);
          return http.get(`patientAppointment/list/filter?${queryString}`);
     }
     callQueueUpdate(payload) {
          return http.put(
               `patientAppointment/update/queue-joining-datetime`,
               payload
          );
     }
     updateStartTime(payload) {
          return http.put(`/patientAppointment/updateStartDateTime`, payload);
     }
     updateQueueStatus(payload) {
          return http.put(`/patientAppointment/update/queue-status-endconsult`, payload);
     }

}

export default new AppointmentService();