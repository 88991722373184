import React from "react";
import Allergy from "./Allergy";
import SocialHistory from "./SocialHistory";
import PastMedicalHistory from "./PastMedicalHistory";
import SurgicalHistory from "./SurgicalHistory";
import FamilyHistory from "./FamilyHistory";
import Medication from "./Medication";
import ChronicCondition from "./ChronicCondition";
import Layout from "../../../Layout";

const MedicaHistory = () => {
  return (
    <Layout>
      <div>
        <div className="flex justify-between m-3">
          <h2 className="font-bold text-lg">Medical History</h2>
        </div>
        <div className="border grid grid-cols-3 gap-2">
          <Allergy />
          <PastMedicalHistory />
          <SurgicalHistory />
          <FamilyHistory />
          <SocialHistory />
          <Medication />
          <ChronicCondition />
        </div>
      </div>
    </Layout>
  );
};

export default MedicaHistory;
