import { message } from "antd";
import axios from "axios";

const http = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Content-type": "application/json",
    },
});

http.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem("access_token");
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

const navigateToLogin = () => {
    localStorage.clear();
    window.location.href = "/";
};

http.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return new Promise((resolve, reject) => {
            if (error.response && error.response.status === 401) {
                message.warning({
                    content: "Your session has ended. Please log in again to continue.",
                    duration: 1.5,
                    onClose: navigateToLogin,
                });
            }
            return reject(error);
        });
    }
);

export default http;


