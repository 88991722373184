import React from "react";
import Logo from "../assets/images/Doxzon-Black.png";
import { Link, useNavigate } from 'react-router-dom';
import { FaLocationDot } from "react-icons/fa6";
import { IoIosCall } from "react-icons/io";
import { IoMail } from "react-icons/io5";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { APP_ROUTES } from "../PageRouting/pageRoutes";


function Footer() {
  const navigate = useNavigate();

  const handleRoute = (route) => {
    navigate(route);
  }

  return (
    <div>
      <div className="flex sm:flex-col regularFont justify-around pt-16 pb-10 w-[90%] mx-auto">
        <div>
        <div className='cursor-pointer' onClick={() => handleRoute(APP_ROUTES.LOGIN)}><img className='pt-1 sm:flex sm:mx-auto' src={Logo} alt="Logo" /></div>
        </div>

        <div className="sm:flex sm:mx-auto sm:mt-10">
          <ul className="flex flex-col font-Poppins gap-y-2">
          <Link to="/#providers" className="leading-normal hover:text-[#616161] no-underline uppercase md:text-[14px] x:text-[15px] l:text-[15px] xl:text-[17px] lg:text-[14px] cursor-pointer">
              For Providers
            </Link>
            <Link to="/#patients" className="leading-normal hover:text-[#616161] no-underline uppercase md:text-[14px] x:text-[15px] l:text-[15px] xl:text-[17px] lg:text-[14px] cursor-pointer">
              For Patients
            </Link>
            <Link to="/#clinic" className="leading-normal hover:text-[#616161] no-underline uppercase md:text-[14px] x:text-[15px] l:text-[15px] xl:text-[17px] lg:text-[14px] cursor-pointer">
              For Clinic
            </Link>
          </ul>
        </div>

        <div className="flex flex-col sm:mt-10 sm:mx-auto text-[#616161] md:text-[13px] x:text-[15px] l:text-[15px] xl:text-[17px] lg:text-[14px]">
            <div className="flex gap-x-2 leading-8 md:leading-6 x:leading-8 l:leading-8 xl:leading-8 2xl:leading-8">
              <FaLocationDot className="sm:mt-2 md:mt-2 x:mt-2 l:mt-2 xl:mt-1 lg:mt-2"/>
              <p className="">#179, Kothari Nagar, Singanallur,<br/>
              Coimbatore - 641 005. INDIA</p>
            </div>

            <div className="flex sm:mx-auto gap-x-2 mt-2">
              <IoIosCall className="lg:mt-[2px] x:mt-[2px] l:mt-[2px] xl:mt-1"/>
              <p className="">+91 90256 33829</p>
            </div>

            <div className="flex sm:mx-auto gap-x-2 mt-2">
              <IoMail className="md:mt-[4px] x:mt-[4px] xl:mt-[4px] lg:mt-[3px]"/>
              <p className="text-[#002f72]"><a className="" href="mailto:admin@cytozon.com">admin@cytozon.com</a></p>
            </div>
        </div>

        <div className="flex sm:mx-auto sm:gap-x-5 sm:mt-10 sm:h-[28px] md:h-[28px] x:h-[28px] l:h-[28px] xl:h-[35px] lg:h-[25px] xl:text-[24px] lg:text-[20px] gap-x-2 text-[#616161]">
          <a className="border-[2px] border-[#616161] hover:text-[#002f72] hover:border-[#002f72] rounded-full px-1 transition-all duration-600 ease-in-out" href="https://www.facebook.com/"><FaFacebookF className="mt-1 l:text-[15px] lg:text-[14px]"/></a>
          <a className="border-[2px] border-[#616161] hover:text-[#002f72] hover:border-[#002f72] rounded-full px-1 transition-all duration-600 ease-in-out" href="https://www.linkedin.com/"><FaLinkedinIn className="mt-1 l:text-[15px] lg:text-[14px]"/></a>
          <a className="border-[2px] border-[#616161] hover:text-[#002f72] hover:border-[#002f72] rounded-full px-1 transition-all duration-600 ease-in-out" href="https://www.instagram.com/"><FaInstagram className="mt-1 l:text-[15px] lg:text-[14px]"/></a>

        </div>
      </div>
      <div className="flex sm:flex-col headingFont md:text-[12px] md:mx-5 x:text-[15px] l:text-[15px] xl:text-[16px] lg:text-[13px] gap-x-1 text-[#616161] justify-center py-5 border-t-[1px] border-[#D1CBC4]">
          <p className="sm:text-center sm:text-[14px]">© 2024 - Cytozon Technologies Pvt Ltd., Custom Software Development & Engineering Services. </p>
          <div className="flex sm:mx-auto sm:gap-x-3 sm:text-[14px] sm:mt-3">
          <p className="text-[#002f72] cursor-pointer" onClick={() => handleRoute(APP_ROUTES.PRIVACYPOLICY)}>Privacy Policy</p>
          <p className="">|</p>
          <p className="text-[#002f72] cursor-pointer" onClick={() => handleRoute(APP_ROUTES.TERMSOFUSE)}>Terms Of Use</p>
          <p className="">|</p>
          <p className="text-[#002f72] cursor-pointer" onClick={() => handleRoute(APP_ROUTES.REFUNDPOLICY)}>Refund Policy</p>
          </div>
      </div>
    </div>
  );
}

export default Footer;
