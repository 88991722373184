import React, { useEffect, useState } from 'react';
import chatbotimage from '../assets/images/chatbot.png';
import { model } from '../HealthcareData';
import { IoMdClose } from 'react-icons/io';

export default function Chatbot({ setActive }) {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [conversationStep, setConversationStep] = useState(0);
  const [currentIntent, setCurrentIntent] = useState(null);

  useEffect(() => {
    setTimeout(() => processInput("hello"), 1000);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!input.trim()) return;
    setMessages((messages) => [{ author: 'user', text: input }, ...messages]);
    setTimeout(() => processInput(input), 1000);
    setInput("");
  };

  const normalizeString = (input) => input.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").toLowerCase();

  const unknownResponses = [
    "I'm sorry, I don't quite understand. Could you rephrase?",
    "Can you give me more details?",
    "I'm here to help, but I didn’t catch that. Try rephrasing your question."
  ];
  
  // const generatedResponse = (intent, step) => {
  //   if (model.responses && model.responses[intent]) {
  //     const responses = model.responses[intent];
  //     return responses[step]?.answer || "I'm here to help!";
  //   }
  //   // Return random response if intent is unknown
  //   return unknownResponses[Math.floor(Math.random() * unknownResponses.length)];
  // };


  const generatedResponse = (intent, step) => {
    if (model.responses && model.responses[intent]) {
      const responses = model.responses[intent];
  
      // Randomize the response for the intent
      const randomIndex = Math.floor(Math.random() * responses.length);
      return responses[randomIndex]?.answer || "I'm here to help!";
    }
  
    // Return random response if intent is unknown
    return unknownResponses[Math.floor(Math.random() * unknownResponses.length)];
  };
  

  const queriesAnswered = () => {
    setTimeout(() => {
      setMessages((messages) => [
        { author: "bot", text: "I'll close this conversation shortly. Say 'hi' anytime to restart!" },
        { author: "bot", text: "Thank you for using our medical assistant chatbot!" },
        { author: "bot", text: "Feel free to reach out anytime for health-related queries." },
        { author: "bot", text: "Take care and stay healthy!" },
        ...messages,
      ]);
      setTimeout(() => setActive(false), 7000);
    }, 2000);
  };

  const simulateTypingEffect = (text, callback) => {
    let index = 0;
    const interval = setInterval(() => {
      callback(text.slice(0, index + 1));
      index++;
  
      if (index === text.length) {
        clearInterval(interval); // Stop the interval when typing is complete
      }
    }, 30); // Adjust typing speed here (50ms per character)
  };

  const processInput = (input) => {
    const normalizedString = normalizeString(input);
    const intent = matchIntent(normalizedString);

    if (currentIntent !== intent) {
      setCurrentIntent(intent);
      setConversationStep(0);
    }


    const responseText = generatedResponse(intent, conversationStep);
  
  // Simulate typing effect
  setMessages((messages) => [{ author: "bot", text: "" }, ...messages]); // Add a blank message for typing effect
  simulateTypingEffect(responseText, (typedText) => {
    setMessages((messages) => {
      const updatedMessages = [...messages];
      updatedMessages[0] = { author: "bot", text: typedText }; // Update the typing message
      return updatedMessages;
    });
  });

  const followUp = model.responses[intent]?.[conversationStep]?.followUp;
  if (followUp) {
    setTimeout(() => processInput(followUp), 2000); // Process follow-up after a delay
    setConversationStep((prevStep) => prevStep + 1);
  }

  if (intent === 'goodbye') queriesAnswered();
};


  //   const response = generatedResponse(intent, conversationStep);
  //   setMessages((messages) => [{ author: "bot", text: response }, ...messages]);

  //   const followUp = model.responses[intent]?.[conversationStep]?.followUp;
  //   if (followUp) {
  //     setTimeout(() => setMessages([{ author: "bot", text: followUp }, ...messages]), 2000);
  //     setConversationStep((prevStep) => prevStep + 1);
  //   }

  //   if (intent === 'goodbye') queriesAnswered();
  // };

  const matchIntent = (input) => {
    const normalizedInput = normalizeString(input);
  
    let exactMatch = null;
    let bestMatch = { intent: "unknown", score: 0 };
  
    for (const [intent, patterns] of Object.entries(model.intents)) {
      for (const pattern of patterns) {
        const normalizedPattern = normalizeString(pattern);
  
        // Step 1: Exact Phrase Matching
        if (normalizedInput === normalizedPattern) {
          return intent; // Return immediately if exact match is found
        }
  
        // Step 2: Check for Full Match Within Input
        const fullMatchRegex = new RegExp(`\\b${normalizedPattern}\\b`, "i");
        if (fullMatchRegex.test(normalizedInput)) {
          exactMatch = intent;
        }
  
        // Step 3: Scoring for Partial Matches
        if (normalizedInput.includes(normalizedPattern)) {
          const matchScore = normalizedPattern.length / normalizedInput.length;
          if (matchScore > bestMatch.score) {
            bestMatch = { intent, score: matchScore };
          }
        }
      }
    }
  
    // Prioritize exact match over best partial match
    return exactMatch || bestMatch.intent;
  };
  
    

    const renderMessage = (message) => {
      // Regular expression to detect URLs
      const urlRegex = /(https?:\/\/[^\s]+)/g;
    
      // Split the message by \n into steps (this applies to both URL and non-URL messages)
      const stepParts = message.split('\n');
    
      return stepParts.map((part, index) => {
        // If the part contains a URL, render the URL as a clickable link
        if (urlRegex.test(part)) {
          // Split by the URL to separate the clickable part
          const parts = part.split(urlRegex);
          return parts.map((subPart, subIndex) =>
            urlRegex.test(subPart) ? (
              <a key={`${index}-${subIndex}`} href={subPart} rel="noopener noreferrer">
                {subPart}
              </a>
            ) : (
              <span key={`${index}-${subIndex}`} dangerouslySetInnerHTML={{ __html: subPart }} />
            )
          );
        }
        
        // If no URL is found, just render the part as normal text
        return <div key={index} dangerouslySetInnerHTML={{ __html: part }} />;
      });
    };
    
    

  return (
    <div className='chatbot'>
      <div className='chatbot-header '>
        <div className='flex ml-2 cursor-pointer w-[10%]'>
        <IoMdClose onClick={() => setActive(false)} />
        </div>
      <div className='flex justify-center items-center w-[90%] mr-10'>
      <img src={chatbotimage} alt='chatbot' />
      <p>DoxBot</p>

      </div>

      </div>

    <div className='messages'>
      {messages.map((message, index) => (
        <div key={message.text + index} className={`message ${message.author}`}>
      {renderMessage(message.text)}
       </div>
     ))}
    </div>


      <form className='m-2 w-full' onSubmit={handleSubmit}>
        <input
          className='w-[79%] outline-none rounded-l-md px-2'
          value={input}
          spellCheck
          onChange={(e) => setInput(e.target.value)}
          placeholder='Type your query...'
          wrap='soft'
        />
        <button
          className='rounded-r-md bg-blue-500 text-white font-semibold'
          type='submit'
          disabled={!input.trim()}
        >
          Send
        </button>
      </form>
    </div>
  );
}
