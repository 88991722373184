import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import plusIcon from "../../../assets/images/Svg/plusIcon.svg";
import AddMedication from "./AddMedication";
import MedicalService from "../../../Services/MedicalService";
import dayjs from "dayjs";

function Medication() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [medicationData, setMedication] = useState([]);

  const handleMedicationClick = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    Medication();
  };


  const Medication = () => {
    let payload = {
      patientCode: patientCode,
      status: 1,
    };
    MedicalService.PatientMedication(payload)
    .then((result) => {
      setMedication(result.data);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  useEffect(() => {
    Medication();
  }, []);

  return (
    <div>
      <div className="card h-[300px] m-5 border rounded-lg">
        <div className="header h-[15%] bg-secondaryColor flex items-center justify-between rounded-lg p-2">
          <h1 className="font-bold text-normal text-white">Medication</h1>
          <img
            src={plusIcon}
            onClick={() => handleMedicationClick()}
            className="h-10 w-10 cursor-pointer"
            alt=""
          />
        </div>
        <div className="body h-[70%] overflow-y-auto bg-brand-lightBg p-2" id="unquie-scroll">
          {medicationData.length > 0
            ? medicationData.map((user, i) => (
                <div>
                  <div key={i} className="flex flex-col gap-2 mt-3 text-sm">
                    <p className="font-medium text-black">Drug Name : <span className="font-medium text-gray-500">{user.drugName}</span></p>
                    <p className="font-medium text-black">Composition : <span className="font-medium text-gray-500">{user.composition ? user.composition : "N/A"}</span> </p>
                    <p className="font-medium text-black">Dosage : <span className="font-medium text-gray-500">{user.dosage} </span></p>
                    <p className="font-medium text-black">Frequency : <span className="font-medium text-gray-500">{user.frequency}</span> </p>
                    <p className="font-medium text-black">Duration : <span className="font-medium text-gray-500">{user.duration} {user.durationType}</span> </p>
                    <p className="font-medium text-black">Indication : <span className="font-medium text-gray-500">{user.indication}</span> </p>
                    <p className="font-medium text-black">Side Effects : <span className="font-medium text-gray-500">{user.sideEffects}</span> </p>
                    <p className="font-medium text-black">Compliance : <span className="font-medium text-gray-500">{user.compliance}</span> </p>
                    <p className="font-medium text-black">Adverse Effects : <span className="font-medium text-gray-500">{user.adverseEffects}</span> </p>
                    <p className="font-medium text-black">
                      Advice related to medicines : <span className="font-medium text-gray-500">{user.adviceRelatedMedicine}</span>
                    </p>
                    {/* <p className="font-medium text-black">From Date : <span className="font-medium text-gray-500">{user.fromDate}</span> </p>
                    <p className="font-medium text-black">To Date : <span className="font-medium text-gray-500">{user.toDate}</span> </p> */}
                    <p className="text-gray-500 font-medium">
                      Onset date :{" "}
                      {dayjs(user.givenDate).format('YYYY-MM-DD')}
                    </p>
                  </div>
                  <hr className="my-4 border-gray-300" />
                </div>
              ))
            : null}
        </div>
        <div className="footer h-[10%]  bg-secondaryColor rounded-md"></div>
      </div>

      {isModalVisible && (
        <Modal
          title={<span className="text-xl font-bold font-sans">Add Medication</span>}
          visible={isModalVisible}
          onOk={() => handleCloseModal()}
          centered={true}
          footer={null}
          onCancel={() => handleCloseModal()}
          okText="Submit"
          cancelText="Cancel"
          width={600}
          maskClosable={false}
        >
          <AddMedication onClose={() => handleCloseModal()} />
        </Modal>
      )}
    </div>
  );
}

export default Medication;
