import React, { useState } from 'react'
import Navbar from '../DashBoardComponents/Navbar';
import Footer from '../DashBoardComponents/Footer';
import Chatbot from '../DashBoardComponents/Chatbot';
import chatbotimage from '../assets/images/chatbot.png';

function Layout({ children }) {
  const [active,setActive] = useState(false);

  return (
    <div className='min-h-screen flex flex-col'>
      <div>
        <Navbar />
      </div>
      <div className='my-24'>
        {children}
      </div>
      {
        active ? (<Chatbot setActive={setActive}/>) : (<div className= 'chatbot-activate' onClick={()=> setActive(true)}>
          <img src={chatbotimage} alt='chatbot' />
        </div>
      )}
    </div>
  )
}

export default Layout;