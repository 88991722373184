import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import Webcam from 'react-webcam';
import { message } from "antd";
import PatientService from "../../Services/PatientService";

function ShareCall(props) {
  const params = useParams();
  const [name, setName] = useState("");
  const [userName, setUserName] = useState();
  const location = useLocation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    prescription: false,
    medicalHistory: false,
    reports: false,
    vitals: false
  });

  useEffect(() => {
    PatientService.getUserName(params.id)
    .then((res) => {
      setUserName(res.data[0].userName)
    })
    .catch((error) => {
      console.error(error);
    })
  }, [])

  const save = (e) => {
    e.preventDefault();
    if (name.trim() === "") {
      message.error("Please enter the name");
    } else {
      const header = {
        secretKey: "CcJYfbsgItHpTQPFr5lg",
      };
      const data = {
        clientCode: "CCM@@202!",
        userType: "PARTICIPANT",
        meetingKey: params.id,
        memberName: name,
        memberEmail: "test@gmail.com",
        memberMobile: "1234567890",
      };
      axios
        .post(
          "https://cb.maggieplus.com/admin-api/client/meeting/authentication",
          data,
          { headers: header }
        )
        .then((result) => {
          window.location.href = result.data.response.url;
        })
        .catch((err) => {
          console.log("Err", err);
        });
    }
  };
  const onChangeHandler = (fieldName, value) => {
    if (fieldName === "name") {
      setName(value);
    }
  };
  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedOptions((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let payload = {
      "appointmentId": params.id,
      "medicalHistory": selectedOptions.medicalHistory ? "Y" : "N",
      "prescription": selectedOptions.prescription ? "Y" : "N",
      "reports": selectedOptions.reports ? "Y" : "N",
      "vitals": selectedOptions.vitals ? "Y" : "N",
      "status": 1
    }
    PatientService.updateMedicalRecords(payload)
    .then((response) => {
      message.error("Updated successfully");
      handleCloseModal();
    })
    .catch((error) => {
      console.error(error);
    });
  };
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-sky-300 p-4">
      <div className="bg-white p-6 rounded-lg shadow-2xl w-full max-w-md">
        <div>
          <h2 className="text-xl font-bold mb-2 text-center">
            Are you ready to join?
          </h2>
          <h6 className="text-center font-medium">
            Hi, Kindly choose a quiet, comfortable place with good internet connectivity before your consultation begins
          </h6>
          {params.type == "Y" && <>
            <h6 className="text-center font-medium mt-2 text-gray-500">
              Please share your medical records by clicking the below button.
            </h6>
            <div className="flex justify-center">
              <button
                className="text-center mb-4 cursor-pointer font-semibold mt-3 border px-2 py-2 rounded-lg bg-blue-600 text-white"
                onClick={handleOpenModal}
              >
                Share Medical Records
              </button>
            </div>
          </>}
          <div className="flex gap-5 mt-2 justify-center">
            <input type="text"
              value={name}
              onChange={(e) => {
                onChangeHandler("name", e.target.value);
              }}
              className="border p-2 rounded-lg w-80 outline-none" placeholder="Enter Name" />
          </div>
        </div>
        <div className="flex justify-center mb-4 mt-3">
          {/* <img
            src={doctorimage}
            alt="User"
            className="rounded-lg w-full h-60"
          /> */}
          <Webcam
            audio={false}
            screenshotFormat="image/jpeg"
            className="rounded-lg w-full h-50"
            videoConstraints={videoConstraints}
          />
        </div>
        {/* <div className="flex justify-evenly mb-4">
          <button className="flex items-center justify-center w-12 h-12 bg-gray-200 rounded-full">
            <HiMicrophone className="w-5 h-5" />
          </button>
          <button className="flex items-center justify-center w-12 h-12 bg-gray-200 rounded-full">
            <MdOutlineVideocam className="w-5 h-5" />
          </button>
          <button className="flex items-center justify-center w-12 h-12 bg-gray-200 rounded-full">
            <BsChatDots className="w-5 h-5" />
          </button>
          <button className="flex items-center justify-center w-12 h-12 bg-gray-200 rounded-full">
            <ImEqualizer2 className="w-5 h-5" />
          </button>
        </div> */}
        {userName ?
          <p className="text-center mb-4 font-bold">
            Consultation with {userName}
          </p> : null}
        <div className="flex justify-between gap-3">
          <button onClick={() => window.location.href = "https://patient.doxzon.com"} className="px-4 py-2 bg-red-600 text-white rounded-md w-1/2">
            Leave
          </button>
          <button onClick={(e) => save(e)} className="px-4 py-2 bg-blue-600 text-white rounded-md w-1/2">
            Join now
          </button>
        </div>
      </div>

      {modalIsOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-2xl w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Share Medical Records</h2>
            <form onSubmit={handleFormSubmit}>
              <div className="mb-2">
                <input
                  type="checkbox"
                  name="prescription"
                  checked={selectedOptions.prescription}
                  onChange={handleCheckboxChange}
                />
                <label className="ml-2 cursor-pointer font-medium">Prescription</label>
              </div>
              <div className="mb-2">
                <input
                  type="checkbox"
                  name="medicalHistory"
                  checked={selectedOptions.medicalHistory}
                  onChange={handleCheckboxChange}
                />
                <label className="ml-2 cursor-pointer font-medium">Medical History</label>
              </div>
              <div className="mb-2">
                <input
                  type="checkbox"
                  name="vitals"
                  checked={selectedOptions.vitals}
                  onChange={handleCheckboxChange}
                />
                <label className="ml-2 cursor-pointer font-medium">Vitals</label>
              </div>
              <div className="mb-2">
                <input
                  type="checkbox"
                  name="reports"
                  checked={selectedOptions.reports}
                  onChange={handleCheckboxChange}
                />
                <label className="ml-2 cursor-pointer font-medium">Reports</label>
              </div>
              <div className="flex justify-end gap-3">
                <button type="button" onClick={handleCloseModal} className="px-4 py-2 bg-red-500 text-white rounded-md">
                  Close
                </button>
                <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-md mr-2">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
export default ShareCall;