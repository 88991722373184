import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

function FAQ() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const data = [
    {
      title: "What is Telemedicine?",
      content:
        "Telemedicine refers to the practice of providing medical care remotely through technology, such as video calls or messaging platforms.",
    },
    {
      title: "How does Telemedicine work?",
      content:
        "Telemedicine works by connecting patients with healthcare providers via video conferencing tools, allowing them to discuss symptoms, receive diagnoses, and get prescriptions without an in-person visit.",
    },
    {
      title: "What are the benefits of Telemedicine?",
      content:
        "Telemedicine offers convenience, accessibility, and reduced costs. It eliminates the need for travel and waiting times, making it easier to access healthcare from anywhere.",
    },
    {
      title: "How can I schedule a Telemedicine appointment?",
      content:
        "To schedule a Telemedicine appointment, you typically need to visit a provider's website or use a telemedicine platform to select a date and time that works for you.",
    },
    {
      title: "Can I get a prescription through Telemedicine?",
      content:
        "Yes, healthcare providers can issue prescriptions during Telemedicine consultations, which can be sent to your pharmacy electronically.",
    },
    {
      title: "How can I join the Telemedicine platform as a healthcare provider?",
      content:
        "Healthcare providers can join a Telemedicine platform by registering on a telemedicine service, meeting platform-specific requirements, and completing the necessary verification steps.",
    },
  ];

  return (
    <div className="faq bg-[#e4efff] py-20">
      <div className="">
        <h1 className="text-center sm:text-[30px] md:text-[50px] lg:text-[50px] x:text-[50px] xl:text-[60px] headingFont text-[#002f72]">
          Frequently Asked Questions
        </h1>
      </div>

      <div className="space-y-2 w-[80%] mx-auto mt-20">
        {data.map((item, index) => (
          <div
            key={index}
            className={`border-b overflow-hidden transition-all duration-500 ease-in-out ${
              openIndex === index ? "bg-[#002f72] text-white" : "bg-white"
            }`}
          >
            <button
              onClick={() => toggleAccordion(index)}
              className={`headingFont x:text-[20px] xl:text-[25px] lg:text-[20px] l:text-[22px] w-full text-left px-10 py-7 flex justify-between items-center transition-all duration-500 ease-in-out ${
                openIndex === index ? "text-white" : "text-[#002f72]"
              }`}
            >
              <span>{item.title}</span>
              {openIndex === index ? (
                <FaChevronUp className="text-white" />
              ) : (
                <FaChevronDown className="text-gray-500" />
              )}
            </button>
            <div
              className={`headingFont text-[#666] overflow-hidden transition-[max-height] duration-500 ease-in-out ${
                openIndex === index ? "max-h-[1000px]" : "max-h-0"
              }`}
            >
              <div className="py-7 px-10 xl:text-[18px] lg:text-[16px] bg-blue-50">
                <p className={`text-[#666] ${openIndex === index ? "text-[#666]" : ""}`}>
                  {item.content}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
