import React, { useState, useRef, useEffect } from 'react'
import { Checkbox, DatePicker, Form, Input, InputNumber, message, Radio } from 'antd';
import UserService from '../../Services/UserService';
import { convertUTCDate } from '../../utils/convertionFunc';

function PatientReg({ handleCancel }) {
    const formRef = useRef();
    const [dob, setDob] = useState('');
    const [slotChecked, setSlotChecked] = useState(false);
    const [doctor, setDoctor] = useState(null);
    const [disable,setDisable] = useState(false);

    useEffect(() => {
        getDoctorsList();
    }, []);

    const getDoctorsList = async () => {
        const userPayload = {
            userId: process.env.REACT_APP_SUPERADMIN_USER,
            status: 1
        };
        try {
            const response = await UserService.getDoctors(userPayload);
            console.log(response.data[0])
            setDoctor(response.data[0]);
        }
        catch (e) {
            console.log(e);
        }
    };

    const handleSlot = () => {
        setSlotChecked(!slotChecked);
    }

    const handlePatientCreate = async () => {
        const { firstname, lastname, gender, email, mobile } = formRef.current.getFieldValue();
        setDisable(true);
        if (!firstname || !lastname || !mobile) {
            message.error('Please fill in required fields.');
            setDisable(false);
            return;
        }

        const payload = {
            account_Id: doctor?.account_Id,
            account_Name: null,
            address1: null,
            address2: null,
            bloodGroup: null,
            city: null,
            clinic_Id: doctor?.clinic_Id,
            clinic_Name: null,
            country: null,
            createdBy: doctor?.userId,
            createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            dob: dob,
            email: email,
            firstName: firstname,
            gender,
            lastName: lastname,
            loginId: null,
            loginIdAvailableYN: null,
            maritalStatus: null,
            meetingLink: null,
            mobile,
            modifiedBy: doctor?.userId,
            modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            parentPatientId: null,
            parentPatientName: null,
            patientId: null,
            patientRegFee: slotChecked ? 500 : 0,
            patientRegFeeYN: slotChecked ? 'Y' : 'N',
            photo: null,
            photoName: null,
            photoType: null,
            pinCode: null,
            relationCode: null,
            relationDescription: null,
            salutation: null,
            state: null,
            status: 1,
            userId: doctor?.userId,
            userName: doctor?.userName,
            sourceChannel: "Website"
        }
        try {
            await UserService.addPatient(payload);
            setDisable(false);
            // const patientPayload = {
            //     patientId: response.data?.Patientid,
            //     status: 1
            // }
            // const patientResponse = await UserService.getPatient(patientPayload);
            // console.log(patientResponse.data);
            // handlePostPatientCreation(patientResponse.data);
            setSlotChecked(false);
            message.success('Patient successfully registered!');
            handleCancel();
            formRef.current.resetFields();
        }
        catch (e) {
            setDisable(false);
            console.log(e);
            message.error(e.response.data.message);
        }
    }

    const handleDob = (value, valueString) => {
        setDob(valueString);
    };


    return (
        <div>
            <Form ref={formRef} initialValues={{ regfee: 500 }}>
                <div className="w-full grid grid-cols-2 p-5 rounded-md gap-4">

                    {/* First Name Field */}
                    <div className="relative md:w-4/5 w-full">
                        <span className="absolute md:text-lg text-base font-medium bg-white px-1 z-10 top-[-0.75rem] left-3 text-[#175b66]">
                            First Name
                        </span>
                        <Form.Item name="firstname" className="w-full">
                            <Input
                                type="text"
                                name="firstname"
                                className="w-full md:h-12 h-10 border border-[#7FE8EE] p-1 rounded-lg outline-none"
                            />
                        </Form.Item>
                    </div>

                    {/* Last Name Field */}
                    <div className="relative md:w-4/5 w-full">
                        <span className="absolute md:text-lg text-base font-medium bg-white px-1 z-10 top-[-0.75rem] left-3 text-[#175b66]">
                            Last Name
                        </span>
                        <Form.Item name="lastname" className="w-full">
                            <Input
                                type="text"
                                name="lastname"
                                className="w-full md:h-12 h-10 border border-[#7FE8EE] p-1 rounded-lg outline-none"
                            />
                        </Form.Item>
                    </div>

                    {/* Mobile Number Field */}
                    <div className="relative md:w-4/5 w-full">
                        <span className="absolute md:text-lg text-base font-medium bg-white px-1 z-10 top-[-0.75rem] left-3 text-[#175b66]">
                            Mobile Number
                        </span>
                        <Form.Item name="mobile" className="w-full">
                            <InputNumber
                                controls={false}
                                type='number'
                                min={0}
                                className="w-full md:h-12 h-10 border border-[#7FE8EE] p-1 rounded-lg outline-none"
                            />
                        </Form.Item>
                    </div>

                    {/* Email Address Field */}
                    {/* <div className="relative md:w-4/5 w-full">
                        <span className="absolute md:text-lg text-base font-medium bg-white z-10 top-[-0.75rem] left-3 text-[#175b66]">
                            Email Address (Optional)
                        </span>
                        <Form.Item name="email" className="w-full">
                            <Input
                                type="email"
                                name="email"
                                className="w-full md:h-12 h-10 border border-[#7FE8EE] p-1 rounded-lg outline-none"
                            />
                        </Form.Item>
                    </div> */}

                    {/* Date of Birth Field */}
                    {/* <div className="relative md:w-4/5 w-full">
                        <span className="absolute md:text-lg text-base font-medium bg-white z-10 top-[-0.75rem] left-3 text-[#175b66]">
                            Date of Birth
                        </span>
                        <Form.Item name="dob" className="w-full">
                            <DatePicker
                                format="MM/DD/YYYY"
                                className="w-full md:h-12 h-10 border border-[#7FE8EE] p-1 rounded-lg outline-none"
                                onChange={handleDob}
                                disabledDate={(current) => current && current.valueOf() > Date.now()}
                            />
                        </Form.Item>
                    </div> */}

                    {/* Gender Field */}
                    <Form.Item name="gender" className="md:w-4/5 w-full">
                        <div>
                            <Radio.Group className="flex gap-4">
                                <Radio className="text-[#F49D15] font-medium" value="M">Male</Radio>
                                <Radio className="text-[#F49D15] font-medium" value="F">Female</Radio>
                            </Radio.Group>
                        </div>
                    </Form.Item>

                    {/* Registration Fee Checkbox */}

                   {/*
                   <div className="md:w-4/5 w-full">
                        <span className="md:text-lg text-base font-medium bg-white z-10 top-[-0.75rem] left-3 text-[#175b66]">
                            Would you like to register
                        </span>
                        <div className="flex items-center gap-2">
                            <Checkbox checked={slotChecked} onChange={handleSlot}>
                                {slotChecked ? 'Yes' : 'No'}
                            </Checkbox>
                        </div>
                    </div>
                    \*} 

                    {/* Registration Fee Input Field */}
                    {slotChecked && (
                        <div className="relative md:w-4/5 w-full mt-4">
                            <span className="absolute md:text-lg text-base font-medium bg-white z-10 top-[-0.75rem] left-3 text-[#175b66]">
                                Registration Fee
                            </span>
                            <Form.Item name="regfee" className="w-full">
                                <InputNumber
                                    disabled={true}
                                    controls={false}
                                    min={0}
                                    className="w-full md:h-12 h-10 border border-[#7FE8EE] p-1 rounded-lg outline-none"
                                />
                            </Form.Item>
                        </div>
                    )}


                </div>
            </Form>

            {/* Buttons */}
            <div className="flex justify-end items-center p-2 gap-3">
                <button
                    onClick={handleCancel}
                    className="md:min-w-28 w-[6rem] md:w-1/6 md:p-3 p-1 bg-white rounded-lg border text-primaryColor border-primaryColor font-semibold custom-button-shadow"
                >
                    Cancel
                </button>
                <button
                    onClick={handlePatientCreate}
                    disabled={disable}
                    className="md:min-w-28 w-[6rem] md:w-1/6 md:p-3 p-1 bg-primaryColor text-white rounded-lg font-semibold disabled:cursor-not-allowed disabled:opacity-50"
                >
                    Save
                </button>
            </div>
        </div>



    )
}

export default PatientReg