import http from "./http_common";

class VitalService {
  queryString(payload) {
    return Object.keys(payload)
      .map((key) => key + "=" + payload[key])
      .join("&");
  }

  VitalMasterlist(payload) {
    var queryString = this.queryString(payload);
    return http.get(`vitalMaster/list/filter?${queryString}`);
  }
  AddVitals(data) {
    return http.post(`patientVitals/BatchSave/`, data);
  }
  VitalsUpdate(data) {
    return http.put(`patientVitals/BatchUpdate`, data);
  }
  getVitalsData(payload) {
    var queryString = this.queryString(payload);
    return http.get(`patientVitals/list/filter?${queryString}`);
  }
  getVitalDateTime(payload) {
    var queryString = this.queryString(payload);
    return http.get(`patientVitals/list/recordedVitalDateTime?${queryString}`);
  }
  getVitalsRecentData(payload) {
    // var queryString = this.queryString(payload);
    return http.get(`patientVitals/list/recentRecords/${payload}`);
  }
}

export default new VitalService();
