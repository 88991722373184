import React, { useState } from "react";
import doctorimage from "../../assets/images/DoctorCall.png";
import { HiMicrophone } from "react-icons/hi";
import { MdOutlineVideocam } from "react-icons/md";
import { BsChatDots } from "react-icons/bs";
import { ImEqualizer2 } from "react-icons/im";

function Sharecallpatient() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    prescription: false,
    medicalHistory: false,
    reports: false,
  });

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedOptions((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log("Selected options:", selectedOptions);
    handleCloseModal();
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-sky-300 p-4">
      <div className="bg-white p-6 rounded-lg shadow-2xl w-full max-w-md">
        <div>
        <h2 className="text-xl font-bold mb-2 text-center">
          Are you ready to join?
        </h2>
        <h6 className="text-center font-medium">
        Hi, Kindly choose a quiet, comfortable place with good internet connectivity before your consultation begins
        </h6>
        <h6 className="text-center font-medium mt-2 text-gray-500">
        Please share your medical records by clicking the below button.
        </h6>
        <div className="flex justify-center">
        <button
          className="text-center mb-4 cursor-pointer font-semibold mt-3 border px-2 py-2 rounded-lg bg-blue-600 text-white"
          onClick={handleOpenModal}
        >
          Share Medical Records
        </button>
        </div>
        {/* <p className="text-lg font-medium">Enter the Name</p> */}
        <div className="flex gap-5 mt-2 justify-center">
          <input type="text" className="border p-2 rounded-lg w-80 outline-none" placeholder="Enter Name"/>
          {/* <button className="border rounded-lg bg-blue-600 text-white p-2 w-20 h-10">Submit</button> */}
        </div>
        </div>
        <div className="flex justify-center mb-4 mt-3">
          <img
            src={doctorimage}
            alt="User"
            className="rounded-lg w-full h-60"
          />
        </div>
        <div className="flex justify-evenly mb-4">
          <button className="flex items-center justify-center w-12 h-12 bg-gray-200 rounded-full">
            <HiMicrophone className="w-5 h-5" />
          </button>
          <button className="flex items-center justify-center w-12 h-12 bg-gray-200 rounded-full">
            <MdOutlineVideocam className="w-5 h-5" />
          </button>
          <button className="flex items-center justify-center w-12 h-12 bg-gray-200 rounded-full">
            <BsChatDots className="w-5 h-5" />
          </button>
          <button className="flex items-center justify-center w-12 h-12 bg-gray-200 rounded-full">
            <ImEqualizer2 className="w-5 h-5" />
          </button>
        </div>
        <p className="text-center mb-4 font-bold">
        Consultation with Dr Name
        </p>
        <div className="flex justify-between gap-3">
          <button className="px-4 py-2 bg-red-600 text-white rounded-md w-1/2">
            Leave
          </button>
          <button className="px-4 py-2 bg-blue-600 text-white rounded-md w-1/2">
            Join now
          </button>
        </div>
      </div>

      {modalIsOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-2xl w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Share Medical Records</h2>
            <form onSubmit={handleFormSubmit}>
              <div className="mb-2">
                <input
                  type="checkbox"
                  name="prescription"
                  checked={selectedOptions.prescription}
                  onChange={handleCheckboxChange}
                />
                <label className="ml-2 cursor-pointer font-medium">Prescription</label>
              </div>
              <div className="mb-2">
                <input
                  type="checkbox"
                  name="medicalHistory"
                  checked={selectedOptions.medicalHistory}
                  onChange={handleCheckboxChange}
                />
                <label className="ml-2 cursor-pointer font-medium">Medical History</label>
              </div>
              <div className="mb-2">
                <input
                  type="checkbox"
                  name="reports"
                  checked={selectedOptions.reports}
                  onChange={handleCheckboxChange}
                />
                <label className="ml-2 cursor-pointer font-medium">Vitals</label>
              </div>
              <div className="mb-2">
                <input
                  type="checkbox"
                  name="vitals"
                  checked={selectedOptions.reports}
                  onChange={handleCheckboxChange}
                />
                <label className="ml-2 cursor-pointer font-medium">Reports</label>
              </div>
              <div className="flex justify-end gap-3">
                <button type="button" onClick={handleCloseModal} className="px-4 py-2 bg-red-500 text-white rounded-md">
                  Close
                </button>
                <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-md mr-2">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sharecallpatient;
