import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import VitalService from "../../../Services/VitalService";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "white",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      >
        <p style={{ margin: 0, color: "black" }}>{`${label}`}</p>
        {payload.map((entry, index) => (
          <p key={index} style={{ margin: 0, color: entry.color }}>
            {`${entry.name}: ${entry.value}`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

function VitalChart(props) {
  const colors = ["rgb(54, 162, 235)", "rgb(255, 99, 132)"];
  const [vitalsData, setVitalsData] = useState([]); // For BPS
  const [vitalsData1, setVitalsData1] = useState([]); // For BPD
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;

  const MyVitalList = () => {
    let payload = {
      patientId: patientCode,
      vitalCodes: props.vitalCode !== "BP" ? props.vitalCode : "BPS",
    };

    if (props.vitalCode === "BP") {
      let payload1 = {
        patientId: patientCode,
        vitalCodes: "BPD",
      };

      Promise.all([VitalService.getVitalsData(payload), VitalService.getVitalsData(payload1)])
        .then(([bpsResult, bpdResult]) => {
          setVitalsData(bpsResult?.data?.slice(0, 5)); // For BPS
          setVitalsData1(bpdResult?.data?.slice(0, 5)); // For BPD
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      VitalService.getVitalsData(payload)
        .then((result) => {
          setVitalsData(result?.data.slice(0, 5));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    MyVitalList();
  }, []);

  // Combine BPS and BPD data into a single dataset for BP
  let combinedData = [];
  if (props.vitalCode === "BP") {
    const bpsData = vitalsData.map((d) => ({
      date: moment(d.recordedDateTime).format("MM/DD"),
      BPS: d.value,
    }));

    const bpdData = vitalsData1.map((d) => ({
      date: moment(d.recordedDateTime).format("MM/DD"),
      BPD: d.value,
    }));

    // Merge BPS and BPD data by date
    combinedData = bpsData.map((bps) => {
      const bpd = bpdData.find((bpd) => bpd.date === bps.date);
      return {
        date: bps.date,
        BPS: bps.BPS,
        BPD: bpd ? bpd.BPD : null, // Ensure data exists for BPD
      };
    });
    combinedData = combinedData.reverse();
  } else {
    combinedData = vitalsData.map((d) => ({
      date: moment(d.recordedDateTime).format("MM/DD"),
      value: d.value,
    })).reverse(); // Reverse for other vitals too
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-11/12 md:w-1/3 my-6 mx-auto max-w-3xl">
          <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="rounded-lg shadow-lg bg-brand-white w-full h-112 p-5 antialiased justify-between border border-gray-200">
              <div className="flex w-auto justify-between mb-4">
                <p className="text-medium font-medium text-2xl">
                  {props.title}
                </p>
                <div className="w-8 h-8 cursor-pointer">
                  <CloseOutlined onClick={props.closePopup} />
                </div>
              </div>
              <div>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart
                    data={combinedData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />

                    {/* Line for BP (BPS and BPD) */}
                    {props.vitalCode === "BP" && (
                      <>
                        <Line
                          type="monotone"
                          dataKey="BPS"
                          stroke={colors[0]}
                          name="BPS"
                        />
                        <Line
                          type="monotone"
                          dataKey="BPD"
                          stroke={colors[1]}
                          name="BPD"
                        />
                      </>
                    )}

                    {/* Single Line for other vitals */}
                    {props.vitalCode !== "BP" && (
                      <Line
                        type="monotone"
                        dataKey="value"
                        stroke={colors[0]}
                        name={props.title}
                      />
                    )}
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
}

export default VitalChart;
