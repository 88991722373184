import http from "./http_common";

class LabService {
  queryString(payload) {
    return Object.keys(payload)
      .map((key) => key + "=" + payload[key])
      .join("&");
  }

  getLabReportData(payload) {
    var queryString = this.queryString(payload);
    return http.get(`labTestOrderResult/getall-labresults?${queryString}`);
  }
}

export default new LabService();
