import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../DashBoardComponents/Navbar";
import Footer from "../../DashBoardComponents/Footer";
import { useNavigate } from "react-router-dom";
import Doctor1 from "../../assets/images/Doctor1.png";
import doctorIcon from "../../assets/images/Svg/doctorIcon.svg";
import Location from "../../assets/images/location.png";
import Videocall from "../../assets/images/video-call-48.png";
import { FaAngleDown, FaAngleUp, FaVideo } from "react-icons/fa";
import { Button, Tooltip } from "react-bootstrap";
import {
  MdKeyboardArrowRight,
  MdLocationOn,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";
import { Drawer, Flex, Input, InputNumber, Select, Spin } from "antd";
import { IoEllipseSharp, IoLocationSharp, IoSearch } from "react-icons/io5";
import UserService from "../../Services/UserService";
import AppointmentService from "../../Services/AppointmentService";
import { changeIntoString, generateDates, generateTimeSlots, monthChecker } from "../../utils/dates";
import moment from "moment";
import nodatafound from "../../assets/images/Svg/No data-found.svg";
import { FiMapPin } from "react-icons/fi";
import Login from "../Login";
import { APP_ROUTES } from "../../PageRouting/pageRoutes";
import Layout from "../../Layout";
import { convertTo24HourFormat } from "../../utils/convertionFunc";
import AppointmentSideDrawer from "./AppointmentSideDrawer";

function ConsultDoctor() {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isLoginDrawerOpen, setIsLoginDrawerOpen] = useState(false);
  const [isDrawerVisible2, setIsDrawerVisible2] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedDoctorSlots, setSelectedDoctorSlots] = useState(null);
  const [morningSlots, setMorningSlots] = useState([]);
  const [slotVisible, setSlotVisible] = useState(false);
  const [eveningSlots, setEveningSlots] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [activeTab, setActiveTab] = useState("digitalConsult");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [slotsLoader, setSlotsLoader] = useState({ loadState: false, user: '' });
  const [dates, setDates] = useState([]);
  const [lastDate, setLastDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [signUpView, setSignUpView] = useState(false);
  const [patientMobileNumber, setPatientMobileNumber] = useState('');
  const [authOtp, setAuthOtp] = useState('');
  const [appointmentPayload, setAppointmentPayload] = useState(null);
  const [isCitiesOpen, setIsCitiesOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const [doctorSpecialties, setDoctorSpecialties] = useState([]);
  const navigate = useNavigate();
  const containerRef = useRef(null);

  useEffect(() => {
    getDoctorsList();
    getSpecialities();
    const datesArr = generateDates(new Date(), 30);
    setDates(datesArr);
    setCurrentMonth(datesArr[0].month);
  }, []);


  const handleSelectClick = () => {
    setIsCitiesOpen(!isCitiesOpen);
  };

  const handleCitySelect = (city) => {
    setSelectedCity(city);
    setIsCitiesOpen(false);
  };

  const getDoctorsList = async () => {
    const userPayload = { displayInWebsite: 'Y', status: 1 };
    setIsLoading(true);
    try {
      const response = await UserService.getDoctors(userPayload);
      const filterDoctors = response?.data?.filter((item) => item.userType === 'P');
      setDoctors(filterDoctors);
      setIsLoading(false);
    }
    catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const getSpecialities = async () => {
    let payload = {
      status: 1
    }
    try {
      const response = await UserService.getDoctorSpeciality(payload);
      if (response) {
        setDoctorSpecialties(response.data);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleRoute = (route, payload) => {
    navigate(route, { state: { appointmentPayload: appointmentPayload ? appointmentPayload : payload } });
  };

  const handleDoctorDetailsPage = (doctor) => {
    navigate(APP_ROUTES.DOCTORDETAILS, { state: { selectedDoctor: doctor } });
  }


  const scrollLeft = () => {
    containerRef.current.scrollBy({ left: -100, behavior: "smooth" });
  };

  const scrollRight = () => {
    containerRef.current.scrollBy({ left: 100, behavior: "smooth" });
  };

  const showDrawer = (doctor) => {
    setSelectedDoctor(doctor);
    fetchSlots(doctor.userId);
    setIsDrawerVisible(true);
  };

  const handleSelected = (date) => {
    setSelectedDate(date)
  }

  const handleSelectedSlot = (slot) => {
    setSelectedSlot(slot);
  }

  const fetchSlots = async (doctorId) => {
    const payload = {
      userId: doctorId,
      status: 1
    }
    setSlotsLoader({
      ...slotsLoader,
      loadState: true,
      user: doctorId
    });
    try {
      const response = await AppointmentService.UserSavedTemplates(payload);
      if (response.data && response.data.length !== 0) {
        setSelectedDoctorSlots(response.data);
        const slicedDate = Number(selectedDate.slice(3, 4));
        const timeSlots = generateTimeSlots(slicedDate, response.data[0].fromTime, response.data[0].toTime);
        setMorningSlots(timeSlots.morningSlots);
        setEveningSlots(timeSlots.eveningSlots);
        setSlotsLoader({
          ...slotsLoader,
          loadState: false,
        });

      }
      else {
        setMorningSlots([]);
        setEveningSlots([]);
        setSlotsLoader({
          ...slotsLoader,
          loadState: false,
        });
      }
    }
    catch (e) {
      setSlotsLoader({
        ...slotsLoader,
        loadState: false,
      });
      console.log(e);
    }
  }

  const handleScrollOnButton = (dateObj) => {
    console.log(dateObj)
  }

  const handleScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
    if (scrollLeft + clientWidth >= scrollWidth - 5) {
      loadMoreDates();
    }
    // const firstVisibleIndex = Math.floor(scrollLeft / 40); // Approximate width of each button
    // const visibleDate = dates[firstVisibleIndex];
    // if (visibleDate) {
    //   setCurrentMonth(visibleDate.month);
    // }
  };

  const handleSelectDate = (dateObj) => {
    setSelectedDate(dateObj.fullDate);
    const selectedDate = moment(dateObj.fullDate, 'MM/DD/YYYY', true);
    const fromDate = moment(selectedDoctorSlots[0]?.fromDate, 'MM/DD/YYYY', true);
    const toDate = moment(selectedDoctorSlots[0]?.toDate, 'MM/DD/YYYY', true);
    const isSelectedDateinSlotRange = selectedDate.isSameOrAfter(fromDate) && selectedDate.isSameOrBefore(toDate);

    if (isSelectedDateinSlotRange) {
      const slicedDate = Number(dateObj.fullDate.slice(3, 5));
      const timeSlots = generateTimeSlots(slicedDate, selectedDoctorSlots[0].fromTime, selectedDoctorSlots[0].toTime);
      setMorningSlots(timeSlots.morningSlots);
      setEveningSlots(timeSlots.eveningSlots);
      setSlotVisible(true);
    } else {
      setSlotVisible(false);
    }
  };


  const loadMoreDates = () => {
    const newLastDate = new Date(lastDate);
    newLastDate.setDate(lastDate.getDate() + 30);

    const newDates = generateDates(newLastDate, 30);
    setDates((prevDates) => [...prevDates, ...newDates]);
    setLastDate(newLastDate);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
    setSlotVisible(false);
    setSelectedDoctorSlots(null)
  };

  const handleContinueBooking = (selectedDate, selectedSlot) => {
    console.log('hi  ')
    if (!selectedDate || !selectedSlot) {
      setErrorMessage('Please select Date and slot!');
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return;
    }
    const slotbookingPayload = {
      selectedDate,
      selectedSlot,
      selectedDoctor
    }

    const patientjson = localStorage.getItem('patientData');
    const patientObj = patientjson ? JSON.parse(patientjson) : null;

    setAppointmentPayload(slotbookingPayload);
    setIsDrawerVisible(false);
    if (patientObj) {
      setSelectedDate("");
      setSelectedSlot("");
      handleRoute(APP_ROUTES.CHECKOUT, slotbookingPayload);
    }
    else {
      setIsLoginDrawerOpen(true);
    }
  };

  const handleCheckout = () => {
    setIsLoginDrawerOpen(false);
    handleRoute(APP_ROUTES.CHECKOUT, appointmentPayload);
  }

  const handleSelectedTimeSlot = (selectedSlot) => {
    const formattedTime = convertTo24HourFormat(selectedSlot);
    setSelectedSlot(formattedTime);
  }

  const closeDrawer1 = () => {
    setIsLoginDrawerOpen(false);
  };

  const handleMobileNumber = (value) => {

    setPatientMobileNumber(value);
  }

  const handleSignupView = () => {
    setSignUpView(true);
  }
  const handleLoginView = () => {
    setSignUpView(false);
  }

  const handleLogin = () => {

    if (!patientMobileNumber) {
      setErrorMessage('Please enter registered mobile number');
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
      return;

    }
    else if (patientMobileNumber) {
      console.log(typeof patientMobileNumber, patientMobileNumber)
      const isValid = (String(patientMobileNumber).length > 10 || String(patientMobileNumber).length < 10);
      if (isValid) {
        setErrorMessage('Please enter a valid mobile number');
        setTimeout(() => {
          setErrorMessage('');
        }, 2000);
        return;
      }
      else {
        setIsLoginDrawerOpen(false);
        setIsDrawerVisible2(true);
      }

    }



  };

  const isSlotEnabled = (slotTime) => {
    console.log(slotTime)
    const slotTimeOnly = slotTime.split(' ')[0];
    const hour = Number(slotTimeOnly.split(':')[0]);
    const min = Number(slotTimeOnly.split(':')[1]);
    const currHour = Number(new Date().getHours());
    const currMin = Number(new Date().getMinutes());
    console.log(currHour, currMin, hour, min);
    if (hour < currHour && min < currMin) {
      return false;
    }
    return true;
  }

  const handleSignUp = () => {

    if (!patientMobileNumber) {
      setErrorMessage('Please enter a mobile number');
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
      return;

    }
    else if (patientMobileNumber) {
      console.log(typeof patientMobileNumber, patientMobileNumber)
      const isValid = (String(patientMobileNumber).length > 10 || String(patientMobileNumber).length < 10);
      if (isValid) {
        setErrorMessage('Please enter a valid mobile number');
        setTimeout(() => {
          setErrorMessage('');
        }, 2000);
        return;
      }
      else {
        setIsLoginDrawerOpen(false);
        setIsDrawerVisible2(true);
      }

    }



  };


  const DoctorCard = ({ doctor, key }) => (
    <div key={key} className="mt-5 gap-5 p-5 min-h-[215px] bg-white shadow-md border rounded-lg">
      <div className="flex justify-center gap-4">
        <div className="flex justify-center mt-3 items-center w-[90px] h-[90px] p-2 rounded-lg hover:-translate-y-4 duration-700 hover:scale-110">
          {
            doctor.imageName ? (
              <img
                src={process.env.REACT_APP_IMAGE_URL + doctor.imageName}
                alt={doctor?.firstName}
                className="h-20 w-20"
                onClick={handleDoctorDetailsPage}
              />
            ) : (
              <img
                src={doctorIcon}
                alt={doctor?.userName}
                className="h-20 w-20"
                onClick={handleDoctorDetailsPage}
              />
            )
          }
        </div>
        <div className="w-full">
          <div className="w-full flex justify-center items-center sm:justify-between">
            <h2 className="w-[90%] text-xl font-semibold">{`${doctor?.salutation} ${doctor?.firstName}`}</h2>
            {/* <img src={doctor.videoCallIcon} alt="" className="h-8 w-8" /> */}
            <Tooltip content="Video Call">
              <FaVideo className="h-4 w-4 text-green-800 cursor-pointer" />
            </Tooltip>
          </div>
          <p className="text-sm text-gray-500">{doctor.qualification}</p>
          <p className="text-sm text-gray-500">{doctor.speciality}</p>
          <div className="flex gap-6 items-center justify-between">
            <div className="flex text-[12px]">
              {/* <img src={doctor.locationIcon} alt="" className="h-4 w-4 mr-1" /> */}
              <MdLocationOn className="h-4 w-4 mr-1 text-[#002F72]" />

              <p>{doctor.city}</p>
            </div>
            <p className="font-bold text-lg">₹{doctor.videoFee}</p>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-3">
        <button
          className="hover:bg-[#2d2bb7] border-2 py-1 px-2 rounded-md text-[#002F72] hover:text-white font-sm text-[14px] shadow-xl hover:scale-75 duration-500"
          onClick={() => handleDoctorDetailsPage(doctor)}
        >
          View Profile
        </button>
        <button
          disabled={slotsLoader.loadState && doctor.userId === slotsLoader.user}
          className="border-2 py-1 px-2 rounded-md text-[#002F72] font-sm shadow-lg disabled:opacity-50"
          onClick={() => showDrawer(doctor)}
        >
          Book Consultation
        </button>
      </div>
    </div>
  );

  return (
    <>
      <Layout>
        <div>
          <div className="container w-[90%] mx-auto">
            {/* <h2
            className="md:text-[40px] text-xl text-[#023584] text-center mb-4 "
            style={{ fontWeight: 700 }}
          >
            Book Your <span className="text-[#266AFB]">Appointment</span>
          </h2> */}
            {/* <h2>{showAll ? "s": "g" }</h2> */}
            {/* {showAll ? (
            <h2
              className="md:text-[40px] text-xl text-[#002F72] text-center mb-4 "
              style={{ fontWeight: 700 }}
            >
              Doctors List
            </h2>
          ) : (
            <h2
              className="md:text-[40px] text-xl text-[#002F72] text-center mb-4 "
              style={{ fontWeight: 700 }}
            >
              Book Your Appointment
            </h2>
          )} */}
            <div className="flex justify-center items-center">
              <div className="flex items-center border border-gray-300 rounded-lg overflow-hidden w-full max-w-md">
                <FiMapPin className="w-5 h-5 ml-2 text-green-500" />
                <div className="flex flex-1 items-center justify-between">
                  <span className="px-3 py-2 bg-white text-gray-700 focus:outline-none cursor-pointer" onClick={handleSelectClick}
                  >
                    {selectedCity ? selectedCity : 'Select'}
                  </span>
                  {isCitiesOpen ? <FaAngleUp /> : <FaAngleDown />}
                </div>

                <div class="relative w-full">
                  <input
                    type="text"
                    placeholder=""
                    className="block w-full rounded-2xl  bg-transparent py-2 pl-6 pr-20 outline-none"
                  />
                  <div className="absolute inset-y-1 right-1 flex justify-end">
                    <button
                      type="submit"
                      className="flex aspect-square h-full items-center justify-center rounded-xl bg-blue-500 text-white transition"
                    >
                      <IoSearch />

                    </button>
                  </div>
                </div>
              </div>
            </div>

            {isCitiesOpen && (
              <div className="mt-4 p-4 bg-white shadow-lg rounded-lg w-full">
                <h3 className="font-semibold text-lg mb-3">Popular Cities</h3>
                <div className="flex flex-wrap gap-3">
                  {["Chennai", "Noida", "Mumbai", "Kolkata", "Bangalore", "Hyderabad", "All Cities"].map((city, index) => (
                    <button
                      key={index}
                      onClick={() => handleCitySelect(city)}
                      className={`px-4 py-2 rounded-full border ${city === selectedCity
                        ? "bg-green-500 text-white"
                        : "bg-white text-green-500 border-green-500"
                        } shadow-sm cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed`}
                      disabled={city !== 'Chennai' && city !== 'Noida'}
                    >
                      {city}
                    </button>
                  ))}
                </div>
              </div>
            )}

            <div className="w-full p-2 bg-cyan-100 mt-5 rounded-lg">
              <div className="flex gap-10">
                <Select
                  className="w-[20%] rounded-lg cursor-pointer bg-white"
                  placeholder="Speciality"
                  allowClear
                  showSearch
                >
                  {doctorSpecialties?.map((spec) => (
                    <Select.Option value={spec.speciality}>
                      {spec.speciality}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  className="w-[20%] rounded-lg cursor-pointer bg-white disabled:opacity-50 disabled:cursor-not-allowed"
                  placeholder="Visit"
                  allowClear
                  showSearch
                  disabled
                >

                </Select>
              </div>
            </div>
            <div>
              {isLoading && doctors?.length === 0 ?
                (
                  <div className="flex items-center justify-center mt-[10%]">
                    <Flex align="center" gap="middle">
                      <Spin size="large" tip="Loading" />
                    </Flex>
                  </div>
                )
                :
                !isLoading && doctors?.length !== 0 ?
                  (
                    <div className="grid grid-cols-3 gap-4">
                      {doctors
                        .slice(0, showAll ? doctors.length : 6)
                        .map((doctor, index) => (
                          <DoctorCard key={index} doctor={doctor} />
                        ))}
                    </div>
                  )
                  :
                  (
                    <div className="flex flex-col items-center justify-center">
                      <img src={nodatafound} alt="nodatafound" className="h-min w-min" />
                      <p className="font-medium text-xl">No data found!!</p>
                    </div>
                  )}
              {!isLoading && (<div className="w-full flex justify-center mt-5">
                <button
                  onClick={() => setShowAll(!showAll)}
                  className="bg-[#002F72] hover:bg-[#2d2bb7] py-1 px-3 rounded-md text-white font-sm text-[14px] shadow-xl  border-white hover:scale-75 duration-500"
                >
                  {showAll ? "See Less" : "See All"}
                </button>
              </div>)}
            </div>
          </div>
        </div>
        <Drawer
          title={
            <span className="font-bold text-lg text-center flex justify-center">
              Schedule Appointment
            </span>
          }
          placement="right"
          width={400}
          onClose={closeDrawer}
          open={isDrawerVisible && !slotsLoader.loadState}
          destroyOnClose
        >
          <AppointmentSideDrawer
            selectedDoctor={selectedDoctor}
            selectedDoctorSlots={selectedDoctorSlots}
            handleContinueBooking={handleContinueBooking}
            errorMessage={errorMessage}
            handleSelected={handleSelected}
            selectedDate={selectedDate}
            handleSelectedSlot={handleSelectedSlot}
            selectedSlot={selectedSlot}
          />

        </Drawer>
        <Drawer
          title={<span className="font-bold text-lg text-center flex justify-center">
          </span>}
          placement="right"
          width={400}
          onClose={closeDrawer1}
          open={isLoginDrawerOpen}
          destroyOnClose
        >
          <Login doctor={selectedDoctor} handleCheckout={handleCheckout} />
        </Drawer>
      </Layout>
    </>
  );
}

export default ConsultDoctor;
