import React, { useEffect, useRef, useState } from "react";
import Doctor1 from "../../assets/images/Doctor1.png";
import Navbar from "../../DashBoardComponents/Navbar";
import Footer from "../../DashBoardComponents/Footer";
import {
  MdKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { changeIntoString, generateDates, generateTimeSlots } from "../../utils/dates";
import doctorIcon from "../../assets/images/Svg/doctorIcon.svg";
import moment from "moment";
import AppointmentService from "../../Services/AppointmentService";
import { Drawer, Flex, InputNumber, message, Spin } from "antd";
import { APP_ROUTES } from "../../PageRouting/pageRoutes";
import Login from "../Login";
import Layout from "../../Layout"
import { convertTo24HourFormat } from "../../utils/convertionFunc";

function BookConsultant() {
  const [activeTab, setActiveTab] = useState("digitalConsult");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [dates, setDates] = useState([]);
  const [selectedDoctorSlots, setSelectedDoctorSlots] = useState(null);
  const [slotsLoader, setSlotsLoader] = useState(false);
  const [morningSlots, setMorningSlots] = useState([]);
  const [slotVisible, setSlotVisible] = useState(false);
  const [eveningSlots, setEveningSlots] = useState([]);
  const [lastDate, setLastDate] = useState(new Date());
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [appointmentPayload, setAppointmentPayload] = useState(null);
  const [isLoginDrawerOpen, setIsLoginDrawerOpen] = useState(false);
  const [isDrawerVisible2, setIsDrawerVisible2] = useState(false);
  const [patientMobileNumber, setPatientMobileNumber] = useState('');
  const [authOtp, setAuthOtp] = useState('');
  const { state } = useLocation();
  const navigate = useNavigate();
  const testOtp = '123456';


  useEffect(() => {
    console.log(state)
    fetchSlots(state?.selectedDoctor?.userId);
    setSelectedDoctor(state?.selectedDoctor);
  }, [state]);

  useEffect(() => {
    const datesArr = generateDates(new Date(), 30);
    setDates(datesArr);
    if (datesArr && datesArr.length !== 0) {
      handleSelectDate(datesArr[0]);
    }
  }, [selectedDoctorSlots]);


  const handleCloseLogin = () => {
    setIsLoginDrawerOpen(false);
  };

  const handleCheckout = () => {
    setIsLoginDrawerOpen(false);
    handleRoute(APP_ROUTES.CHECKOUT, appointmentPayload);
  }

  const closeDrawer2 = () => {
    setIsDrawerVisible2(false);
  };

  const handleMobileNumber = (value) => {
    setPatientMobileNumber(value);
  }

  const handleEnteredOtp = (e) => {
    const value = e.target.value;
    setAuthOtp(value);
  }

  const handleVerifyOtp = () => {
    console.log(authOtp, testOtp)
    if (authOtp !== testOtp) {
      setErrorMessage('Invalid OTP number!!');
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
      return;
    }
    handleRoute('/checkout')
  }

  const handleLogin = () => {

    if (!patientMobileNumber) {
      setErrorMessage('Please enter registered mobile number');
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
      return;

    }
    else if (patientMobileNumber) {
      console.log(typeof patientMobileNumber, patientMobileNumber)
      const isValid = (String(patientMobileNumber).length > 10 || String(patientMobileNumber).length < 10);
      if (isValid) {
        setErrorMessage('Please enter a valid mobile number');
        setTimeout(() => {
          setErrorMessage('');
        }, 2000);
        return;
      }
      else {
        setIsLoginDrawerOpen(false);
        setIsDrawerVisible2(true);
      }

    }



  };


  const fetchSlots = async (doctorId) => {
    const payload = {
      userId: doctorId,
      status: 1
    }
    setSlotsLoader(true);
    try {
      const response = await AppointmentService.UserSavedTemplates(payload);
      console.log(response.data);
      if (response.data && response.data.length !== 0) {
        setSelectedDoctorSlots(response.data);
        const timeSlots = generateTimeSlots(response.data[0].fromTime, response.data[0].toTime);
        setMorningSlots(timeSlots.morningSlots);
        setEveningSlots(timeSlots.eveningSlots);
        console.log(timeSlots.morningSlots, timeSlots.eveningSlots);
        setSlotsLoader(false);
      }
      else {
        setMorningSlots([]);
        setEveningSlots([]);
        setSlotsLoader(false);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleSelectedTimeSlot = (selectedSlot) => {
    const formattedTime = convertTo24HourFormat(selectedSlot);
    setSelectedSlot(formattedTime);
  }

  const handleRoute = (route, payload) => {
    navigate(route, { state: { appointmentPayload: payload } });
  };

  const handleContinueBooking = () => {
    if (!selectedDate || !selectedSlot) {
      message.error('Please select Date and slot!');
      return;
    }
    const slotbookingPayload = {
      selectedDate,
      selectedSlot,
      selectedDoctor
    }

    setAppointmentPayload(slotbookingPayload);
    const patientjson = localStorage.getItem('patientData');
    const patientObj = patientjson ? JSON.parse(patientjson) : null;

    if (patientObj) {
      handleRoute(APP_ROUTES.CHECKOUT, slotbookingPayload);
    }
    else {
      setIsLoginDrawerOpen(true);
    }


  };

  const handleScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
    if (scrollLeft + clientWidth >= scrollWidth - 5) {
      loadMoreDates();
    }
    // const firstVisibleIndex = Math.floor(scrollLeft / 40); // Approximate width of each button
    // const visibleDate = dates[firstVisibleIndex];
    // if (visibleDate) {
    //   setCurrentMonth(visibleDate.month);
    // }
  };

  const loadMoreDates = () => {
    const newLastDate = new Date(lastDate);
    newLastDate.setDate(lastDate.getDate() + 30);

    const newDates = generateDates(newLastDate, 30);
    setDates((prevDates) => [...prevDates, ...newDates]);
    setLastDate(newLastDate);
  };

  const handleSelectDate = (dateObj) => {
    setSelectedDate(dateObj.fullDate);
    if (selectedDoctorSlots) {
      const isSelectedDateinSlotRange = moment(dateObj.fullDate).isSameOrAfter(selectedDoctorSlots[0]?.fromDate) && moment(dateObj.fullDate).isSameOrBefore(selectedDoctorSlots[0]?.toDate);
      if (isSelectedDateinSlotRange) {
        const slicedDate = Number(dateObj.fullDate.slice(3, 5));
        const timeSlots = generateTimeSlots(slicedDate, selectedDoctorSlots[0].fromTime, selectedDoctorSlots[0].toTime);
        setMorningSlots(timeSlots.morningSlots);
        setEveningSlots(timeSlots.eveningSlots);
        console.log(timeSlots.eveningSlots);
        setSlotVisible(true);
      }
      else {
        setSlotVisible(false);
        setMorningSlots([]);
        setEveningSlots([]);
      }
    }
  }

  const slots = ["03:00 PM", "03:15 PM", "03:30 PM", "03:45 PM"];
  const slots1 = ["09:00 AM", "09:15 AM", "09:30 AM", "09:45 AM"];

  const containerRef = useRef(null);

  const scrollLeft = () => {
    containerRef.current.scrollBy({ left: -100, behavior: "smooth" });
  };

  const scrollRight = () => {
    containerRef.current.scrollBy({ left: 100, behavior: "smooth" });
  };

  return (
    <>
      <Layout>
        {slotsLoader ? (
          <div className="flex items-center justify-center mt-[10%]">
            <Flex align="center" gap="middle">
              <Spin size="large" tip="Loading" />
            </Flex>
          </div>
        ) :
          (<div className="container mx-auto p-4">
            <div className="bg-white shadow-md rounded-lg p-6">
              <div className="flex">
                <div>
                  <div className="flex justify-between mx-4">
                    <div>
                      {
                        selectedDoctor?.imageName ? (
                          <img
                            src={process.env.REACT_APP_IMAGE_URL + selectedDoctor?.imageName}
                            alt="DoctorPic"
                            className="w-32 h-28 mx-auto rounded-sm"
                          />
                        ) : (
                          <img
                            src={doctorIcon}
                            alt="DoctorPic"
                            className="w-40 h-28 mx-auto rounded-sm"
                          />
                        )
                      }
                    </div>
                    <div>
                      <p className="text-[#002F72] text-2xl font-bold">
                        {`${selectedDoctor?.salutation}${selectedDoctor?.firstName} ${selectedDoctor?.lastName}`}
                      </p>
                      <p className="mt-1 text-base font-semibold text-gray-700">
                        {selectedDoctor?.speciality}
                      </p>
                      <p className="text-gray-700 mt-2">
                        <span className="font-semibold text-[#002F72]">
                          Qualification:
                        </span>{" "}
                        {selectedDoctor?.qualification}
                      </p>
                      {/* <p className="text-gray-700 mt-2">
                      <span className="font-semibold text-[#002F72]">
                        Experience:
                      </span>{" "}
                      10 Years
                    </p> */}
                      <p className="text-gray-700 mt-2">
                        <span className="font-semibold text-[#002F72]">
                          Location:
                        </span>{" "}
                        {selectedDoctor?.city}
                      </p>
                      {/* <p className="text-gray-700 mt-2">
                      <span className="font-semibold text-[#002F72]">
                        Language:
                      </span>{" "}
                      Tamil, English, Hindi, Telugu
                    </p> */}
                    </div>
                  </div>
                  <div className="mt-6">
                    <div className="mt-4">
                      <div className="mt-4">
                        <h2 className="text-xl font-semibold text-[#002F72]">
                          About the Doctor
                        </h2>
                        <p className="text-gray-700 mt-2">
                          {`${selectedDoctor?.salutation}.${selectedDoctor?.firstName} ${selectedDoctor?.lastName}`} is{selectedDoctor?.qualification} and fellowship in Advanced
                          {selectedDoctor?.speciality}. He is renowned {selectedDoctor?.speciality}
                          of {selectedDoctor?.city}. Currently, {`${selectedDoctor?.salutation}.${selectedDoctor?.firstName}`} is associated with Doxzon
                          Care.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[40%] mx-auto p-4 bg-white border rounded-lg shadow-md">
                  <div className="flex justify-between border-b pb-2 mb-4">
                    <button
                      onClick={() => setActiveTab("digitalConsult")}
                      className={`text-sm font-bold uppercase px-4 py-2 ${activeTab === "digitalConsult"
                        ? "border-b-2 border-blue-500 text-blue-600"
                        : "text-gray-600"
                        }`}
                    >
                      Video Consultation
                    </button>
                  </div>

                  <div>
                    <div className="flex justify-between items-center border-b pb-2 mb-4">
                      <div className="text-lg font-medium">Video Consultation</div>
                      <div className="text-lg font-semibold">₹{selectedDoctor?.videoFee}</div>
                    </div>

                    {/* Date Selection */}
                    <div className="w-full flex justify-evenly items-center gap-1 mb-4">
                      {/* Left Arrow */}
                      <div className="w-[10%]">
                        <MdOutlineKeyboardArrowLeft
                          onClick={scrollLeft}
                          className="w-10 h-10 text-[#3985f0] cursor-pointer"
                        />
                      </div>
                      {/* Date Container */}
                      <div
                        ref={containerRef}
                        onScroll={handleScroll}
                        className="flex items-center gap-3 overflow-x-auto scrollWidth"
                        style={{ scrollBehavior: "smooth" }}
                      >

                        {dates.map((dateObj, index) => (
                          <button
                            key={index}
                            onClick={() => handleSelectDate(dateObj)}
                            className={`px-3 py-2 border rounded-md ${selectedDate === dateObj.fullDate
                              ? "bg-blue-100 border-blue-500"
                              : "bg-white"
                              }`}
                          >
                            {dateObj.day} {dateObj.date}
                          </button>
                        ))}
                      </div>

                      {/* Right Arrow */}
                      <div className="w-[10%]">
                        <MdKeyboardArrowRight
                          onClick={scrollRight}
                          className="w-10 h-10 text-[#3985f0] cursor-pointer"
                        />
                      </div>
                    </div>
                    {selectedDate && (<div className="p-1 font-semibold text-lg">
                      {changeIntoString(selectedDate)}
                    </div>)}
                    {/* Slot Selection */}
                    <div className="w-full flex flex-col items-center border-t pt-4">
                      <div className="flex flex-col items-center mb-4">
                        <p className="text-blue-800 font-semibold mb-2">
                          MORNING SLOTS
                        </p>
                        {
                          slotVisible && morningSlots.length !== 0 ?
                            (
                              <div className="flex flex-wrap justify-between gap-2 w-full">
                                {morningSlots.map((slot, index) => (
                                  <button
                                    key={index}
                                    onClick={() => handleSelectedTimeSlot(slot)}
                                    className={`px-4 py-2 border rounded-md ${selectedSlot === convertTo24HourFormat(slot)
                                      ? "bg-blue-100 border-blue-500"
                                      : "bg-white"
                                      }`}
                                  >
                                    {slot}
                                  </button>
                                ))}
                              </div>
                            )
                            :
                            (
                              <div className="w-full h-[inherit] min-h-14 flex items-center justify-center">
                                <p className="font-semibold">No slots available</p>
                              </div>
                            )

                        }

                      </div>
                      <div className="flex flex-col items-center mb-4">
                        <p className="text-blue-800 font-semibold mb-2">
                          AFTERNOON SLOTS
                        </p>
                        {
                          slotVisible && eveningSlots.length !== 0 ?
                            (
                              <div className="flex flex-wrap justify-between gap-2 w-full">
                                {eveningSlots.map((slot, index) => (
                                  <button
                                    key={index}
                                    onClick={() => handleSelectedTimeSlot(slot)}
                                    className={`px-4 py-2 border rounded-md ${selectedSlot === convertTo24HourFormat(slot)
                                      ? "bg-blue-100 border-blue-500"
                                      : "bg-white"
                                      }`}
                                  >
                                    {slot}
                                  </button>
                                ))}
                              </div>
                            )
                            :
                            (
                              <div className="w-full h-[inherit] min-h-14 flex items-center justify-center">
                                <p className="font-semibold">No slots available</p>
                              </div>
                            )
                        }

                      </div>

                      {/* Continue Booking Button */}
                      <button onClick={handleContinueBooking} className="mt-4 w-full py-2 bg-blue-500 text-white font-semibold rounded-lg">
                        Continue Booking
                      </button>
                      {/* <p className="text-xs text-center text-gray-500 mt-2">
                        *Free chat follow-up for up to 7 days post consultation
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>)}
        <Drawer
          title={<span className="font-bold text-lg text-center flex justify-center">
          </span>}
          placement="right"
          width={400}
          onClose={handleCloseLogin}
          open={isLoginDrawerOpen}
        >
          {/* <div className="">
          <div>
            <p className="text-xl font-bold text-[#002f72]">Login</p>
            <InputNumber
              controls={false}
              maxLength={10}
              className="border rounded-md p-2 w-full mt-5 outline-none"
              onKeyDown={(event) => {
                const allowedKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight'];

                if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
                  event.preventDefault();  // Prevent non-numeric and non-control key input
                }
              }}
              onChange={handleMobileNumber} />
            <p className="text-[#002f72] text-xs font-medium mt-3">OTP will be send to this number by SMS</p>
            <div className="flex gap-2 items-center mt-5">
              <input
                type="checkbox"
                className="w-3 h-3 cursor-pointer"
              />
              <p className="text-gray-500 mt-3">Share health tips, appointment details and offers with me on Whatsup</p>
            </div>
            <p className="text-red-500">{errorMessage && errorMessage}</p>
            <div className="flex justify-center mt-7">
              <button onClick={handleLogin} className="border rounded-md p-2 w-[90%] bg-blue-500 text-white font-semibold text-base">Continue</button>
            </div>
            <div className="flex gap-2 mt-5 mx-2 ">
              <p className="text-[#002f72] text-sm ">Don't we have account?</p>
              <p className="font-bold text-[#002f72] underline cursor-pointer">Signup here</p>
            </div>
            <div className="mt-7 text-center">
              <p className="text-gray-500 text-sm">By clicking Continue, you agree to Doxzon 24/7's <br /><span className="text-[#002f72] font-semibold">Privacy Policy,Terms and Condition</span></p>
            </div>
          </div>
        </div> */}
          <Login doctor={selectedDoctor} handleCheckout={handleCheckout} />
        </Drawer>
        <Drawer
          title={<span className="font-bold text-lg text-center flex justify-center">
          </span>}
          placement="right"
          width={400}
          onClose={closeDrawer2}
          visible={isDrawerVisible2}
        >
          <div className="">
            <div>
              <p className="text-xl font-bold text-[#002f72]">Enter OTP</p>
              <p className="text-[#002f72] text-xs font-medium mt-3">Please enter the OTP sent to 9976685889</p>
              <input type="text" className="border rounded-md p-2 w-full mt-5 outline-none" onChange={handleEnteredOtp} />
              <div className="flex justify-start">
                <p className="text-xs font-semibold mt-3 cursor-pointer text-[#002f72]">RESEND OTP</p>
              </div>
              <p className="text-red-500">{errorMessage && errorMessage}</p>
              <div>
                <button className="border rounded-md p-2 w-full bg-blue-500 text-white font-semibold text-base mt-7" onClick={handleVerifyOtp}>Verify</button>
              </div>
            </div>
          </div>
        </Drawer>
      </Layout>
    </>
  );
}

export default BookConsultant;
