import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, message } from "antd";
import VitalService from "../../../Services/VitalService";
import moment from "moment";
import { useData } from "../../../Services/Context";

const AddVitals = (props) => {
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [vitalMasterData, setVitalMasterData] = useState([]);
  const { patientLoginData } = useData();

  const patientName = `${activePatient?.salutation || ""}${activePatient?.firstName || ""} ${activePatient?.lastName || ""}`.trim();

  const [formData, setFormData] = useState({
    bloodPressureSYS: 0,
    bloodPressureDIA: 0,
    pulseOx: 0,
    heartRate: 0,
    respirationRate: 0,
    Temperature: 0,
    Height: 0,
    Weight: 0,
    BMI: "",
  });

  const VitalMaster = () => {
    let payload = {
      patientId: patientCode,
      status: 1,
    };
    VitalService.VitalMasterlist(payload).then((result) => {
      setVitalMasterData(result.data);
    });
  };
  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };
  useEffect(() => {
    VitalMaster();
  }, []);

  const handleInputChange = (value, fieldName) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
    if (fieldName === "Height") {
      const Weight = formData["Weight"];
      const Height = value;
      if (Number(Height !== 0) && Number(Weight) !== 0) {
        const bmi = calculateBMI(Height, Weight);
        setFormData((prevFormData) => ({
          ...prevFormData,
          BMI: bmi,
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          BMI: "",
        }));
      }
    } else if (fieldName === "Weight") {
      const Height = formData["Height"];
      const Weight = value;
      if (Number(Height !== 0) && Number(Weight) !== 0) {
        const bmi = calculateBMI(Height, Weight);
        setFormData((prevFormData) => ({
          ...prevFormData,
          BMI: bmi,
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          BMI: "",
        }));
      }
    }
  };

  const handleCancel = () => {
    props.onClose();
  }

  const calculateBMI = (height, weight) => {
    const heightInMeters = Number(height) / 100;
    const bmi = Number(weight) / (heightInMeters * heightInMeters);
    return bmi.toFixed(1);
  };

  const handleVitalSubmit = () => {
    const payload = [];
    const currentDate = convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss");
    Object.entries(formData).forEach(([fieldName, value]) => {
      // Check if the value is valid (non-empty)
      if (value !== null && value !== undefined && value !== "" && value !== 0) {
        const vitalRange = vitalMasterData.find(
          (vital) => vital.vitalName.toLowerCase() === fieldName.toLowerCase()
        );
  
        if (vitalRange) {
          payload.push({
            consultationId: null,
            createdBy: patientCode,
            createdDate: currentDate,
            createdName: patientName,
            modifiedBy: patientCode,
            modifiedDate: currentDate,
            patientId: patientCode,
            recordedDateTime: currentDate,
            status: 1,
            units: vitalRange.units,
            value: value,
            vitalCode: vitalRange.vitalCode,
            vitalName: vitalRange.vitalName,
            account_Id: patientLoginData?.account_Id,
            clinic_Id: patientLoginData?.clinic_Id,
          });
        }
      }
    });
      if (payload.length === 0) {
      message.error("No vitals data to submit.", 3);
      return; 
    }
      VitalService.AddVitals(payload)
      .then((response) => {
        message.success("Vitals Added Successfully", 4);
        props.onClose();
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      });
  };

  return (
    <div className="mt-4 border border-brand-textGray rounded-xl p-1">
      <Form layout="vertical">
        <Row gutter={24}>
          <Col className="gutter-row custom-col" span={9}>
            <Form.Item
              label={
                <span className="label-content">Blood Pressure(Sys/Dia) </span>
              }
              className="mx-2"
            >
              <Input.Group compact>
                <Input
                  style={{ width: "50%" }}
                  className="h-10"
                  placeholder="Sys"
                  suffix={<span style={{ color: "#BEC0C3" }}>mmHg</span>}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "Blood Pressure(SYS)")
                  }
                />
                <Input
                  style={{ width: "50%" }}
                  className="h-10"
                  placeholder="Dia"
                  suffix={<span style={{ color: "#BEC0C3" }}>mmHg</span>}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "Blood Pressure(DIA)")
                  }
                />
              </Input.Group>
            </Form.Item>
          </Col>
          {/* <Col className="gutter-row custom" span={5}>
            <Form.Item
             label={
              <span>
              </span>
            }
            className="mx-2">
              
            </Form.Item>
          </Col> */}
          <Col span={6}>
            <Form.Item label="Pulse Oxygen" className="mx-2">
              <Input
                className="h-10"
                suffix={<span style={{ color: "#BEC0C3" }}>%</span>}
                onChange={(e) => handleInputChange(e.target.value, "Pulse Oxygen")}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Heart Rate" className="mx-2">
              <Input
                className="h-10"
                suffix={<span style={{ color: "#BEC0C3" }}>Beats/min</span>}
                onChange={(e) =>
                  handleInputChange(e.target.value, "Heart Rate")
                }
              ></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={9}>
            <Form.Item label="Respiration Rate" className="mx-2">
              <Input
                className="h-10"
                suffix={<span style={{ color: "#BEC0C3" }}>Beats/min</span>}
                onChange={(e) =>
                  handleInputChange(e.target.value, "Respiration Rate")
                }
              ></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Temperature" className="mx-2">
              <Input
                className="h-10"
                suffix={<span style={{ color: "#BEC0C3" }}>°F</span>}
                onChange={(e) =>
                  handleInputChange(e.target.value, "Temperature")
                }
              ></Input>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <hr className="my-2 border-gray-400" />
      <h2 className="text-base font-semibold">Anthropometry</h2>
      <Form layout="vertical" className="mt-3">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="Height" className="mx-2">
              <Input
                className="h-10"
                suffix={<span style={{ color: "#BEC0C3" }}>cm</span>}
                onChange={(e) => handleInputChange(e.target.value, "Height")}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Weight" className="mx-2">
              <Input
                className="h-10"
                suffix={<span style={{ color: "#BEC0C3" }}>kg</span>}
                onChange={(e) => handleInputChange(e.target.value, "Weight")}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="BMI" className="mx-2">
              <Input
                className="h-10"
                disabled
                suffix={
                  <span style={{ color: "#BEC0C3" }}>
                    Kg/m<sup>2</sup>
                  </span>
                }
                onChange={(e) => handleInputChange(e.target.value, "BMI")}
                value={formData.BMI}
              ></Input>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <div className="flex items-center w-full justify-end my-5">
              <button
                className="border-brand-secondaryColor border rounded text-brand-secondaryColor text-sm px-6 py-1.5"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="bg-primaryColor border-brand-secondaryColor border text-white rounded px-5 py-1.5 text-brand-textWhite text-sm ml-3"
                onClick={handleVitalSubmit}
              >
                Submit
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddVitals;
