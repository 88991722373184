import http from "./http_common";

class PaymentService {
  queryString(payload) {
    return Object.keys(payload)
      .map((key) => key + "=" + payload[key])
      .join("&");
  }
  PhonepeTransaction(data) {
    return http.post(`/phonepeTransaction/consultation/save`, data);
  }
  BillingTransaction(data) {
    return http.post(`/billingTransaction/consultation/save`, data);
  }
  getTransactionStatus(payload) {
    var queryString = this.queryString(payload);
    return http.get(`phonePeResponse1/paymentRedirectDetails?${queryString}`);
  }
  getBillingTransaction(payload) {
    var queryString = this.queryString(payload);
    return http.get(`billingTransaction/list/filter?${queryString}`);
  }

  getBillingCount(payload) {
    var queryString = this.queryString(payload);
    return http.get(
      `/billingTransaction/getBillingTransactionCountStatus?${queryString}`
    );
  }
}
export default new PaymentService();
