import { Form, Input, message, Modal } from 'antd';
import React, { useRef, useState } from 'react';
import { UserSwitchOutlined } from "@ant-design/icons";
import AuthenticationService from "../Services/AuthenticationService";
import { decryptPayload, encryptPayload, generateAesKey } from "../utils/encryptPassword"
import { Base64 } from 'js-base64';
import PatientRegistration from './PatientRegistration';
import { APP_ROUTES } from '../PageRouting/pageRoutes';
import { useNavigate } from 'react-router-dom';
import { useData } from '../Services/Context';
import { AiOutlineUserSwitch } from 'react-icons/ai';
import Doxzonlogo from "../assets/images/doxzonnewlogo.png";

const Login = ({ doctor, handleCheckout }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [patientloginType, setPatientloginType] = useState(false);
  const [patOtpScreen, setPatOtpScreen] = useState(false);
  const [isResendEnabled, setIsResendEnabled] = useState(true);
  const [resendBtnClicked, setResendBtnClicked] = useState(false);
  const [timer, setTimer] = useState(120);
  const [disable, setDisable] = useState(false);
  const [credentials, setCredentials] = useState({
    userId: '',
    password: ''
  });
  const { setPatientData } = useData([]);
  const formRef = useRef();
  const verifyformRef = useRef();
  const inputRefs = useRef([]);


  const handleLogin = async () => {
    setDisable(true);
    let payload = {};
    if (!credentials.userId || !credentials.password) {
      message.error('User Name/ Password is empty!');
      setDisable(false);
      return;
    }
    payload.userCode = credentials.userId;
    payload.passWord = Base64.encode(credentials.password);
    const loginPayload = {
      password: payload.passWord,
      userId: `PAT_${payload.userCode}`,
    };

    try {
      const res = await AuthenticationService.TokenAuthentication(loginPayload);
      localStorage.setItem("access_token", res.data.access_token);
      if (res.data.error === "1") {
        // message.error(res.data.error_description);
        setErrorMessage(res.data.error_description);
        setTimeout(() => {
          setErrorMessage('');
        }, 2000);
        setDisable(false);
        return;
      }
      const encryptedPass = generateAesKey(credentials.password);
      let patientPayload = {
        currentVal: encryptedPass,
        userCode: payload.userCode,
      };
      const response = await AuthenticationService.Login(patientPayload);
      localStorage.setItem("access_token", res.data.access_token);
      localStorage.setItem('patientData', JSON.stringify(response.data?.patient));
      localStorage.setItem('loginObj', JSON.stringify({ patient: response.data?.patient }));
      setPatientData(response?.data?.patient);
      setDisable(false);
      handleCheckout();

    } catch (error) {
      if (error.response.data.status === 404) {
        // message.error("Not Found. Please check your credentials.");
        setErrorMessage('Not Found. Please check your credentials.');
        setDisable(false);
        setTimeout(() => {
          setErrorMessage('');
        }, 2000);
      }
      console.log(error)
    }
  }

  const handleSignUp = () => {
    setIsSignupModalOpen(!isSignupModalOpen);
  }

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) && index < 5) {
      inputRefs.current[index + 1].focus();
    }
    else if (!e.target.value && index >= 1) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleResend = async (e) => {
    e.preventDefault();
    setIsResendEnabled(false);
    setResendBtnClicked(true);
    setTimeout(() => {
      setIsResendEnabled(true);
    }, 120000);

    try {
      let userPayload = {
        patientId: credentials.userId,
      };
      await AuthenticationService.getPatientMobileOtp(userPayload);
    } catch (e) {
      console.log(e);
    }
  };

  const handleOtpVerification = async (e) => {
    e.preventDefault();
    const { one, two, three, four, five, six } =
      verifyformRef.current.getFieldsValue();
    console.log(' otp ', verifyformRef.current.getFieldsValue());
    let finalNum = one + two + three + four + five + six;
    const encryptPassword = generateAesKey(finalNum);

    let verifyPayload = {
      numVal: encryptPassword,
      patientId: credentials.userId,
    };

    try {
      const response = await AuthenticationService.verifyPatientOtp(verifyPayload);
      localStorage.setItem('patientData', JSON.stringify(response.data?.patient));
      localStorage.setItem('loginObj', JSON.stringify({ patient: response.data?.patient }));
      setPatientData(response?.data?.patient);
      handleCheckout();

    } catch (e) {
      // message.error(e.response.data.message);
      setErrorMessage(e.response.data.message);
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
      return;
    }


  };

  const handleCred = (e, type) => {
    const value = e.target.value;
    if (type === 'password') {
      setCredentials({
        ...credentials,
        password: value
      });
    }
    else if (type === 'userid') {
      setCredentials({
        ...credentials,
        userId: value
      });
    }


  }

  const handleNumberValidation = () => {
    if (validatePhoneNumber(credentials.userId)) {
      generateOTP(credentials.userId);
    }
  }

  const generateOTP = async (userId) => {

    const tokenPayload = {
      password: 'NA',
      userId: `P${userId}`,
    };

    try {
      const tokenRes = await AuthenticationService.TokenAuthentication(tokenPayload);
      if (tokenRes.data.error === "1") {
        // message.error(tokenRes.data.error_description);
        setErrorMessage(tokenRes.data.error_description);
        setTimeout(() => {
          setErrorMessage('');
        }, 2000);
        return;
      }
      localStorage.setItem("access_token", tokenRes.data.access_token);

      let userPayload = {
        patientId: userId,
      };

      const response = await AuthenticationService.getPatientMobileOtp(userPayload);
      if (response.data === 1) {
        setPatOtpScreen(true);
      }


    } catch (e) {
      console.log(e);
    }
  };


  const validateEmail = (email) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validatePhoneNumber = (number) =>
    /^\d{10}$/.test(number);

  const handlePatientlogType = () => {
    setPatientloginType(!patientloginType);
  }




  return (
    // <div className="">
    //   <div>
    //     <p className="text-xl font-bold text-[#002f72] my-4">Login</p>
    //     <Form ref={formRef} className='flex flex-col'>
    //       <Form.Item name="userCode">
    //         <Input
    //           placeholder="User Name"
    //           className="h-10 w-80 px-2 border-[1px] border-brand-borderColor appearance-none bg-none  focus:outline-none focus:ring-0 " style={{ background: 'none' }}
    //           suffix={<UserSwitchOutlined className="h-5 w-5 text-gray-500" />}
    //         />
    //       </Form.Item>
    //       <Form.Item name="passWord">
    //         <Input.Password
    //           placeholder="Password"
    //           className="h-10 w-80 px-2 border-[1px] border-brand-borderColor appearance-none bg-none  focus:outline-none focus:ring-0 " style={{ background: 'none' }}
    //         />
    //       </Form.Item>
    //     </Form>
    //     {/* <InputNumber
    //       controls={false}
    //       maxLength={10}
    //       className="border rounded-md p-2 w-full mt-5 outline-none"
    //       onKeyDown={(event) => {
    //         const allowedKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight'];

    //         if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
    //           event.preventDefault();  // Prevent non-numeric and non-control key input
    //         }
    //       }}
    //       onChange={handleMobileNumber} /> */}

    //     {/* <p className="text-[#002f72] text-xs font-medium mt-3">OTP will be send to this number by SMS</p> */}
    //     <div className="flex gap-2 items-center mt-5">
    //       <input
    //         type="checkbox"
    //         className="w-3 h-3 cursor-pointer"
    //       />
    //       <p className="text-gray-500 mt-3">Share health tips, appointment details and offers with me on Whatsup</p>
    //     </div>
    //     <p className="text-red-500">{errorMessage && errorMessage}</p>
    //     <div className="flex justify-center mt-7">
    //       <button onClick={handleLogin} className="border rounded-md p-2 w-[90%] bg-blue-500 text-white font-semibold text-base">Continue</button>
    //     </div>
    //     <div className="flex gap-2 mt-5 mx-2 ">
    //       <p className="text-[#002f72] text-sm ">Don't we have account?</p>
    //       <p className="font-bold text-[#002f72] underline cursor-pointer" onClick={handleSignUp}>Signup here</p>
    //     </div>
    //     {/* <div className="mt-7 text-center">
    //       <p className="text-gray-500 text-sm">By clicking Continue, you agree to Doxzon's <br /><span className="text-[#002f72] font-semibold">Privacy Policy,Terms and Condition</span></p>
    //     </div> */}
    //   </div>
    //   <Modal
    //     open={isSignupModalOpen}
    //     onCancel={handleSignUp}
    //     footer={[]}
    //   >
    //     <PatientRegistration doctor={doctor} handleCancel={handleSignUp} />
    //   </Modal>
    // </div>
    <div className="w-full bg-White p-2">
      <div className="flex justify-center items-center mb-2">
        <img src={Doxzonlogo} className="w-28 h-10" alt="appLogo" />
      </div>
      <div className='py-3'>
        <h1 className='font-semibold text-xl p-1'>Login</h1>
      </div>
      {
        //if patientlogintype is true it will be otp based login else password based login
        patientloginType ?
          (
            <div>
              {
                //if patotpscreen is true we will show otp entering screen. else show the mobile number entering field
                patOtpScreen ?
                  (
                    <>

                      <Form ref={verifyformRef}>
                        <div className="w-full flex gap-2 items-center justify-evenly">
                          {[...Array(6)].map((_, index) => (
                            <Form.Item
                              key={index}
                              name={
                                ["one", "two", "three", "four", "five", "six"][index]
                              }
                              className="w-[8%]"
                              rules={[
                                {
                                  pattern: /^[0-9]{1}$/,
                                },
                              ]}
                            >
                              <Input
                                maxLength={1}
                                ref={(el) => (inputRefs.current[index] = el)}
                                onChange={(e) => handleInputChange(e, index)}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                className="outline-none border rounded-lg border-borderColor p-1 text-center font-medium text-lg"
                              />
                            </Form.Item>
                          ))}
                        </div>
                      </Form>
                      <div className='h-2 py-4'>
                        <p className="text-[#ef4444]">{errorMessage ? errorMessage : ' '}</p>
                      </div>
                      <div className="flex items-center gap-2 mt-4">
                        <button
                          className={`w-full p-3 bg-lightBlue rounded-lg font-semibold disabled:cursor-not-allowed`}
                          disabled={!isResendEnabled}
                          onClick={handleResend}
                        >
                          Resend OTP {resendBtnClicked ? `(${timer})` : null}
                        </button>
                        <button
                          className="w-full p-3 bg-primaryColor text-white rounded-lg font-semibold"
                          onClick={handleOtpVerification}
                        >
                          Verify OTP
                        </button>
                      </div>
                    </>
                  )
                  :
                  (
                    <>
                      <div className="text-left w-[100%]">
                        {/* <label className="pr-3">User Name:</label> */}
                        <Form.Item name={'userCode'}>
                          <Input
                            type="text"
                            placeholder="Email Or Mobile"
                            suffix={
                              <AiOutlineUserSwitch className="h-4 w-4 text-gray-500" />
                            }
                            className="h-10 w-full px-2 border border-gray-500 appearance-none bg-none  focus:outline-none focus:ring-0 disabled:opacity-50"
                            style={{ background: "none" }}
                            value={credentials?.userId}
                            onChange={(e) => handleCred(e, 'userid')}
                          />
                        </Form.Item>
                      </div>
                      <div className='h-2 py-4'>
                        <p className="text-[#ef4444]">{errorMessage ? errorMessage : ' '}</p>
                      </div>
                      <div className="text-center mt-4 w-[100%]">
                        <button
                          onClick={handleNumberValidation}
                          className="w-full px-5 py-2 bg-primaryColor text-white rounded-md"
                        >
                          GET OTP
                        </button>
                      </div>
                    </>
                  )
              }

            </div>
          )
          :
          (
            <>
              <div className="text-left w-[100%]">
                <Input
                  type="text"
                  placeholder="Email Or Mobile"
                  suffix={
                    <AiOutlineUserSwitch className="h-4 w-4 text-gray-500" />
                  }
                  className="h-10 w-full px-2 border border-gray-500 appearance-none bg-none  focus:outline-none focus:ring-0 disabled:opacity-50"
                  style={{ background: "none" }}
                  value={credentials.userId}
                  onChange={(e) => handleCred(e, 'userid')}
                />
              </div>
              <div className="mt-5 text-left w-[100%]">
                {/* <label className="pr-4">Password:</label> */}
                <Input.Password
                  type="password"
                  placeholder="Password"
                  className="h-10 w-full px-2 border border-gray-500 appearance-none bg-none focus:outline-none focus:ring-0 "
                  style={{ background: "none" }}
                  value={credentials.password}
                  onChange={(e) => handleCred(e, 'password')}
                />
              </div>
              <div className='h-2 py-4'>
                <p className="text-[#ef4444]">{errorMessage ? errorMessage : ' '}</p>
              </div>
              <div className="text-center mt-4 w-[100%]">
                <button
                  disabled={disable}
                  onClick={handleLogin}
                  className="w-full px-5 py-2 bg-primaryColor text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Login
                </button>
              </div>
            </>

          )
      }

      <div className="w-full flex items-center mt-6">
        <hr className="w-[28%]" />
        <p onClick={handlePatientlogType} className="text-sm font-semibold text-primaryColor hover:underline cursor-pointer px-2">
          {patientloginType ? 'Login with password' : 'Login with mobile'}
        </p>
        <hr className="w-[28%]" />
      </div>

      <div className="w-full flex items-center mt-6">
        <p className='text-sm font-normal'>If you haven't signed up</p>
        <p onClick={handleSignUp} className="text-sm font-semibold text-primaryColor hover:underline cursor-pointer px-1">
          Click here
        </p>
      </div>

      <Modal
        open={isSignupModalOpen}
        onCancel={handleSignUp}
        footer={[]}
      >
        <PatientRegistration doctor={doctor} handleCancel={handleSignUp} />
      </Modal>

    </div >
  );
};

export default Login;
