import { Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import AuthService from "../../Services/AuthService";
import { generateAesKey } from "../../utils/encryptPassword";
import moment from "moment";

function ChangePassword() {
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    rePassword: "",
  });

  const [digit, setDigit] = useState(false);
  const [err, setErr] = useState("");
  const [passLength, setPassLength] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [special, setSpecial] = useState(false);
  const [regexP] = useState(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/
  );
  useEffect(() => {
    setPassword({
      oldPassword: "",
      newPassword: "",
      rePassword: "",
    });
  }, []);
  const passwordValidate = (value) => {
    const regexNum = /\d/;
    const regexUppercase = /[A-Z]/;
    const regexCharLength = /^.{8,72}$/;
    const special = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    regexNum.test(value) ? setDigit(true) : setDigit(false);
    regexUppercase.test(value) ? setUpperCase(true) : setUpperCase(false);
    regexCharLength.test(value) ? setPassLength(true) : setPassLength(false);
    special.test(value) ? setSpecial(true) : setSpecial(false);
  };

  const handleChange = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
    if (e.target.name === "newPassword") {
      passwordValidate(e.target.value);
    }
  };

  const handleCancel = () => {
    setPassword({});
  }
  
  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  const savechangepassword = (e) => {
    e.preventDefault();
    const newEncryptedPassword = generateAesKey(password.rePassword);
    const currentPassword = generateAesKey(password.oldPassword);

    let payload = {
      createdBy: patientCode,
      createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      modifiedBy: patientCode,
      modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      email: activePatient?.email,
      mobile: activePatient?.mobile,
      currentVal: currentPassword,
      newVal: newEncryptedPassword,
      status: 1,
      patientId: patientCode,
    };
    AuthService.ChangePassword(payload)
      .then((result) => {
        if(result.data == 1) {
          setPassword({});
          setTimeout(() => {
            message.success("The password has been changed successfully");
          }, 1500);
        } else if (result.data.error == 'OK'){
          message.error(result.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <section className="home-banner bg-gray-100 rounded-b-lg md:min-h-[80vh] py-4 relative  overflow-hidden">
        <div className="w-[80%] mx-auto">
          <div className="w-full rounded-md p-2 bg-White">
            <div className="md:w-[50%] mx-auto">
              <Form
                // form={form}
                layout="vertical"
                autoComplete="off"
                className="CommonForm grid grid-cols-1 gap-3 md:gap-5"
              >
                <Form.Item
                  className="mb-0"
                  label={
                    <div className="text-primaryColor">
                      Current Password{" "}
                      <span style={{ color: "#FF000F" }}>*</span>
                    </div>
                  }
                >
                  <Input.Password
                    name="oldPassword"
                    value={password.oldPassword}
                    onChange={handleChange}
                    // maxLength={6}
                    placeholder="Please enter current password"
                  />
                </Form.Item>
                <Form.Item
                  className="mb-0"
                  label={
                    <div className="text-primaryColor">
                      New Password <span style={{ color: "#FF000F" }}>*</span>
                    </div>
                  }
                >
                  <Input.Password
                    name="newPassword"
                    value={password.newPassword}
                    onChange={handleChange}
                    // maxLength={6}
                    placeholder="Please enter new password"
                  />
                </Form.Item>
                <div className="flex flex-wrap gap-4">
                  <span
                    disabled
                    className={`${
                      passLength
                        ? "text-secondaryColor "
                        : "text-dangerColor"
                    } cursor-not-allowed bg-gray  w-34 text-xs px-2 py-1  rounded-sm mr-2`}
                  >
                    8 characters
                  </span>
                  <span
                    disabled
                    className={`${
                      special
                        ? "text-secondaryColor "
                        : "text-dangerColor"
                    } cursor-not-allowed bg-gray w-34 text-xs px-2 py-1  rounded-sm mr-2`}
                  >
                    1 special character
                  </span>
                  <span
                    disabled
                    className={`${
                      upperCase
                        ? "text-secondaryColor "
                        : "text-dangerColor"
                    } cursor-not-allowed bg-gray w-34 text-xs px-2 py-1  rounded-sm mr-2`}
                  >
                    1 uppercase
                  </span>
                  <span
                    disabled
                    className={`${
                      digit ? "text-secondaryColor " : "text-dangerColor"
                    } cursor-not-allowed bg-gray text-xs  px-2 w-34 py-1 p rounded-sm mr-2`}
                  >
                    1 numeric
                  </span>
                </div>
                <Form.Item
                  className="mb-0"
                  label={
                    <div className="text-primaryColor">
                      Confirm New Password{" "}
                      <span style={{ color: "#FF000F" }}>*</span>
                    </div>
                  }
                >
                  <Input.Password
                    name="rePassword"
                    value={password.rePassword}
                    onChange={handleChange}
                    // maxLength={6}
                    placeholder="Please enter confirm new password"
                  />
                </Form.Item>
                {/* {password?.rePassword && password?.newPassword !== password?.rePassword && <span className="text-danger  text-xs">Confirm New Password doses not match New Password.</span>}
                <span className="text-danger text-xs">{errMsg}</span>
                <span className="text-secondaryColor text-xs">{confirmValidation}</span> */}
              </Form>

              <div className="grid grid-cols-2 items-center gap-5 mt-5">
                <button
                  onClick={handleCancel}
                  className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[40px] text-base"
                >
                  Cancel
                </button>
                <button
                  onClick={savechangepassword}
                  //   disabled={isDisable}
                  className="bg-primaryColor w-full h-[40px] text-base rounded-lg text-white"
                >
                  Save Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ChangePassword;
