import React, { useEffect, useState } from "react";
import { LineChart, Line, ResponsiveContainer } from "recharts";
import moment from "moment";
import VitalService from "../../Services/VitalService";

function VitalDashboard(props) {
  const colors = {
    BPS: "rgb(54, 162, 235)",
    BPD: "rgb(6, 192, 38)",
    PO: "rgb(255, 99, 132)",
    HR: "rgb(255, 159, 64)",
    TP: "rgb(153, 102, 255)",
    RR: "rgb(255,20,147)",
    WE: "rgb(255,255,0)",
    BMI: "rgb(128, 0, 128)",
  };
  
  const [vitalsData, setVitalsData] = useState([]);
  const [vitalsData1, setVitalsData1] = useState([]);
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;

  const MyVitalList = () => {
    let payload = {
      patientId: patientCode,
      vitalCodes: props.vitalCode !== "BP" ? props.vitalCode : "BPS",
    };

    if (props.vitalCode === "BP") {
      let payload1 = {
        patientId: patientCode,
        vitalCodes: "BPD",
      };

      Promise.all([
        VitalService.getVitalsData(payload),
        VitalService.getVitalsData(payload1),
      ])
        .then(([bpsResult, bpdResult]) => {
          setVitalsData(bpsResult.data.slice(0, 5));
          setVitalsData1(bpdResult.data.slice(0, 5));
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      VitalService.getVitalsData(payload)
        .then((result) => {
          setVitalsData(result.data.slice(0, 5));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    MyVitalList();
  }, [props.vitalCode]);

  let combinedData = [];
  if (props.vitalCode === "BP") {
    const bpsData = vitalsData.map((d) => ({
      date: moment(d.recordedDateTime).format("MM/DD"),
      BPS: d.value,
    }));

    const bpdData = vitalsData1.map((d) => ({
      date: moment(d.recordedDateTime).format("MM/DD"),
      BPD: d.value,
    }));

    // Merge BPS and BPD data by date
    combinedData = bpsData.map((bps) => {
      const bpd = bpdData.find((bpd) => bpd.date === bps.date);
      return {
        date: bps.date,
        BPS: bps.BPS,
        BPD: bpd ? bpd.BPD : null,
      };
    });
    combinedData = combinedData.reverse();
  } else {
    combinedData = vitalsData
      .map((d) => ({
        date: moment(d.recordedDateTime).format("MM/DD"),
        value: d.value,
      }))
      .reverse();
  }

  return (
    <div className="">
      <ResponsiveContainer width="100%" height={150}>
        <LineChart
          data={combinedData}
          margin={{ top: 40, right: 0, left: 0, bottom: 5 }}
        >
          {props.vitalCode === "BP" && (
            <>
              <Line
                type="linear"
                dataKey="BPS"
                stroke={colors.BPS}
                name="BPS"
                strokeWidth={2}
                dot={{ fill: colors.BPS, strokeWidth: 0 }}
              />
              <Line
                type="linear"
                dataKey="BPD"
                stroke={colors.BPD}
                name="BPD"
                strokeWidth={2}
                dot={{ fill: colors.BPD, strokeWidth: 0 }}
              />
            </>
          )}

          {/* Render a Line for other vitals using the corresponding color */}
          {props.vitalCode !== "BP" && (
            <Line
              type="linear"
              dataKey="value"
              stroke={colors[props.vitalCode]}
              name={props.title}
              strokeWidth={2}
              dot={{ fill: colors[props.vitalCode], strokeWidth: 0 }}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default VitalDashboard;
