import React, { useEffect, useState } from "react";
import plusIcon from "../../../assets/images/Svg/plusIcon.svg";
import { Modal } from "antd";
import AddChronicCondition from "./AddChronicCondition";
import ChronicConditionService from "../../../Services/ChronicConditionService";
import moment from "moment";
import dayjs from "dayjs";

function ChronicCondition() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [chronicConditions, setChronicConditions] = useState([]);

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };
  const handleChronicClick = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    PatientchronicConditions();
  };

  const PatientchronicConditions = () => {
    let payload = {
      patientId: patientCode,
    };
    ChronicConditionService.PatientchronicConditions(payload)
    .then((result) => {
      setChronicConditions(result.data)
    })
    .catch((error) => {
      console.error(error);
    });
  };

  useEffect(() => {
    PatientchronicConditions();
  }, []);
  return (
    <div>
      <div className="card h-[300px] m-5 border rounded-lg">
        <div className="header h-[15%] bg-secondaryColor flex items-center justify-between  rounded-lg p-2">
          <h1 className="font-bold text-normal text-white ">
            Chronic Conditions
          </h1>
          <img
            src={plusIcon}
            onClick={() => handleChronicClick()}
            className="h-10 w-10 cursor-pointer"
            alt=""
          />
        </div>
        <div className="body h-[70%] overflow-y-auto  bg-brand-lightBg p-2" id="unquie-scroll">
          {chronicConditions.length > 0
            ? chronicConditions.map((user, i) => (
                <div>
                  <div key={i} className="items-center mt-3 font-light text-sm">
                    <div className="font-medium text-black">ChronicCondition : <span className="font-medium text-gray-500">{user?.chronicDescription}</span></div>
                    <div className="mt-2">
                      <span className="font-medium text-gray-500">
                      Onset date :
                      {dayjs(user.givenDate).format('YYYY-MM-DD')}
                      </span>
                  
                    </div>
                  </div>
                  <hr className="my-4 border-gray-300" />
                </div>
              ))
            : null}
        </div>
        <div className="footer h-[10%]  bg-secondaryColor rounded-md"></div>
      </div>

      {isModalVisible && (
        <Modal
        title={<span className="text-base font-bold font-sans">Add Chronic Condition</span>}
        visible={isModalVisible}
          onOk={() => handleCloseModal()}
          centered={true}
          footer={null}
          onCancel={() => handleCloseModal()}
          okText="Submit"
          cancelText="Cancel"
          width={500}
          maskClosable={false}
        >
          <AddChronicCondition onClose={() => handleCloseModal()} />
        </Modal>
      )}
    </div>
  );
}

export default ChronicCondition;
