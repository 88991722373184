import React from 'react'
import { Button } from "antd";
import FilterIcon from "../../assets/images/Svg/FilterIcon.svg"
import NotificationTable from './NotificationTable';


const Notifications = () => {
  return (
       <div className=" h-screen px-5 pt-5">
       <div className="flex justify-between">
          <h2 className="font-bold text-lg">Notifications</h2>
          <div className="flex gap-2">
            <Button className="w-20 h-9 px-2 flex items-center gap-2 custom_filter">
              <span>
                <img src={FilterIcon} alt="Icon" />
              </span>
              <span className="text-white">Filter</span>
            </Button>
            <button className="border rounded-md w-20 h-9 px-2 flex justify-center text-white items-center bg-[#4096FF] text-sm gap-2">
              View
            </button>
          </div>
        </div>
        <div>
          <NotificationTable />
        </div>
       </div>
  )
}

export default Notifications;
