import http from "./http_common";

class MedicalService {
  queryString(payload) {
    return Object.keys(payload)
      .map((key) => key + "=" + payload[key])
      .join("&");
  }
  loadICDCodesothers(payload) {
    var queryString = Object.keys(payload)
      .map((key) => key + "=" + payload[key])
      .join("&");
    return http.get(`common-icd-codes/list?${queryString}`);
  }
  AddPatientAllery(data) {
    return http.post(`PatientAllergies/save/`, data);
  }
  AddSocialHistory(data) {
    return http.post(`PatientSocialHistory/save/`, data);
  }
  AddPastMedicalHistory(data) {
    return http.post(`PatientPastMedicalHistory/save/`, data);
  }
  AddSurgicalHistory(data) {
    return http.post(`PatientSurgicalHistory/save/`, data);
  }
  AddFamilyHistory(data) {
    return http.post(`PatientFamilyHistory/save/`, data);
  }
  AddMedication(data) {
    return http.post(`PatientMedication/save/`, data);
  }
  AddPatientReport(data) {
    return http.post(`patientDocument/`, data);
  }
  PatientAllergiesList(payload) {
    var queryString = this.queryString(payload);
    return http.get(`PatientAllergies/list/filter?${queryString}`);
  }
  PastMedicalHistory(payload) {
    var queryString = this.queryString(payload);
    return http.get(`PatientPastMedicalHistory/list/filter?${queryString}`);
  }
  PatientSocialHistory(payload) {
    var queryString = this.queryString(payload);
    return http.get(`PatientSocialHistory/list/filter?${queryString}`);
  }
  PatientSurgicalHistory(payload) {
    var queryString = this.queryString(payload);
    return http.get(`PatientSurgicalHistory/list/filter?${queryString}`);
  }
  PatientFamilyHistory(payload) {
    var queryString = this.queryString(payload);
    return http.get(`PatientFamilyHistory/list/filter?${queryString}`);
  }
  PatientMedication(payload) {
    var queryString = this.queryString(payload);
    return http.get(`PatientMedication/list/filter?${queryString}`);
  }
  DrugList(medicineName) {
    return http.get(
      `/drugInfo/list/filter?medicineName=${medicineName}&pageNo=${1}&pageSize=${20}`
    );
  }
  ShareMedicalrecords(data) {
    return http.post(`patientHistoryAccess/`, data);
  }
  getShareMedicalrecords(payload) {
    var queryString = this.queryString(payload);
    return http.get(`patientHistoryAccess/list/filter?${queryString}`);
  }
}

export default new MedicalService();
