import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import VitalService from "../../Services/VitalService";
import BP from "../../assets/images/Vitals/Blood-Pressure.svg";
import HR from "../../assets/images/Vitals/Heart-Rate.svg";
import RP from "../../assets/images/Vitals/respiration.svg";
import oxygen from "../../assets/images/PulseRate.jpg";
import TP from "../../assets/images/Vitals/Temperature.svg";
import WE from "../../assets/images/Vitals/Weight.svg";
import bmi from "../../assets/images/BMI.jpg";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "white",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      >
        <p style={{ margin: 0, color: "black" }}>{`${label}`}</p>
        {payload.map((entry, index) => (
          <p key={index} style={{ margin: 0, color: entry.color }}>
            {`${entry.name}: ${entry.value}`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const CustomLegendFormatter = (value, entry, type) => {
  return <span style={{ color: "blue" }}>{type}</span>;
};

function VitalChartBoard(props) {
  const colors = [
    "rgb(54, 162, 235)",
    "rgb(75, 192, 192)",
    "rgb(255, 99, 132)",
  ];
  const [vitalsData, setVitalsData] = useState([]);
  const [vitalsData1, setVitalsData1] = useState([]); // For BPD
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [vitalCode, setVitalCode] = useState('BP');
  const [vitalName, setVitalName] = useState('Heart Rate');

  const vitalObject = [
    { src: BP, vitalCode: "BP", vitalName: 'Blood Pressure', marginLeft: "37px" },
    { src: HR, vitalCode: "HR", vitalName: 'Heart Rate', marginLeft: "15px" },
    { src: oxygen, vitalCode: "PO", vitalName: 'Pulse Oxygen', marginLeft: "15px" },
    { src: WE, vitalCode: "WE", vitalName: 'Weight', marginLeft: "15px" },
    { src: RP, vitalCode: "RR", vitalName: 'Respiration Rate', marginLeft: "15px" },
    { src: bmi, vitalCode: "BMI", vitalName: 'BMI', marginLeft: "15px" },
    { src: TP, vitalCode: "TP", vitalName: 'Temperature', marginLeft: "15px" },
  ];
  const handleVitalClick = (code, name) => {
    setVitalCode(code);
    setVitalName(name)
  }
  const MyVitalList = () => {
    let payload = {
      patientId: patientCode,
      vitalCodes: vitalCode !== "BP" ? vitalCode : "BPS",
    };

    if (vitalCode === "BP") {
      let payload1 = {
        patientId: patientCode,
        vitalCodes: "BPD",
      };

      Promise.all([VitalService.getVitalsData(payload), VitalService.getVitalsData(payload1)])
        .then(([bpsResult, bpdResult]) => {
          setVitalsData(bpsResult?.data?.slice(0, 5)); // For BPS
          setVitalsData1(bpdResult?.data?.slice(0, 5)); // For BPD
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      VitalService.getVitalsData(payload)
        .then((result) => {
          setVitalsData(result?.data?.slice(0, 5));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    MyVitalList();
  }, [vitalCode]);

  let combinedData = [];
  if (vitalCode === "BP") {
    const bpsData = vitalsData.map((d) => ({
      date: moment(d.recordedDateTime).format("MM/DD"),
      BPS: d.value,
    }));

    const bpdData = vitalsData1.map((d) => ({
      date: moment(d.recordedDateTime).format("MM/DD"),
      BPD: d.value,
    }));

    // Merge BPS and BPD data by date
    combinedData = bpsData.map((bps) => {
      const bpd = bpdData.find((bpd) => bpd.date === bps.date);
      return {
        date: bps.date,
        BPS: bps.BPS,
        BPD: bpd ? bpd.BPD : null, // Ensure data exists for BPD
      };
    });
    combinedData = combinedData.reverse();
  } else {
    combinedData = vitalsData.map((d) => ({
      date: moment(d.recordedDateTime).format("MM/DD"),
      value: d.value,
      name:d.vitalName
    })).reverse(); // Reverse for other vitals too
  }

  return (
    <>
      <div className="relative w-[80%] mx-auto z-50">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-[75%] mx-auto ">
            <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="rounded-lg shadow-lg bg-brand-white w-full h-112 p-5 antialiased justify-between border border-gray-200">
                <div className="flex w-auto justify-between mb-4">
                  <p className="text-medium font-medium text-2xl">
                    {vitalName}
                  </p>
                  <div className="flex justify-between items-center">
                    {vitalObject.map((img, index) => (
                      <img
                        key={index}
                        src={img.src}
                        onClick={()=>handleVitalClick(img?.vitalCode, img?.vitalName)}
                        style={{
                          marginLeft: img.marginLeft,
                          width: "2rem",
                          height: "2rem",
                        }}
                        className="cursor-pointer"
                      />
                    ))}
                  </div>
                  <div className="w-8 h-8 cursor-pointer">
                    <CloseOutlined onClick={props.closePopup} />
                  </div>
                </div>
                <div>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart
                    data={combinedData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    {/* Line for BP (BPS and BPD) */}
                    {vitalCode === "BP" && (
                      <>
                        <Line
                          type="monotone"
                          dataKey="BPS"
                          stroke={colors[0]}
                          name="BPS"
                        />
                        <Line
                          type="monotone"
                          dataKey="BPD"
                          stroke={colors[1]}
                          name="BPD"
                        />
                      </>
                    )}
                    {/* Single Line for other vitals */}
                    {vitalCode !== "BP" && (
                      <Line
                        type="monotone"
                        dataKey="value"
                        stroke={colors[0]}
                        name={combinedData[0]?.name}
                      />
                    )}
                  </LineChart>
                </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
}

export default VitalChartBoard;
