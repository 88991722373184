import React, { createContext, useState, useContext } from "react";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [resultData, setResultData] = useState(null);
    const [patientLoginData, setPatientData] = useState(null);
    const [shareRecord, setShareRecord] = useState(null);

  return (
    <DataContext.Provider value={{ resultData, setResultData, patientLoginData, setPatientData, shareRecord, setShareRecord }}>
      {children}
    </DataContext.Provider>
  );
};


export const useData = () => useContext(DataContext);