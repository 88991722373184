import React from "react";

function Contact() {
  return (
    <div className="contact h-auto">
      <div className="w-[50%] sm:w-[100%] mx-auto px-10">
        <h1 className="headingFont sm:text-center sm:text-[30px] md:text-[30px] lg:text-[30px] x:text-[40px] l:text-[40px] xl:text-[40px] 2xl:text-[50px] text-[#002f72]">
          Get In Touch With Us
        </h1>
        <form action="" className="w-[80%] sm:mx-auto xl:text-[14px] 2xl:text-[16px]">
          <div className="flex sm:flex-col gap-x-5 mt-5">
            <input
              type="text"
              placeholder="Name"
              className="py-3 px-4  x:w-[350px] xl:w-[350px] 2xl:w-[470px] outline-none border-[1px] border-[#E1CAAE]"
            />

            <input
              type="text"
              placeholder="Email Id"
              className="py-3 px-4 sm:mt-5 xl:w-[350px] 2xl:w-[470px] outline-none border-[1px] border-[#E1CAAE]"
            />
          </div>
          <div className="flex sm:flex-col gap-x-5 mt-5">
            <input
              type="text"
              placeholder="Phone"
              className="py-3 px-4 xl:w-[350px] 2xl:w-[470px] outline-none border-[1px] border-[#E1CAAE]"
            />

            <input
              type="text"
              placeholder="Subject"
              className="py-3 px-4 sm:mt-5 xl:w-[350px] 2xl:w-[470px] outline-none border-[1px] border-[#E1CAAE]"
            />
          </div>
          <div>
            <textarea placeholder="Message" className="py-3 px-4 w-full md:w-[450px] lg:w-[450px] x:w-[450px] xl:w-[510px] 2xl:w-[960px] mt-5 h-[100px] outline-none border-[1px] border-[#E1CAAE]"></textarea>
          </div>

          <button className="uppercase sm:w-full bg-[#002f72] lg:text-[14px] x:text-[14px] px-10 py-3 mt-5 rounded-full text-white hover:bg-[#3498DB] transition-all duration-400 ease-in-out">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
