import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Form, Input, DatePicker, Select, message } from "antd";
import moment from "moment";
import MedicalService from "../../../Services/MedicalService";
import dayjs from "dayjs";
import { useData } from "../../../Services/Context";

function AddPastMedicalHistory(props) {
  const [form] = Form.useForm();
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [pastValue, setPastValue] = useState();
  const [manualPast, setManualPast] = useState('');
  const { patientLoginData } = useData();
  const InputRef = useRef()
  const patientName = `${activePatient?.salutation || ""}${activePatient?.firstName || ""} ${activePatient?.lastName || ""}`.trim();
  const [disable, setDisable] = useState(false);

  const handleCancel = () => {
    props.onClose();
  };
  const handlehealthChange = (value) => {
    setPastValue(value);
  };
  useEffect(() => {
    if (manualPast && InputRef.current) InputRef.current.focus();
  }, [manualPast]);

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const healthConditions = [
    { name: "Diabetes", value: "Diabetes" },
    { name: "Hypertension", value: "Hypertension" },
    { name: "Thyroid", value: "Thyroid" },
    { name: "Tuberculosis", value: "Tuberculosis" },
    {
      name: "Myocardial infarction/ Heart attack",
      value: "Myocardial infarction/ Heart attack",
    },
    { name: "Cholesterol high", value: "Cholesterol high" },
    { name: "Jaundice", value: "Jaundice" },
    { name: "Kidney disease", value: "Kidney disease" },
    {
      name: "COPD (Chronic Obstructive Pulmonary disease)",
      value: "COPD (Chronic Obstructive Pulmonary disease)",
    },
    { name: "Asthma", value: "Asthma" },
    { name: "Cancers", value: "Cancers" },
    { name: "HIV", value: "HIV" },
    { name: "Hepatitis B", value: "Hepatitis B" },
    { name: "Hepatitis C", value: "Hepatitis C" },
    { name: "Typhoid", value: "Typhoid" },
    { name: "Malaria", value: "Malaria" },
    { name: "Dengue", value: "Dengue" },
    { name: "Anemia", value: "Anemia" },
    { name: "RE Cataract", value: "RE Cataract" },
    { name: "LE Cataract", value: "LE Cataract" },
    { name: "BE Cataract", value: "BE Cataract" },
    { name: "Eye - Pterygium", value: "Eye - Pterygium" },
    { name: "Eye - DCR", value: "Eye - DCR" },
    { name: "Others", value: "Others" },
  ];
  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  const onFinish = () => {
    setDisable(true);
    let values = form.getFieldValue();
    if ( !(pastValue || manualPast) || !values.givenDate) {
      message.error("Please fill all fields");
      setDisable(false);
      return;
    }
    let payload = {
      createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      givenDate: dayjs(values.givenDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
      createdBy: patientCode,
      modifiedBy: patientCode,
      status: 1,
      patientId: patientCode,
      pastInfo: pastValue?pastValue:manualPast,
      description: null,
      createdName: patientName,
      account_Id: patientLoginData?.account_Id,
      clinic_Id: patientLoginData?.clinic_Id,
      modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      fromDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      toDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
    };
    MedicalService.AddPastMedicalHistory(payload)
      .then((result) => {
        props.onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleHealthSearch = (value) => {
    const isPresent = healthConditions.findIndex((item) => item.name.includes(value));
    if (isPresent === -1) {
      setManualPast(value);
    }
  }

  const handleManualPast = (e) => {
    const value = e.target.value;
    if (value) {
      setManualPast(value);
    }
    else {
      setManualPast('');
    }
  }

  return (
    <div>
      <Form
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  Past Medical History{" "}
                  <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="pastInfo"
            >
             <Select
                style={{ width: "100%" }}
                showSearch
                onSearch={handleHealthSearch}
                onChange={handlehealthChange}
                placeholder="Select"
                //  value={pastValue}
                options={healthConditions}
                filterOption={(input, option) =>
                  option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                className={`${manualPast ? 'hidden' : 'block'}`}
                allowClear
              />
              <Input type="text"
                className={`${manualPast ? 'block' : 'hidden'}`}
                value={manualPast}
                onChange={(e) => handleManualPast(e)}
                ref={InputRef}
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item label={<span>Date <span style={{ color: "#FF000F" }}>*</span></span>} name="givenDate">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Select"
                format="MM-DD-YYYY"
                disabledDate={disabledFutureDate}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <div className="flex items-center w-full justify-end my-5">
              <button
                onClick={handleCancel}
                className="border-brand-secondaryColor border rounded text-brand-secondaryColor text-sm px-6 py-1.5"
              >
                Cancel
              </button>
              <button disabled={disable} className="bg-secondaryColor border-brand-secondaryColor border rounded px-5 py-1.5 text-brand-white text-sm ml-3">
                Submit
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default AddPastMedicalHistory;
