import React, { useEffect, useState } from 'react'
import PatientService from '../../Services/PatientService';
import { Checkbox, Input, Table } from 'antd';

function FamilyMember({ handleFamMemModalClose, handleSelParPat }) {
    const [parentDetails, setParentDetails] = useState(null);
    const [selectedParent, setSelectedParent] = useState(null);
    const [mobileNumber, setMobileNumber] = useState('');


    const handleMobileNumber = (e) => {
        const value = e.target.value;
        setMobileNumber(value);
    }

    const fetchParentPatient = async (number) => {
        const payload = {
            mobile: number,
            status: 1
        }

        try {
            const response = await PatientService.getPatient(payload);
            setParentDetails(response.data);
        }
        catch (e) {
            console.log(e);
        }
    }

    const columns = [
        {
            title: "",
            dataIndex: "patientId",
            render: (patientId, record) => (
                <Checkbox
                    className=""
                    checked={selectedParent && selectedParent.patientId === patientId}
                    onChange={() => handleRadioChange(record)}
                />
            ),
        },
        {
            title: "Patient ID",
            dataIndex: "patientId",
            key: "patientId",
        },
        {
            title: "Name",
            dataIndex: "firstName",
            key: "name",
        },
        // {
        //   title: "Email ID",
        //   dataIndex: "email",
        //   key: "email",
        // },
        {
            title: "Mobile",
            dataIndex: "mobile",
            key: "mobile",
        },
    ];

    const handleRadioChange = (record) => {
        handleSelParPat(record);
        handleFamMemModalClose();
    };

    const handleSearch = () => {
        fetchParentPatient(mobileNumber);
    }

    return (
        <>
            <div className="flex gap-2 mt-4 ">
                <Input
                    placeholder="Enter patient mobile number  or name to filter patients"
                    name="mobile"
                    onChange={handleMobileNumber}
                />
                <button className="h-10 px-10 border custom_filter rounded-lg text-white p-2 text-sm" onClick={handleSearch}>
                    Search
                </button>
            </div>
            <div className="block mt-2">
                <Table
                    columns={columns}
                    dataSource={parentDetails}
                    pagination={{ pageSize: 3 }}
                    scroll={{ x: "max-content", y: false }}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                handleRadioChange(record);
                            },
                        };
                    }}
                    className="cursor-pointer"
                ></Table>
            </div>
        </>

    )
}

export default FamilyMember