import http from "./http_common";

class AuthService {
  TokenAuthentication(payload){
    var queryString = Object.keys(payload).map((key) => key + "=" + payload[key]).join("&");
    return http.get(`/oauth/token?${queryString}`);
  }

  Login(data){
      return http.post('/Login/patient-login',data) 
  }

  changePassword(payload){
    return http.post('/password-config/change-password/',payload);
  }

  forgetPassword(payload){
    return http.post('/password-config/otpforgetpassword',payload);
  }

  verifyPassword(payload){
    return http.post('/password-config/verifyforgetOTP/',payload);
  }

  getPatientMobileOtp(payload){
    return http.post('/PatientOTP/generatePatientOTP',payload);
  }

  getUserMobileOtp(payload){
    return http.post('/UserOTP/generateUserOTP',payload);
  }
  

  verifyPatientOtp(payload){
    return http.post('/PatientOTP/verifyPatientOTP',payload);
  }

  updatePassword(payload){
    return http.post('/password-config/updatePassword/',payload);
  }

  
}

export default new AuthService();
