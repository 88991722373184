import React, { useEffect, useState } from "react";
import plusIcon from "../../../assets/images/Svg/plusIcon.svg";
import AddSocialHistory from "./AddSocialHistory";
import { Modal } from "antd";
import MedicalService from "../../../Services/MedicalService";
import dayjs from "dayjs";

function SocialHistory() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [socialHistoryData, setSocialHistoryData] = useState([]);

  const handleSocialClick = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    SocialHistory();
  };

  const SocialHistory = () => {
    let payload = {
      patientId: patientCode,
      status: 1,
    };
    MedicalService.PatientSocialHistory(payload)
    .then((result) => {
      setSocialHistoryData(result.data);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  useEffect(() => {
    SocialHistory();
  }, []);

  return (
    <div>
      <div className="card h-[300px] m-5 border rounded-lg">
        <div className="header h-[15%] bg-secondaryColor flex items-center justify-between  rounded-lg p-2">
          <h1 className="font-bold text-normal  text-white ">Social History</h1>
          <img
            src={plusIcon}
            onClick={() => handleSocialClick()}
            className="h-10 w-10 cursor-pointer"
            alt=""
          />
        </div>
        <div className="body h-[70%] items-center bg-brand-lightBg overflow-y-auto" id="unquie-scroll">
          {socialHistoryData.length > 0
            ? socialHistoryData.map((user, i) => (
                <div>
                  <div key={i} className="mt-3 p-2 text-sm flex flex-col gap-2">
                    <h4 className="font-medium text-black">Social History : <span className="font-medium text-gray-500">{user.description}</span> </h4>
                    <h4 className="font-medium text-black">Occupation : <span className="font-medium text-gray-500">{user.occupation}</span></h4>
                    <h4 className="font-medium text-black">Marital Status: <span className="font-medium text-gray-500">{user.maritalStatus}</span></h4>
                    <h4 className="font-medium text-black">Social relations with family: <span className="font-medium text-gray-500">{user.familyRelation}</span></h4>
                    <h4 className="font-medium text-black">
                      Social relations with friends: <span className="font-medium text-gray-500">{user.friendsRelation}</span>
                    </h4>
                    <h4 className="font-medium text-black">
                      Social relations with community: <span className="font-medium text-gray-500">{user.communityRelation}</span>
                    </h4>
                    <h4 className="font-medium text-black">
                      Social relations with people at work :{" "}
                      <span className="font-medium text-gray-500">{user.colleagueRelation}</span>
                    </h4>
                  </div>
                  <div className="ml-2 ">
                      <span className="font-medium text-gray-500 text-sm">
                      Onset date :{" "}
                      {dayjs(user.givenDate).format('YYYY-MM-DD')}
                      </span>
                  
                    </div>
                  <hr className="my-4 border-gray-300" />
                </div>
              ))
            : null}
        </div>
        <div className="footer h-[10%]  bg-secondaryColor rounded-md"></div>
      </div>

      {isModalVisible && (
        <Modal
          title={<span className="text-base font-bold font-sans">Add Social History</span>}
          visible={isModalVisible}
          onOk={() => handleCloseModal()}
          centered={true}
          footer={null}
          onCancel={() => handleCloseModal()}
          okText="Submit"
          cancelText="Cancel"
          width={610}
          maskClosable={false}
        >
          <AddSocialHistory onClose={() => handleCloseModal()} />
        </Modal>
      )}
    </div>
  );
}

export default SocialHistory;
