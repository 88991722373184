import React from "react";
import { Lotus } from "./Lotus";

function HeaderPrescription({
  clinicDetails,
  doctorData,
  hospitalAccountData,
}) {
  return (
    <div>
      <div className="border-b-2 border-gray-300 flex justify-between items-start">
        <div>
          <div className="flex">
            <img
              src={
                process.env.REACT_APP_IMAGE_URL + hospitalAccountData?.logoName
              }
              alt="Clinic Logo"
              className="md:w-24 w-12 md:h-24 h-12 mx-4"
            />
            <div className="text-center mt-3">
              <label className="text-4xl font-serif font-medium text-[#154A6B]">
                {clinicDetails?.clinic_Name}
              </label>
            </div>
          </div>
          <div className="md:flex gap-3">
            <p className="text-sm text-[#A7527E] mt-4 mb-2">
              Morning:{" "}
              <span className="text-[#184B67]">
                {clinicDetails?.morningFromTime &&
                  clinicDetails?.morningToTime && (
                    <span className="text-[#184B67]">{` ${clinicDetails?.morningFromTime} - ${clinicDetails?.morningToTime} `}</span>
                  )}
              </span>{" "}
              Evening:{" "}
              <span className="text-[#184B67]">
                {clinicDetails?.eveningFromTime &&
                  clinicDetails?.eveningToTime && (
                    <span className="text-[#184B67]">
                      {` ${clinicDetails?.eveningFromTime} - ${clinicDetails?.eveningToTime} `}{" "}
                    </span>
                  )}
              </span>
            </p>
          </div>
        </div>
        <div className="text-right text-[#184B67]">
          <label className="text-2xl font-bold text-[#244D67]">
            {doctorData?.userSalutation} {doctorData?.userFirstName}{" "}
            {doctorData?.userLastName}
          </label>
          <p className="text-[#244D67] font-medium">
            <span className="text-xs font-medium">
              {doctorData?.qualification}
            </span>
            <br />
            <span className="text-xs font-medium">
              {" "}
              {doctorData?.speciality}
            </span>{" "}
            <br />
            Reg.no.{doctorData?.registrationNumber}
          </p>
        </div>
      </div>
    </div>
  );
}

export default HeaderPrescription;
