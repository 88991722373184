import React, { useState } from "react";
import FilterIcon from "../../assets/images/Svg/FilterIcon.svg"
import { Button, Modal } from "antd";
import PaymentsTable from "./PaymentsTable";
import PaymentsFilter from "./PaymentsFilter";
import { CloseOutlined } from "@ant-design/icons";

const Payments = () => {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [filterValues, setFilterValues] = useState(null);

  const handleFilter = () => {
    if (filterValues) {
      setFilterValues(null); // Clear filter if already applied
    } else {
      setIsModalOpen1(true); // Open modal if no filter is applied
    }
  };

  const handleCloseModal = (values) => {
    setFilterValues(values || null);
    setIsModalOpen1(false);
  };

  return (
    <>
      <div className=" h-screen px-5 pt-5">
        <div className="flex justify-between">
          <h2 className="font-bold text-lg">Payments</h2>
          <div className="flex gap-2">
            <Button
              onClick={handleFilter}
              className="w-20 h-9 px-2 flex items-center gap-2 custom_filter"
            >
              {filterValues ? (
                <div className="flex items-center">
                  <span className="text-white mx-1">Clear</span>
                  <CloseOutlined
                    style={{ color: "white", marginLeft: "4px" }}
                  />
                </div>
              ) : (
                <div className="flex items-center">
                  <span>
                    <img src={FilterIcon} alt="Icon" />
                  </span>
                  <span className="text-white ml-2">Filter</span>
                </div>
              )}
            </Button>
          </div>
        </div>
        <PaymentsTable filterValues={filterValues} />
      </div>

      {isModalOpen1 && (
        <Modal
          title={<span className="text-base font-bold font-sans">Payments Filter</span>}
          visible={isModalOpen1}
          onOk={() => handleCloseModal()}
          centered={true}
          footer={null}
          onCancel={() => handleCloseModal()}
          okText="Submit"
          cancelText="Cancel"
          width={500}
          maskClosable={false}
        >
          <PaymentsFilter onClose={(values) => handleCloseModal(values)} />
        </Modal>
      )}
    </>
  );
};

export default Payments;
