import React, { useEffect, useRef, useState } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import AuthService from "../../Services/AuthService";
import moment from "moment";
import PatientService from "../../Services/PatientService";
import dayjs from "dayjs";
const { TextArea } = Input;

const AddFamilyMember = (props) => {
  const activePatient = JSON.parse(localStorage.getItem("patient"));
  const patientCode = activePatient?.patientId;
  const [photoValue, setPhotoValue] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [profileType, setProfileType] = useState(null);
  const fileInputRef = useRef(null);
  const [form] = Form.useForm();
  const [stateData, setStateData] = useState([]);
  const [genderValue, setGenderValue] = useState();
  const [bloodValue, setBloodValue] = useState();
  const [maritalStatus, setMaritalStatus] = useState();
  const [salutationValue, setSalutationValue] = useState();
  const [relationValue, setRelationValue] = useState();

  const gender = [
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
  ];
  const salutation = [
    { name: "Mr.", value: "Mr." },
    { name: "Mrs.", value: "Mrs." },
    { name: "Dr.", value: "Dr." },
    { name: "Ms.", value: "Ms." },
  ];
  const bloodGroup = [
    { name: "A+", value: "A+" },
    { name: "A-", value: "A-" },
    { name: "B+", value: "B+" },
    { name: "B-", value: "B-" },
    { name: "AB+", value: "AB+" },
    { name: "AB-", value: "AB-" },
    { name: "O+", value: "O+" },
    { name: "O-", value: "O-" },
  ];
  const marital = [
    { label: "Married", value: "M" },
    { label: "Unmarried", value: "U" },
    { label: "Seperated", value: "S" },
    { label: "Divorced", value: "D" },
    { label: "Widowed", value: "W" },
  ];
  const Relation = [
    { name: "Father", value: "Father" },
    { name: "Mother", value: "Mother" },
    { name: "Daughter", value: "Daughter" },
    { name: "Son", value: "Son" },
    { name: "Brohter", value: "Brother" },
    { name: "Sister", value: "Sister" },
    { name: "Spouse", value: "Spouse" },
    { name: "Grandparent", value: "Grandparent" },
    { name: "Relative", value: "Relative" },
    { name: "Staff", value: "Staff" },
    { name: "Others", value: "Others" },
  ];
  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  const StateList = () => {
    AuthService.stateMaster().then((result) => {
      if (result.data.message === "Record Not Found") {
        setStateData([]);
      } else {
        setStateData(result.data || []);
      }
    });
  };

  useEffect(() => {
    StateList();
  }, []);

  const handleCancel = () => {
    props.onClose();
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        let imageArray = reader.result.split(",");
        setProfileImage(reader.result);
        const imageDataUrl = imageArray[1];
        const imageType = file.type;
        const fileExtension = imageType.split("/")[1];
        setPhotoValue(imageDataUrl);
        setProfileType(fileExtension);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const onGenderChange = (values) => {
    setGenderValue(values);
  };

  const handleSalutation = (values) => {
    setSalutationValue(values);
  };
  const handleBloodGroup = (values) => {
    setBloodValue(values);
  };
  const handleMerital = (values) => {
    setMaritalStatus(values);
  };
  const handleRelation = (values) => {
    setRelationValue(values);
  };

  const handleSubmit = () => {
    let values = form.getFieldValue();
    let payload = {
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      country: values.country,
      createdBy: patientCode,
      dob: dayjs(values.dob, "MM/DD/YYYY").format("MM/DD/YYYY"),
      email: values.email,
      bloodGroup: values.bloodGroup,
      salutation: values.salutation,
      firstName: values.firstName,
      lastName: values.lastName,
      patientId: patientCode,
      parentPatientId: patientCode,
      gender: values.gender,
      modifiedBy: patientCode,
      maritalStatus: values.maritalStatus,
      mobile: values.mobile,
      createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      relationDescription: values.relationDescription,
      photo: photoValue,
      photoType: profileType,
      pinCode: values.pinCode,
      state: values.state,
      status: 1,
    };
    PatientService.AddFamilyMember(payload)
      .then((result) => {
        message.success("FamilyMember Added Successfully",4);
        props.onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <Form
        className="formDesign patientForm mt-7 mb-10"
        name="basic"
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={handleSubmit}
      >
        <Row gutter={24}>
          <div className="flex flex-col m-auto mb-2">
            <div
              onClick={handleClick}
              className="Upload h-24 w-24 border-2 p-4 rounded-full cursor-pointer relative"
            >
              <input
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
                ref={fileInputRef}
              />
              <div
                onClick={handleClick}
                className="absolute bottom-0 right-0 rounded-full text-center p-1 bg-slate-300 cursor-pointer"
                style={{ width: "24px", height: "24px", zIndex: 1 }}
              >
                <PlusOutlined style={{}} />
              </div>
              {profileImage && (
                <img
                  src={profileImage}
                  alt="Profile"
                  className="absolute top-0 left-0 w-full h-full object-cover rounded-full"
                />
              )}
            </div>
            <label htmlFor="" className="mt-2">
              Upload Profile
            </label>
          </div>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  Salutation
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="salutation"
            >
              <Select
                placeholder="Select"
                style={{
                  width: "100%",
                }}
                value={salutationValue}
                onChange={handleSalutation}
                options={salutation.map((ele) => ({
                  value: ele.value,
                  label: ele.name,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  First Name
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="firstName"
            >
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  Last Name
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="lastName"
            >
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  D.O.B
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="dob"
            >
              <DatePicker className="w-full" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  Mobile Number
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="mobile"
            >
              <Input className="w-full" maxLength={10} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  Email ID
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="email"
            >
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  Gender
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="gender"
            >
              <Select
                placeholder="Select"
                style={{
                  width: "100%",
                }}
                value={genderValue}
                onChange={onGenderChange}
                options={gender.map((ele) => ({
                  value: ele.value,
                  label: ele.label,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label={
                <span>
                  Blood Group
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="bloodGroup"
            >
              <Select
                placeholder="Select"
                style={{
                  width: "100%",
                }}
                value={bloodValue}
                onChange={handleBloodGroup}
                options={bloodGroup.map((ele) => ({
                  value: ele.value,
                  label: ele.name,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={<span>Marital Status</span>} name="maritalStatus">
              <Select
                placeholder="Select"
                style={{
                  width: "100%",
                }}
                value={maritalStatus}
                onChange={handleMerital}
                options={marital.map((ele) => ({
                  value: ele.value,
                  label: ele.label,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={<span>Relation</span>} name="relationDescription">
              <Select
                placeholder="Select"
                style={{
                  width: "100%",
                }}
                value={relationValue}
                onChange={handleRelation}
                options={Relation.map((ele) => ({
                  value: ele.value,
                  label: ele.name,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name="address1"
              label={
                <span>
                  Address 1 <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
            >
              <TextArea
                style={{
                  resize: "none",
                }}
                rows={2}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="address2" label={<span>Address 2</span>}>
              <TextArea
                style={{
                  resize: "none",
                }}
                rows={2}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={<span>City</span>} name="city">
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={<span>State</span>} name="state">
              <Select
                showSearch
                options={stateData.map((ele) => ({
                  value: ele.stateId,
                  label: ele.stateName,
                }))}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />{" "}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Country" name="country">
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={<span>Pincode</span>} name="pinCode">
              <Input className="w-full" maxLength={6} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <div className="flex justify-end  border-brand-colorBorder gap-3 pt-2">
            <Button onClick={handleCancel} className="h-10 Btnwhite px-8">
              Cancel
            </Button>
            <Button className="h-10 px-10" type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddFamilyMember;
