import React, { useState } from "react";
import { Col, Row, Form, Input, DatePicker, Select, message } from "antd";
import moment from "moment";
import MedicalService from "../../../Services/MedicalService";
import dayjs from "dayjs";
import { useData } from "../../../Services/Context";

function AddSocialHistory(props) {
  const [maritalValue, setMaritalValue] = useState([]);
  const [socialFamilyValue, setSocialFamilyValue] = useState([]);
  const [socialFriendsValue, setSocialFriendsValue] = useState([]);
  const [socialCommunityValue, setSocialCommunityValue] = useState([]);
  const [socialColleagueValue, setSocialColleagueValue] = useState([]);
  const [form] = Form.useForm();
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const { patientLoginData } = useData();
  const patientName = `${activePatient?.salutation || ""}${activePatient?.firstName || ""} ${activePatient?.lastName || ""}`.trim();
  const [disable, setDisable] = useState(false);

  const handleCancel = () => {
    props.onClose();
  }

  const handleMaritalChange = (value) => {
    setMaritalValue(value);
  };

  const handleFamilyValueChange = (value) => {
    setSocialFamilyValue(value);
  };

  const handleFriendsValueChange = (value) => {
    setSocialFriendsValue(value);
  };

  const handleCommunityValueChange = (value) => {
    setSocialCommunityValue(value);
  };

  const handleColleagueValueChange = (value) => {
    setSocialColleagueValue(value);
  };

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const socialOption = [
    { value: "Normal", label: "Normal" },
    { value: "Stressed", label: "Stressed" },
  ];

  const MaritalStatus = [
    { value: "Single", label: "Single" },
    { value: "Married", label: "Married" },
    { value: "Separated", label: "Separated" },
    { value: "Divorced", label: "Divorced" },
    { value: "Widowed", label: "Widowed" },
  ];

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  const onFinish = (values) => {
    setDisable(true);
    if (!values.description || !values.occupation || !maritalValue || !socialFamilyValue || !socialFriendsValue || !socialCommunityValue || !socialColleagueValue || !values.givenDate) {
      message.error("Please fill all fields");
      setDisable(false);
      return;
    }
    let payload = {
      createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      givenDate: dayjs(values.givenDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
      createdBy: patientCode,
      modifiedBy: patientCode,
      status: 1,
      patientId: patientCode,
      modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      fromDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      toDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      description: values.description,
      colleagueRelation: socialColleagueValue,
      communityRelation: socialCommunityValue,
      familyRelation: socialFamilyValue,
      friendsRelation: socialFriendsValue,
      maritalStatus: maritalValue,
      occupation: values.occupation,
      createdName: patientName,
      account_Id: patientLoginData?.account_Id,
      clinic_Id: patientLoginData?.clinic_Id,
    };
    MedicalService.AddSocialHistory(payload)
      .then((result) => {
        props.onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Form
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        onFinish={onFinish}
      >
        <div className="border rounded-lg p-2">
        <Row gutter={20}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  Social History <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="description"
            >
              <Input rows={2} placeholder="Enter " />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  Occupation <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="occupation"
            >
              <Input rows={2} placeholder="Enter " />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  Marital status <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="maritalStatus"
            >
              <Select
                style={{ width: "100%" }}
                onChange={handleMaritalChange}
                placeholder="Select"
                value={maritalValue}
                options={MaritalStatus.map((ele) => ({
                  value: ele.value,
                  label: ele.label,
                  key: ele.value,
                }))}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  Social relationship with family <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="familyRelation"
            >
              <Select
                style={{ width: "100%" }}
                onChange={handleFamilyValueChange}
                placeholder="Select"
                value={socialFamilyValue}
                options={socialOption.map((ele) => ({
                  value: ele.value,
                  label: ele.label,
                  key: ele.value,
                }))}
              />{" "}
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  Social relations with friends <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="friendsRelation"
            >
              <Select
                style={{ width: "100%" }}
                onChange={handleFriendsValueChange}
                placeholder="Select"
                value={socialFriendsValue}
                options={socialOption.map((ele) => ({
                  value: ele.value,
                  label: ele.label,
                  key: ele.value,
                }))}
              />{" "}
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  Social relationship with community <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="communityRelation"
            >
              <Select
                style={{ width: "100%" }}
                onChange={handleCommunityValueChange}
                placeholder="Select"
                value={socialCommunityValue}
                options={socialOption.map((ele) => ({
                  value: ele.value,
                  label: ele.label,
                  key: ele.value,
                }))}
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                Social relationship with people at work <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="colleagueRelation"
            >
              <Select
                style={{ width: "100%" }}
                onChange={handleColleagueValueChange}
                placeholder="Select"
                value={socialColleagueValue}
                options={socialOption.map((ele) => ({
                  value: ele.value,
                  label: ele.label,
                  key: ele.value,
                }))}
              />{" "}
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  Date <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="givenDate"
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Select"
                format="MM-DD-YYYY"
                disabledDate={disabledFutureDate}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <div className="flex items-center w-full justify-end my-5">
              <button
                onClick={handleCancel}
                className="border-brand-secondaryColor border rounded text-brand-secondaryColor text-sm px-6 py-1.5"
              >
                Cancel
              </button>
              <button disabled={disable} className="bg-secondaryColor border-brand-secondaryColor border rounded px-5 py-1.5 text-brand-white text-sm ml-3">
                Submit
              </button>
            </div>
          </Col>
        </Row>
        </div>
      </Form>
    </div>
  );
}

export default AddSocialHistory;
