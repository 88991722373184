import React, { useState } from "react";
import { Col, Row, Form, Input, DatePicker, message } from "antd";
import moment from "moment";
import MedicalService from "../../../Services/MedicalService";
import dayjs from "dayjs";
import { useData } from "../../../Services/Context";

function AddFamilyHistory(props) {
  const [form] = Form.useForm();
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [pastValue, setPastValue] = useState();
  const { TextArea } = Input;
  const { patientLoginData } = useData();
  const patientName = `${activePatient?.salutation || ""}${
    activePatient?.firstName || ""
  } ${activePatient?.lastName || ""}`.trim();
  const [disable, setDisable] = useState(false);

  const handleCancel = () => {
    props.onClose();
  };
  const handlehealthChange = (value) => {
    setPastValue(value);
  };

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const onFinish = (values) => {
    setDisable(true);
    if (!values.description || !values.givenDate) {
      message.error("Please fill all fields");
      setDisable(false);
      return;
    }

    let payload = {
      createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      givenDate: dayjs(values.givenDate, "YYYY-MM-DD HH:mm:ss").format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      createdBy: patientCode,
      modifiedBy: patientCode,
      status: 1,
      patientId: patientCode,
      description: values.description,
      account_Id: patientLoginData?.account_Id,
      clinic_Id: patientLoginData?.clinic_Id,
      patientBloodGroup: activePatient?.bloodGroup,
      patientDob: activePatient?.dob,
      patientEmail: activePatient?.email,
      patientName: patientName,
      createdName: patientName,
      modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      fromDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      toDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
    };
    MedicalService.AddFamilyHistory(payload)
      .then((result) => {
        props.onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Form
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  Family History <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="description"
            >
              <TextArea rows={3} placeholder="Enter Family History" />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  Date <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="givenDate"
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Select"
                format="MM-DD-YYYY"
                disabledDate={disabledFutureDate}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <div className="flex items-center w-full justify-end my-5">
              <button
                onClick={handleCancel}
                className="border-brand-secondaryColor border rounded text-brand-secondaryColor text-sm px-6 py-1.5"
              >
                Cancel
              </button>
              <button
                disabled={disable}
                className="bg-secondaryColor border-brand-secondaryColor border rounded px-5 py-1.5 text-brand-white text-sm ml-3"
              >
                Submit
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
export default AddFamilyHistory;
