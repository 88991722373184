import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Input, Row, Select, Modal, DatePicker, message } from "antd";
import AddFamilyMember from "./AddFamilyMember";
import PatientService from "../../Services/PatientService";
import AuthService from "../../Services/AuthService";
import dayjs from "dayjs";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import { useData } from "../../Services/Context";
import profile from "../../assets/images/profile.png";
import Layout from "../../Layout";
import { FaSearch } from "react-icons/fa";
import FamilyMember from "./FamilyMember";
import CommonService from "../../Services/CommonService";

const gender = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
];
const salutation = [
  { name: "Mr.", value: "Mr." },
  { name: "Mrs.", value: "Mrs." },
  { name: "Dr.", value: "Dr." },
  { name: "Ms.", value: "Ms." },
];
const bloodGroup = [
  { name: "A+", value: "A+" },
  { name: "A-", value: "A-" },
  { name: "B+", value: "B+" },
  { name: "B-", value: "B-" },
  { name: "AB+", value: "AB+" },
  { name: "AB-", value: "AB-" },
  { name: "O+", value: "O+" },
  { name: "O-", value: "O-" },
];
const marital = [
  { label: "Married", value: "M" },
  { label: "Unmarried", value: "U" },
  { label: "Seperated", value: "S" },
  { label: "Divorced", value: "D" },
  { label: "Widowed", value: "W" },
];

const Relation = [
  { name: "Father", value: "Father" },
  { name: "Mother", value: "Mother" },
  { name: "Daughter", value: "Daughter" },
  { name: "Son", value: "Son" },
  { name: "Brohter", value: "Brother" },
  { name: "Sister", value: "Sister" },
  { name: "Spouse", value: "Spouse" },
  { name: "Grandparent", value: "Grandparent" },
  { name: "Relative", value: "Relative" },
  { name: "Staff", value: "Staff" },
  { name: "Others", value: "Others" },
];

const MyProfile = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [patientData, setPatientData] = useState([
    JSON.parse(localStorage.getItem("patientData")),
  ]);
  const [patientFamilyData, setPatientFamilyData] = useState([]);
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const LoginPatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const fileInputRef = useRef(null);
  const [photoValue, setPhotoValue] = useState(null);
  const [profileImage, setProfileImage] = useState(
    process.env.REACT_APP_IMAGE_URL + LoginPatient?.photoName
  );
  const [profileType, setProfileType] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [genderValue, setGenderValue] = useState();
  const [bloodValue, setBloodValue] = useState();
  const [maritalStatus, setMaritalStatus] = useState();
  const [salutationValue, setSalutationValue] = useState();
  const [familyMemModal, setFamilyMemModal] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [relationList,setRelationList] = useState([]);
  const showModal = () => setIsModalOpen(true);
  const handleOk = () => setIsModalOpen(false);
  const { patientLoginData } = useData();
  const { TextArea } = Input;
  const { setResultData } = useData([]);

  useEffect(() => {
    // PatientDetail();
    StateList();
    getRelationsList();
    // PatientFamilyDetail();
  }, []);

  useEffect(() => {
    if (patientData[0] && (patientData[0].dob || patientData[0].patientId)) {
      if (patientData[0].dob) {
        const formattedDob = dayjs(patientData[0].dob, "DD/MM/YYYY");
        if (formattedDob.isValid()) {
          const updatedPatientData = [...patientData];
          updatedPatientData[0].dob = formattedDob;
          setPatientData(updatedPatientData);
          form.setFieldsValue(updatedPatientData[0]);
        } else {
          console.error("Invalid date format:", patientData[0].dob);
        }
      } else {
        form.setFieldsValue(patientData[0]);
      }
    } else {
      console.error("No patient data or invalid patient ID");
    }
  }, [patientCode]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // PatientFamilyDetail();
  };

  const getRelationsList = async() => {
    const payload = {status:1};
    try{
      const response = await CommonService.RelationList(payload);
      setRelationList(response.data);
    }
    catch(e){
      console.log(e);
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        let imageArray = reader.result.split(",");
        setProfileImage(reader.result);
        const imageDataUrl = imageArray[1];
        const imageType = file.type;
        const fileExtension = imageType.split("/")[1];
        setPhotoValue(imageDataUrl);
        setProfileType(fileExtension);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };


  const handleSelParPat = (data) => {
    console.log(' data ', data);
    form.setFieldsValue({ parentname: data.firstName });
    setSelectedPatient(data);
  }

  // const PatientDetail = () => {
  //   let payload = {
  //     patientId: patientCode,
  //   };
  //   PatientService.getPatient(payload).then((result) => {
  //     if (result.data.message === "Record Not Found") {
  //       setPatientData([]);
  //     } else {
  //       setPatientData(result.data || []);
  //     }
  //   });,
  // };

  // const PatientFamilyDetail = () => {
  //   let payload = {
  //     parentPatientId: patientCode,
  //   };
  //   PatientService.getPatient(payload).then((result) => {
  //     if (result.data.message === "Record Not Found") {
  //       setPatientFamilyData([]);
  //       // setIsEditing([]);
  //     } else {
  //       setPatientFamilyData(result.data || []);
  //       setIsEditing(new Array(result.data.length).fill(false));
  //     }
  //   });
  // };

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };


  const StateList = async () => {
    try {
      const result = await AuthService.stateMaster();
      if (result.data.message === "Record Not Found") {
        setStateData([]);
      } else {
        setStateData(result.data || []);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
      setStateData([]);
    }
  };


  const handleEdit = () => {
    setIsEditing(true);
  };
  const onGenderChange = (values) => {
    setGenderValue(values);
  };

  const handleSalutation = (values) => {
    setSalutationValue(values);
  };
  const handleBloodGroup = (values) => {
    setBloodValue(values);
  };
  const handleMerital = (values) => {
    setMaritalStatus(values);
  };

  const onFinish = () => {
    let values = form.getFieldValue();
    console.log(form.getFieldValue());
    const mandatoryFields = [
      'salutation',
      'firstName',
      'lastName',
      'dob',
      'gender',
      'bloodGroup',
      'maritalStatus',
      'mobile',
      'address1',
      'city',
      'state',
      'country',
      'pinCode',
    ];

    const emptyFields = mandatoryFields.filter(field => !values[field]);

    if (emptyFields.length > 0) {
      message.error('Please fill in all mandatory fields');
      return;
    }
    if(values.parentname && !values.relation){
      message.error('Please select relation.');
      return;
    }
    const relationDesc = relationList?.find((rel) => rel.relationCode === values.relation)?.relationDescription;
    let payload = {
      ...patientData,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      country: values.country,
      state: values.state,
      createdBy: patientData[0]?.createdBy,
      dob: dayjs(values.dob, "MM/DD/YYYY").format("MM/DD/YYYY"),
      email: patientData[0]?.email,
      bloodGroup: values.bloodGroup,
      salutation: values.salutation,
      firstName: values.firstName,
      lastName: values.lastName,
      parentPatientId: selectedPatient ? selectedPatient?.patientId:'',
      parentPatientName: selectedPatient ? selectedPatient?.firstName:'',
      relationCode: values.relation,
      relationDescription: selectedPatient ? relationDesc :'',
      patientId: patientCode,
      gender: values.gender,
      id: patientData[0]?.id,
      modifiedBy: patientCode,
      maritalStatus: values.maritalStatus,
      mobile: values.mobile,
      loginIdAvailableYN: patientData[0].loginIdAvailableYN,
      modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      photo: photoValue,
      photoName: patientData[0]?.photoName,
      photoType: profileType,
      pinCode: values.pinCode,
      status: 1,
      account_Id: patientLoginData?.account_Id,
      clinic_Id: patientLoginData?.clinic_Id,
    };
    PatientService.patientUpdate(payload)
      .then((result) => {
        message.success("Updated Successfully", 4);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlefamilyMemberModal = () => {
    setFamilyMemModal(!familyMemModal);
  }

  const handleFamMemModalClose = () => {
    setFamilyMemModal(!familyMemModal);
  }

  return (
    <Layout>
      <div className="p-5">
        <div className="flex justify-between">
          <h2 className="font-bold text-lg">My Profile</h2>
          {/* <div className="flex gap-2">
            <Button
              className=" h-9 px-2 flex justify-center text-white items-center gap-2 custom_filter"
              onClick={showModal}
            >
              + Add Family Member
            </Button>
          </div> */}
        </div>
        <div className="mt-6 border p-3 rounded">
          <div className="flex justify-end">
            {/* {!isEditing && ( */}
            <button
              className="w-20 border rounded-md bg-blue-500 font-medium h-9 px-2 flex justify-center text-white items-center gap-2 hover:text-white"
              onClick={handleEdit}
            >
              Edit
            </button>
            {/* )} */}
          </div>
          <Form
            form={form}
            // onFinish={onFinish}
            layout="vertical"
            className="grid grid-cols-5 gap-3"
            autoComplete="off"
          >

            <>
              <div onClick={handleClick} className="grid place-items-center">
                <div className="Upload h-24 w-24 border-1 p-4 rounded-full cursor-pointer relative">
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    disabled={!isEditing}
                  />
                  <div
                    // onClick={handleClick}
                    className="absolute bottom-0 right-0 rounded-full text-center p-1 bg-slate-300 cursor-pointer"
                    style={{ width: "24px", height: "24px", zIndex: 1 }}
                  >
                    <PlusOutlined style={{}} />
                  </div>
                  {profileImage ? (
                    <img
                      src={profileImage}
                      className="absolute top-0 left-0 w-full h-full object-cover rounded-full"
                    />
                  ) : (
                    <img
                      src={profile}
                      className="absolute top-0 left-0 w-full h-full object-cover rounded-full"
                    />
                  )}
                </div>
                <label htmlFor="" className="mt-2">
                  Upload Profile
                </label>
              </div>
            </>
            <>
              <Form.Item
                name="salutation"
                label={
                  <span>
                    Salutation <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
              >
                <Select
                  className="h-10"
                  placeholder="Select"
                  style={{
                    width: "100%",
                  }}
                  value={salutationValue}
                  onChange={handleSalutation}
                  options={salutation.map((ele) => ({
                    value: ele.value,
                    label: ele.name,
                  }))}
                  disabled={!isEditing}
                />

              </Form.Item>
            </>
            <>
              <Form.Item
                name="firstName"
                label={
                  <span>
                    First Name <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
              >
                <Input
                  placeholder="Enter"
                  className="h-10"
                  disabled={!isEditing}
                />
              </Form.Item>
            </>
            <>
              <Form.Item
                name="lastName"
                label={
                  <span>
                    LastName <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
              >
                <Input
                  placeholder="Enter"
                  className="h-10"
                  disabled={!isEditing}
                />
              </Form.Item>
            </>
            <>
              <Form.Item
                name="dob"
                label={
                  <span>
                    DOB <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  className="w-full h-10"
                  disabled={!isEditing}
                />
              </Form.Item>
            </>
            <>
              <Form.Item
                name="patientId"
                label={
                  <span>
                    Patient ID
                  </span>
                }
              >
                <Input placeholder="Enter" className="h-10" disabled={true} />
              </Form.Item>
            </>
            <>
              <Form.Item
                name="email"
                label={
                  <span>
                    E-mail
                  </span>
                }
              >
                <Input placeholder="Enter" className="h-10" disabled={true} />
              </Form.Item>
            </>
            <>
              <Form.Item
                label={
                  <span>
                    Gender <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
                name="gender"
              >
                <Select
                  className="h-10"
                  placeholder="Select"
                  style={{
                    width: "100%",
                  }}
                  value={genderValue}
                  onChange={onGenderChange}
                  options={gender.map((ele) => ({
                    value: ele.value,
                    label: ele.label,
                  }))}
                  disabled={!isEditing}
                />
              </Form.Item>
            </>
            <>
              <Form.Item
                label={
                  <span>
                    Blood Group <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
                name="bloodGroup"
              >
                <Select
                  className="h-10"
                  placeholder="Select"
                  style={{
                    width: "100%",
                  }}
                  value={bloodValue}
                  onChange={handleBloodGroup}
                  options={bloodGroup.map((ele) => ({
                    value: ele.value,
                    label: ele.name,
                  }))}
                  disabled={!isEditing}
                />
              </Form.Item>
            </>
            <>
              <Form.Item
                label={
                  <span>
                    Marital Status <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
                name="maritalStatus"
              >
                <Select
                  className="h-10"
                  placeholder="Select"
                  style={{
                    width: "100%",
                  }}
                  value={maritalStatus}
                  onChange={handleMerital}
                  options={marital.map((ele) => ({
                    value: ele.value,
                    label: ele.label,
                  }))}
                  disabled={!isEditing}
                />
              </Form.Item>
            </>
            <>
              <Form.Item
                name="mobile"
                label={
                  <span>
                    Mobile
                  </span>
                }
              >
                <Input
                  placeholder="Enter"
                  className="h-10"
                  maxLength={10}
                  disabled={true}
                />
              </Form.Item>
            </>


            <>
              <Form.Item
                name="address1"
                label={
                  <span>
                    Address 1 <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
              >
                <TextArea
                  style={{
                    resize: "none",
                  }}
                  rows={2}
                  disabled={!isEditing}
                />
              </Form.Item>
            </>
            <>
              <Form.Item name="address2" label={<span>Address 2</span>}>
                <TextArea
                  style={{
                    resize: "none",
                  }}
                  rows={2}
                  disabled={!isEditing}
                />
              </Form.Item>
            </>
            <>
              <Form.Item
                name="city"
                label={
                  <span>
                    City <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
              >
                <Input
                  placeholder="Enter"
                  className="h-10"
                  disabled={!isEditing}
                />
              </Form.Item>
            </>
            <>
              <Form.Item
                name="state"
                label={
                  <span>
                    State <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
              >
                <Select
                  showSearch
                  className="h-10"
                  options={stateData.map((ele) => ({
                    value: ele.stateId,
                    label: ele.stateName,
                  }))}
                  disabled={!isEditing}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                />
              </Form.Item>
            </>
            <>
              <Form.Item
                name="country"
                label={
                  <span>
                    Country <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
              >
                <Input
                  placeholder="Enter"
                  className="h-10"
                  value="India"
                  disabled={!isEditing}
                />
              </Form.Item>
            </>
            <>
              <Form.Item
                name="pinCode"
                label={
                  <span>
                    Pincode <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
              >
                <Input
                  placeholder="Enter"
                  className="h-10"
                  disabled={!isEditing}
                  maxLength={6}
                />
              </Form.Item>
            </>
            <div className="w-full flex items-center gap-2">
              <Form.Item key={selectedPatient} name="parentname" label={<span>Parent Patient Name</span>}>
                <Input
                  placeholder="Parent Patient"
                  className="h-10"
                  maxLength={10}
                  disabled={!selectedPatient && !isEditing}
                />
              </Form.Item>
              <button disabled={!isEditing} onClick={handlefamilyMemberModal} className="cursor-pointer disabled:cursor-not-allowed disabled:opacity-70">
                <FaSearch className="text-primaryColor text-lg" />
              </button>
            </div>
            <>
              <Form.Item name="relation" label={<span>Relation</span>}>
                <Select className="h-10" disabled={!isEditing}>
                  {
                    relationList?.map((relation) => (
                      <Select.Option value={relation.relationCode}>{relation.relationDescription}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </>
          </Form>
          <>
            <div className="flex items-center w-full justify-end my-5">
              {isEditing && (
                <button
                  onClick={onFinish}
                  className="bg-secondaryColor text-white border-brand-secondaryColor border rounded px-5 py-1.5 text-sm ml-3">
                  Save Changes
                </button>
              )}
            </div>
          </>
        </div>

        {/* <div>
          {patientFamilyData.length > 0 &&
            patientFamilyData.map((user, index) => (
              <PatientForm
                key={user.id}
                user={user}
                salutation={salutation}
                gender={gender}
                bloodGroup={bloodGroup}
                marital={marital}
                Relation={Relation}
                stateData={stateData}
                patientData={patientFamilyData}
                handleFinish={(values) => handleFinish(values, index)}
                isEditing={isEditing[index]}
                setIsEditing={(value) => {
                  const newEditingState = [...isEditing];
                  newEditingState[index] = value;
                  setIsEditing(newEditingState);
                }}
              />
            ))}
        </div> */}
      </div>

      {/* {isModalOpen && (
        <Modal
          title="Add Family Member"
          visible={isModalOpen}
          onOk={() => handleCloseModal()}
          centered={true}
          footer={null}
          onCancel={() => handleCloseModal()}
          okText="Submit"
          cancelText="Cancel"
          width="60%"
          maskClosable={false}
        >
          <AddFamilyMember onClose={() => handleCloseModal()} />
        </Modal>
      )} */}

      <Modal
        title={<h2 className="text-lg font-semibold">Parent Patient Details</h2>}
        open={familyMemModal}
        onCancel={handleFamMemModalClose}
        destroyOnClose
        footer={[]}
        width={"50%"}
      >
        <FamilyMember handleSelParPat={handleSelParPat} handleFamMemModalClose={handleFamMemModalClose} />
      </Modal>
    </Layout>
  );
};

export default MyProfile;
