import { Checkbox, DatePicker, Form, InputNumber, message, Radio } from 'antd';
import React, { useRef, useState } from 'react'
import { convertUTCDate } from '../utils/convertionFunc';
import UserService from '../Services/UserService';

function PatientRegistration({ doctor, handlePostPatientCreation, handleCancel }) {
    const formRef = useRef();
    const [dob, setDob] = useState('');
    const [slotChecked, setSlotChecked] = useState(false);

    const handleSlot = () => {
        setSlotChecked(!slotChecked);
    }

    const handlePatientCreate = async () => {
        const { firstname, lastname, gender, email, mobile } = formRef.current.getFieldValue();
        if (!firstname || !lastname || !dob || !mobile) {
            message.error('Please fill in required fields.');
        }

        const payload = {
            account_Id: doctor?.account_Id,
            account_Name: "",
            address1: "",
            address2: "",
            bloodGroup: "",
            city: "",
            clinic_Id: doctor?.clinic_Id,
            clinic_Name: "",
            country: "",
            createdBy: doctor?.userId,
            createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            dob: dob,
            email: email,
            firstName: firstname,
            gender,
            lastName: lastname,
            loginId: "",
            loginIdAvailableYN: "",
            maritalStatus: "",
            meetingLink: "",
            mobile,
            modifiedBy: doctor?.userId,
            modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            parentPatientId: "",
            parentPatientName: "",
            patientId: "",
            patientRegFee: slotChecked ? 500 : 0,
            patientRegFeeYN: slotChecked ? 'Y' : 'N',
            photo: "",
            photoName: "",
            photoType: "",
            pinCode: "",
            relationCode: "",
            relationDescription: "",
            salutation: "",
            state: "",
            status: 1,
            userId: doctor?.userId,
            userName: doctor?.userName
        }
        console.log(payload, ' .... ');
        try {
            const response = await UserService.addPatient(payload);
            const patientPayload = {
                patientId: response.data?.Patientid,
                status: 1
            }
            const patientResponse = await UserService.getPatient(patientPayload);
            console.log(patientResponse.data);
            // handlePostPatientCreation(patientResponse.data);
            setSlotChecked(false);
            handleCancel();
            formRef.current.resetFields();
        }
        catch (e) {
            console.log(e)
        }
    }

    const handleDob = (value, valueString) => {
        setDob(valueString);
    };


    return (
        <div className='bg-[#F7F7F5]'>
            <Form ref={formRef}
                initialValues={{
                    regfee: 500
                }}
            >
                {/* <div className="rounded-lg"> */}
                    <div className="w-full p-5 rounded-md">
                        <div className="mb-4">
                            <label className="block mb-1 text-[#175b66] font-bold">Name</label>
                            <Form.Item name="firstname">
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    className="border-b-2 border-[#15A88B] rounded w-full p-2 mb-2 outline-none mt-2"
                                />
                            </Form.Item>
                            <Form.Item name="lastname">
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    className="border-b-2 border-[#15A88B] rounded w-full p-2 mb-2 outline-none mt-2"
                                />
                            </Form.Item>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1 text-[#175b66] font-bold">Mobile Number</label>
                            <Form.Item name="mobile">
                                <InputNumber
                                    controls={false}
                                    min={0}
                                    maxLength={10}
                                    onKeyDown={(event) => {
                                        const allowedKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight'];

                                        if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
                                            event.preventDefault();  // Prevent non-numeric and non-control key input
                                        }
                                    }}
                                    placeholder="Mobile number"
                                    className="border-b-2 border-[#15A88B] rounded w-full p-2 mb-2 outline-none mt-2 disabled:opacity-50"
                                />
                            </Form.Item>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1 text-[#175b66] font-bold">Date of Birth</label>
                            {/* <Form.Item> */}
                            {/* <input
                    type="text"
                    placeholder="dd/mm/yyyy"
                    className="border-b-2 border-[#15A88B] rounded w-full p-2 mb-2 outline-none mt-2"
                  /> */}
                            <DatePicker
                                placeholder="Date of birth"
                                format="MM/DD/YYYY"
                                // className="w-full md:h-12 h-10 border-2 rounded-lg"
                                className="border-b-2 border-[#15A88B] rounded w-full p-2 mb-2 outline-none mt-2"
                                onChange={handleDob}
                                disabledDate={(current) => current && current.valueOf() > Date.now()}
                            />
                            {/* </Form.Item> */}
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1 text-[#175b66] font-bold">Gender</label>
                            <div className="flex gap-10 mt-2">
                                <Form.Item name="gender">
                                    <Radio.Group>
                                        <Radio.Button className="bg-white shadow-lg text-[#F49D15] font-medium" value="M">Male</Radio.Button>
                                        <Radio.Button className="bg-white  shadow-lg  text-[#F49D15] font-medium" value="F">Female</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                                {/* <button className="bg-white px-5 shadow-lg py-2 rounded-lg text-[#F49D15] font-medium">
                    Male
                  </button> */}
                                {/* <button className="bg-white px-5 shadow-lg py-2 rounded-lg text-[#F49D15] font-medium">
                    Female
                  </button> */}
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1 text-[#175b66] font-bold">Email Address(Optional)</label>
                            <Form.Item name="email">
                                <input
                                    type="email"
                                    placeholder="Email id"
                                    className="border-b-2 border-[#15A88B] rounded w-full p-2 mb-2 outline-none mt-2"
                                />
                            </Form.Item>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1 text-[#175b66] font-bold">Registration Fee</label>
                            <div className="flex items-center gap-2">
                                <Form.Item valuePropName="checked">
                                    <Checkbox checked={slotChecked} onChange={handleSlot}>
                                        {slotChecked ? 'Yes' : 'No'}
                                    </Checkbox>
                                </Form.Item>
                                {slotChecked &&
                                    (<Form.Item name='regfee' className="w-full">
                                        <InputNumber
                                            disabled={true}
                                            controls={false}
                                            min={0}
                                            type="text"
                                            placeholder="Registration Fee"
                                            className="border-b-2 border-[#15A88B] rounded w-full p-2 mb-2 outline-none mt-2 disabled:opacity-50"
                                        />
                                    </Form.Item>)}
                            </div>

                        </div>
                    </div>

                {/* </div> */}
            </Form>
            <div className="flex justify-end items-center p-2 gap-3">
                <button
                    onClick={handleCancel}
                    className="md:min-w-28 w-[6rem] md:w-1/6 md:p-3 p-1 bg-White rounded-lg border text-primaryColor border-primaryColor font-semibold custom-button-shadow"
                >
                    Cancel
                </button>
                <button
                    onClick={handlePatientCreate}
                    className="md:min-w-28 w-[6rem] md:w-1/6 md:p-3 p-1 bg-primaryColor text-White rounded-lg font-semibold disabled:cursor-not-allowed disabled:opacity-50"
                >
                    Save
                </button>
            </div>
        </div>
    )
}

export default PatientRegistration