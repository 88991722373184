import moment from "moment";

export const generateDates = (startFrom, numDays) => {
    const dates = [];
    const currentDate = new Date(startFrom);

    for (let i = 0; i < numDays; i++) {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() + i);

        const day = newDate.toLocaleDateString("en-US", { weekday: 'short' }); // e.g., 'Mon'
        const date = newDate.getDate(); // e.g., 7
        const month = newDate.toLocaleDateString("en-US", { month: 'long' }); // e.g., 'October'
        const fullDate = moment(newDate).format('MM/DD/YYYY');

        dates.push({ day, date, month, fullDate });
    }

    return dates;
};

export const monthChecker = (month) => {
    switch (month) {
        case '01':
            return 'January';
        case '02':
            return 'February';
        case '03':
            return 'March';
        case '04':
            return 'April';
        case '05':
            return 'May';
        case '06':
            return 'June';
        case '07':
            return 'July';
        case '08':
            return 'August';
        case '09':
            return 'September';
        case '10':
            return 'October';
        case '11':
            return 'November';
        case '12':
            return 'December';
        default:
            return 'Invalid Month';
    }
}

export const changeIntoString = (date) => {
    const month = monthChecker(date.slice(0, 2));
    const day = date.slice(3, 5);
    const year = date.slice(6);
    return `${day} ${month} ${year}`
}

export const generateTimeSlots = (selectedDate,fromTime, toTime) => {
    const morningSlots = [];
    const eveningSlots = [];
    const morningEnd = moment('12:00 pm', 'h:mm a'); // Noon boundary
    let currentSlot = moment(fromTime, 'h:mm a');
    const endTime = moment(toTime, 'h:mm a');
    const now = moment();
console.log(selectedDate)
    while (currentSlot.isBefore(endTime)) {
        
        if (selectedDate <= new Date().getDate() &&  currentSlot.isBefore(now)) {
            currentSlot = currentSlot.add(15, 'minutes');
            continue;
        }
        // Add the current slot to the appropriate section
        const formattedSlot = currentSlot.format('h:mm a');

        if (currentSlot.isBefore(morningEnd)) {
            morningSlots.push(formattedSlot); // Morning section
        } else {
            eveningSlots.push(formattedSlot); // Evening section
        }

        // Increment the time by 15 minutes
        currentSlot = currentSlot.add(15, 'minutes');
    }

    console.log(morningSlots, eveningSlots);

    return { morningSlots, eveningSlots };
};
