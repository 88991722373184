import React from "react";
import {  Segmented } from "antd";
import { useState } from "react";
import MyPresctription from "./MyPrescription/index";
import MyVitals from "./MyVitals/MyVitals";
import MedicaHistory from "./MedicaHistory";

const MedicalRecords = () => {
  const [selectedOption, setSelectedOption] = useState("Medical history");

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };
  return (
      <div className=" h-screen px-5 pt-5">
        <div className="flex mb-2 ">
          <h2 className="font-bold text-lg">Medical Records</h2>
        </div>
        <div className="flex  items-center mb-8">
          <div className="w-7/12">
            <Segmented
              className="appSegment"
              onChange={handleOptionChange}
              block
              options={[
                "Medical history",
                "My prescription",
                "My vitals",
                // "My Reports",
              ]}
            />
          </div>
        </div>
        <div className="relative p-1 h-full customHeight">
          {selectedOption === "Medical history" && <MedicaHistory />}

          {selectedOption === "My prescription" && <MyPresctription />}

          {selectedOption === "My vitals" && <MyVitals/>}
        </div>
      </div>
  );
};

export default MedicalRecords;
