import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Select, Spin, message } from "antd";
import bp from "../../../assets/images/bloodpressureicon.png";
import heart from "../../../assets/images/heartrate.gif";
import temp from "../../../assets/images/Temp.jpg";
import oxygen from "../../../assets/images/Svg/oxygen.svg";
import pulse from "../../../assets/images/PulseRate.jpg";
import height from "../../../assets/images/Height.jpg";
import weight from "../../../assets/images/Weight.jpg";
import bmi from "../../../assets/images/BMI.jpg";
import Vector from "../../../assets/images/Vector.png"
import EditIcon from "../../../assets/images/Svg/edit.svg";
import AddVitals from "./AddVitals";
import VitalService from "../../../Services/VitalService";
import VitalChart from "./VitalChart";
import ViewVital from "./ViewVital";
import { useData } from "../../../Services/Context";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import Layout from "../../../Layout";

const MyVitals = () => {
  const [vitalsData, setVitalsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewGraph, setViewGraph] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [selectedVitalType, setSelectedVitalType] = useState(null);
  const [selectedVitalData, setSelectedVitalData] = useState([]);
  const [selectedVitalCode, setSelectedVitalCode] = useState("");
  const [vitalName, setVitalName] = useState("");
  const [isEditing, setIsEditing] = useState("");
  const [editedValue, setEditedValue] = useState();
  const [sysValue, setSysValue] = useState("");
  const [diaValue, setDiaValue] = useState("");
  const [vital, setVital] = useState([null, null]);
  const [vitalCode, setVitalCode] = useState(null);
  const { patientLoginData } = useData();
  const [hasBlurredSYS, setHasBlurredSYS] = useState(false);
  const [hasBlurredDIA, setHasBlurredDIA] = useState(false);
  const [vitalList, setVitalList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  const handleClosemodal = () => {
    setIsModalVisible(false);
    vitalalldata();
    // MyVitalList();
  };

  const convertUTCDateLocal = (utcDate, format) => {
    if (!format) {
      format = "DD/MM/YYYY, HH:mm:ss";
    }
    const localDate = moment.utc(utcDate).local();
    return localDate.format(format);
  };

  const handleEditClick = (vitalData, key) => {
    setIsEditing(key);

    if (key === "BP") {
      // Handle Blood Pressure (BP) case
      const systolicVital = vitalData.find(
        (vital) => vital.vitalCode === "BPS"
      );
      const diastolicVital = vitalData.find(
        (vital) => vital.vitalCode === "BPD"
      );

      setSysValue(systolicVital?.value || "");
      setDiaValue(diastolicVital?.value || "");

      // Set vital state with both Systolic (BPS) and Diastolic (BPD)
      setVital([systolicVital, diastolicVital]);
    } else {
      // For other vital types like TP, PO, set vital state directly
      setEditedValue(vitalData[0]?.value || "");
      setVital(vitalData);
    }
  };

  const handleInputChange = (event, type) => {
    if (type === "SYS") setSysValue(event.target.value);
    else if (type === "DIA") setDiaValue(event.target.value);
    else setEditedValue(event.target.value);
  };

  const handleInputBlur = (type) => {
    if (type === "SYS") setHasBlurredSYS(true);
    else if (type === "DIA") setHasBlurredDIA(true);
    // else setIsEditing(false);

    // If both SYS and DIA have been blurred, process the update
    if (isEditing === "BP" && hasBlurredSYS && hasBlurredDIA) {
      // Ensure both values are present before creating the payload
      if (sysValue && diaValue) {
        const updatedSystolic = {
          ...vital[0],
          value: sysValue,
          account_Id: patientLoginData?.account_Id,
          clinic_Id: patientLoginData?.clinic_Id,
        };

        const updatedDiastolic = {
          ...vital[1],
          value: diaValue,
          account_Id: patientLoginData?.account_Id,
          clinic_Id: patientLoginData?.clinic_Id,
        };

        const payload = [updatedSystolic, updatedDiastolic];

        // Make the API call
        VitalService.VitalsUpdate(payload)
          .then((response) => {
            vitalalldata();
            // MyVitalList();
            message.success("Blood Pressure Updated Successfully", 4);
          })
          .catch((error) => {
            console.error("Error sending data:", error);
            message.error("Failed to update blood pressure", 4);
          });

        // Reset the blur flags and input values after the update
        setHasBlurredSYS(false);
        setHasBlurredDIA(false);
        setIsEditing(false);
      }
    } else if (isEditing !== "BP" && editedValue) {
      // Handle updating for non-BP values
      const updatedVital = {
        ...vital[0],
        value: editedValue,
        account_Id: patientLoginData?.account_Id,
        clinic_Id: patientLoginData?.clinic_Id,
      };

      const payload = [updatedVital];

      VitalService.VitalsUpdate(payload)
        .then((response) => {
          vitalalldata();
          // MyVitalList();
          message.success("Vital Updated Successfully", 4);
        })
        .catch((error) => {
          console.error("Error sending data:", error);
          message.error("Failed to update vital", 4);
        });
      setIsEditing(false);
    }
  };
  const handleVitalDateChange = (value) => {
    setSelectedDate(value);
    MyVitalList(value);
  };
  const vitalalldata = async (value) => {
    let payload = {
      patientId: activePatient.patientId,
      // fromDate: value,
      // toDate: value,
      status: 1,
    };
    // setLoading(true);
    await VitalService.getVitalDateTime(payload)
      .then((result) => {
        setVitalList(result.data);
        if (result.data && result.data.length > 0) {
          const defaultDate = result.data[0]?.recordedDateTime;
          setSelectedDate(defaultDate);
          MyVitalList(defaultDate);
        }
        else{
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch reports:", error);
        setVitalList([]);
      });
    // .finally(() => {
    //   setLoading(false);
    // });
  };

  const MyVitalList = (values) => {
    let payload = {
      patientId: activePatient.patientId,
      fromDate: values,
      toDate: values,
    };
    VitalService.getVitalsData(payload)
      .then((result) => {
        setVitalsData(result?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    vitalalldata();
  }, []);

  const handleViewAll = (type1, type2) => {
    console.log(type1, "type1");
    console.log(type2, "type2");
    setSelectedVitalCode(`${type1},${type2}`);
    const vital =
      type1 === "BPS"
        ? "Blood Pressure"
        : type1 === "TP"
        ? "Temperature"
        : type1 === "HI"
        ? "Height"
        : type1 === "WE"
        ? "Weight"
        : type1 === "BMI"
        ? "BMI"
        : type1 === "HR"
        ? "Heart Rate"
        : type1 === "RR"
        ? "Respiration Rate"
        : type1 === "PO"
        ? "Pulse Oxygen"
        : null;
    setVitalName(vital);
    setViewModal(true);
  };

  const tempValue = vitalsData.length > 1 && vitalsData[0]?.value;
  const heightValue = vitalsData.length > 1 && vitalsData[1]?.value;
  const weightValue = vitalsData.length > 1 && vitalsData[2]?.value;
  const bmiValue = vitalsData.length > 1 && vitalsData[3]?.value;
  const bloodPressureSYS = vitalsData.length > 1 && vitalsData[4]?.value;
  const bloodPressureDIA = vitalsData.length > 1 && vitalsData[5]?.value;
  const pluseValue = vitalsData.length > 1 && vitalsData[6]?.value;
  const heartRateValue = vitalsData.length > 1 && vitalsData[7]?.value;
  const resRateValue = vitalsData.length > 1 && vitalsData[8]?.value;

  const handleGraph = (type, code, data) => {
    setSelectedVitalType(type);
    setSelectedVitalData(data);
    setVitalCode(code);
    setViewGraph(true);
  };

  return (
    <Layout>
      <div>
        <div className="flex justify-between my-3 mx-3">
          <h2 className="font-bold text-lg">My Vitals</h2>
          <div className="flex gap-2">
            <Select
              placeholder="Select Date"
              className="w-56 mx-1"
              // value={selectedDate}
              options={vitalList?.map((record) => {
                return {
                  label: convertUTCDateLocal(record?.recordedDateTime),
                  value: record?.recordedDateTime,
                };
              })}
              onChange={handleVitalDateChange}
            />
            <Button
              type="default"
              onClick={() => {
                setIsModalVisible(true);
              }}
              // icon={<PlusOutlined />}
              className="border-blue-800 text-blue-500 hover:border-blue-700 hover:text-blue-700"
            >
              Add Vitals
            </Button>
          </div>
        </div>
      </div>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
            width: "100%",
          }}
        >
          <Spin indicator={loadingIcon} size="large" />
        </div>
      ) : vitalsData && vitalsData.length > 0 ? (
        <>
          <div className="flex justify-evenly gap-5 mt-5">
            <div className="flex items-center border border-gray-300 rounded-lg shadow-md p-4 bg-white w-full max-w-sm">
              <div className="flex-grow">
                <div className="flex justify-between">
                  <div className="flex">
                    <img src={bp} alt="" className="w-8 h-8 mr-2" />
                    <h3 className="text-lg font-medium">Blood Pressure</h3>
                  </div>
                  {isEditing == "BP" ? (
                    <>
                      <div className="flex items-center">
                        <Input.Group className="">
                          <Input
                            maxLength={3}
                            placeholder="SYS"
                            value={sysValue}
                            onChange={(e) => handleInputChange(e, "SYS")}
                            onBlur={() => handleInputBlur("SYS")}
                            className="outline-none border text-center"
                            style={{ width: "3rem" }}
                          />
                          {/* <span style={{ width: "10%", textAlign: "center" }}>
                        /
                      </span> */}
                          <Input
                            maxLength={3}
                            placeholder="DIA"
                            value={diaValue}
                            onChange={(e) => handleInputChange(e, "DIA")}
                            onBlur={() => handleInputBlur("DIA")}
                            className="outline-none border text-center"
                            style={{ width: "3rem" }}
                          />
                        </Input.Group>
                      </div>
                      <div>
                        <span className="text-base text-gray-700"> mmHg</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <p className="text-lg font-semibold">
                          {
                            vitalsData.filter(
                              (vital) => vital.vitalCode === "BPS"
                            )[0]?.value
                          }
                          /
                          {
                            vitalsData.filter(
                              (vital) => vital.vitalCode === "BPD"
                            )[0]?.value
                          }{" "}
                          <span className="text-base text-gray-700">mmHg</span>
                        </p>
                      </div>
                      <div>
                        <img
                          src={EditIcon}
                          onClick={() => {
                            const bpsData = vitalsData.find((vital) => vital.vitalCode === "BPS");
                            const bpdData = vitalsData.find((vital) => vital.vitalCode === "BPD");
                            handleEditClick([bpsData, bpdData], "BP");
                          }}
                          className="w-5 cursor-pointer"
                        />
                      </div>
                    </>
                  )}
                </div>
                <hr className="my-4 border-gray-300" />
                <div className="flex justify-between">
                  <img
                    className="cursor-pointer"
                    src={Vector}
                    onClick={() =>
                      handleGraph("Blood Pressure", 'BP', [
                        vitalsData[4],
                        vitalsData[5],
                      ])
                    }
                  />
                  <div
                    onClick={() => handleViewAll("BPS", "BPD")}
                    className="font-medium cursor-pointer text-blue-600"
                  >
                    View All
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center border border-gray-300 rounded-lg shadow-md p-4 bg-white w-full max-w-sm">
              <div className="flex-grow">
                <div className="flex justify-between">
                  <div className="flex">
                    <img src={temp} alt="" className="w-8 h-8 mr-2" />
                    <h3 className="text-lg font-medium">Temperature</h3>
                  </div>
                  {isEditing == "TP" ? (
                    <>
                      <Input
                        type="text"
                        value={editedValue}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        className="w-[4rem]"
                      />
                      <span className="text-base text-gray-700">°F</span>
                    </>
                  ) : (
                    <>
                      <div>
                        <p className="text-lg font-semibold">
                          {
                            vitalsData.filter(
                              (vital) => vital.vitalCode === "TP"
                            )[0]?.value
                          }{" "}
                          <span className="text-base text-gray-700">°F</span>
                        </p>
                      </div>
                      <div>
                        <img
                          src={EditIcon}
                          onClick={() => handleEditClick(vitalsData.filter(
                            (vital) => vital.vitalCode === "TP"
                          ), "TP")}
                          className="w-5 cursor-pointer"
                        />
                      </div>
                    </>
                  )}
                </div>
                <hr className="my-4 border-gray-300" />
                <div className="flex justify-between">
                  <img
                    className="cursor-pointer"
                    src={Vector}
                    onClick={() =>
                      handleGraph("Temperature", "TP", [vitalsData[0]])
                    }
                  />
                  <div
                    onClick={() => handleViewAll("TP")}
                    className="font-medium cursor-pointer text-blue-600"
                  >
                    View All
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center border border-gray-300 rounded-lg shadow-md p-4 bg-white w-full max-w-sm">
              <div className="flex-grow">
                <div className="flex justify-between">
                  <div className="flex">
                    <img src={heart} alt="" className="w-8 h-8 mr-2" />
                    <h3 className="text-lg font-medium">Heart Rate</h3>
                  </div>
                  {isEditing == "HR" ? (
                    <>
                      <Input
                        type="text"
                        value={editedValue}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        className="w-[4rem]"
                      />
                      <span className="text-base text-gray-900">Beats/min</span>
                    </>
                  ) : (
                    <>
                      <div>
                        <p className="text-lg font-semibold">
                          {
                            vitalsData.filter(
                              (vital) => vital.vitalCode === "HR"
                            )[0]?.value
                          }{" "}
                          <span className="text-base text-gray-700">
                            Beats/min
                          </span>
                        </p>
                      </div>
                      <div>
                        <img
                          src={EditIcon}
                          onClick={() => handleEditClick( vitalsData.filter(
                            (vital) => vital.vitalCode === "HR"
                          ), "HR")}
                          className="w-5 cursor-pointer"
                        />
                      </div>
                    </>
                  )}
                </div>
                <hr className="my-4 border-gray-300" />
                <div className="flex justify-between">
                  <img
                    className="cursor-pointer"
                    src={Vector}
                    onClick={() =>
                      handleGraph("Heart Rate", "HR", [vitalsData[7]])
                    }
                  />
                  <div
                    onClick={() => handleViewAll("HR")}
                    className="font-medium cursor-pointer text-blue-600"
                  >
                    View All
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-evenly gap-5 mt-6">
            <div className="flex items-center border border-gray-300 rounded-lg shadow-md p-4 bg-white w-full max-w-sm">
              <div className="flex-grow">
                <div className="flex justify-between">
                  <div className="flex">
                    <img src={pulse} alt="" className="w-8 h-8 mr-2" />
                    <h3 className="text-lg font-medium">Pulse Oxygen</h3>
                  </div>
                  {isEditing == "PO" ? (
                    <>
                      <Input
                        type="text"
                        value={editedValue}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        className="w-[4rem]"
                      />
                      <span className="text-base text-gray-900">%</span>
                    </>
                  ) : (
                    <>
                      <div>
                        <p className="text-lg font-semibold">
                          {
                            vitalsData.filter(
                              (vital) => vital.vitalCode === "PO"
                            )[0]?.value
                          }{" "}
                          <span className="text-base text-gray-700">%</span>
                        </p>
                      </div>
                      <div>
                        <img
                          src={EditIcon}
                          onClick={() => handleEditClick(vitalsData.filter(
                            (vital) => vital.vitalCode === "PO"
                          ), "PO")}
                          className="w-5 cursor-pointer"
                        />
                      </div>
                    </>
                  )}
                </div>
                <hr className="my-4 border-gray-300" />
                <div className="flex justify-between">
                  <img
                    className="cursor-pointer"
                    src={Vector}
                    onClick={() =>
                      handleGraph("Pulse Oxygen", "PO", [vitalsData[6]])
                    }
                  />
                  <div
                    onClick={() => handleViewAll("PO")}
                    className="font-medium cursor-pointer text-blue-600"
                  >
                    View All
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center border border-gray-300 rounded-lg shadow-md p-4 bg-white w-full max-w-sm">
              <div className="flex-grow">
                <div className="flex justify-between">
                  <div className="flex">
                    <img src={oxygen} alt="" className="w-8 h-8 mr-2" />
                    <h3 className="text-lg font-medium">Respiration Rate</h3>
                  </div>
                  {isEditing == "RR" ? (
                    <>
                      <Input
                        type="text"
                        value={editedValue}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        className="w-[4rem]"
                      />
                      <span className="text-base text-gray-900">Beats/min</span>
                    </>
                  ) : (
                    <>
                      <div>
                        <p className="text-lg font-semibold">
                          {
                            vitalsData.filter(
                              (vital) => vital.vitalCode === "RR"
                            )[0]?.value
                          }{" "}
                          <span className="text-base text-gray-700">
                            Beats/min
                          </span>
                        </p>
                      </div>
                      <div>
                        <img
                          src={EditIcon}
                          onClick={() => handleEditClick(vitalsData.filter(
                            (vital) => vital.vitalCode === "RR"
                          ), "RR")}
                          className="w-5 cursor-pointer"
                        />
                      </div>
                    </>
                  )}
                </div>
                <hr className="my-4 border-gray-300" />
                <div className="flex justify-between">
                  <img
                    className="cursor-pointer"
                    src={Vector}
                    onClick={() =>
                      handleGraph("Respiration Rate", "RR", [vitalsData[8]])
                    }
                  />
                  <div
                    onClick={() => handleViewAll("RR")}
                    className="font-medium cursor-pointer text-blue-600"
                  >
                    View All
                  </div>
                </div>
              </div>
            </div>

            <div className="flex  items-center  w-full max-w-sm"></div>
          </div>
          <hr className="my-4 border-gray-400" />
          <div className="mx-2">
            <h2 className="text-lg font-bold">Anthropometry</h2>
          </div>
          <div className="flex justify-evenly gap-5 my-6">
            <div className="flex items-center border border-gray-300 rounded-lg shadow-md p-4 bg-white w-full max-w-sm">
              <div className="flex-grow">
                <div className="flex justify-between">
                  <div className="flex">
                    <img src={height} alt="" className="w-8 h-8 mr-2" />
                    <h3 className="text-lg font-medium">Height</h3>
                  </div>
                  {isEditing == "HI" ? (
                    <>
                      <Input
                        type="text"
                        value={editedValue}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        className="w-[4rem]"
                      />
                      <span className="text-base text-gray-900">Cm</span>
                    </>
                  ) : (
                    <>
                      <div>
                        <p className="text-lg font-semibold">
                          {
                            vitalsData.filter(
                              (vital) => vital.vitalCode === "HI"
                            )[0]?.value
                          }{" "}
                          <span className="text-base text-gray-700">Cm</span>
                        </p>
                      </div>
                      <div>
                        <img
                          src={EditIcon}
                          onClick={() => handleEditClick(vitalsData.filter(
                            (vital) => vital.vitalCode === "HI"
                          ), "HI")}
                          className="w-5 cursor-pointer"
                        />
                      </div>
                    </>
                  )}
                </div>
                <hr className="my-4 border-gray-300" />
                <div className="flex justify-between">
                  <img
                    className="cursor-pointer"
                    src={Vector}
                    onClick={() => handleGraph("Height", "HI", [vitalsData[1]])}
                  />
                  <div
                    onClick={() => handleViewAll("HI")}
                    className="font-medium cursor-pointer text-blue-600"
                  >
                    View All
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center border border-gray-300 rounded-lg shadow-md p-4 bg-white w-full max-w-sm">
              <div className="flex-grow">
                <div className="flex justify-between">
                  <div className="flex">
                    <img src={weight} alt="" className="w-8 h-8 mr-2" />
                    <h3 className="text-lg font-medium">Weight</h3>
                  </div>
                  {isEditing == "WE" ? (
                    <>
                      <Input
                        type="text"
                        value={editedValue}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        className="w-[4rem]"
                      />
                      <span className="text-base text-gray-900">Kg</span>
                    </>
                  ) : (
                    <>
                      <div>
                        <p className="text-lg font-semibold">
                          {
                            vitalsData.filter(
                              (vital) => vital.vitalCode === "WE"
                            )[0]?.value
                          }{" "}
                          <span className="text-base text-gray-700">Kg</span>
                        </p>
                      </div>
                      <div>
                        <img
                          src={EditIcon}
                          onClick={() => handleEditClick(vitalsData.filter(
                            (vital) => vital.vitalCode === "WE"
                          ), "WE")}
                          className="w-5 cursor-pointer"
                        />
                      </div>
                    </>
                  )}
                </div>
                <hr className="my-4 border-gray-300" />
                <div className="flex justify-between">
                  <img
                    className="cursor-pointer"
                    src={Vector}
                    onClick={() => handleGraph("Weight", "WE", [vitalsData[2]])}
                  />
                  <div
                    onClick={() => handleViewAll("WE")}
                    className="font-medium cursor-pointer text-blue-600"
                  >
                    View All
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center border border-gray-300 rounded-lg shadow-md p-4 bg-white w-full max-w-sm">
              <div className="flex-grow">
                <div className="flex justify-between">
                  <div className="flex">
                    <img src={bmi} alt="" className="w-8 h-8 mr-2" />
                    <h3 className="text-lg font-medium">BMI</h3>
                  </div>

                  <div>
                    <p className="text-lg font-semibold">
                      {
                        vitalsData.filter(
                          (vital) => vital.vitalCode === "BMI"
                        )[0]?.value
                      }{" "}
                      <span className="text-base text-gray-700">Kg/m2</span>
                    </p>
                  </div>
                  <div>
                    {/* <img
                        src={EditIcon}
                        onClick={() => handleEditClick([vitalsData[8]], "RR")}
                        className="w-5 cursor-pointer"
                      /> */}
                  </div>
                </div>
                <hr className="my-4 border-gray-300" />
                <div className="flex justify-between">
                  <img
                    className="cursor-pointer"
                    src={Vector}
                    onClick={() => handleGraph("BMI", "BMI", [vitalsData[3]])}
                  />
                  <div
                    onClick={() => handleViewAll("BMI")}
                    className="font-medium cursor-pointer text-blue-600"
                  >
                    View All
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
            borderRadius: "8px",
            color: "#595959",
            height: "200px",
            textAlign: "center",
            width: "100%",
          }}
        >
          {/* <p style={{ fontSize: "18px", fontWeight: "bold" }}>
            No Vitals found
          </p> */}
        </div>
      )}
      {isModalVisible && (
        <Modal
          title={<span className="text-lg font-semibold">Vitals</span>}
          visible={isModalVisible}
          onOk={() => {
            setIsModalVisible(false);
          }}
          centered={true}
          footer={null}
          onCancel={() => {
            console.log("Cancel button clicked");
            setIsModalVisible(false);
          }}
          okText="Submit"
          cancelText="Cancel"
          width={650}
          maskClosable={false}
        >
          <AddVitals onClose={() => handleClosemodal()} />
        </Modal>
      )}

      {viewModal && (
        <Modal
          title={`Vitals - ${vitalName}`}
          visible={viewModal}
          onOk={() => {
            setViewModal(false);
          }}
          centered={true}
          footer={null}
          onCancel={() => {
            console.log("Cancel button clicked");
            setViewModal(false);
          }}
          okText="Submit"
          cancelText="Cancel"
          width={400}
          maskClosable={false}
        >
          <ViewVital
            selectedVitalCode={selectedVitalCode}
            vitalName={vitalName}
            onClose={() => handleClosemodal()}
          />
        </Modal>
      )}

      {viewGraph && (
        <VitalChart
          title={`${selectedVitalType} Graph`}
          data={selectedVitalCode}
          type={selectedVitalType}
          vitalCode={vitalCode}
          closePopup={() => setViewGraph(false)}
        />
      )}
    </Layout>
  );
};

export default MyVitals;
