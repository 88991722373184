import React, { useState } from 'react';
import Check from '../assets/images/tick.png';
import Cross from '../assets/images/cross.png';
import MultiStepModal from './MultiStepModal'; // Import the multi-step modal

function Plans() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className='plans py-20 sm:pt-20 sm:pb-[750px]  mx-20 sm:mx-10 md:mx-10 l:mx-20 x:mx-20 lg:mx-20 xl:mx-20 2xl:mx-20'>
      <div>
        <h1 className='headingFont text-center sm:text-[40px] md:text-[55px] lg:text-[40px] x:text-[50px] xl:text-[50px] 2xl:text-[60px] text-[#002f72]'>Choose Your Best Plan</h1>
      </div>

      <div className='my-20 relative'>
        <div className='flex sm:flex-col justify-between sm:justify-around headingFont mt-10'>
          <div className='basic py-10 w-1/3 sm:w-[100%] bg-[#f8f8f8]'>
            <h1 className='lg:text-[25px] sm:text-[25px] md:text-[30px] x:text-[30px] xl:text-[30px] 2xl:text-[50px] text-[#002f72] text-center'>Basic</h1>
            <div className='flex flex-col sm:text-[14px]'>
              <div className='flex gap-x-2 mx-auto mt-7'>
                <img className='2xl:h-[18px] x:h-[12px] xl:h-[12px] my-auto' src={Check} alt="" />
                <p className='text-[#666] 2xl:text-[18px] x:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[12px] font-thin'>Tele Medicine</p>
              </div>
              <div className='flex gap-x-2 mx-auto mt-7'>
                <img className='2xl:h-[18px] x:h-[12px] xl:h-[12px] my-auto' src={Check} alt="" />
                <p className='text-[#666] 2xl:text-[18px] x:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[12px] font-thin'>e-Prescription</p>
              </div>
              <div className='flex gap-x-2 mx-auto mt-7'>
                <img className='2xl:h-[18px] x:h-[12px] xl:h-[12px] my-auto' src={Check} alt="" />
                <p className='text-[#666] 2xl:text-[18px] x:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[12px] font-thin'>Scheduling Appointment</p>
              </div>
              <div className='flex gap-x-2 mx-auto mt-7'>
                <img className='2xl:h-[18px] x:h-[12px] xl:h-[12px] my-auto' src={Cross} alt="" />
                <p className='text-[#666] 2xl:text-[18px] x:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[12px] font-thin'>Electronic Health Records</p>
              </div>
              <div className='flex gap-x-2 mx-auto mt-7'>
                <img className='2xl:h-[18px] x:h-[12px] xl:h-[12px] my-auto' src={Cross} alt="" />
                <p className='text-[#666] 2xl:text-[18px] x:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[12px] font-thin'>Secure Messaging</p>
              </div>
              <div className='text-center text-[#002f72] bg-white w-[70%] mx-auto mt-10 py-5 shadow-2xl'>
                <h1 className='2xl:text-[40px] sm:text-[25px] md:text-[28px] x:text-[30px] xl:text-[30px]'>Rs. 2000 <span className='2xl
                :text-[20px] sm:text-[20px] md:text-[25px] x:text-[16px] xl:text-[16px]'>/3 Months</span></h1>

                <button
                  onClick={toggleModal}
                  className='uppercase text-white bg-[#002f72] md:px-7 md:py-3 md:text-[14px] px-10 py-4 rounded-full mt-8 hover:bg-[#3498DB] transition-all duration-400 ease-in-out'>
                  Subscribe
                </button>
              </div>
            </div>
          </div>

          <div className='basic py-10 w-1/3 sm:w-[100%] sm:mt-10 bg-[#f8f8f8]'>
            <h1 className='lg:text-[25px] sm:text-[25px] md:text-[30px] x:text-[30px] xl:text-[30px] 2xl:text-[50px] text-[#002f72] text-center'>Pro</h1>
            <div className='flex flex-col sm:text-[14px]'>
              <div className='flex gap-x-2 mx-auto mt-7'>
                <img className='2xl:h-[18px] x:h-[12px] xl:h-[12px] my-auto' src={Check} alt="" />
                <p className='text-[#666] 2xl:text-[18px] x:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[12px]  font-thin'>Tele Medicine</p>
              </div>
              <div className='flex gap-x-2 mx-auto mt-7'>
                <img className='2xl:h-[18px] x:h-[12px] xl:h-[12px] my-auto' src={Check} alt="" />
                <p className='text-[#666] 2xl:text-[18px] x:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[12px]  font-thin'>e-Prescription</p>
              </div>
              <div className='flex gap-x-2 mx-auto mt-7'>
                <img className='2xl:h-[18px] x:h-[12px] xl:h-[12px] my-auto' src={Check} alt="" />
                <p className='text-[#666] 2xl:text-[18px] x:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[12px]  font-thin'>Scheduling Appointment</p>
              </div>
              <div className='flex gap-x-2 mx-auto mt-7'>
                <img className='2xl:h-[18px] x:h-[12px] xl:h-[12px] my-auto' src={Cross} alt="" />
                <p className='text-[#666] 2xl:text-[18px] x:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[12px]  font-thin'>Electronic Health Records</p>
              </div>
              <div className='flex gap-x-2 mx-auto mt-7'>
                <img className='2xl:h-[18px] x:h-[12px] xl:h-[12px] my-auto' src={Cross} alt="" />
                <p className='text-[#666] 2xl:text-[18px] x:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[12px]  font-thin'>Secure Messaging</p>
              </div>
              <div className='text-center text-[#002f72] bg-white w-[70%] mx-auto mt-10 py-5 shadow-2xl'>
                <h1 className='2xl:text-[40px] sm:text-[25px] md:text-[28px] x:text-[30px] xl:text-[30px]'>Rs. 4000 <span className='2xl
                :text-[20px] sm:text-[20px] md:text-[25px] x:text-[16px] xl:text-[16px]'>/3 Month</span></h1>

                <button
                  onClick={toggleModal}
                  className='uppercase text-white bg-[#002f72] md:px-7 md:py-3 md:text-[14px] px-10 py-4 rounded-full mt-8 hover:bg-[#3498DB] transition-all duration-400 ease-in-out'>
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='headingFont bg-white absolute sm:top-[1200px] 2xl:left-[30.5%] x:left-[30.5%] xl:left-[30.5%] lg:left-[31.3%] md:left-[30%] shadow-2xl -top-10 pt-10 sm:w-[100%] 2xl:w-[940px] md:w-[270px]  xl:w-[500px] lg:w-[320px] l:w-[430px] x:w-[410px]'>
          <div className='basic py-10'>
            <h1 className='sm:text-[30px] x:text-[30px] md:text-[30px] xl:text-[30px] 2xl:text-[50px] lg:text-[30px] text-[#002f72] text-center'>Standard</h1>
            <div className='flex flex-col sm:text-[14px]'>
              <div className='flex gap-x-2 mx-auto mt-7'>
                <img className='2xl:h-[18px] x:h-[12px] xl:h-[12px] my-auto' src={Check} alt="" />
                <p className='text-[#666] 2xl:text-[18px] x:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[12px]  font-thin'>Tele Medicine</p>
              </div>
              <div className='flex gap-x-2 mx-auto mt-7'>
                <img className='2xl:h-[18px] x:h-[12px] xl:h-[12px] my-auto' src={Check} alt="" />
                <p className='text-[#666] 2xl:text-[18px] x:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[12px]  font-thin'>e-Prescription</p>
              </div>
              <div className='flex gap-x-2 mx-auto mt-7'>
                <img className='2xl:h-[18px] x:h-[12px] xl:h-[12px] my-auto' src={Check} alt="" />
                <p className='text-[#666] 2xl:text-[18px] x:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[12px]  font-thin'>Scheduling Appointment</p>
              </div>
              <div className='flex gap-x-2 mx-auto mt-7'>
                <img className='2xl:h-[18px] x:h-[12px] xl:h-[12px] my-auto' src={Cross} alt="" />
                <p className='text-[#666] 2xl:text-[18px] x:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[12px]  font-thin'>Electronic Health Records</p>
              </div>
              <div className='flex gap-x-2 mx-auto mt-7'>
                <img className='2xl:h-[18px] x:h-[12px] xl:h-[12px] my-auto' src={Cross} alt="" />
                <p className='text-[#666] 2xl:text-[18px] x:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[12px]  font-thin'>Secure Messaging</p>
              </div>
              <div className='text-center text-white bg-[#002f72] w-[80%] mx-auto mt-10 py-10'>
                <h1 className='text-[40px] sm:text-[25px] md:text-[28px] lg:text-[25px]'>Rs. 3000 <span className='text-[20px] lg:text-[17px] sm:text-[17px]  md:text-[25px] x:text-[17px] l:text-[17px] xl:text-[17px] 2xl:text-[17px]'>/3 Month</span></h1>

                <button
                  onClick={toggleModal}
                  className='uppercase text-[#002f72] bg-white px-10 py-4 rounded-full mt-8 hover:bg-[#002f72] hover:text-white border-[1px] border-white transition-all duration-400 ease-in-out'>
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Show the MultiStepModal when the state is true */}
        {isModalOpen && <MultiStepModal closeModal={toggleModal} />}
      </div>
    </div>
  );
}

export default Plans;
