const CryptoJS = require("crypto-js");

export const generateAesKey = (PasswordVal) => {
    try {
        const initVector = process.env.REACT_APP_VECTORKEY;
        const key = process.env.REACT_APP_SECRETKEY;
        const iv = CryptoJS.enc.Utf8.parse(initVector);
        const skeySpec = CryptoJS.enc.Utf8.parse(key);

        const encrypted = CryptoJS.AES.encrypt(
            PasswordVal,
            skeySpec,
            { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
        );
        return encrypted.toString();

    } catch (ex) {
        console.error(ex);
        return null;
    }
};

export const encryptPayload = (payload) => {
    const payloadString = JSON.stringify(payload);
    const encrypted = CryptoJS.AES.encrypt(payloadString, process.env.REACT_APP_SECRETKEY).toString();
    return encrypted;
};

export const decryptPayload = (encryptedPayload) => {
    const bytes = CryptoJS.AES.decrypt(encryptedPayload, process.env.REACT_APP_SECRETKEY);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    const decryptedPayload = JSON.parse(decryptedString);
    return decryptedPayload;
  };



