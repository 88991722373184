import { createStore, applyMiddleware, compose } from "redux";
import asyncReducer from "./allReducer";
import { thunk } from "redux-thunk";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  asyncReducer,
  composeEnhancer(applyMiddleware(thunk))
);
export default store;
