import React from 'react';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import { APP_ROUTES } from './pageRoutes';
import Home from '../Pages/Home';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import TermsOfUse from '../Pages/TermsOfUse';
import RefundPolicy from '../Pages/RefundPolicy';
import ConsultDoctor from '../Pages/BookAppointment/ConsultDoctor';
import BookConsultant from '../Pages/BookAppointment/BookConsultant';
import Checkout from '../Pages/Checkout';
// newly added page imports from patient app
import ShareCall from '../Pages/VideoConsultation/ShareCall';
import Notifications from "../Pages/Notifications";
import VideoCall from "../Pages/VideoConsultation"
import MedicaHistory from "../Pages/MedicalRecords/MedicaHistory";
import MyPresctription from "../Pages/MedicalRecords/MyPrescription";
import MyVitals from "../Pages/MedicalRecords/MyVitals/MyVitals";
import MyReports from "../Pages/MedicalRecords/MyReports";
import ForgotPassword from "../Authentication/ForgotPassword";
import Sharecallpatient from "../Pages/VideoConsultation/Sharecallpatient";
import PaymentPage from "../Pages/MyAppointments/PaymentPage";
import PaymentPage1 from "../Pages/MyAppointments/PaymentPage1";
import SharePrescription from "../Pages/MedicalRecords/MyPrescription/SharePrescription";
import LabPrescription from "../Pages/MedicalRecords/MyPrescription/LabPrescription";
import PharmacyPrescription from "../Pages/MedicalRecords/MyPrescription/PharmacyPrescription";
import PrescriptionTemplate from "../Pages/MedicalRecords/MyPrescription/PrescriptionTemplate";
import MyLabReports from "../Pages/MedicalRecords/MyLabReports";
import MyAppointments from "../Pages/MyAppointments";
import MyProfile from "../Pages/MyProfile";
import MedicalRecords from "../Pages/MedicalRecords";
import Payments from "../Pages/Payments";
import Settings from '../Pages/Settings';


export const PageRoutes = () => {
    return (
        <Routes>
            <Route path={APP_ROUTES.LOGIN} element={<Home />} />
            <Route path={APP_ROUTES.PRIVACYPOLICY} element={<PrivacyPolicy />} />
            <Route path={APP_ROUTES.TERMSOFUSE} element={<TermsOfUse />} />
            <Route path={APP_ROUTES.REFUNDPOLICY} element={<RefundPolicy />} />
            <Route path={APP_ROUTES.CONSULTDOCTOR} element={<ConsultDoctor />} />
            <Route path={APP_ROUTES.DOCTORDETAILS} element={<BookConsultant />} />
            <Route path={APP_ROUTES.CHECKOUT} element={<Checkout />} />
            <Route path={APP_ROUTES.PRIVACYPOLICY} element={<PrivacyPolicy />} />
            {/* newly added pages from patient app */}
            <Route exact path={APP_ROUTES.SHARECALL} element={<ShareCall />} />
            <Route exact path={APP_ROUTES.SHARECALLPATIENT} element={<Sharecallpatient />} />
            <Route path={APP_ROUTES.MYAPPOINMENTS} element={<MyAppointments />} />
            <Route path={APP_ROUTES.MYPROFILE} element={<MyProfile />} />
            <Route path={APP_ROUTES.MEDICALRECORDS} element={<MedicalRecords />} />
            <Route path={APP_ROUTES.MEDICALHISTORY} element={<MedicaHistory />} />
            <Route path={APP_ROUTES.MYPRESCRIPTION} element={<MyPresctription />} />
            <Route path={APP_ROUTES.SHAREPRSCRIPTION} element={<SharePrescription />} />
            <Route path={APP_ROUTES.LABPRSCRIPTION} element={<LabPrescription />} />
            <Route path={APP_ROUTES.PHARMACYPRSCRIPTION} element={<PharmacyPrescription />} />
            <Route path={APP_ROUTES.MYVITALS} element={<MyVitals />} />
            <Route path={APP_ROUTES.MYREPORTS} element={<MyReports />} />
            <Route path={APP_ROUTES.MYLABREPORTS} element={<MyLabReports />} />
            <Route path={APP_ROUTES.PAYMENTS} element={<Payments />} />
            <Route path={APP_ROUTES.NOTIFICATIONS} element={<Notifications />} />
            <Route path={APP_ROUTES.VIDEOCALL} element={<VideoCall />} />
            <Route path={APP_ROUTES.FORGOTPASSWORD} element={<ForgotPassword />} />
            <Route path={APP_ROUTES.PrescriptionTemplate} element={<PrescriptionTemplate />} />
            <Route path={APP_ROUTES.PHONEPEPAYMENT} element={<PaymentPage />} />
            <Route path={APP_ROUTES.PHONEPEPAYMENT1} element={<PaymentPage1 />} />
            <Route path={APP_ROUTES.SETTINGS} element={<Settings />} />
        </Routes>
    )
}
