import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { SaveVideoCall, DeleteVideoUrl } from "../../Redux/videoCallActions";
import { useDispatch } from "react-redux";
import Sharemedicalrecords from "./Sharemedicalrecords";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../PageRouting/pageRoutes";
import moment from "moment";
import AppointmentService from "../../Services/AppointmentService";
import { Spin } from "antd";

const VideoCall = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [url, setUrl] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const location = useLocation();
  const selectedPatient = location?.state?.selectedAppDetails;
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  useEffect(() => {
    getLink();
  }, []);
  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };
  const closeVideo = () => {
    setIsLoading(true);
    // let payload = {
    //   "appointmentId": selectedPatient.appointmentId,
    //   "modifiedBy": patientCode,
    //   "modifiedDate": convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
    //   "patientId": patientCode,
    //   "queueStatus": "N",
    //   "userId": selectedPatient.userId,
    //   "joiningDateTime": convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss")
    // };
    // AppointmentService.callQueueUpdate(payload).then((result) => {
    //   dispatch(DeleteVideoUrl());
    //   navigate(APP_ROUTES.DASHBOARD);
    //  });

    let payload = {
      appointmentId: selectedPatient.appointmentId,
      patientId: patientCode,
      userId: selectedPatient.userId,
    };
    AppointmentService.updateQueueStatus(payload)
    .then((result) => {
      dispatch(DeleteVideoUrl());
      setIsLoading(false);
      navigate(APP_ROUTES.LOGIN);
    })
    .catch((error) => {
      setIsLoading(false);
      console.error("Failed to update queue status:", error);
    });
  };

  const getLink = () => {
    const header = {
      secretKey: "CcJYfbsgItHpTQPFr5lg",
    };
    const data = {
      clientCode: "CCM@@202!",
      userType: "PARTICIPANT",
      meetingKey: selectedPatient?.appointmentId,
      memberName: selectedPatient?.patientName,
      memberEmail: "test@gmail.com",
      memberMobile: "1234567890",
    };
    axios
      .post(
        "https://cb.maggieplus.com/admin-api/client/meeting/authentication",
        data,
        { headers: header }
      )
      .then((result) => {
        setUrl(result.data.response.url);
      })
      .catch((err) => {
        console.log("Err", err);
      });
  };

  useEffect(() => {
    if (url) {
      dispatch(
        SaveVideoCall({
          callurl: url,
          callType: true,
          apptDetails: selectedPatient,
        })
      );
    }
  }, [url]);

  const handleShareClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
      <div>
        <div className="relative">
          <div
            id="videoload"
            style={{
              width: "100px",
              height: "100px",
              margin: "auto",
              textAlign: "center",
              display: "none",
            }}
          >
            Loading...
          </div>
          {url && (
            <div id="videoframe">
              <div
                dangerouslySetInnerHTML={{
                  __html: `<iframe id="ccc" height="465" style="width: 100%;" src=${url} allow="camera;microphone"></iframe>`,
                }}
              ></div>
            </div>
          )}
          <div className="flex items-center justify-between mx-2">
            <button
              onClick={handleShareClick}
              className="border-primaryColor border bg-primaryColor rounded-md p-2 text-white flex items-center"
            >
              Share Medical Records
            </button>
            <button
              onClick={() => closeVideo()}
              disabled={isLoading}
              className="border-red border bg-red m-3 rounded-md p-2 text-white flex relative text-right items-center hover:bg-brand-hoverRed hover:border-brand-hoverRed"
            >
              End Consultation
            </button>
          </div>
          {showModal && (
            <Sharemedicalrecords
              userId={selectedPatient?.userId}
              onClose={handleCloseModal}
            />
          )}
        </div>
      </div>
  );
};

export default VideoCall;
