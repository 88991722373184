import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import MedicalService from "../../Services/MedicalService";
import { message } from "antd";
import { useData } from "../../Services/Context";
import moment from "moment";

const Sharemedicalrecords = ({ onClose, userId }) => {
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [medicalRecord, setMedicalRecord] = useState(null);
  const { setShareRecord } = useData([]);

  const [checkboxState, setCheckboxState] = useState({
    medicalHistory: false,
    prescription: false,
    reports: false,
    vitals: false,
    agree: true,
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleShareClick = async () => {
    let payload = {
      accessFromDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      accessToDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      createdBy: patientCode,
      createdDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      medicalHistory: checkboxState.medicalHistory ? "Y" : "N",
      modifiedBy: patientCode,
      modifiedDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      patientId: patientCode,
      prescription: checkboxState.prescription ? "Y" : "N",
      reports: checkboxState.reports ? "Y" : "N",
      vitals: checkboxState.vitals ? "Y" : "N",
      // status: checkboxState.agree ? 1 : 0,
      status : 1,
      userId: userId,
    };
    try {
      const response = await MedicalService.ShareMedicalrecords(payload);
      onClose();
      message.success("Medical Records Shared");
    } catch (error) {
      console.error("Error sharing medical records:", error);
    }
  };

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  useEffect(() => {
    const fetchRecords = async () => {
      let payload = {
        patientId: patientCode,
        userId: userId,
        accessDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      };
      try {
        const result = await MedicalService.getShareMedicalrecords(payload);
        setMedicalRecord(result.data);
        setShareRecord(result.data);
        setCheckboxState({
          medicalHistory: result.data[0]?.medicalHistory === "Y",
          prescription: result.data[0]?.prescription === "Y",
          reports: result.data[0]?.reports === "Y",
          vitals: result.data[0]?.vitals === "Y",
          // agree: result.data[0]?.status === 1,
        });
        console.log('result.data?.reports',result.data[0])
      } catch (error) {
        console.error("Error fetching medical records:", error);
      }
    };

    fetchRecords();
  }, [patientCode, userId]);

  return (
    <div
      className="fixed top-0 left-0 w-full h-full flex  z-10 items-center justify-center bg-gray-800 bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="relative w-96 p-4 bg-white border border-gray-300 rounded-md shadow-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="p-1">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="medicalHistory"
              name="medicalHistory"
              className="form-checkbox mr-2 text-blue-600 border-gray-300 rounded focus:ring-blue-500 cursor-pointer"
              checked={checkboxState.medicalHistory}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="medicalHistory" className="text-lg font-medium">
              Medical History
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="prescription"
              name="prescription"
              className="form-checkbox mr-2 text-blue-600 border-gray-300 rounded focus:ring-blue-500 cursor-pointer"
              checked={checkboxState.prescription}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="prescription" className="text-lg font-medium">
              Prescription
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="vitals"
              name="vitals"
              className="mr-2 cursor-pointer"
              checked={checkboxState.vitals}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="vitals" className="text-lg font-medium">
              Vitals
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="reports"
              name="reports"
              className="form-checkbox mr-2 text-blue-600 border-gray-300 rounded focus:ring-blue-500 cursor-pointer"
              checked={checkboxState.reports}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="reports" className="text-lg font-medium">
              Reports
            </label>
          </div>
          <hr className="mt-3" />
          <div className="flex items-center gap-2 pt-5">
            <input
              type="checkbox"
              id="agree"
              name="agree"
              className="form-checkbox mr-2 text-blue-600 border-gray-300 rounded focus:ring-blue-500 cursor-pointer"
              checked={checkboxState.agree}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="agree" className="text-base text-gray-700">
              I agree to share my Medical Records with the doctor
            </label>
          </div>
          <div className="flex justify-end gap-3 mt-4">
            <button
              className="border-brand-primaryColor border bg-primaryColor rounded-md p-2 text-white flex items-center text-sm"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              onClick={handleShareClick}
              className={"border-brand-primaryColor border rounded-md p-2 text-white flex items-center text-sm  bg-primaryColor"}
              // disabled={!checkboxState.agree}
            >
              Share
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Sharemedicalrecords;
