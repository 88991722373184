import React, { useEffect, useId, useState, useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import dayjs from "dayjs";
import HeaderPrescription from "./HeaderPrescription";
import FooterPrescription from "./FooterPrescription";
import AuthService from "../../../Services/AuthService";
import PatientService from "../../../Services/PatientService";

const ViewPrescription = ({ prescription }) => {
  const contentRef = useRef();
  const [doctorData, setDoctorData] = useState([]);
  const [doctorSign, setDoctorSign] = useState(null);
  const [doctorStamp, setDoctorStamp] = useState(null);
  const [hospitalLogo, setHospitalLogo] = useState(null);
  const [selectedPatientDetails, setSelectedPatientDetails] = useState([]);
  const [clinicDetails, setClinicDetails] = useState(null);
  const [hospitalAccountData, setHospitalAccountData] = useState(null);
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [age, setAge] = useState();
  const [gender, setGender] = useState();

  const patientName = `${
    selectedPatientDetails[0]?.firstName || ""
  } ${selectedPatientDetails[0]?.lastName || ""}`.trim();

  const patientName1 = `${selectedPatientDetails[0]?.firstName || ""} ${
    selectedPatientDetails[0]?.lastName || ""
  }`.trim();

  // useEffect(() => {
  //   AuthService.getUser(prescription?.userId).then((result) => {
  //     // setDoctorData(result.data[0]);
  //     fetchClinicData(result.data[0]?.clinic_Id);
  //     fetchHospitalData(result.data[0]?.account_Id);
  //   });
  // }, []);

  const fetchHospitalData = async (id) => {
    try {
      const payload = {
        account_Id: id,
        status: 1,
      };
      const response = await AuthService.getHospitalAccountDataLogo(payload);
      setHospitalAccountData(response.data);
      console.log('response.data[0]',response.data[0])
      setHospitalLogo("data:image/jpeg;base64," + response.data[0]?.logoB64);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchClinicData = async (AccId) => {
    try {
      const response = await PatientService.getClinicData(AccId);
      setClinicDetails(response.data[0]);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          userId: prescription?.userId,
          status: 1,
        };
        const result = await AuthService.getUserData(payload);
        setDoctorData(result.data);
        
        setDoctorSign(
          "data:image/jpeg;base64," + result.data[0]?.doctorSignatureB64
        );
        setDoctorStamp(
          "data:image/jpeg;base64," + result.data[0]?.doctorStampB64
        );
        console.log('result.data[0]',result.data);
        fetchClinicData(result.data?.clinic_Id);
        fetchHospitalData(result.data?.account_Id);
      } catch (error) {
        console.error("error", error);
      }
    };
    fetchData();
  }, [prescription?.userId]);

  useEffect(() => {
    let payload = {
      patientId: patientCode,
    };
    PatientService.getPatient(payload)
      .then((result) => {
        setSelectedPatientDetails(result.data);
        setGender(result.data[0].gender);
        const dobYear = result.data[0].dob.slice(6);
        console.log("dobYear ", dobYear);
        const currYear = new Date().getFullYear();
        console.log("age ", Number(dobYear));
        if (dobYear) {
          const patientAge = currYear - Number(dobYear);
          console.log("age ", patientAge);
          setAge(patientAge);
        }
      })
      .catch((error) => {
        console.error("Error fetching patient list:", error);
      });
  }, [patientCode]);

  console.log('selectedPatientDetails',selectedPatientDetails);
  
  // const download = () => {
  //   const input = document.getElementById(getId);
  //   const pdf = new jsPDF({
  //     orientation: "portrait",
  //     unit: "px",
  //     format: "a4",
  //     userUnit: "px",
  //   });
  //   pdf
  //     .html(input, {
  //       html2canvas: { scale: 0.5, allowTaint: false },
  //       margin: [1, 1, 1, 1],
  //       autoPaging: true,
  //     })
  //     .then(() => {
  //       const fileName = "e-Prescription-" + +".pdf";
  //       pdf.save(fileName);
  //     });
  // };

  const download = () => {
    const input = contentRef.current;
    html2canvas(input, {
      scale: 2,
      useCORS: true,
      logging: true,
      onclone: (clonedDoc) => {
        const imgElement = clonedDoc.querySelector("img");
        imgElement.crossOrigin = "anonymous";
      },
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: "a4",
          userUnit: "px",
        });

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

        const width = imgWidth * ratio;
        const height = imgHeight * ratio;

        pdf.addImage(imgData, "PNG", 0, 0, width, height);
        pdf.save("Prescription-" + patientName1 + ".pdf");
      })
      .catch((error) => {
        console.error("Error capturing the content:", error);
      });
  };

  return (
    <section>
      <div
        ref={contentRef}
        className="w-full mb-4 border rounded overflow-x-auto py-4 px-4"
      >
        <div className="px-2">
          {/* <div className="flex justify-between">
            <div className="lg:flex flex flex-col">
              <h6 className="font-bold doc-font text-brand-primaryColor">
                {prescription?.userName}
              </h6>
              <h6 className="font-semibold doc-font">
                {doctorData?.qualification}
              </h6>
              <h6 className="font-semibold ">{doctorData?.speciality}</h6>
              <h6 className="font-semibold ">
                Regn No: {doctorData?.registrationNumber}
              </h6>
            </div>
            <div>
              <img src={hospitalLogo} alt="" className="w-16 h-16" />{" "}
            </div>
            <div className="flex gap-3">
              <div>
                <h6 className="font-bold text-brand-primaryColor">
                  {clinicDetails?.clinic_Name}
                </h6>
                <h6 className="font-semibold text-gray-700">
                  {clinicDetails?.clinicAddress}
                </h6>
                <h6 className="font-semibold text-gray-700">
                  {clinicDetails?.area_Name}
                </h6>
                <h6 className="font-semibold text-gray-700 ">
                  Ph: {clinicDetails?.contactNo}
                </h6>
              </div>
            </div>
          </div> */}
          <HeaderPrescription  clinicDetails={clinicDetails} doctorData={doctorData}  hospitalAccountData={hospitalAccountData}/>

          {/* <div className="border-b-2 border-gray-300 mt-3"></div> */}

          <div className="flex justify-end text-sm font-bold gap-1 mt-3">
            Date:
            <span className="ml-1 text-sm font-medium">
              {dayjs(prescription?.consultationDate).format("DD-MM-YYYY")}
            </span>
          </div>

          {/* <div className="mb-3 mt-5"> */}
            <div className="md:flex gap-3 my-2">
              <div className="text-sm font-bold flex gap-1">
                Name:
                <span className="text-sm text-gray-500 font-semibold">{patientName}</span>
              </div>
              <div className="text-sm font-bold md:flex gap-1">Age/Gender:</div>
              <span className="text-sm text-gray-500 font-semibold">{age ? age : "NA"}/{gender?.trim().toUpperCase() === "M" ? "Male" : "Female"}</span>
              <div className="text-sm font-bold md:flex gap-1">Phone Number:
              <span className="text-sm text-gray-500 font-semibold">
                {selectedPatientDetails[0]?.mobile}
              </span>
              </div>
              <div className="text-sm font-bold flex gap-1 ">
                Visit Type:
                <span className="text-sm text-gray-500 font-semibold">
                  {prescription?.consultationType
                    // ? prescription?.consultationType
                    // : "In-Person"
                    }
                </span>
              </div>
            </div>
          {/* </div> */}
          <div className="border-b border-gray-300"></div>
          <div className="flex gap-3 my-3 flex-wrap justify-stretch">
            {prescription?.patientVitals.find(
              (vital) => vital.vitalCode === "BPS"
            )?.value &&
            prescription?.patientVitals.find(
              (vital) => vital.vitalCode === "BPD"
            )?.value ? (
              <div className="flex gap-1 w-[20%]">
                <label className="font-medium text-sm">BP:</label>
                <span className="text-gray-500 text-sm">
                  {`${
                    prescription?.patientVitals.find(
                      (vital) => vital.vitalCode === "BPS"
                    )?.value
                  }/${
                    prescription?.patientVitals.find(
                      (vital) => vital.vitalCode === "BPD"
                    )?.value
                  }`}{" "}
                  mmHg
                </span>
              </div>
            ) : null}
            {prescription?.patientVitals.find(
              (vital) => vital.vitalCode === "PO"
            )?.value ? (
              <div className="flex gap-1 w-[20%]">
                <label className="font-medium text-sm">Pulse Ox:</label>
                <span className="text-gray-500 text-sm">
                  {
                    prescription?.patientVitals.find(
                      (vital) => vital.vitalCode === "PO"
                    )?.value
                  }{" "}
                  %
                </span>
              </div>
            ) : null}
            {prescription?.patientVitals.find(
              (vital) => vital.vitalCode === "HR"
            )?.value ? (
              <div className="flex gap-1 w-[20%]">
                <label className="font-medium text-sm">Heart Rate:</label>
                <span className="text-gray-500 text-sm">
                  {
                    prescription?.patientVitals.find(
                      (vital) => vital.vitalCode === "HR"
                    )?.value
                  }{" "}
                  Beats/min
                </span>
              </div>
            ) : null}
            {prescription?.patientVitals.find(
              (vital) => vital.vitalCode === "RR"
            )?.value ? (
              <div className="flex gap-1 w-[30%]">
                <label className="font-medium text-sm">Respiration Rate:</label>
                <span className="text-gray-500 text-sm">
                  {
                    prescription?.patientVitals.find(
                      (vital) => vital.vitalCode === "RR"
                    )?.value
                  }{" "}
                  Breaths/min
                </span>
              </div>
            ) : null}
            {prescription?.patientVitals.find(
              (vital) => vital.vitalCode === "TP"
            )?.value ? (
              <div className="flex gap-1 w-[20%]">
                <label className="font-medium text-sm">Temperature:</label>
                <span className="text-gray-500 text-sm">
                  {
                    prescription?.patientVitals.find(
                      (vital) => vital.vitalCode === "TP"
                    )?.value
                  }{" "}
                  °F
                </span>
              </div>
            ) : null}
            {prescription?.patientVitals.find(
              (vital) => vital.vitalCode === "HI"
            )?.value ? (
              <div className="flex gap-1 w-[20%]">
                <label className="font-medium text-sm">Height:</label>
                <span className="text-gray-500 text-sm">
                  {
                    prescription?.patientVitals.find(
                      (vital) => vital.vitalCode === "HI"
                    )?.value
                  }{" "}
                  cm
                </span>
              </div>
            ) : null}
            {prescription?.patientVitals.find(
              (vital) => vital.vitalCode === "WE"
            )?.value ? (
              <div className="flex gap-1 w-[20%]">
                <label className="font-medium text-sm">Weight:</label>
                <span className="text-gray-500 text-sm">
                  {
                    prescription?.patientVitals.find(
                      (vital) => vital.vitalCode === "WE"
                    )?.value
                  }{" "}
                  kg
                </span>
              </div>
            ) : null}
            {prescription?.patientVitals.find(
              (vital) => vital.vitalCode === "BMI"
            )?.value ? (
              <div className="flex gap-1 w-[20%]">
                <label className="font-medium text-sm">BMI:</label>
                <span className="text-gray-500 text-sm">
                  {
                    prescription?.patientVitals.find(
                      (vital) => vital.vitalCode === "BMI"
                    )?.value
                  }{" "}
                  Kg/m<sup>2</sup>
                </span>
              </div>
            ) : null}
          </div>

          <hr className="hr-line mt-3" />

          <div className="grid grid-cols-1 my-2">
            <p>
              <b className="">Chief Complaint: </b>
              {prescription?.consultationReason}
            </p>
          </div>
          <div className="grid grid-cols-1 my-2">
            <p>
              <b className="">Symptoms: </b>
              {prescription?.symptoms}
            </p>
          </div>
          {prescription?.patientDiagnosisDetails?.filter(
            (diagnosis) => diagnosis.diagnosisType === "Primary"
          )?.length !== 0 && (
            <div className="grid grid-cols-1 my-2">
              <p className="">
                <b className="">Primary Diagnosis:</b>
              </p>
              <ol className="list-decimal ml-5">
                {prescription?.patientDiagnosisDetails
                  ?.filter(
                    (diagnosis) => diagnosis?.diagnosisType === "Primary"
                  )
                  ?.map((primaryDiagnosis) => (
                    <li>
                      <span>{primaryDiagnosis?.diagnosisDescription}</span>
                      <span className="font-bold">
                        ({primaryDiagnosis?.diagnosisCode})
                      </span>
                    </li>
                  ))}
              </ol>
            </div>
          )}
          {prescription?.patientDiagnosisDetails?.filter(
            (diagnosis) => diagnosis?.diagnosisType === "Secondary"
          )?.length !== 0 && (
            <div className="grid grid-cols-1 my-2">
              <p className="">
                <b className="">Secondary Diagnosis:</b>
              </p>
              <ol className="list-decimal ml-5">
                {prescription?.patientDiagnosisDetails
                  ?.filter(
                    (diagnosis) => diagnosis?.diagnosisType === "Secondary"
                  )
                  ?.map((secondaryDiagnosis) => (
                    <li>
                      <span>{secondaryDiagnosis?.diagnosisDescription}</span>
                      <span className="font-bold">
                        ({secondaryDiagnosis?.diagnosisCode})
                      </span>
                    </li>
                  ))}
              </ol>
            </div>
          )}

          <div className="">
            <p className="mb-2">
              <b className="">Medicines:</b>
            </p>
            <table className="table-auto w-full mb-5">
              <thead>
                <tr className="border-b-2 border-t-2 border-gray-300">
                  {/* <th className="dark:border-slate-700 px-3 py-2 mb-2 text-left"></th> */}
                  <th className="dark:border-slate-700 px-3 py-2 mb-2 text-left">
                    Medicines
                  </th>
                  <th className="dark:border-slate-700 px-3 py-2 mb-2 text-left">
                    Dosage
                  </th>
                  <th className="dark:border-slate-700 px-3 py-2 mb-2 text-left">
                    Duration
                  </th>
                  {/* <th className="border-b dark:border-slate-700 px-3 py-2 mb-2 text-left">Notes</th> */}
                </tr>
              </thead>
              <tbody>
                {prescription?.drugPrescription?.map((medicine, idx) => (
                  <tr key={idx} className="border-b-2 border-gray-300">
                    {/* <td className="px-3 py-1">{idx + 1}</td> */}
                    <td className="px-3 py-1">{medicine.drugName}</td>
                    <td className=" px-3 py-1">
                      <p>{medicine.dosage}</p>
                      <p>
                        (
                        {medicine?.afterBeforeFood === "AF"
                          ? "After Food"
                          : "Before Food"}
                        )
                      </p>
                    </td>
                    <td className="px-3 py-1">
                      {medicine.duration} days
                      <p>
                        {medicine?.notes?.length > 20
                          ? `${medicine?.instruction?.slice(0, 20)}...`
                          : medicine?.instruction}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="mt-2  mb-5">
              <p className="  mb-2">
                <b className="">Lab Test:</b>
              </p>

              <div>
                <div className="">
                  <div className="text-md font-bold font-rubik text-left border dark:border-slate-700 px-3 py-1">
                    Test Name
                  </div>
                </div>
                <div className=" ">
                  {prescription?.labPrescription?.map((lab, idx) => (
                    <div
                      key={idx}
                      className="text-sm  border dark:border-slate-700 px-3 py-1"
                    >
                      {lab.labTestName}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="my-2">
            <div className="w-6/6  my-2">
              <div className="mb-5">
                <p>
                  <b className=" mr-3">Recommendation:</b>
                  <span className="">{prescription?.recommendation}</span>
                </p>
              </div>

              <div>
                <p className=" my-2">
                  <b className=" mr-1">Scheduled follow up visit date:</b>
                  {dayjs(prescription?.followupVisitDate).format("DD-MM-YYYY")}
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-between">
            <div className="flex items-center mt-3">
              {doctorStamp && (
                <img
                  src={process.env.REACT_APP_IMAGE_URL + doctorData?.doctorStampName}
                  alt="Stamp"
                  onError={(e) => {
                    e.target.style.display = "none";
                  }}
                  width={120}
                />
              )}
            </div>

            <div className="">
              {doctorSign && (
                <div className="mb-2">
                  <img src={process.env.REACT_APP_IMAGE_URL + doctorData?.doctorSignatureName}
                  onError={(e) => {
                    e.target.style.display = "none";
                  }}
                  alt="Signature" width={100} />
                  <p className="font-rubik text-gray-primary text-sm">
                  {doctorData?.userSalutation} {doctorData?.userFirstName} {doctorData?.userLastName}
                  </p>
                  <p className="font-rubik text-gray-primary text-sm">
                    {doctorData?.qualification}
                  </p>
                  <p className="font-rubik text-gray-primary text-sm">
                    {doctorData?.speciality}
                  </p>
                  <p className="font-rubik text-gray-primary text-sm">
                    Regn No. {doctorData?.registrationNumber}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="grid grid-cols-1 ">
          <div className="px-1 py-1">
            <p className="text-gray-500 text-sm ">
              <b>Disclaimer:</b>
              <ul className="list-disc ml-8">
                <li>
                  The information and advice provided here is provisional in
                  nature as it is based on the limited information made
                  available by the patient
                </li>
                <li>
                  The patient is advised to visit in person for thorough
                  examination at the earliest
                </li>
                <li>
                  The information is confidential in nature and for recipient's
                  use only
                </li>
                <li>The Prescription is generated on a Teleconsultation</li>
                <li>Not valid for medico - legal purpose</li>
              </ul>
              <li className="font-sans text-xs  marker:text-sky-400">
                Our pharmacist has reviewed the prescription and has identified
                the medicine/ lab test based on our understanding of the
                uploaded prescription. You need to confirm medical accuracy with
                your doctor and CureBay does not take responsibility for the
                translation.
              </li>
            </p>
          </div>
        </div> */}
        <FooterPrescription  clinicDetails={clinicDetails} doctorData={doctorData}/>
      </div>
      <div className="flex items-center justify-end gap-2">
        <button
          onClick={() => download()}
          className="text-white bg-brand-primaryColor w-44 h-12  rounded-lg "
        >
          Download Prescription
        </button>
      </div>
    </section>
  );
};

export default ViewPrescription;
