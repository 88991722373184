import React, { useEffect, useState } from "react";
import plusIcon from "../../../assets/images/Svg/plusIcon.svg";
import AddAllergy from "./AddAllergy";
import { Modal } from "antd";
import MedicalService from "../../../Services/MedicalService";
import dayjs from "dayjs";

function Allergy() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [patientAlleryData, setPatientAlleryData] = useState([]);

  const handleAllergyClick = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    PatientAllergies();
  };

  const PatientAllergies = () => {
    let payload = {
      patientCode: patientCode,
      status: 1,
    };
    MedicalService.PatientAllergiesList(payload)
    .then((result) => {
      setPatientAlleryData(result.data);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  useEffect(() => {
    PatientAllergies();
  }, []);

  return (
    <div>
      <div className="card h-[300px] m-5 border rounded-lg">
        <div className="header h-[15%] bg-secondaryColor flex items-center justify-between rounded-lg p-2">
          <h1 className="font-bold text-normal  text-white ">
            Allergy Details
          </h1>
          <img
            src={plusIcon}
            onClick={() => handleAllergyClick()}
            className="h-10 w-10 cursor-pointer"
            alt=""
          />
        </div>
        <div
          className="body h-[70%]  overflow-y-auto items-center bg-brand-lightBg"
          id="unquie-scroll"
        >
          {patientAlleryData.length > 0 ? (
            patientAlleryData.map((user, i) => (
                <div key={i} className="mt-3 flex flex-col gap-2">
                  <div className="text-gray-500  font-light text-sm">
                    <span className="font-medium text-black relative list-dec-before pl-3">
                      Drug Allergies :
                    </span>{" "}
                    <span className="font-medium text-gray-500">
                      {" "}
                      {user.drugAllergy}
                    </span>
                  </div>
                  <div className="text-gray-500  font-light text-sm">
                    <span className="font-medium text-black relative list-dec-before pl-3">
                      Food Allergies :
                    </span>{" "}
                    <span className="font-medium text-gray-500">
                      {" "}
                      {user.foodAllergy}{" "}
                    </span>
                  </div>
                  <div className="text-gray-500 text-black  font-light text-sm">
                    <span className="font-medium text-black relative list-dec-before pl-3">
                      Chemical Allergies :
                    </span>{" "}
                    <span className="font-medium text-gray-500">
                      {" "}
                      {user.chemicalAllergy}
                    </span>
                  </div>
                  <div className="text-gray-500 text-black  font-light text-sm">
                    <span className="font-medium text-black relative list-dec-before pl-3">
                      Other Allergies :
                    </span>{" "}
                    <span className="font-medium text-gray-500">
                      {" "}
                      {user.otherAllergy}
                    </span>
                  </div>
                  <div className="flex ml-3 mt-1">
                    <div className="text-gray-400  font-medium text-sm">
                      Check up date :{" "}
                      {dayjs(user.givenDate).format('YYYY-MM-DD')}
                    </div>
                  </div>
                  {i !== patientAlleryData.length - 1 && (
                    <hr className="my-4 border-gray-300" />
                  )}
                </div>
              ))
          ) : (
            <div></div>
          )}
        </div>
        <div className="footer h-[10%]  bg-secondaryColor rounded-md"></div>
      </div>

      {isModalVisible && (
        <Modal
          title={<span className="text-base font-semibold font-sans">Add Allergy</span>}
          visible={isModalVisible}
          onOk={() => handleCloseModal()}
          centered={true}
          footer={null}
          onCancel={() => handleCloseModal()}
          okText="Submit"
          cancelText="Cancel"
          width={500}
          maskClosable={false}
        >
          <AddAllergy onClose={() => handleCloseModal()} />
        </Modal>
      )}
    </div>
  );
}

export default Allergy;
