import React from 'react'
import { Pagination,  Table } from "antd";

const NotificationTable = () => {

    const columns = [ 
        {
          title: "Date",
          dataIndex: "date",
          sorter: {
            compare: (a, b) => new Date(a.registration) - new Date(b.registration),
          },
        },
    
        {
          title: "From",
          dataIndex: "from",
          sorter: {
            compare: (a, b) => new Date(a.registration) - new Date(b.registration),
          },
        },
        {
          title: "Subject",
          dataIndex: "subject",
          sorter: {
            compare: (a, b) => new Date(a.registration) - new Date(b.registration),
          },
        },
        {
          title: "Content",
          dataIndex: "content",
          
        },
        
      ];
    
      const data = [
        {
          key: "1",
          patientName: "Atlas",          
          date: "21-03-2022",
          from: "Dr.Joel",
          subject: "Appoinment reshedule by Patient",
          content: "Hi Atlas,Your appoinment with dr. joel is confirmed(e-clinic-Newyork)",
        },
        {
          key: "1",
          patientName: "Atlas",          
          date: "21-03-2022",
          from: "Dr.Joel",
          subject: "Appoinment reshedule by Patient",
          content: "Hi Atlas,Your appoinment with dr. joel is confirmed(e-clinic-Newyork)",
        },
        {
          key: "1",
          patientName: "Atlas",          
          date: "21-03-2022",
          from: "Dr.Joel",
          subject: "Appoinment reshedule by Patient",
          content: "Hi Atlas,Your appoinment with dr. joel is confirmed(e-clinic-Newyork)",
        },
        {
          key: "1",
          patientName: "Atlas",          
          date: "21-03-2022",
          from: "Dr.Joel",
          subject: "Appoinment reshedule by Patient",
          content: "Hi Atlas,Your appoinment with dr. joel is confirmed(e-clinic-Newyork)",
        },
        
        
      ];


  return (
    <div className="flex flex-col  justify-between mt-6">
      <div>
        <Table
          columns={columns}
          dataSource={data}
          // onChange={onChange}
          pagination={false}
        />
      </div>
      <div className="flex justify-center items-center mt-24">
        <span className="mr-2">Page</span>
          <Pagination total={10} showSizeChanger />
        </div>
    </div>
  )
}

export default NotificationTable
