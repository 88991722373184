import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import FooterPrescription from "./FooterPrescription";
import HeaderPrescription from "./HeaderPrescription";
import AuthService from "../../../Services/AuthService";

function SharePrescription() {
  const contentRef = useRef();
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [accountDetails, setAccountDetails] = useState(null);
  const [clinicDetails, setClinicDetails] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [patientDetails, setPatientDetails] = useState(null);
  const [consultationDetails, setConsultationDetails] = useState(null);
  const [age, setAge] = useState();
  const [gender, setGender] = useState();
  const [doctorSign, setDoctorSign] = useState(null);
  const [doctorStamp, setDoctorStamp] = useState(null);
  const [params, setParams] = useState(null);

  const getCodeFromURL = () => {
    const query = window.location.search;
    const codeMatch = query.match(/ref=([^&/]*)/);
    return codeMatch ? codeMatch[1] : null;
  };

  useEffect(() => {
    let param = getCodeFromURL();
    setParams(param);
    if (params) {
      AuthService.getSharePrescription(params)
        .then((res) => {
          setPrescriptionData(res.data);
          setClinicDetails(res?.data?.clinicDetails);
          setUserDetails(res?.data?.userDetails);
          setDoctorSign(
            "data:image/jpeg;base64," +
              res.data?.userDetails?.doctorSignatureB64
          );
          setDoctorStamp(
            "data:image/jpeg;base64," + res.data?.userDetails?.doctorStampB64
          );
          setAccountDetails(res?.data?.accountDetails);
          setPatientDetails(res?.data?.patientDetails);
          setConsultationDetails(res?.data?.consultationDetails);
          setGender(res?.data?.patientDetails.patientGender);
          const dobYear = res?.data?.patientDetails?.patientDob.slice(6);
          const currYear = new Date().getFullYear();
          if (dobYear) {
            const patientAge = currYear - Number(dobYear);
            setAge(patientAge);
          }
        })

        .catch((error) => {
          console.error(error);
        });
    }
  }, [params]);

  const download = () => {
    const input = contentRef.current;
    html2canvas(input, {
      scale: 2,
      useCORS: true,
      logging: true,
      onclone: (clonedDoc) => {
        const imgElement = clonedDoc.querySelector("img");
        imgElement.crossOrigin = "anonymous";
      },
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: "a4",
          userUnit: "px",
        });

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

        const width = imgWidth * ratio;
        const height = imgHeight * ratio;

        pdf.addImage(imgData, "PNG", 0, 0, width, height);
        pdf.save(
          "Prescription-" +
            patientDetails?.patientFirstName +
            " " +
            patientDetails?.patientLastName +
            ".pdf"
        );
      })
      .catch((error) => {
        console.error("Error capturing the content:", error);
      });
  };
  return (
    <section>
      <div className="m-5">
        <div
          ref={contentRef}
          className="w-full rounded overflow-x-auto cursor-pointer py-4 px-4"
        >
          {" "}
          <div className="px-6 py-2 border">
            {/* <div className="flex justify-between">
              <div className="lg:flex flex flex-col">
                <h6 className="font-bold doc-font text-brand-primaryColor">
                  {userDetails?.userSalutation} {userDetails?.userFirstName}{" "}
                  {userDetails?.userLastName}
                </h6>
                <h6 className="font-semibold doc-font">
                  {userDetails?.qualification}
                </h6>
                <h6 className="font-semibold ">{userDetails?.speciality}</h6>
                <h6 className="font-semibold ">
                  Regn No: {userDetails?.registrationNumber}
                </h6>
              </div>
              <div>
                <img
                  src={
                    process.env.REACT_APP_IMAGE_URL + accountDetails?.logoName
                  }
                  alt=""
                  className="w-16 h-16"
                />{" "}
              </div>
              <div className="flex gap-3">
                <div>
                  <h6 className="font-bold text-brand-primaryColor">
                    {clinicDetails?.clinic_Name}
                  </h6>
                  <h6 className="font-semibold text-gray-700">
                    {clinicDetails?.clinicAddress}
                  </h6>
                  <h6 className="font-semibold text-gray-700">
                    {clinicDetails?.area_Name}
                  </h6>
                  <h6 className="font-semibold text-gray-700 ">
                    Ph: {clinicDetails?.contactNo}
                  </h6>
                </div>
              </div>
            </div> */}
            <HeaderPrescription clinicDetails={clinicDetails} hospitalAccountData={accountDetails} doctorData={userDetails}/>

            {/* <div className="border-b-2 border-gray-300 mt-3"></div> */}

            <div className="flex justify-end text-sm font-bold gap-1 mt-3">
              Date:
              <span className="ml-1 text-sm font-medium">
                {dayjs(prescriptionData?.consultationDate).format("DD-MM-YYYY")}
              </span>
            </div>

            <div className="mb-3 mt-5">
              <div className="flex gap-3">
                <div className="text-sm font-bold flex gap-1">
                  Name:
                  <span className="text-sm font-normal">
                    {patientDetails?.patientSalutation}{" "}
                    {patientDetails?.patientFirstName}{" "}
                    {patientDetails?.patientLastName}
                  </span>
                </div>
                <div className="text-sm font-bold">Age/Gender:</div>
                <span className=" text-sm">
                  {age ? age : "NA"}/
                  {gender?.trim().toUpperCase() === "M" ? "Male" : "Female"}
                </span>
                <div className=" text-sm font-bold">Phone Number:</div>
                <span className=" text-sm">
                  {patientDetails?.patientMobile}
                </span>
                <div className="text-sm font-bold flex gap-1 ">
                  Visit Type:
                  <span className=" text-sm font-normal">
                    {consultationDetails?.consultationType ? consultationDetails?.consultationType : "In-person"}
                  </span>
                </div>
              </div>
            </div>

            <div className="border-b border-gray-300"></div>
            <div className="flex gap-3 my-3 flex-wrap justify-stretch">
              {consultationDetails?.vitalsPrescriptionDetails.find(
                (vital) => vital.vitalCode === "BPS"
              )?.value &&
              consultationDetails?.vitalsPrescriptionDetails.find(
                (vital) => vital.vitalCode === "BPD"
              )?.value ? (
                <div className="flex gap-1 w-[20%]">
                  <label className="font-medium text-sm">BP:</label>
                  <span className="text-gray-500 text-sm">
                    {`${
                      consultationDetails?.vitalsPrescriptionDetails.find(
                        (vital) => vital.vitalCode === "BPS"
                      )?.value
                    }/${
                      consultationDetails?.vitalsPrescriptionDetails.find(
                        (vital) => vital.vitalCode === "BPD"
                      )?.value
                    }`}{" "}
                    mmHg
                  </span>
                </div>
              ) : null}
              {consultationDetails?.vitalsPrescriptionDetails.find(
                (vital) => vital.vitalCode === "PO"
              )?.value ? (
                <div className="flex gap-1 w-[20%]">
                  <label className="font-medium text-sm">Pulse Ox:</label>
                  <span className="text-gray-500 text-sm">
                    {
                      consultationDetails?.vitalsPrescriptionDetails.find(
                        (vital) => vital.vitalCode === "PO"
                      )?.value
                    }{" "}
                    %
                  </span>
                </div>
              ) : null}
              {consultationDetails?.vitalsPrescriptionDetails.find(
                (vital) => vital.vitalCode === "HR"
              )?.value ? (
                <div className="flex gap-1 w-[20%]">
                  <label className="font-medium text-sm">Heart Rate:</label>
                  <span className="text-gray-500 text-sm">
                    {
                      consultationDetails?.vitalsPrescriptionDetails.find(
                        (vital) => vital.vitalCode === "HR"
                      )?.value
                    }{" "}
                    Beats/min
                  </span>
                </div>
              ) : null}
              {consultationDetails?.vitalsPrescriptionDetails.find(
                (vital) => vital.vitalCode === "RR"
              )?.value ? (
                <div className="flex gap-1 w-[30%]">
                  <label className="font-medium text-sm">
                    Respiration Rate:
                  </label>
                  <span className="text-gray-500 text-sm">
                    {
                      consultationDetails?.vitalsPrescriptionDetails.find(
                        (vital) => vital.vitalCode === "RR"
                      )?.value
                    }{" "}
                    Breaths/min
                  </span>
                </div>
              ) : null}
              {consultationDetails?.vitalsPrescriptionDetails.find(
                (vital) => vital.vitalCode === "TP"
              )?.value ? (
                <div className="flex gap-1 w-[20%]">
                  <label className="font-medium text-sm">Temperature:</label>
                  <span className="text-gray-500 text-sm">
                    {
                      consultationDetails?.vitalsPrescriptionDetails.find(
                        (vital) => vital.vitalCode === "TP"
                      )?.value
                    }{" "}
                    °F
                  </span>
                </div>
              ) : null}
              {consultationDetails?.vitalsPrescriptionDetails.find(
                (vital) => vital.vitalCode === "HI"
              )?.value ? (
                <div className="flex gap-1 w-[20%]">
                  <label className="font-medium text-sm">Height:</label>
                  <span className="text-gray-500 text-sm">
                    {
                      consultationDetails?.vitalsPrescriptionDetails.find(
                        (vital) => vital.vitalCode === "HI"
                      )?.value
                    }{" "}
                    cm
                  </span>
                </div>
              ) : null}
              {consultationDetails?.vitalsPrescriptionDetails.find(
                (vital) => vital.vitalCode === "WE"
              )?.value ? (
                <div className="flex gap-1 w-[20%]">
                  <label className="font-medium text-sm">Weight:</label>
                  <span className="text-gray-500 text-sm">
                    {
                      consultationDetails?.vitalsPrescriptionDetails.find(
                        (vital) => vital.vitalCode === "WE"
                      )?.value
                    }{" "}
                    kg
                  </span>
                </div>
              ) : null}
              {consultationDetails?.vitalsPrescriptionDetails.find(
                (vital) => vital.vitalCode === "BMI"
              )?.value ? (
                <div className="flex gap-1 w-[20%]">
                  <label className="font-medium text-sm">BMI:</label>
                  <span className="text-gray-500 text-sm">
                    {
                      consultationDetails?.vitalsPrescriptionDetails.find(
                        (vital) => vital.vitalCode === "BMI"
                      )?.value
                    }{" "}
                    Kg/m<sup>2</sup>
                  </span>
                </div>
              ) : null}
            </div>
            <hr className="hr-line mt-3" />
            <div className="grid grid-cols-1 my-2">
              <p>
                <b className="">Chief Complaint : </b>
                {consultationDetails?.consultationReason}
              </p>
            </div>
            <div className="grid grid-cols-1 my-2">
              <p>
                <b className="">Symptoms : </b>
                {consultationDetails?.symptoms}
              </p>
            </div>

            {consultationDetails?.diagnosisPrescriptionDetails?.filter(
              (diagnosis) => diagnosis.diagnosisType === "Primary"
            )?.length !== 0 && (
              <div className="grid grid-cols-1 my-2">
                <p className="">
                  <b className="">Primary Diagnosis:</b>
                </p>
                <ol className="list-decimal ml-5">
                  {consultationDetails?.diagnosisPrescriptionDetails
                    ?.filter(
                      (diagnosis) => diagnosis?.diagnosisType === "Primary"
                    )
                    ?.map((primaryDiagnosis) => (
                      <li>
                        <span>{primaryDiagnosis?.diagnosisDescription}</span>
                        <span className="font-bold">
                          ({primaryDiagnosis?.diagnosisCode})
                        </span>
                      </li>
                    ))}
                </ol>
              </div>
            )}
            {consultationDetails?.diagnosisPrescriptionDetails?.filter(
              (diagnosis) => diagnosis?.diagnosisType === "Secondary"
            )?.length !== 0 && (
              <div className="grid grid-cols-1 my-2">
                <p className="">
                  <b className="">Secondary Diagnosis:</b>
                </p>
                <ol className="list-decimal ml-5">
                  {consultationDetails?.diagnosisPrescriptionDetails
                    ?.filter(
                      (diagnosis) => diagnosis?.diagnosisType === "Secondary"
                    )
                    ?.map((secondaryDiagnosis) => (
                      <li>
                        <span>{secondaryDiagnosis?.diagnosisDescription}</span>
                        <span className="font-bold">
                          ({secondaryDiagnosis?.diagnosisCode})
                        </span>
                      </li>
                    ))}
                </ol>
              </div>
            )}

            <div className="">
              <p className="mb-2">
                <b className="">Medicines:</b>
              </p>
              <table className="table-auto w-full mb-5">
                <thead>
                  <tr className="border-b-2 border-t-2 border-gray-300">
                    <th className="dark:border-slate-700 px-3 py-2 mb-2 text-left"></th>
                    <th className="dark:border-slate-700 px-3 py-2 mb-2 text-left">
                      Medication
                    </th>
                    <th className="dark:border-slate-700 px-3 py-2 mb-2 text-left">
                      Dosage
                    </th>
                    <th className="dark:border-slate-700 px-3 py-2 mb-2 text-left">
                      Duration
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {consultationDetails?.drugPrescriptionDetails?.map(
                    (medicine, idx) => (
                      <tr key={idx} className="border-b-2 border-gray-300">
                        <td className="px-3 py-1">{idx + 1}</td>
                        <td className="px-3 py-1">{medicine.drugName}</td>
                        <td className=" px-3 py-1">
                          <p>{medicine.dosage}</p>
                          <p className="mb-1">
                            (
                            {medicine?.afterBeforeFood === "AF"
                              ? "After Food"
                              : "Before Food"}
                            )
                          </p>
                        </td>
                        <td className="px-3 py-1">
                          {medicine.duration} days
                          <p>
                            {medicine?.notes?.length > 20
                              ? `${medicine?.instruction?.slice(0, 20)}...`
                              : medicine?.instruction}
                          </p>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>

              <div className="mt-2  mb-5">
                <p className="  mb-2">
                  <b className="">Lab Test:</b>
                </p>

                <div>
                  <div className="">
                    <div className="text-md mb-1 font-bold font-rubik text-left border dark:border-slate-700 px-3 py-1">
                      Test Name
                    </div>
                  </div>
                  <div className=" ">
                    {consultationDetails?.labPrescriptionDetails?.map(
                      (lab, idx) => (
                        <div
                          key={idx}
                          className="text-sm mb-1 border dark:border-slate-700 px-3 py-1"
                        >
                          {lab.labTestName}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="my-2">
              <div className="w-6/6  my-2">
                <div className="mb-5">
                  <p>
                    <b className=" mr-3">Recommendation:</b>
                    <span className="">
                      {consultationDetails?.recommendation}
                    </span>
                  </p>
                </div>

                <div>
                  <p className=" my-2">
                    <b className=" mr-1">Scheduled follow up visit date:</b>
                    {dayjs(consultationDetails?.followupVisitDate).format(
                      "DD-MM-YYYY"
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex justify-between">
              <div className="flex items-center mt-3">
                {doctorStamp && (
                  <img
                    src={doctorStamp}
                    alt="Stamp"
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                    width={120}
                  />
                )}
              </div>

              <div className="">
                {doctorSign && (
                  <div className="mb-2">
                    <img src={doctorSign} alt="Signature" width={100} />
                    <p className="font-rubik text-gray-primary text-sm">
                      {userDetails?.userSalutation} {userDetails?.userFirstName}{" "}
                      {userDetails?.userLastName}
                    </p>
                    <p className="font-rubik text-gray-primary text-sm">
                      {userDetails?.qualification}
                    </p>
                    <p className="font-rubik text-gray-primary text-sm">
                      {userDetails?.speciality}
                    </p>
                    <p className="font-rubik text-gray-primary text-sm">
                      Regn No. {userDetails?.registrationNumber}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <FooterPrescription />
          </div>
          {/* <div className="grid grid-cols-1 ">
            <div className="px-1 py-1">
              <p className="text-gray-500 text-sm ">
                <b>Disclaimer:</b>
                <ul className="list-disc ml-8">
                  <li>
                    The information and advice provided here is provisional in
                    nature as it is based on the limited information made
                    available by the patient
                  </li>
                  <li>
                    The patient is advised to visit in person for thorough
                    examination at the earliest
                  </li>
                  <li>
                    The information is confidential in nature and for
                    recipient's use only
                  </li>
                  <li>The Prescription is generated on a Teleconsultation</li>
                  <li>Not valid for medico - legal purpose</li>
                </ul>
                <li className="font-sans text-xs  marker:text-sky-400">
                  Our pharmacist has reviewed the prescription and has
                  identified the medicine/ lab test based on our understanding
                  of the uploaded prescription. You need to confirm medical
                  accuracy with your doctor and CureBay does not take
                  responsibility for the translation.
                </li>
              </p>
            </div>
          </div> */}

        </div>
      </div>
      <div className="flex mx-8 mb-6 items-center justify-end gap-2">
        <button
          onClick={() => download()}
          className="text-white bg-brand-primaryColor w-44 h-12  rounded-lg "
        >
          Download Prescription
        </button>
      </div>
    </section>
  );
}

export default SharePrescription;
