import React from "react";
import Icon1 from "../assets/images/paticon1.png";
import Icon2 from "../assets/images/paticon2.png";
import Icon3 from "../assets/images/paticon3.png";
import Icon4 from "../assets/images/paticon4.png";

function Patients() {
  return (
    <div className="patients py-20 text-white">
      <div className="text-center relative z-2">
        <h1 className="headingFont sm:text-[40px] md:text-[55px] lg:text-[40px] x:text-[50px] xl:text-[50px] 2xl:text-[60px]">For Patients</h1>
        <p className="w-[70%] mx-auto md:text-[20px] lg:text-[15px] x:text-[17px] xl:text-[17px] 2xl:text-[21px]">
          Our platform ensures secure, private consultations and easy access to
          your medical records. Experience the ease and convenience of virtual
          healthcare, tailored to fit your busy lifestyle.
        </p>
      </div>

      <div className="relative z-2 flex flex-wrap sm:flex-col md:flex-col l:flex-row x:flex-row lg:flex-row xl:flex-row 2xl:flex-row w-[80%] mx-auto mt-20">
        <div className="flex sm:flex-col md:flex-col l:flex-row x:flex-row lg:flex-row xl:flex-row 2xl:flex-row l:w-[50%] x:w-[50%] lg:w-[50%] xl:w-[50%] 2xl:w-[50%] sm:w-[100%] md:w-[100%] w-[50%] gap-x-7">
          <img className="2xl:w-[150px] 2xl:h-[150px] x:w-[100px] x:h-[100px] xl:w-[100px] xl:h-[100px] lg:w-[80px] lg:h-[80px] sm:h-[80px] sm:w-[80px] md:h-[100px] md:w-[100px] sm:mx-auto md:mx-auto" src={Icon1} alt="" />
          <div className="">
            <h1 className="headingFont 2xl:text-[25px] x:text-[22px] xl:text-[22px] sm:text-center md:text-center sm:mt-5 md:mt-5 sm:text-[20px] md:text-[25px] l:text-left x:text-left lg:text-left xl:text-left 2xl:text-left">
              Tele Medicine
            </h1>
            <p className="mt-2 w-[80%] sm:text-[15px] md:text-[20px] 2xl:text-[20px] xl:text-[16px] x:text-[16px] lg:text-[14px] l:text-left x:text-left lg:text-left xl:text-left 2xl:text-left sm:text-center md:text-center 2xl:leading-8 xl:leading-7 sm:mx-auto md:mx-auto x:mx-0 l:mx-0 xl:mx-0 lg:mx-0 2xl:mx-0">
              Telemedicine leverages technology to connect patients with
              healthcare providers for consultations, diagnosis, and treatment,
              regardless of geographic barriers. High-definition video calls for
              face-to-face interaction with healthcare professionals.
              <br />
              Access to healthcare services at any time, ensuring patients
              receive timely care.
            </p>
          </div>
        </div>
        <div className="flex sm:flex-col md:flex-col l:flex-row x:flex-row lg:flex-row xl:flex-row 2xl:flex-row l:w-[50%] x:w-[50%] lg:w-[50%] xl:w-[50%] 2xl:w-[50%] sm:w-[100%] md:w-[100%] w-[50%] gap-x-7 sm:mt-10 md:mt-10 x:mt-0 l:mt-0 xl:mt-0 lg:mt-0 2xl:mt-0">
          <img className="2xl:w-[150px] 2xl:h-[150px] x:w-[100px] x:h-[100px] xl:w-[100px] xl:h-[100px] lg:w-[80px] lg:h-[80px] sm:h-[100px] sm:w-[100px] sm:mx-auto md:h-[100px] md:w-[100px] md:mx-auto" src={Icon2} alt="" />
          <div className="">
            <h1 className="headingFont 2xl:text-[25px] x:text-[22px] xl:text-[22px] sm:text-center md:text-center sm:mt-5 md:mt-5 sm:text-[20px] md:text-[25px] l:text-left x:text-left lg:text-left xl:text-left 2xl:text-left">
              Electronic Health Records (EHR)
            </h1>
            <p className="mt-2 w-[80%] sm:text-[15px] md:text-[20px] 2xl:text-[20px] xl:text-[16px] x:text-[16px] lg:text-[14px] l:text-left x:text-left lg:text-left xl:text-left 2xl:text-left sm:text-center md:text-center 2xl:leading-8 xl:leading-7 sm:mx-auto md:mx-auto x:mx-0 l:mx-0 xl:mx-0 lg:mx-0 2xl:mx-0 ">
              A comprehensive digital record system for storing and managing
              patient health information. All patient information in one place,
              including medical history, lab results, and treatment plans.
              <br />
              Ensures only authorized personnel can access patient records.
              Allows patients to view their health records and test results
              online.
            </p>
          </div>
        </div>
        <div className="flex sm:flex-col md:flex-col l:flex-row x:flex-row lg:flex-row xl:flex-row 2xl:flex-row l:w-[50%] x:w-[50%] lg:w-[50%] xl:w-[50%] 2xl:w-[50%] sm:w-[100%] md:w-[100%] w-[50%] gap-x-7 sm:mt-10 md:mt-10 x:mt-5 l:mt-5 xl:mt-5 lg:mt-5 2xl:mt-10">
          <img className="2xl:w-[150px] 2xl:h-[150px] x:w-[100px] x:h-[100px] xl:w-[100px] xl:h-[100px] lg:w-[80px] lg:h-[80px] sm:h-[100px] sm:w-[100px] sm:mx-auto md:h-[100px] md:w-[100px] md:mx-auto" src={Icon3} alt="" />
          <div className="">
            <h1 className="headingFont 2xl:text-[25px] x:text-[22px] xl:text-[22px] sm:text-center md:text-center sm:mt-5 md:mt-5 sm:text-[20px] md:text-[25px] l:text-left x:text-left lg:text-left xl:text-left 2xl:text-left">
              Scheduling Appointment
            </h1>
            <p className="mt-2 w-[80%] sm:text-[15px] md:text-[20px] 2xl:text-[20px] xl:text-[16px] x:text-[16px] lg:text-[14px] l:text-left x:text-left lg:text-left xl:text-left 2xl:text-left sm:text-center md:text-center 2xl:leading-8 xl:leading-7 sm:mx-auto md:mx-auto x:mx-0 l:mx-0 xl:mx-0 lg:mx-0 2xl:mx-0 ">
              A user-friendly platform for patients to schedule, reschedule, or
              cancel appointments with their healthcare providers. Simple and
              intuitive interface for booking appointments. View and select
              available time slots in real-time.
            </p>
          </div> 
        </div>
        <div className="flex sm:flex-col md:flex-col l:flex-row x:flex-row lg:flex-row xl:flex-row 2xl:flex-row l:w-[50%] x:w-[50%] lg:w-[50%] xl:w-[50%] 2xl:w-[50%] sm:w-[100%] md:w-[100%] w-[50%] gap-x-7 sm:mt-10 md:mt-10 x:mt-5 l:mt-5 xl:mt-5 lg:mt-5 2xl:mt-10">
          <img className="2xl:w-[150px] 2xl:h-[150px] x:w-[100px] x:h-[100px] xl:w-[100px] xl:h-[100px] lg:w-[80px] lg:h-[80px] sm:h-[100px] sm:w-[100px] sm:mx-auto md:h-[100px] md:w-[100px] md:mx-auto" src={Icon4} alt="" />
          <div className="">
            <h1 className="headingFont 2xl:text-[25px] x:text-[22px] xl:text-[22px] sm:text-center md:text-center sm:mt-5 md:mt-5 sm:text-[20px] md:text-[25px] l:text-left x:text-left lg:text-left xl:text-left 2xl:text-left">
              e-Prescription
            </h1>
            <p className="mt-2 w-[80%] sm:text-[15px] md:text-[20px] 2xl:text-[20px] xl:text-[16px] x:text-[16px] lg:text-[14px] l:text-left x:text-left lg:text-left xl:text-left 2xl:text-left sm:text-center md:text-center 2xl:leading-8 xl:leading-7 sm:mx-auto md:mx-auto x:mx-0 l:mx-0 xl:mx-0 lg:mx-0 2xl:mx-0 ">
              e-Prescription streamlines the prescription process by allowing
              healthcare providers to send prescriptions directly to pharmacies
              electronically. Quick and secure transmission of prescriptions to
              the patient's preferred pharmacy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Patients;
