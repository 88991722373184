import { message } from "antd";
import enrollmentServices from "../Services/enrollmentServices";
import PaymentService from "../Services/PaymentService";
import moment from "moment";
import { Base64 } from "js-base64";
import { Buffer } from "buffer";
import sha256 from "crypto-js/sha256";
import AppointmentService from "../Services/AppointmentService";
import axios from "axios";
import { APP_ROUTES } from "../PageRouting/pageRoutes";
window.Buffer = Buffer;
// export const NewPatientPayment = async (data) => {
//     try {
//         const result = await enrollmentServices.Invite(data);

//         if (!result.data) {
//             message.error("Registration Failed");
//             return;
//         }

//         const convertUTCDate = (date, format = "MM/DD/YYYY") => {
//             const d = new Date();
//             date = new Date(
//                 moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
//                 " " +
//                 d.getHours() +
//                 ":" +
//                 d.getMinutes()
//             );
//             return moment(new Date(date)).utc().format(format);
//         };

//         const storedLoginResult = localStorage.getItem("login_result");
//         const loginResultObject = storedLoginResult
//             ? JSON.parse(storedLoginResult)
//             : null;

//         if (result.data.code === "FAILED") {
//             message.error(result.data.message);
//             return;
//         }

//         if (result.data.code === "SUCCESS") {
//             let date = moment(new Date()).format("YYYYMMDDHHmmssSSS");
//             let transactionId = "MT" + date;
//             let appointmentDetails =
//                 transactionId +
//                 "|" +
//                 result.data?.responseDetails.patientId +
//                 "|" +
//                 data?.userId //loginResultObject.user? changed to data
//                 // loginResultObject.user.userId
//                 +
//                 "|" +
//                 result.data?.responseDetails.appointmentId +
//                 "|" +
//                 data?.account_Id //loginResultObject.user? changed to data
//                 // loginResultObject.user.account_Id
//                 +
//                 "|" +
//                 data?.clinic_Id; //loginResultObject.user? changed to data
//                 // loginResultObject.user.clinic_Id;
//                 ;
//             let base64string = Base64.encode(appointmentDetails);
//             let paymentPayload = {
//                 merchantId: "DOXZONUAT",
//                 merchantTransactionId: transactionId,
//                 merchantUserId: "MUID123",
//                 amount: parseFloat(result.data.responseDetails?.totalAmount) * 100,
//                 redirectUrl: `https://doctor.doxzon.com/phonepepayment/${base64string}`,
//                 redirectMode: "POST",
//                 callbackUrl:
//                     "https://doctorapi.doxzon.com/doxzon/phonePeResponse1/paymentCallBackDetails",
//                 mobileNumber: result.data.responseDetails?.mobile,
//                 paymentInstrument: {
//                     type: "PAY_PAGE",
//                 },
//             };

//             const SALT_KEY = "58d4bcb6-f046-4e58-8c62-66505c0e32d9";
//             const PHONE_PE_HOST_URL =
//                 "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay";
//             let bufferObj = Buffer.from(JSON.stringify(paymentPayload), "utf8");
//             let base64EncodedPayload = bufferObj.toString("base64");
//             let string = base64EncodedPayload + "/pg/v1/pay" + SALT_KEY;
//             let sha256_val = sha256(string);
//             let xVerifyChecksum = sha256_val + "###" + 1;

//             let phonepeTransaction = {
//                 account_Id: data?.account_Id, //loginResultObject.user? changed to data
//                 checksum: xVerifyChecksum,
//                 clinic_id: data?.clinic_Id, //loginResultObject.user? changed to data
//                 createdBy: result.data.responseDetails?.patientId,
//                 createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
//                 modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
//                 paidDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
//                 modifiedBy: data?.userId, //loginResultObject.user? changed to data
//                 patientId: result.data.responseDetails?.patientId,
//                 enoCodePayload: base64EncodedPayload,
//                 providerreferenceId: "",
//                 serviceCode: result.data.responseDetails?.serviceCode,
//                 serviceName: result.data.responseDetails?.serviceName,
//                 status: 1,
//                 transactionId: transactionId,
//                 userId: data?.userId, //loginResultObject.user? changed to data
//                 amount: result.data.responseDetails?.totalAmount,
//             };

//             const res = await PaymentService.PhonepeTransaction(phonepeTransaction)

//             if (res.data === 1) {
//                 let appointmentId = result.data.responseDetails?.appointmentId;
//                 const appointmentRes = await AppointmentService.GetAppointmentId(
//                     appointmentId
//                 );

//                 let appointmentUpdate = {
//                     appointmentId: result.data.responseDetails?.appointmentId,
//                     email: result.data.responseDetails?.email,
//                     meetingLink: result.data.responseDetails?.meetingLink,
//                     mobile: result.data.responseDetails?.mobile,
//                     id: appointmentRes.data[0].id,
//                     patientId: result.data.responseDetails?.patientId,
//                     status: 1,
//                     paymentReceived: "Y",
//                     userId: data?.userId, //loginResultObject.user? changed to data
//                 };

//                 const updateRes = await AppointmentService.AppointmentUpdate(
//                     appointmentUpdate
//                 );

//                 let billingTransaction = {
//                     account_Id: data?.account_Id, //loginResultObject.user? changed to data
//                     amount: result.data.responseDetails?.totalAmount,
//                     clinic_id: data?.clinic_Id, //loginResultObject.user? changed to data
//                     createdBy: result.data.responseDetails?.patientId,
//                     createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
//                     discountAmount: 0,
//                     discountPercentage: 0,
//                     gst: 0,
//                     modifiedBy: result.data.responseDetails?.patientId,
//                     modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
//                     paidAmount: result.data.responseDetails?.totalAmount,
//                     patientId: result.data.responseDetails?.patientId,
//                     paymentDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
//                     paymode: "PhonePe",
//                     serviceCode: "APPT",
//                     serviceCost: result.data.responseDetails?.totalAmount,
//                     serviceName: "Appoinment Consultation",
//                     serviceTransId: result.data.responseDetails?.appointmentId,
//                     status: 0,
//                     transactionId: transactionId,
//                     userId: data?.userId, //loginResultObject.user? changed to data
//                 };

//                 const billingRes = await PaymentService.BillingTransaction(
//                     billingTransaction
//                 );

//                 if (billingRes.data === 1) {
//                     const configurationObject = {
//                         method: "POST",
//                         url: PHONE_PE_HOST_URL,
//                         data: { request: base64EncodedPayload },
//                         headers: {
//                             Accept: "application/json",
//                             "Content-Type": "application/json",
//                             "X-VERIFY": xVerifyChecksum,
//                         },
//                     };
//                     const paymentRes = await axios(configurationObject);
//                     window.location.replace(
//                         paymentRes.data.data.instrumentResponse.redirectInfo.url
//                     );
//                 }
//             }
//         }
//     } catch (error) {
//         console.error("An error occurred:", error);
//         message.error("This patient already exists");
//     }
// }

export const ExisTingPatientPayment = async (data) => {
  try {
    const result = await enrollmentServices.Invite(data);

    if (!result.data) {
      message.error("Registration Failed, Retry");
      return;
    }

    const convertUTCDate = (date, format = "MM/DD/YYYY") => {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
      return moment(new Date(date)).utc().format(format);
    };

    const storedLoginResult = localStorage.getItem("login_result");
    const loginResultObject = storedLoginResult
      ? JSON.parse(storedLoginResult)
      : null;

    if (result.data.code === "SUCCESS") {
      let date = moment(new Date()).format("YYYYMMDDHHmmssSSS");
      let transactionId = "MT" + date;
      let appointmentDetails =
        transactionId +
        "|" +
        result.data?.responseDetails.patientId +
        "|" +
        data.userId + //changed this in admin. data is taken from params
        // loginResultObject.user.userId
        "|" +
        result.data?.responseDetails.appointmentId +
        "|" +
        data.account_Id +
        // loginResultObject.user.account_Id
        "|" +
        data.clinic_Id;
      // loginResultObject.user.clinic_Id;
      let base64string = Base64.encode(appointmentDetails);
      let paymentPayload = {
        merchantId: "DOXZONUAT",
        merchantTransactionId: transactionId,
        merchantUserId: "MUID123",
        amount: parseFloat(result.data.responseDetails?.totalAmount) * 100,
        redirectUrl: `https://doctor.doxzon.com/phonepepayment/${base64string}`,

        redirectMode: "POST",
        callbackUrl:
          "https://doctorapi.doxzon.com/doxzon/phonePeResponse1/paymentCallBackDetails",
        mobileNumber: result.data.responseDetails?.mobile,
        paymentInstrument: {
          type: "PAY_PAGE",
        },
      };

      const SALT_KEY = "58d4bcb6-f046-4e58-8c62-66505c0e32d9";
      const PHONE_PE_HOST_URL =
        "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay";
      let bufferObj = Buffer.from(JSON.stringify(paymentPayload), "utf8");
      let base64EncodedPayload = bufferObj.toString("base64");
      let string = base64EncodedPayload + "/pg/v1/pay" + SALT_KEY;
      let sha256_val = sha256(string);
      let xVerifyChecksum = sha256_val + "###" + 1;

      let phonepeTransaction = {
        account_Id: data?.account_Id, //loginResultObject.user? changed to data
        checksum: xVerifyChecksum,
        clinic_id: data?.clinic_Id, //loginResultObject.user? changed to data
        createdBy: result.data.responseDetails?.patientId,
        createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
        modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
        paidDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
        modifiedBy: data?.userId, //loginResultObject.user? changed to data
        patientId: result.data.responseDetails?.patientId,
        enoCodePayload: base64EncodedPayload,
        providerreferenceId: "",
        serviceCode: result.data.responseDetails?.serviceCode,
        serviceName: result.data.responseDetails?.serviceName,
        status: 1,
        transactionId: transactionId,
        userId: data?.userId, //loginResultObject.user? changed to data
        amount: result.data.responseDetails?.totalAmount,
      };

      const res = await PaymentService.PhonepeTransaction(phonepeTransaction);

      if (res.data === 1) {
        let appointmentId = result.data.responseDetails?.appointmentId;
        const appointmentRes = await AppointmentService.GetAppointmentId(
          appointmentId
        );

        let appointmentUpdate = {
          appointmentId: result.data.responseDetails?.appointmentId,
          email: result.data.responseDetails?.email,
          meetingLink: result.data.responseDetails?.meetingLink,
          mobile: result.data.responseDetails?.mobile,
          id: appointmentRes.data[0].id,
          patientId: result.data.responseDetails?.patientId,
          status: 1,
          userId: data?.userId, //loginResultObject.user? changed to data
          paymentReceived: "Y",
        };

        const updateRes = await AppointmentService.AppointmentUpdate(
          appointmentUpdate
        );

        let billingTransaction = {
          account_Id: data?.account_Id, //loginResultObject.user? changed to data
          amount: result.data.responseDetails?.totalAmount,
          clinic_id: data?.clinic_Id, //loginResultObject.user? changed to data
          createdBy: result.data.responseDetails?.patientId,
          createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          discountAmount: 0,
          discountPercentage: 0,
          gst: 0,
          modifiedBy: result.data.responseDetails?.patientId,
          modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          paidAmount: result.data.responseDetails?.totalAmount,
          patientId: result.data.responseDetails?.patientId,
          paymentDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          paymode: "PhonePe",
          serviceCode: "APPT",
          serviceCost: result.data.responseDetails?.totalAmount,
          serviceName: "Appoinment Consultation",
          serviceTransId: result.data.responseDetails?.appointmentId,
          status: 0,
          transactionId: transactionId,
          userId: data?.userId, //loginResultObject.user? changed to data
        };

        const billingRes = await PaymentService.BillingTransaction(
          billingTransaction
        );

        if (billingRes.data === 1) {
          const configurationObject = {
            method: "POST",
            url: PHONE_PE_HOST_URL,
            data: { request: base64EncodedPayload },
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-VERIFY": xVerifyChecksum,
            },
          };
          const paymentRes = await axios(configurationObject);
          window.location.replace(
            paymentRes.data.data.instrumentResponse.redirectInfo.url
          );
        }
      }
    }
  } catch (error) {
    console.error("An error occurred:", error);
    message.error("An error occurred while sending invite. Please try again.");
  }
};

export const NewPatientPayment = async (data, doctorDetails) => {
  console.log("data", data);

  try {
    const result = await enrollmentServices.Invite(data);

    console.log("result.data", result.data);

    if (!result.data) {
      message.error("Registration Failed");
      return;
    }

    const convertUTCDate = (date, format = "MM/DD/YYYY") => {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
      return moment(new Date(date)).utc().format(format);
    };

    const loginResultObject = doctorDetails;
    console.log("loginResultObject", loginResultObject);

    if (result.data.code === "FAILED") {
      message.error(result.data.message);
      return;
    }

    if (result.data.code === "SUCCESS") {
      let date = moment(new Date()).format("YYYYMMDDHHmmssSSS");
      let transactionId = "MT" + date;
      let appointmentDetails =
        transactionId +
        "|" +
        result.data?.responseDetails.patientId +
        "|" +
        loginResultObject.userId +
        "|" +
        result.data?.responseDetails.appointmentId +
        "|" +
        loginResultObject.account_Id +
        "|" +
        loginResultObject.clinic_Id;
      let base64string = Base64.encode(appointmentDetails);
      let paymentPayload = {
        merchantId: "DOXZONUAT",
        merchantTransactionId: transactionId,
        merchantUserId: "MUID123",
        amount: parseFloat(result.data.responseDetails?.totalAmount) * 100,
        redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}phonepepayment/${base64string}`,
        redirectMode: "POST",
        callbackUrl: `${process.env.REACT_APP_BASE_URL}/phonePeResponse1/paymentCallBackDetails`,
        mobileNumber: result.data.responseDetails?.mobile,
        paymentInstrument: {
          type: "PAY_PAGE",
        },
      };

      const SALT_KEY = "58d4bcb6-f046-4e58-8c62-66505c0e32d9";
      const PHONE_PE_HOST_URL =
        "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay";
      let bufferObj = Buffer.from(JSON.stringify(paymentPayload), "utf8");
      let base64EncodedPayload = bufferObj.toString("base64");
      let string = base64EncodedPayload + "/pg/v1/pay" + SALT_KEY;
      let sha256_val = sha256(string);
      let xVerifyChecksum = sha256_val + "###" + 1;

      let phonepeTransaction = {
        account_Id: loginResultObject.account_Id,
        checksum: xVerifyChecksum,
        clinic_id: loginResultObject.clinic_Id,
        createdBy: result.data.responseDetails?.patientId,
        createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
        modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
        paidDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
        modifiedBy: result.data.responseDetails?.patientId,
        patientId: result.data.responseDetails?.patientId,
        enoCodePayload: base64EncodedPayload,
        providerreferenceId: "",
        serviceCode: "APPT",
        serviceName: "Appointment Consultation",
        status: 1,
        serviceTransId: result.data?.responseDetails.appointmentId,
        transactionId: transactionId,
        userId: loginResultObject.userId,
        amount: result.data.responseDetails?.totalAmount,
      };

      const res = await PaymentService.PhonepeTransaction(phonepeTransaction);

      if (res.data === 1) {
        let appointmentId = result.data.responseDetails?.appointmentId;
        const appointmentRes = await AppointmentService.getAppointmentId(
          appointmentId
        );

        let appointmentUpdate = {
          appointmentId: result.data.responseDetails?.appointmentId,
          email: result.data.responseDetails?.email,
          meetingLink: result.data.responseDetails?.meetingLink,
          mobile: result.data.responseDetails?.mobile,
          id: appointmentRes.data[0].id,
          patientId: result.data.responseDetails?.patientId,
          status: 1,
          userId: loginResultObject.userId,
          paymentReceived: "Y",
          createdBy: loginResultObject.userId,
          modifiedBy: loginResultObject.userId,
        };

        const updateRes = await AppointmentService.AppointmentUpdate(
          appointmentUpdate
        );

        let billingTransaction = {
          account_Id: loginResultObject.account_Id,
          amount: result.data.responseDetails?.totalAmount,
          clinic_id: loginResultObject.clinic_Id,
          createdBy: result.data.responseDetails?.patientId,
          createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          discountAmount: 0,
          discountPercentage: 0,
          gst: 0,
          modifiedBy: result.data.responseDetails?.patientId,
          modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          paidAmount: result.data.responseDetails?.totalAmount,
          patientId: result.data.responseDetails?.patientId,
          paymentDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          paymode: "PhonePe",
          serviceCode: "",
          serviceCost: result.data.responseDetails?.totalAmount,
          serviceName: "",
          serviceTransId: result.data.responseDetails?.appointmentId,
          status: 0,
          transactionId: transactionId,
          userId: loginResultObject.userId,
          billingTransactionServices: [],
        };

        let array1 = {
          account_Id: loginResultObject.account_Id,
          amount: data.consultationFee,
          clinic_id: loginResultObject.clinic_Id,
          createdBy: result.data.responseDetails?.patientId,
          createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          fromDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          modifiedBy: result.data.responseDetails?.patientId,
          modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          paidAmount: data.consultationFee,
          patientId: result.data.responseDetails?.patientId,
          paymentDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          paymode: "PhonePe",
          serviceCode: "APPT",
          serviceCost: data.consultationFee,
          serviceName: "Appointment Consultation",
          serviceTransId: result.data.responseDetails?.appointmentId,
          status: 0,
          toDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          transactionId: transactionId,
          userId: loginResultObject.userId,
        };

        billingTransaction.billingTransactionServices.push(array1);

        // let data1 = JSON.parse(data);

        // console.log("data", data1);

        if (data.patientRegFeeYN === "Y") {
          billingTransaction.billingTransactionServices = [
            {
              account_Id: loginResultObject.account_Id,
              amount: data.patientRegFee,
              clinic_id: loginResultObject.clinic_Id,
              createdBy: result.data.responseDetails?.patientId,
              createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
              fromDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
              modifiedBy: result.data.responseDetails?.patientId,
              modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
              paidAmount: data.patientRegFee,
              patientId: result.data.responseDetails?.patientId,
              paymentDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
              paymode: "PhonePe",
              serviceCode: "REG_FEE",
              serviceCost: data.patientRegFee,
              serviceName: "Patient Registration Fee",
              serviceTransId: result.data.responseDetails?.appointmentId,
              status: 0,
              toDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
              transactionId: transactionId,
              userId: loginResultObject.userId,
            },
          ];
        }

        const billingRes = await PaymentService.BillingTransaction(
          billingTransaction
        );

        if (billingRes.data === 1) {
          const configurationObject = {
            method: "POST",
            url: PHONE_PE_HOST_URL,
            data: { request: base64EncodedPayload },
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-VERIFY": xVerifyChecksum,
            },
          };
          const paymentRes = await axios(configurationObject);
          window.location.replace(
            paymentRes.data.data.instrumentResponse.redirectInfo.url
          );
        }
      }
    }
  } catch (error) {
    console.error("An error occurred:", error);
    message.error("This patient already exists");
  }
};
