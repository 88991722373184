import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import banner1 from "../assets/images/banner1.jpg";
// import banner2 from "../assets/images/banner2.jpg";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";

function Hero() {
  const CustomNextArrow = ({ onClick }) => {
    return (
      <div className="custom-next" onClick={onClick}>
        <MdOutlineKeyboardArrowRight size={40} color="black" />{" "}
        {/* Custom icon */}
      </div>
    );
  };

  const CustomPrevArrow = ({ onClick }) => {
    return (
      <div className="custom-prev" onClick={onClick}>
        <MdOutlineKeyboardArrowLeft size={40} color="black" />{" "}
        {/* Custom icon */}
      </div>
    );
  };

  const settings = {
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    cssEase: "linear",
    pauseOnHover: false,
    draggable: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    dotsClass: "slick-dots custom-dots hidden",
  };

  return (
    <div className="hero-container overflow-hidden 2xl:h-[100vh] w-[100vw] xl:h-[100vh] lg:h-[100%] l:h-[100vh] x:h-[100vh] ">
      <Slider {...settings}>
        <div className="banner1  overflow-hidden h-[100vh] w-[100vw] relative flex">
          {/* <img src={banner1} alt="Slide 1" className="hero-image" /> */}
          <h1 className="heading absolute sm:top-[50%] sm:left-[50%] md:top-[50%] md:left-[50%] x:top-[50%] x:left-[50%] lg:top-[50%] lg:left-[50%] l:top-[50%] xl:top-[60%] xl:left-[52%] 2xl:top-[60%] 2xl:left-[55%] z-40 text-left sm:text-[20px] md:text-[30px] x:text-[40px] lg:text-[50px] xl:text-[45px] 2xl:text-[70px] lg:leading-[55px] xl:leading-[65px] 2xl:leading-[75px] font-semibold">
            Revolutionizing <br />
            Healthcare With
            <br /> Telemedicine
          </h1>
          <p className="subHeading absolute sm:top-[70%] sm:left-[54.5%] md:top-[70%] md:left-[56.5%] x:left-[58.5%] lg:top-[73%] lg:left-[54%] l:top-[72%] l:left-[58.8%] xl:top-[76%] xl:left-[60%] x:top-[75%]  2xl:top-[78%] 2xl:left-[62.8%] sm:text-[14px] x:text-[20px] xl:text-[18px] 2xl:text-[24px] md:text-[14px] font-normal mt-5">
            Experience the future of healthcare with virtual consultations.
            Connect with top doctors from the comfort of your home.
          </p>
        </div>
        <div className="relative banner2 overflow-hidden h-[100vh] w-[100vw]">
          {/* <img src={banner2} alt="Slide 2" className="hero-image" /> */}
          <h1 className="heading2 absolute lg:leading-[40px] xl:leading-[50px] 2xl:leading-[65px] sm:top-[50%] sm:left-[40%] md:top-[50%] md:left-[33%] x:top-[50%] x:left-[25%] lg:top-[50%]  lg:left-[30%] xl:top-[50%] xl:left-[25%] 2xl:top-[50%] 2xl:left-[30%] z-40 text-left sm:text-[20px] md:text-[30px] x:text-[40px] lg:text-[40px] xl:text-[40px] 2xl:text-[60px] font-semibold">
            Monitor Your Health
            <br /> Digitally
          </h1>
          <p className="subHeading2 absolute sm:top-[65%] sm:left-[45%] md:top-[63%] md:left-[40.5%] x:top-[63%] x:left-[28%] lg:top-[62%] lg:left-[33.5%] xl:top-[59%] xl:left-[27.8%] 2xl:top-[63%] 2xl:left-[30%] sm:text-[14px] md:text-[14px] xl:text-[16px] 2xl:text-[20px] font-normal mt-5">
            Track your vitals seemlessly with our advanced digital tools.
            <br /> Stay informed and proactive about your health.
          </p>
        </div>
      </Slider>
    </div>
  );
}

export default Hero;
