import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, DatePicker, Select, message } from "antd";
import moment from "moment";
import MedicalService from "../../../Services/MedicalService";
import dayjs from "dayjs";
import { useData } from "../../../Services/Context";
import { Option } from "antd/es/mentions";

function AddMedication(props) {
  const [form] = Form.useForm();
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [drugValue, setDrugValue] = useState("");
  const [medicines, setMedicines] = useState([]);
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  const [selectedMedicine, setSelectedMedicine] = useState([]);
  const [composition, setComposition] = useState("");
  const [fromDate, setFromDate] = useState("");
  const { patientLoginData } = useData();
  const patientName = `${activePatient?.salutation || ""}${
    activePatient?.firstName || ""
  } ${activePatient?.lastName || ""}`.trim();
  const [disable, setDisable] = useState(false);

  const handleCancel = () => {
    props.onClose();
  };
  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleDate = (date, dateString) => {
    if (dateString) {
      const constructedTime = dateString + " " + "00:00:00";
      console.log(dateString);
      setFromDate(constructedTime);
    } else {
      setFromDate("");
    }
  };

  const handleKeyPress = (e) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const frequencyOptions = [
    "0-0-1",
    "0-1-1",
    "0-1-0",
    "1-1-1",
    "1-0-0",
    "1-0-1",
    "1-1-0",
    "1-1-1-1",
    "1-0-0-1",
    "1-0-0-0",
    "1-1-0-0",
    "1-1-1-0",
    "0-0-0-1",
    "0-0-1-1",
    "0-0-1-0",
    "0-1-0-0",
    "0-1-1-1",
    "s.o.s",
    "q.wk.",
    "b.i.d.",
    "t.i.d.",
    "q.i.d.",
    "q.h.s.",
    "5X a day",
    "q.4h",
    "q.6h",
    "q.0.d.",
    "prn.",
    "q.d.",
    "I.M.",
    "Subq.",
    "Rectally",
    "I.V.",
    "O.D.",
    "O.S.",
    "O.U.",
    "2 ml",
    "2.5 ml",
    "5 ml",
    "10 ml",
  ];

  const handleSelectChange = (value) => {
    setSelectedFrequency(value);
  };

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  const handleChange = (value, option) => {
    if (option) {
      MedicalService.DrugList(option.label)
        .then((res) => {
          setSelectedMedicine(res.data[0]);
          setDrugValue(res.data[0]?.medicineName);
          const composition = res.data[0]?.composition;
          form.setFieldsValue({ composition });
          setComposition(composition || "");
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      setDrugValue(value);
    }
  };

  const onSearch = (value) => {
    setDrugValue(value);
    MedicalService.DrugList(value)
      .then((res) => {
        const fetchedMedicines = res.data;
        let updatedMedicines = fetchedMedicines;

        const filteredOptions = fetchedMedicines.filter((option) =>
          option.medicineName.toLowerCase().includes(value.toLowerCase())
        );

        if (filteredOptions.length === 0 && value.trim() !== "") {
          const newOption = {
            code: "",
            medicineName: value,
          };
          updatedMedicines = [...fetchedMedicines, newOption];
        }
        setMedicines(updatedMedicines);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const onFinish = (values) => {
    setDisable(true);
    if (!values.durationtype || !values.duration || !values.drugName || !values.dosage || !fromDate) {
      message.error("Please fill in the required field");
      setDisable(false);
      return;
    }
    let payload = [
      {
        createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
        givenDate: fromDate,
        createdBy: patientCode,
        modifiedBy: patientCode,
        status: 1,
        patientCode: patientCode,
        enteredBy: "P",
        createdName: patientName,
        modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
        fromDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
        toDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
        startCreatedTime: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
        endCreatedTime: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
        adverseEffects: values.adverseEffects,
        adviceRelatedMedicine: values.adviceRelatedMedicine,
        compliance: values.compliance,
        composition: values.composition,
        dosage: values.dosage,
        dose: values.dose,
        drugName: values.drugName,
        duration: values.duration,
        durationType: values.durationtype,
        frequency: selectedFrequency,
        indication: values.indication,
        sideEffects: values.sideEffects,
        account_Id: patientLoginData?.account_Id,
        clinic_Id: patientLoginData?.clinic_Id,
      },
    ];
    MedicalService.AddMedication(payload)
      .then((result) => {
        props.onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Form
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        onFinish={onFinish}
      >
        <div className="border rounded-lg p-2">
        <Row gutter={20}>
          <Col className="gutter-row" span={12}>
            <Form.Item label={<span>Drug Name <span style={{ color: "#FF000F" }}>*</span></span>} name="drugName">
              <Select
                showSearch
                placeholder="Enter Medicine Name"
                className="w-full "
                onSearch={onSearch}
                onChange={handleChange}
                value={drugValue}
                options={medicines.map((medicine) => {
                  return {
                    label: medicine.medicineName,
                    value: medicine.medicineName,
                  };
                })}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                notFoundContent={null}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label={<span>Composition </span>} name="composition">
              <Input
                className="h-8 w-full rounded-md border-gray-200 px-4 outline-none border"
                defaultValue={selectedMedicine?.composition}
                disabled
                onChange={(e) => setComposition(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label={<span>Dosage <span style={{ color: "#FF000F" }}>*</span></span>} name="dosage">
              <Input rows={2} placeholder="Enter " />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item label={<span>Frequency</span>} name="frequency">
              <Select placeholder="Select Frequency" 
              className="w-full"
              onChange={handleSelectChange}
              value={selectedFrequency}
              >
                {frequencyOptions.map((option, index) => (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>{" "}
            </Form.Item>
          </Col>
          {/* <Col className="gutter-row" span={12}>
            <Form.Item label={<span>Duration </span>} name="duration">
              <Input rows={2} placeholder="Enter " />
            </Form.Item>
          </Col> */}
          <div className="w-2/4 my-2">
            <div className="mx-2">
              <span>
                Duration <span style={{ color: "#FF000F" }}>*</span>
              </span>
            </div>
            <div className="w-[95%] h-[25px] relative flex items-center border border-brand-textGray p-4 rounded-lg mx-2 mt-3">
              <Form.Item
                name="duration"
                className="w-2/4 h-[inherit] absolute top-0"
              >
                <Input
                  onKeyPress={handleKeyPress}
                  // rows={2}
                  className="border-none outline-none"
                  placeholder="Enter Duration"
                />
              </Form.Item>
              <Form.Item
                name="durationtype"
                className="w-2/4 h-[inherit] absolute right-0 top-[0px]"
              >
                <Select
                  placeholder="Select Duration Type"
                  className="border-none"
                >
                  <Select.Option value="Days">Days</Select.Option>
                  <Select.Option value="Weeks">Weeks</Select.Option>
                  <Select.Option value="Months">Months</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <Col className="gutter-row" span={12}>
            <Form.Item label={<span>Indication </span>} name="indication">
              <Input rows={2} placeholder="Enter " />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label={<span>Side effects</span>} name="sideEffects">
              <Input rows={2} placeholder="Enter " />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label={<span>Compliance </span>} name="compliance">
              <Input
                className="h-8 w-full rounded-md border-gray-200 px-4 outline-none border"
                // defaultValue={selectedMedicine?.composition}
                // onChange={(e) => setComposition(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={<span>Adverse effects</span>}
              name="adverseEffects"
            >
              <Input rows={2} placeholder="Enter " />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={<span>Advice related to medicines by the doctor.</span>}
              name="adviceRelatedMedicine"
            >
              <Input rows={2} placeholder="Enter " />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  From / Checkup Date <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="fromdate"
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Select"
                format="YYYY-MM-DD"
                onChange={handleDate}
                disabledDate={disabledFutureDate}
              />
            </Form.Item>
          </Col>
          {/* <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  Date <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="givenDate"
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Select"
                format="MM-DD-YYYY"
              />
            </Form.Item>
          </Col> */}
          {/* <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  From Date <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="fromDate"
            >
              <DatePicker
                style={{ width: "100%" }}
                onChange={onDateChange}
                placeholder="Select"
                format="MM-DD-YYYY"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  To Date <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="toDate"
            >
              <DatePicker
                style={{ width: "100%" }}
                onChange={onDateChange}
                placeholder="Select"
                format="MM-DD-YYYY"
              />
            </Form.Item>
          </Col> */}
          <Col className="gutter-row" span={24}>
            <div className="flex items-center w-full justify-end my-5">
              <button
                onClick={handleCancel}
                className="border-brand-secondaryColor border rounded text-brand-secondaryColor text-sm px-6 py-1.5"
              >
                Cancel
              </button>
              <button disabled={disable} className="bg-secondaryColor border-brand-secondaryColor border rounded px-5 py-1.5 text-brand-white text-sm ml-3">
                Submit
              </button>
            </div>
          </Col>
        </Row>
        </div>
      </Form>
    </div>
  );
}

export default AddMedication;
