import http from "./http_common";

class AuthService {
  queryString(payload) {
    return Object.keys(payload)
      .map((key) => key + "=" + payload[key])
      .join("&");
  }

  getMyPrescription(payload) {
    var queryString = this.queryString(payload);
    return http.get(`patientDocument/list/filter?${queryString}`);
  }
  getSharePrescription(id) {
    return http.get(`prescriptionDetails/list/filter?refNumber=${id}`);
  }
  getMyPrescriptionView(payload) {
    var queryString = this.queryString(payload);
    return http.get(`consultation/list/filter?${queryString}`);
  }
  getMyReport(payload) {
    var queryString = this.queryString(payload);
    return http.get(`patientDocument/list/filter?${queryString}`);
  }
  getClinicData(payload) {
    return http.get(`/Clinic_Master/list/filter?clinic_Id=${payload}`);
  }
  stateMaster() {
    return http.get(`/stateMaster/list/filter?status=1`);
  }
  ChangePassword(payload) {
    return http.post(`patientpassword-config/change-password/`, payload);
  }
  getUser(payload) {
    return http.get(`user/list/filter?userId=${payload}`);
  }
  getUserData(payload) {
    var queryString = this.queryString(payload);
    return http.get(`user/getStampAndSignature?${queryString}`);
  }
  getHospitalAccountData(id) {
    return http.get(`/accountMaster/list/filter?account_Id=${id}`);
  }
  getHospitalAccountDataLogo(id) {
    var queryString = this.queryString(id);
    return http.get(`/accountMaster/getLogo?${queryString}`);
  }
}

export default new AuthService();
