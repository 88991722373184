import { Button, Modal, Space, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import ViewPrescription from "./ViewPrescription";
import AuthService from "../../../Services/AuthService";
import FilterIcon from "../../../assets/images/Svg/FilterIcon.svg";
import PrescriptionFilter from "./PrescriptionFilter";
import dayjs from "dayjs";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import Layout from "../../../Layout";

function MyPrescription({ selectedPatient }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [prescriptionsList, setPrescriptionsList] = useState([]);
  const [selectedPrescription, setSelectedPrescription] = useState({});
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [isLoading, setIsLoading] = useState(false);
  const [filterValues, setFilterValues] = useState(null);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  const patientName = `${activePatient?.salutation || ""} ${activePatient?.firstName || ""
    } ${activePatient?.lastName || ""}`.trim();

  const handleClick = (presc) => {
    setIsModalOpen(true);
    setSelectedPrescription(presc);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleFilter = () => {
    if (filterValues) {
      setFilterValues(null);
    } else {
      setIsModalOpen1(true);
    }
  };

  const handleCloseModal = (values) => {
    setFilterValues(values);
    // fetchAllPrescriptions(values);
    setIsModalOpen1(false);
  };

  const fetchAllPrescriptions = async () => {
    let filters = { ...filterValues };
    setIsLoading(true);

    if (filters) {
      if (filters.fromDate) {
        filters.fromDate = dayjs(filters.fromDate, "MM/DD/YYYY").format("MM/DD/YYYY");
      }
      if (filters.toDate) {
        filters.toDate = dayjs(filters.toDate, "MM/DD/YYYY").format("MM/DD/YYYY");
      }
    }

    try {
      let payload = {
        patientId: patientCode,
        detailsYN: "Y",
        status: 2,
      };

      // Add filter parameters to the payload if they exist
      if (filters) {
        if (filters.fromDate) {
          payload.fromDate = filters.fromDate;
        }
        if (filters.toDate) {
          payload.toDate = filters.toDate;
        }
        if (filters.userName) {
          payload.userName = filters.userName;
        }
        if (filters.clinic_Name) {
          payload.clinic_Name = filters.clinic_Name;
        }
        if (filters.appointmentId) {
          payload.appointmentId = filters.appointmentId;
        }
      }
      // Corrected this part to chain the then and catch properly
      AuthService.getMyPrescriptionView(payload)
        .then((result) => {
          setPrescriptionsList(result.data);
          setIsLoading(false)
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false)
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAllPrescriptions();
  }, [filterValues]);

  const updatedPrescriptionsList = prescriptionsList?.map((prescription) => ({
    ...prescription,
    patientName: patientName,
  }));

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patientName",
      width: 175,
    },
    {
      title: "Appointment Id",
      dataIndex: "appointmentId",
    },
    {
      title: "Consultation Reason",
      dataIndex: "consultationReason",
      width: 200,
    },
    {
      title: "Doctor Name",
      dataIndex: "userName",
      key: "userName",
      // render: (text) => `Dr. ${text}`,
    },
    {
      title: "Clinic Name",
      dataIndex: "clinic_Name",
    },
    {
      title: "Date",
      dataIndex: "consultationDate",
      render: (_, record) => (
        <Space size="middle" className="">
          {record?.consultationDate?.slice(0, 10)}
        </Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <Space size="middle" className="">
          <button
            className="p-2 rounded-md bg-color1 text-white flex items-center justify-center"
            onClick={() => handleClick(record)}
          >
            View prescription
          </button>
        </Space>
      ),
    },
  ];

  return (
    <Layout>
      <div className="p-4">
        <div className="flex justify-between">
          <div className="font-bold text-xl">My Prescription</div>
          <div className="flex  gap-3">
            <Button
              className="w-20 h-9 mb-3 px-2 flex items-center gap-2 custom_filter"
              onClick={handleFilter}
            >
              {filterValues ? (
                <div className="flex items-center">
                  <span className="text-white mx-1">Clear</span>
                  <CloseOutlined
                    style={{ color: "white", marginLeft: "4px" }}
                  />
                </div>
              ) : (
                <div className="flex items-center">
                  <span>
                    <img src={FilterIcon} alt="Icon" />
                  </span>
                  <span className="text-white ml-2">Filter</span>
                </div>
              )}
            </Button>
          </div>
        </div>
        <Table
          dataSource={updatedPrescriptionsList}
          columns={columns}
          pagination={{
            pageSize: 10,
            position: ["bottomCenter"],
          }}
          loading={{
            spinning: isLoading,
            indicator: <Spin indicator={loadingIcon} size="large" />,
          }}
          scroll={{ x: "max-content", y: false }}
        />
        <Modal
          // title="View Prescription"
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          style={{ top: 20 }}
          width={1000}
          footer={false}
        // footer={[
        //   <div className="flex items-center justify-end gap-2">
        //     <button className="text-white bg-brand-primaryColor w-44 h-12  rounded-lg ">
        //       Download Prescription
        //     </button>
        //   </div>
        // ]}
        >
          <ViewPrescription
            prescription={selectedPrescription}
            selectedPatient={selectedPatient}
          />
        </Modal>

        {isModalOpen1 && (
          <Modal
            title={<span className="text-base font-bold font-sans">Prescription Filter</span>}
            visible={isModalOpen1}
            onOk={() => handleCloseModal()}
            centered={true}
            footer={null}
            onCancel={() => handleCloseModal()}
            okText="Submit"
            cancelText="Cancel"
            width={500}
            maskClosable={false}
          // getContainer={false}
          // bodyStyle={{
          //   maxHeight: '70vh',
          //   overflowY: 'auto',  
          // }}
          >
            <PrescriptionFilter
              onClose={(values) => handleCloseModal(values)}
            />
          </Modal>
        )}
      </div>
    </Layout>
  );
}

export default MyPrescription;
