import React, { useEffect, useRef, useState } from 'react'
import { IoLocationSharp } from 'react-icons/io5'
import { MdKeyboardArrowRight, MdOutlineKeyboardArrowLeft } from 'react-icons/md'
import { changeIntoString, generateDates, generateTimeSlots } from '../../utils/dates';
import moment from 'moment';
import { convertTo24HourFormat } from '../../utils/convertionFunc';
import doctorIcon from "../../assets/images/Svg/doctorIcon.svg";

function AppointmentSideDrawer({ selectedDoctor, selectedDoctorSlots, handleContinueBooking, errorMessage,handleSelected,selectedDate,handleSelectedSlot,selectedSlot }) {
    const [dates, setDates] = useState([]);
    const [lastDate, setLastDate] = useState(new Date());
    const [morningSlots, setMorningSlots] = useState([]);
    const [slotVisible, setSlotVisible] = useState(false);
    const [eveningSlots, setEveningSlots] = useState([]);
    const containerRef = useRef(null);

    useEffect(() => {
        console.log(' slots from comp ', selectedDoctorSlots);
        const datesArr = generateDates(new Date(), 30);
        if (datesArr && datesArr.length !== 0) {
            handleSelectDate(datesArr[0]);
        }
        setDates(datesArr);
    }, [])

    const handleScroll = () => {
        const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
        if (scrollLeft + clientWidth >= scrollWidth - 5) {
            loadMoreDates();
        }
    };

    const loadMoreDates = () => {
        const newLastDate = new Date(lastDate);
        newLastDate.setDate(lastDate.getDate() + 30);

        const newDates = generateDates(newLastDate, 30);
        setDates((prevDates) => [...prevDates, ...newDates]);
        setLastDate(newLastDate);
    };

    const scrollLeft = () => {
        containerRef.current.scrollBy({ left: -100, behavior: "smooth" });
    };

    const scrollRight = () => {
        containerRef.current.scrollBy({ left: 100, behavior: "smooth" });
    };

    const handleSelectDate = (dateObj) => {
        console.log(' before proce ',dateObj);
       handleSelected(dateObj.fullDate);
        if (selectedDoctorSlots) {
            const currselectedDate = moment(dateObj.fullDate, 'MM/DD/YYYY', true);
            const fromDate = moment(selectedDoctorSlots[0]?.fromDate, 'MM/DD/YYYY', true);
            const toDate = moment(selectedDoctorSlots[0]?.toDate, 'MM/DD/YYYY', true);
            const isSelectedDateinSlotRange = currselectedDate.isSameOrAfter(fromDate) && currselectedDate.isSameOrBefore(toDate);

            if (isSelectedDateinSlotRange) {
                const slicedDate = Number(dateObj.fullDate.slice(3, 5));
                const timeSlots = generateTimeSlots(slicedDate, selectedDoctorSlots[0].fromTime, selectedDoctorSlots[0].toTime);
                setMorningSlots(timeSlots.morningSlots);
                setEveningSlots(timeSlots.eveningSlots);
                setSlotVisible(true);
                console.log('A hi from inside of if ');
            } else {
                setSlotVisible(false);
                setMorningSlots([]);
                setEveningSlots([]); 
            }
        }
    };

    const handleSelectedTimeSlot = (selectedSlot) => {
        const formattedTime = convertTo24HourFormat(selectedSlot);
        handleSelectedSlot(formattedTime)
    }


    return (
        <>
            <div>
                <div className="flex gap-2 border rounded-lg p-3">
                    <div>
                        {
                            selectedDoctor.imageName ? (
                                <img
                                    src={process.env.REACT_APP_IMAGE_URL + selectedDoctor?.imageName}
                                    alt={selectedDoctor.userName}
                                    className="rounded-full w-10 h-10"
                                />
                            ) : (
                                <img
                                    src={doctorIcon}
                                    alt={selectedDoctor.userName}
                                    className="rounded-full w-10 h-10"
                                />
                            )
                        }
                    </div>
                    <div>
                        <p className="font-medium text-base">{`${selectedDoctor?.salutation}${selectedDoctor?.firstName} ${selectedDoctor?.lastName}`}</p>
                        <p className="text-xs font-medium text-gray-400 mt-1">
                            {selectedDoctor?.qualification}
                        </p>
                        <div className="flex gap-2 mt-1">
                            <IoLocationSharp className="mt-1 text-[#001a3fae]" />
                            <p>{selectedDoctor?.city}, {selectedDoctor?.state}</p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between border-b pb-2 mt-3">
                    <button
                        className={`text-sm font-bold uppercase px-4 py-2 border-b-2 border-blue-500 text-blue-600`}>
                        Video Consultation
                    </button>
                </div>

                <div>
                    <div className="flex justify-between items-center border-b pb-2 mb-4 mt-2">
                        <div className="text-lg font-medium">Video Consultation</div>
                        <div className="text-lg font-semibold">₹{selectedDoctor.videoFee}</div>
                    </div>
                    <div>
                    </div>
                    {/* Date Selection */}
                    <div className="w-full flex justify-evenly items-center gap-1 mb-4">
                        {/* Left Arrow */}
                        <div className="w-[10%]">
                            <MdOutlineKeyboardArrowLeft
                                onClick={scrollLeft}
                                className="w-10 h-10 p-1 text-[#3985f0] cursor-pointer"
                            />
                        </div>

                        {/* Date Container */}
                        <div
                            ref={containerRef}
                            onScroll={handleScroll}
                            className="flex items-center gap-3 overflow-x-auto scrollWidth"
                            style={{ scrollBehavior: "smooth" }}
                        >
                            {dates.map((dateObj, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleSelectDate(dateObj)}
                                    className={`p-1 border rounded-md font-bold text-sm disabled:opacity-50 ${selectedDate === dateObj.fullDate
                                        ? "bg-blue-100 border-blue-500"
                                        : "bg-white"
                                        }`}
                                >
                                    {dateObj.day} {dateObj.date}
                                </button>
                            ))}
                        </div>

                        {/* Right Arrow */}
                        <div className="w-[10%]">
                            <MdKeyboardArrowRight
                                onClick={scrollRight}
                                className="w-10 h-10 text-[#3985f0] cursor-pointer"
                            />
                        </div>
                    </div>
                    {selectedDate && (<div className="p-1 font-semibold text-lg">
                        {changeIntoString(selectedDate)}
                    </div>)}
                    {/* Slot Selection */}
                    <div className="flex flex-col items-center border-t">
                        <div className="flex flex-col items-center mb-4">
                            <p className="text-blue-800 font-semibold mt-2">
                                MORNING SLOTS
                            </p>
                            {slotVisible && morningSlots.length !== 0 ?
                                (
                                    <div className="flex justify-evenly flex-wrap gap-2 w-full mt-1">
                                        {morningSlots.map((slot, index) => (
                                            <button
                                                key={index}
                                                onClick={() => handleSelectedTimeSlot(slot)}
                                                className={`px-4 py-2 border rounded-md ${selectedSlot === convertTo24HourFormat(slot)
                                                    ? "bg-blue-100 border-blue-500"
                                                    : "bg-white"
                                                    }`}
                                            >
                                                {slot}
                                            </button>
                                        ))}
                                    </div>
                                ) :
                                (
                                    <div className="w-full h-[inherit] min-h-14 flex items-center justify-center">
                                        <p className="font-semibold">No slots available</p>
                                    </div>
                                )}
                        </div>
                        <div className="flex flex-col items-center mb-4">
                            <p className="text-blue-800 font-semibold mb-2">
                                AFTERNOON SLOTS
                            </p>
                            {slotVisible && eveningSlots.length !== 0 ?
                                (
                                    <div className="flex justify-evenly flex-wrap gap-2 w-full mt-1">
                                        {eveningSlots.map((slot, index) => (
                                            <button
                                                key={index}
                                                onClick={() => handleSelectedTimeSlot(slot)}
                                                className={`px-4 py-2 border rounded-md ${selectedSlot === convertTo24HourFormat(slot)
                                                    ? "bg-blue-100 border-blue-500"
                                                    : "bg-white"
                                                    }`}
                                            >
                                                {slot}
                                            </button>
                                        ))}
                                    </div>
                                ) :
                                (
                                    <div className="w-full h-[inherit] min-h-14 flex items-center justify-center">
                                        <p className="font-semibold">No slots available</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <p className="text-red-500">{errorMessage && errorMessage}</p>
                    {/* Continue Booking Button */}
                    <button onClick={() => handleContinueBooking(selectedDate,selectedSlot)} className="mt-4 w-full py-2 bg-blue-500 text-white font-semibold rounded-lg">
                        Continue Booking
                    </button>
                </div>

            </div>
        </>
    )
}

export default AppointmentSideDrawer