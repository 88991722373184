import React, { useEffect } from "react";
import { useParams } from "react-router";
import { TiTickOutline } from "react-icons/ti";
import { BsPatchCheck } from "react-icons/bs";

const PaymentPage1 = ({ phonepeResponse }) => {
  const params = useParams();
  console.log("params", params);

  useEffect(() => {
    if (phonepeResponse) {
      const form = document.createElement("form");
      form.method = "POST";
      form.action =
        `${process.env.REACT_APP_BASE_URL}phonePeResponse1/paymentDetails01`;

      Object.keys(phonepeResponse).forEach((key) => {
        const hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = key;
        hiddenField.value = phonepeResponse[key];
        form.appendChild(hiddenField);
      });
      console.log("phoneperesponse", phonepeResponse);
      document.body.appendChild(form);
      form.submit();
    }
  }, [phonepeResponse]);

  // useEffect(() => {
  //   const PHONE_PE_HOST_URL = "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/status/";

  //   try {
  //     const configurationObject = {
  //       method: "POST",
  //       url: PHONE_PE_HOST_URL,
  //       data: { request: base64EncodedPayload },
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         "X-VERIFY": xVerifyChecksum,
  //         accept: "application/json",
  //       },
  //     };
  //     const response = await axios(configurationObject);
  //     let res = response.data.data.instrumentResponse.redirectInfo;
  //     window.location.replace(res.url);
  //     console.log("response",response)
  //     return response.data;
  //   } catch (error) {
  //     console.log("Warning", error);
  //     return error.response;
  //   }
  // },[])

  return (
      <div>
        <div className="bg-white p-6 rounded-xl shadow-lg mt-10 max-w-sm mx-auto border">
          <div className="text-center">
            <div className="bg-[#E6F2EE] w-12 h-12 rounded-full mx-auto mb-4 flex items-center justify-center">
            <div className="bg-green-500 w-7 h-7 rounded-full mx-auto flex items-center justify-center">
            <BsPatchCheck className="text-white"/>       
                 </div>
            </div>
            <div>
              <h2 className="text-xl text-gray-600 font-medium mb-2">Payment Success!</h2>
              <p className=" mb-4 font-bold">INR 500</p>
            </div>
          </div>
          <div className="border border-b-2"></div>
          <div className="m-3 ">
            <div className="flex justify-between ">
              <p className="text-gray-600 font-medium text-base">Transaction ID</p>
              <p className="text-gray-600 font-medium text-base">000085752257</p>
            </div>
            <div className="flex justify-between mt-2">
            <p className="text-gray-600 font-medium text-base">Payment Time</p>
            <p className="text-gray-600 font-medium text-base">30-06-2024, 10:20:15</p>
            </div>
            <div className="">
            <button className="text-center border rounded-lg mt-5 w-full p-2 bg-green-500 text-white text-lg font-medium">Done</button>
            </div>
          </div>
        </div>
      </div>
  );
};

export default PaymentPage1;
