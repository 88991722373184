import { Modal, Space, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import LabService from "../../../Services/LabService";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";

function LabReportTable(props) {
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const [labData, setLabData] = useState([]);
  const patientCode = activePatient?.patientId;
  const [reportView, setReportView] = useState(false);
  const filterValues = props.filterValues;
  const [isLoading, setIsLoading] = useState(false);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  const labReport = () => {
    setIsLoading(true);
    if (filterValues) {
      if (filterValues.fromDate) {
        filterValues.fromDate = dayjs(
          filterValues.fromDate,
          "MM/DD/YYYY"
        ).format("MM/DD/YYYY");
      }
      if (filterValues.toDate) {
        filterValues.toDate = dayjs(filterValues.toDate, "MM/DD/YYYY").format(
          "MM/DD/YYYY"
        );
      }
    }
    let payload = {
      status: 1,
      patientId: patientCode,
    };
    if (filterValues) {
      if (filterValues.fromDate) {
        payload.fromDate = filterValues.fromDate;
      }
      if (filterValues.toDate) {
        payload.toDate = filterValues.toDate;
      }
      if (filterValues.labTestOrderId) {
        payload.labTestOrderId = filterValues.labTestOrderId;
      }
    }
    LabService.getLabReportData(payload)
      .then((result) => {
        setLabData(result.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    labReport();
  }, [filterValues]);

  const handleReportView = (record) => {
    setReportView(record.resultDocumentName);
  };

  const columns = [
    {
      title: "Name of the Test",
      dataIndex: "labTestName",
      width: 250,
    },
    {
      title: "Date & Time",
      dataIndex: "uploadedDateTime",
      width:200
    },
    {
      title: "Lab OrderId",
      dataIndex: "labTestOrderId",
    },
    {
      title: "Diagnostics Name",
      dataIndex: "labName",
    },
    {
      title: "View Report",
      dataIndex: "resultDocumentName",
      render: (_, record) => (
        <Space size="middle" className="">
          <button
            className="p-2 rounded-md bg-secondaryColor text-white flex items-center justify-center"
            onClick={() => handleReportView(record)}
          >
            View Report
          </button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="flex flex-col  justify-between mt-6">
        <div>
          <Table
            columns={columns}
            dataSource={labData}
            loading={{
              spinning: isLoading,
              indicator: <Spin indicator={loadingIcon} size="large" />,
            }}
            pagination={{
              pageSize: 10,
              position: ["bottomCenter"],
            }}
            scroll={{ x: "max-content", y: false }}
          />
        </div>
      </div>

      {reportView && (
        <Modal
          visible={reportView}
          onOk={() => {
            setReportView(false);
          }}
          centered={true}
          footer={null}
          onCancel={() => {
            console.log("Cancel button clicked");
            setReportView(false);
          }}
          okText="Submit"
          cancelText="Cancel"
          maskClosable={true}
          width={850}
        >
          <embed
            src={process.env.REACT_APP_IMAGE_URL + reportView}
            type="application/pdf"
            width="100%"
            height="550px"
          />
        </Modal>
      )}
    </>
  );
}

export default LabReportTable;