import React, { useState } from "react";
import Logo from "../assets/images/Doxzon-Black.png";
import Modal from "react-modal";
import { FaQuestionCircle } from "react-icons/fa";

// Updated formatCardNumber function
function formatCardNumber(value) {
  const cleaned = value.replace(/\D+/g, "");
  const formattedCardNumber = cleaned.replace(/(.{4})/g, "$1 ").trim();
  return formattedCardNumber.slice(0, 19);
}


function formatExpiry(value) {
  const cleaned = value.replace(/\D+/g, "");
  let month = cleaned.slice(0, 2);
  let year = cleaned.slice(2); 
  if (month > "12") {
    month = "12";
  }
  return month + (year ? `/${year}` : "");
}


Modal.setAppElement("#root");

const MultiStepModal = ({ closeModal }) => {
  const [step, setStep] = useState(1);
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvv, setCvv] = useState("");

  const nextStep = () => setStep(2);
  const prevStep = () => setStep(1);


  const handleCardNumberChange = (e) => {
    const formattedCardNumber = formatCardNumber(e.target.value);
    setCardNumber(formattedCardNumber); 
  };

  const handleExpiryChange = (e) => {
    const formattedExpiry = formatExpiry(e.target.value);
    setExpiry(formattedExpiry);
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={closeModal}
      className="fixed xl:left-[35%] x:left-[30%] l:left-[30%] md:left-[20%] sm:left-[15%] top-20 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="ModalFont bg-white w-[500px] sm:w-[300px] sm:max-h-[80vh] overflow-y-auto md:max-h-[90vh]  rounded-lg p-2 shadow-xl">
        <div className=" flex">
          <img className="mx-auto sm:h-5" src={Logo} alt="" />
        </div>
        <div className="flex items-center justify-center mt-6 mb-6 sm:mt-2 sm:mb-2">
          <div className="flex items-center space-x-8">
            <div className="flex items-center">
              <div
                className={`w-8 h-8 rounded-full flex items-center justify-center text-white ${
                  step === 1 ? "bg-blue-500" : "bg-gray-300"
                }`}
              >
                {step === 1 ? "1" : "✓"}
              </div>
              <span
                className={`ml-2 sm:text-[10px] ${step === 1 ? "font-bold" : "text-gray-500"}`}
              >
                Your Info
              </span>
            </div>
            <hr className="border-t border-gray-300 w-12" />
            <div className="flex items-center">
              <div
                className={`w-8 h-8 rounded-full flex items-center justify-center text-white ${
                  step === 2 ? "bg-blue-500" : "bg-gray-300"
                }`}
              >
                2
              </div>
              <span
                className={`ml-2 sm:text-[10px] ${step === 2 ? "font-bold" : "text-gray-500"}`}
              >
                Payment
              </span>
            </div>
          </div>
        </div>

        {/* Step 1: Your Info */}
        {step === 1 && (
          <div>
            <div className="flex sm:flex-col sm:mx-auto gap-x-2">
              <div className="sm:flex-col sm:mx-auto sm:w-[100%]">
                <label className="text-slate-500 sm:text-[12px]" htmlFor="">
                  First Name
                </label>
                <br/>
                <input
                  className="outline-none border-[1px] sm:w-full sm:text-[14px] sm:px-3 sm:py-1 px-5 py-2 rounded-md"
                  type="text"
                />
              </div>
              <div className="sm:flex-col sm:mx-auto sm:w-[100%]">
                <label className="text-slate-500 sm:text-[12px]" htmlFor="">
                  Last Name
                </label>
                <br/>
                <input
                  className="outline-none border-[1px] sm:w-full sm:text-[14px] sm:px-3 sm:py-1 px-5 py-2 rounded-md"
                  type="text"
                />
              </div>
            </div>
            <div className="flex sm:flex-col gap-x-2">
            <div className=" sm:flex-col sm:mx-auto sm:w-[100%]">
                <label className="text-slate-500 sm:text-[12px]" htmlFor="">
                  Email
                </label>
                <br/>
                <input
                  className="outline-none border-[1px] sm:w-full sm:text-[14px] sm:px-3 sm:py-1 px-5 py-2 rounded-md"
                  type="email"
                />
              </div>
              <div className="sm:flex-col sm:mx-auto sm:w-[100%]">
                <label className="text-slate-500 sm:text-[12px]" htmlFor="">
                  Room Name
                </label>
                <br/>
                <input
                  className="outline-none border-[1px] sm:w-full sm:text-[14px] sm:px-3 sm:py-1 px-5 py-2 rounded-md"
                  type="text"
                />
              </div>
            </div>
              <div className="sm:flex-col sm:mx-auto sm:w-[100%]">
                <label className="text-slate-500 sm:text-[12px]" htmlFor="">
                  Password
                </label>
                <br/>
                <input
                  className="outline-none border-[1px] sm:w-full sm:text-[14px] sm:px-3 sm:py-1 px-5 py-2 rounded-md"
                  type="password"
                />
              </div>

            <div className="flex gap-x-2 text-slate-500 mt-5 sm:text-[12px]">
              <input type="checkbox" />
              <p>I agree to the terms and conditions</p>
            </div>
            <div className="flex justify-between mt-4">
              <button
                onClick={closeModal}
                className="text-white bg-gray-500 px-4 py-2 rounded-md sm:text-[12px]"
              >
                Cancel
              </button>
              <button
                onClick={nextStep}
                className="bg-blue-500 text-white px-4 py-2 rounded-md sm:text-[12px]"
              >
                Next
              </button>
            </div>

            <div className="mt-5 sm:mt-2">
              <p className="text-slate-500 sm:text-[12px]">
                Already Have An Account?{" "}
                <a className="text-[#3498DB]" href="/">
                  SignIn
                </a>
              </p>
            </div>
          </div>
        )}

        {/* Step 2: Payment */}
        {step === 2 && (
          <div className="px-10 xl:mt-20 2xl:mt-20 lg:mt-0 text-slate-500">
            <h2 className="text-lg x:text-[17px] font-semibold xl:mb-5 2xl:mb-5 x:mb-1 lg:mb-0">Payment Amount</h2>
            <p className=" text-[23px] x:text-[20px] font-bold mb-2">
              ₹ 3,000 <br />
              <span className="flex text-[16px] gap-x-1">
                +tax
                <FaQuestionCircle className="my-auto cursor-pointer" />
              </span>
            </p>
            <p className="text-[12px] text-[#616161] lg:hidden md:hidden mt-1 xl:mb-5 2xl:mb-5 lg:mb-0">
              This is a monthly payment. We'll automatically charge you starting
              today, <span className="font-bold">10/07/2024</span>
            </p>

            <form className="">
              <div className="flex flex-col">
                <label className="text-[12px] text-[#111] font-bold" htmlFor="">
                  Card Number
                </label>
                <input
                  id="ccn"
                  type="tel"
                  inputMode="numeric"
                  pattern="[0-9\s]{13,19}"
                  autoComplete="cc-number"
                  maxLength="19"
                  placeholder="Enter Card Number - xxxx xxxx xxxx xxxx"
                  className="outline-none border-[1px] px-2 py-2 shadow-xl"
                  value={cardNumber}
                  onChange={handleCardNumberChange}
                  required
                />
              </div>

              <div className="flex flex-col xl:mt-5 2xl:mt-5 l:mt-3 x:mt-2 lg:mt-3">
                <label className="text-[12px] text-[#111] font-bold" htmlFor="">
                  MM/YY
                </label>
                <input
                  id="expiry"
                  type="tel"
                  inputMode="numeric"
                  maxLength="5"
                  placeholder="MM/YY"
                  className="outline-none border-[1px] px-2 py-2 shadow-xl"
                  value={expiry}
                  onChange={handleExpiryChange}
                  required
                />
              </div>

              <div className="flex flex-col mt-5 xl:mt-5 2xl:mt-5 l:mt-3 x:mt-2 lg:mt-3">
                <label className="text-[12px] text-[#111] font-bold" htmlFor="">
                  CVV
                </label>
                <input
                  id="cvv"
                  type="password"
                  inputMode="numeric"
                  maxLength="3"
                  placeholder="Enter CVV"
                  className="outline-none border-[1px] px-2 py-2 shadow-xl"
                  value={cvv}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    setCvv(value);
                  }}
                  pattern="\d*"
                  required
                />
              </div>
            </form>

            {/* Payment form elements go here */}
            <div className="flex justify-between mt-5">
              <button
                onClick={prevStep}
                className="text-white bg-gray-500 px-4 py-2 rounded-md"
              >
                Previous
              </button>
              <button className="bg-blue-500 text-white px-4 py-2 rounded-md">
                Pay Now
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default MultiStepModal;
