import React, { useEffect, useRef, useState } from "react";
import { Carousel, Form, Input, message, Radio } from "antd";
import { Base64 } from "js-base64";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AuthenticationService from "../Services/AuthenticationService";
import { APP_ROUTES } from "../PageRouting/pageRoutes";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { useData } from "../Services/Context";
import { encryptPayload, generateAesKey } from "../utils/encryptPassword";
import Doxzonlogo from "../assets/images/doxzonnewlogo.png";
import TestDoctor from "../assets/images/bg1.jpg"

const Login = ({ handleLoginModal, handleSignup }) => {
  const navigate = useNavigate();
  const state = useData([]);
  const { setPatientData } = useData([]);
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [disable, setDisable] = useState(false);
  const [step, setStep] = useState("initial");
  const [resendBtnClicked, setResendBtnClicked] = useState(false);
  const [isResendEnabled, setIsResendEnabled] = useState(true);
  const [timer, setTimer] = useState(120);
  const [userType, setUserType] = useState('patient');
  const [selectedApp, setSelectedApp] = useState('');
  const [patientloginType, setPatientloginType] = useState(true);
  const [doctorloginType, setDoctorloginType] = useState(true);
  const [patOtpScreen, setPatOtpScreen] = useState(false);
  const [docOtpScreen, setDocOtpScreen] = useState(false);
  const [otp, setOtp] = useState();
  const formRef = useRef();
  const verifyformRef = useRef();
  const inputRefs = useRef([]);

  useEffect(() => {
    if (resendBtnClicked === true) {
      const timerInterval = setInterval(() => {
        setTimer((prevState) => {
          if (prevState === 0) {
            clearInterval(timerInterval);
            setResendBtnClicked(false);
            return 0;
          }
          return prevState - 1;
        });
      }, 1000);
      return () => {
        clearInterval(timerInterval);
        setTimer(120);
      };
    }
  }, [resendBtnClicked]);

  const handleLogin = async (e, usertype) => {
    const { userCode, passWord } = formRef.current.getFieldValue();
    setDisable(true);
    let payload = {};
    if (!userCode || !passWord) {
      message.error('User Name/ Password is empty!');
      setDisable(false);
      return;
    }
    payload.userCode = userCode;
    payload.passWord = Base64.encode(passWord);
    const loginPayload = {
      password: payload.passWord,
    };
    console.log(userType);
    if (usertype === 'patient') {
      loginPayload.userId = `PAT_${payload.userCode}`;
    }
    else if (usertype === 'doctor') {
      setStep('appConfirmation');
      setDisable(false);
      return;
    }

    try {
      const res = await AuthenticationService.TokenAuthentication(loginPayload);
      localStorage.setItem("access_token", res.data.access_token);
      if (res.data.error === "1") {
        message.error(res.data.error_description);
        setDisable(false);
        return;
      }
      const encryptedPass = generateAesKey(passWord);
      let patientPayload = {
        currentVal: encryptedPass,
        userCode: payload.userCode,
      };
      const response = await AuthenticationService.Login(patientPayload);
      setDisable(false);
      localStorage.setItem("access_token", res.data.access_token);
      localStorage.setItem('patientData', JSON.stringify(response.data?.patient));
      localStorage.setItem('loginObj', JSON.stringify({ patient: response.data?.patient }));
      setPatientData(response?.data?.patient);
      handleLoginModal();
    } catch (e) {
      setDisable(false);
      console.log(e);
    }
  };

  const handleApplicationSelection = (e) => {
    setSelectedApp(e.target.value);
  }

  const handleDoctorRedirect = () => {
    let passwordPayload;
    let otpPayload;
    let encryptedPayload;
    if (otp) {
      otpPayload = {
        userId,
        numVal: otp,
        type: 'otp'
      }
      encryptedPayload = encryptPayload(otpPayload);
    }
    else {
      passwordPayload = {
        userId: userId,
        currVal: password,
        type: 'password'
      }
      console.log(passwordPayload);
      encryptedPayload = encryptPayload(passwordPayload);
    }

    if (selectedApp === 'doctor') {
      window.location.replace(`${process.env.REACT_APP_DOCTOR_URL}/authenticate?code=${encryptedPayload}`);
      // window.location.replace(`http://localhost:3002/authenticate?code=${encryptedPayload}`);
    }
    else if (selectedApp === 'practice_admin') {
      window.location.replace(`${process.env.REACT_APP_ADMIN_URL}/authenticate?code=${encryptedPayload}`);
    }

  }

  const handleForgotClick = () => {
    navigate(APP_ROUTES.FORGOTPASSWORD);
  };

  const validateEmail = (email) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validatePhoneNumber = (number) =>
    /^\d{10}$/.test(number);

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) && index < 5) {
      inputRefs.current[index + 1].focus();
    }
    else if (!e.target.value && index >= 1) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleUserId = (e) => {
    const value = e.target.value;
    setUserId(value);
  }

  const generateOTP = async (userId) => {

    const tokenPayload = {
      password: 'NA',
      userId: `${userType === 'doctor' ? 'U' : 'P'}${userId}`,
    };

    try {
      const tokenRes = await AuthenticationService.TokenAuthentication(tokenPayload);
      if (tokenRes.data.error === "1") {
        // message.error(tokenRes.data.error_description);
        message.error('Please enter a registered mobile number.');
        setDisable(false);
        return;
      }
      localStorage.setItem("access_token", tokenRes.data.access_token);
      if (userType === 'doctor') {
        let userPayload = {
          userId,
        };

        const response = await AuthenticationService.getUserMobileOtp(userPayload);
        if (response.data === 1) {
          setDocOtpScreen(true);
          setDisable(false);
        }
      }
      else {
        let userPayload = {
          patientId: userId,
        };

        const response = await AuthenticationService.getPatientMobileOtp(userPayload);
        if (response.data === 1) {
          setPatOtpScreen(true);
          setDisable(false);
        }
      }

    } catch (e) {
      console.log(e);
      setDisable(false);
      if (e && e?.response?.data?.status === 404) {
        message.error('Please enter a registered mobile number.');
      }
    }
  };

  const handleOtpVerification = async (e) => {
    e.preventDefault();
    setDisable(true);
    const { one, two, three, four, five, six } =
      verifyformRef.current.getFieldsValue();
    console.log(' otp ', verifyformRef.current.getFieldsValue());
    let finalNum = one + two + three + four + five + six;
    const encryptPassword = generateAesKey(finalNum);

    let verifyPayload = {
      numVal: encryptPassword,
      patientId: userId,
    };
    if (userType === 'doctor') {
      setStep('appConfirmation');
      setOtp(finalNum);
      setDisable(false);
      return;
    }
    else {
      try {
        const response = await AuthenticationService.verifyPatientOtp(verifyPayload);
        localStorage.setItem('patientData', JSON.stringify(response.data?.patient));
        localStorage.setItem('loginObj', JSON.stringify({ patient: response.data?.patient }));
        setPatientData(response?.data?.patient);
        setDisable(false);
        handleLoginModal();

      } catch (e) {
        message.error(e.response.data.message);
        setDisable(false);
        return;
      }

    }

  };

  const handleResend = async (e) => {
    e.preventDefault();
    setIsResendEnabled(false);
    setResendBtnClicked(true);
    setTimeout(() => {
      setIsResendEnabled(true);
    }, 120000);

    try {
      if (userType === 'doctor') {
        let userPayload = {
          userId,
        };
        const response = await AuthenticationService.getUserMobileOtp(userPayload);
      }
      else {
        let userPayload = {
          patientId: userId,
        };
        const response = await AuthenticationService.getPatientMobileOtp(userPayload);
      }

    } catch (e) {
      console.log(e);
    }
  };

  const handleUserType = (e) => {
    setUserType(e.target.value);
  }

  const handlePatientlogType = () => {
    setPatientloginType(!patientloginType);
  }

  const handleDoctorlogType = () => {
    setDoctorloginType(!doctorloginType);
  }

  const handleNumberValidation = () => {
    setDisable(true);
    if (validatePhoneNumber(userId)) {
      generateOTP(userId);
    }
    else {
      message.error('Enter valid mobile number');
      setDisable(false);
    }
  }

  const handleDocConfirmation = () => {
    setStep('appConfirmation');
  }


  return (
    <div className="w-full flex justify-center items-center bg-White">
      {
        step === 'appConfirmation' ?
          (
            <div className="w-full rounded-md py-2 px-1">
              <h1 className="text-lg font-semibold pb-3">Which application you want to login?</h1>
              <Radio.Group onChange={handleApplicationSelection} className="flex flex-col gap-3 py-3">
                <Radio value={'doctor'}>Doctor App</Radio>
                <Radio value={'practice_admin'}>Practice Admin App</Radio>
              </Radio.Group>
              <div className="flex justify-end items-center gap-3">
                <button
                  onClick={handleLoginModal}
                  className="md:min-w-28 w-[6rem] md:w-1/6 md:p-3 p-1 bg-White rounded-lg border text-primaryColor border-primaryColor font-semibold custom-button-shadow"
                >
                  Cancel
                </button>
                <button
                  disabled={selectedApp === ''}
                  onClick={handleDoctorRedirect}
                  className="md:min-w-28 w-[6rem] md:w-1/6 md:p-3 p-1 bg-primaryColor text-White rounded-lg font-semibold disabled:cursor-not-allowed disabled:opacity-50"
                >
                  Proceed
                </button>
              </div>
            </div>
          )

          :
          (
            <div className="w-full rounded-md p-7">
              <div className="flex justify-center items-center mb-2">
                <img src={Doxzonlogo} className="w-28 h-10" alt="appLogo" />
              </div>
              <div className="flex justify-center py-3">
                <Radio.Group value={userType} className="flex gap-4" onChange={handleUserType}>
                  <Radio value='doctor'>Doctor</Radio>
                  <Radio value='patient'>Patient</Radio>
                </Radio.Group>
              </div>

              <Form ref={formRef} className="flex flex-col p-5">

                {
                  userType === 'patient' ?
                    (
                      <div>
                        {
                          //if patientlogintype is true it will be otp based login else password based login
                          patientloginType ?
                            (
                              <div>
                                {
                                  //if patotpscreen is true we will show otp entering screen. else show the mobile number entering field
                                  patOtpScreen ?
                                    (
                                      <>
                                        <div>
                                          <label className="text-lg font-medium">OTP </label>
                                          <span style={{ color: "#FF000F" }}>*</span>
                                        </div>
                                        <Form ref={verifyformRef}>
                                          <div className="w-full flex gap-2 items-center justify-evenly">
                                            {[...Array(6)].map((_, index) => (
                                              <Form.Item
                                                key={index}
                                                name={
                                                  ["one", "two", "three", "four", "five", "six"][index]
                                                }
                                                className="w-1/5"
                                                rules={[
                                                  {
                                                    pattern: /^[0-9]{1}$/,
                                                  },
                                                ]}
                                              >
                                                <Input
                                                  maxLength={1}
                                                  ref={(el) => (inputRefs.current[index] = el)}
                                                  onChange={(e) => handleInputChange(e, index)}
                                                  onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                  className="outline-none border rounded-lg border-borderColor p-3 text-center font-medium text-xl"
                                                />
                                              </Form.Item>
                                            ))}
                                          </div>
                                        </Form>
                                        <div className="flex items-center gap-2">
                                          <button
                                            className={`w-full p-3 bg-lightBlue rounded-lg font-semibold disabled:cursor-not-allowed`}
                                            disabled={!isResendEnabled}
                                            onClick={handleResend}
                                          >
                                            Resend OTP {resendBtnClicked ? `(${timer})` : null}
                                          </button>
                                          <button
                                            disabled={disable}
                                            className="w-full p-3 bg-primaryColor text-white rounded-lg font-semibold disabled:opacity-50 disabled:cursor-not-allowed"
                                            onClick={handleOtpVerification}
                                          >
                                            Verify OTP
                                          </button>
                                        </div>
                                      </>
                                    )
                                    :
                                    (
                                      <>
                                        <div className="text-left w-[100%]">
                                          {/* <label className="pr-3">User Name:</label> */}
                                          <Form.Item name={'userCode'}>
                                            <Input
                                              type="text"
                                              disabled={step === 'email' || step === 'mobile'}
                                              placeholder="UserId Or Mobile"
                                              suffix={
                                                <AiOutlineUserSwitch className="h-4 w-4 text-gray-500" />
                                              }
                                              className="h-10 w-full px-2 border border-gray-500 appearance-none bg-none  focus:outline-none focus:ring-0 disabled:opacity-50"
                                              style={{ background: "none" }}
                                              value={userId}
                                              onChange={handleUserId}
                                            />
                                          </Form.Item>
                                        </div>
                                        <div className="text-center mt-4 w-[100%]">
                                          <button
                                            disabled={disable}
                                            onClick={handleNumberValidation}
                                            className="w-full px-5 py-2 bg-primaryColor text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                                          >
                                            GET OTP
                                          </button>
                                        </div>
                                      </>
                                    )
                                }

                              </div>
                            )
                            :
                            (
                              <>
                                <div className="text-left w-[100%]">
                                  {/* <label className="pr-3">User Name:</label> */}
                                  <Form.Item name={'userCode'}>
                                    <Input
                                      type="text"
                                      disabled={step === 'email' || step === 'mobile'}
                                      placeholder="UserId Or Mobile"
                                      suffix={
                                        <AiOutlineUserSwitch className="h-4 w-4 text-gray-500" />
                                      }
                                      className="h-10 w-full px-2 border border-gray-500 appearance-none bg-none  focus:outline-none focus:ring-0 disabled:opacity-50"
                                      style={{ background: "none" }}
                                      value={userId}
                                      onChange={handleUserId}
                                    />
                                  </Form.Item>
                                </div>
                                <div className="mt-5 text-left w-[100%]">
                                  {/* <label className="pr-4">Password:</label> */}
                                  <Form.Item name={'passWord'}>
                                    <Input.Password
                                      type="password"
                                      placeholder="Password"
                                      className="h-10 w-full px-2 border border-gray-500 appearance-none bg-none focus:outline-none focus:ring-0 "
                                      style={{ background: "none" }}
                                      value={password}
                                      onChange={(e) => setPassword(e.target.value)}
                                    />
                                  </Form.Item>
                                  <div
                                    onClick={handleForgotClick}
                                    className="text-end text-sm font-semibold text-primaryColor hover:underline cursor-pointer mt-2"
                                  >
                                    Forgot Password?
                                  </div>
                                </div>
                                <div className="text-center mt-4 w-[100%]">
                                  <button
                                    disabled={disable}
                                    onClick={(e) => handleLogin(e, userType)}
                                    className="w-full px-5 py-2 bg-primaryColor text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                                  >
                                    Login
                                  </button>
                                </div>
                              </>

                            )
                        }

                        <div className="w-full flex items-center mt-6">
                          <hr className="w-[28%]" />
                          <p onClick={handlePatientlogType} className="text-sm font-semibold text-primaryColor hover:underline cursor-pointer px-2">
                            {patientloginType ? 'Login with password' : 'Login with mobile'}
                          </p>
                          <hr className="w-[28%]" />
                        </div>
                        <div className="w-full flex items-center mt-6">
                          <p className="text-sm font-semibold">Don't have an account? <span className="hover:underline text-primaryColor cursor-pointer px-2" onClick={handleSignup}>Sign Up</span></p>
                        </div>

                      </div>
                    )
                    : userType === 'doctor' ?
                      (
                        <div>
                          {
                            //if doctorlogintype is true it will be otp based login else password based login
                            doctorloginType ?
                              (
                                <div>
                                  {
                                    //if docotpscreen is true we will show otp entering screen. else show the mobile number entering field
                                    docOtpScreen ?
                                      (
                                        <>
                                          <div>
                                            <label className="text-lg font-medium">OTP </label>
                                            <span style={{ color: "#FF000F" }}>*</span>
                                          </div>
                                          <Form ref={verifyformRef}>
                                            <div className="w-full flex gap-2 items-center justify-evenly">
                                              {[...Array(6)].map((_, index) => (
                                                <Form.Item
                                                  key={index}
                                                  name={
                                                    ["one", "two", "three", "four", "five", "six"][index]
                                                  }
                                                  className="w-1/5"
                                                  rules={[
                                                    {
                                                      pattern: /^[0-9]{1}$/,
                                                    },
                                                  ]}
                                                >
                                                  <Input
                                                    maxLength={1}
                                                    ref={(el) => (inputRefs.current[index] = el)}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    onKeyPress={(event) => {
                                                      if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                      }
                                                    }}
                                                    className="outline-none border rounded-lg border-borderColor p-3 text-center font-medium text-xl"
                                                  />
                                                </Form.Item>
                                              ))}
                                            </div>
                                          </Form>
                                          <div className="flex items-center gap-2">
                                            <button
                                              className={`w-full p-3 bg-lightBlue rounded-lg font-semibold disabled:cursor-not-allowed`}
                                              disabled={!isResendEnabled}
                                              onClick={handleResend}
                                            >
                                              Resend OTP {resendBtnClicked ? `(${timer})` : null}
                                            </button>
                                            <button
                                              disabled={disable}
                                              className="w-full p-3 bg-primaryColor text-white rounded-lg font-semibold disabled:opacity-50 disabled:cursor-not-allowed"
                                              onClick={handleOtpVerification}
                                            >
                                              Verify OTP
                                            </button>
                                          </div>
                                        </>
                                      )
                                      :
                                      (
                                        <>
                                          <div className="text-left w-[100%]">
                                            {/* <label className="pr-3">User Name:</label> */}
                                            <Form.Item name={'userCode'}>
                                              <Input
                                                type="text"
                                                disabled={step === 'email' || step === 'mobile'}
                                                placeholder="UserId Or Mobile"
                                                suffix={
                                                  <AiOutlineUserSwitch className="h-4 w-4 text-gray-500" />
                                                }
                                                className="h-10 w-full px-2 border border-gray-500 appearance-none bg-none  focus:outline-none focus:ring-0 disabled:opacity-50"
                                                style={{ background: "none" }}
                                                value={userId}
                                                onChange={handleUserId}
                                              />
                                            </Form.Item>
                                          </div>
                                          <div className="text-center mt-4 w-[100%]">
                                            <button
                                              disabled={disable}
                                              onClick={handleNumberValidation}
                                              className="w-full px-5 py-2 bg-primaryColor text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                                            >
                                              GET OTP
                                            </button>
                                          </div>
                                        </>
                                      )
                                  }

                                </div>
                              )
                              :
                              (
                                <>
                                  <div className="text-left w-[100%]">
                                    {/* <label className="pr-3">User Name:</label> */}
                                    <Form.Item name={'userCode'}>
                                      <Input
                                        type="text"
                                        disabled={step === 'email' || step === 'mobile'}
                                        placeholder="UserId Or Mobile"
                                        suffix={
                                          <AiOutlineUserSwitch className="h-4 w-4 text-gray-500" />
                                        }
                                        className="h-10 w-full px-2 border border-gray-500 appearance-none bg-none  focus:outline-none focus:ring-0 disabled:opacity-50"
                                        style={{ background: "none" }}
                                        value={userId}
                                        onChange={handleUserId}
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="mt-5 text-left w-[100%]">
                                    {/* <label className="pr-4">Password:</label> */}
                                    <Form.Item name={'passWord'}>
                                      <Input.Password
                                        type="password"
                                        placeholder="Password"
                                        className="h-10 w-full px-2 border border-gray-500 appearance-none bg-none focus:outline-none focus:ring-0 "
                                        style={{ background: "none" }}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                      />
                                    </Form.Item>
                                    <div
                                      onClick={handleForgotClick}
                                      className="text-end text-sm font-semibold text-primaryColor hover:underline cursor-pointer mt-2"
                                    >
                                      Forgot Password?
                                    </div>
                                  </div>
                                  <div className="text-center mt-4 w-[100%]">
                                    <button
                                      onClick={handleDocConfirmation}
                                      className="w-full px-5 py-2 bg-primaryColor text-white rounded-md"
                                    >
                                      Login
                                    </button>
                                  </div>
                                </>

                              )
                          }

                          <div className="w-full flex items-center mt-6">
                            <hr className="w-[28%]" />
                            <p onClick={handleDoctorlogType} className="text-sm font-semibold text-primaryColor hover:underline cursor-pointer px-2">
                              {doctorloginType ? 'Login with password' : 'Login with mobile'}
                            </p>
                            <hr className="w-[28%]" />
                          </div>
                          <div className="w-full flex items-center mt-6">
                            <p className="text-sm font-semibold">Don't have an account? <span className="hover:underline text-primaryColor cursor-pointer px-2" onClick={handleSignup}>Sign Up</span></p>
                          </div>

                        </div>
                      ) : ''
                }
              </Form>
            </div >
          )
      }
    </div >
  );
};

export default Login;
