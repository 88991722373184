import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import plusIcon from "../../../assets/images/Svg/plusIcon.svg";
import AddSurgicalHistory from "./AddSurgicalHistory";
import MedicalService from "../../../Services/MedicalService";
import dayjs from "dayjs";

function SurgicalHistory() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [surgicalHistoryData, setSuricalHistoryData] = useState([]);

  const handleSurgicalClick = () => {
    setIsModalVisible(true);
  };
  const handleCloseModal = () => {
    setIsModalVisible(false);
    SurgicalHistory();
  };

  const SurgicalHistory = () => {
    let payload = {
      patientId: patientCode,
      status: 1,
    };
    MedicalService.PatientSurgicalHistory(payload)
    .then((result) => {
      setSuricalHistoryData(result.data);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  useEffect(() => {
    SurgicalHistory();
  }, []);
  return (
    <div>
      <div className="card h-[300px] m-5 border rounded-lg">
        <div className="header h-[15%] bg-secondaryColor flex items-center justify-between  rounded-lg p-2">
          <h1 className="font-bold text-normal  text-white ">
            Surgical History
          </h1>
          <img
            src={plusIcon}
            onClick={() => handleSurgicalClick()}
            className="h-10 w-10 cursor-pointer"
            alt=""
          />
        </div>
        <div
          className="body h-[70%] overflow-y-auto bg-brand-lightBg p-2"
          id="unquie-scroll"
        >
          {surgicalHistoryData.length > 0
            ? surgicalHistoryData.map((user, i) => (
                <div>
                  <div key={i} className="items-center text-sm mt-3">
                    <div className="font-medium text-black">
                      {" "}
                      Surgical History :{" "}
                      <span className="font-medium text-gray-500">
                        {user.surgicalInfo}
                      </span>
                    </div>
                    <div className="ml-auto text-gray-500 mt-2 font-medium">
                      Onset date :{" "}
                      {dayjs(user.givenDate).format("YYYY-MM-DD")}
                    </div>
                  </div>
                  <hr className="my-4 border-gray-300" />
                </div>
              ))
            : null}
        </div>
        <div className="footer h-[10%]  bg-secondaryColor rounded-md"></div>
      </div>

      {isModalVisible && (
        <Modal
          title={<span className="text-base font-bold font-sans">Add Surgical History</span>}
          visible={isModalVisible}
          onOk={() => handleCloseModal()}
          centered={true}
          footer={null}
          onCancel={() => handleCloseModal()}
          okText="Submit"
          cancelText="Cancel"
          width={500}
          maskClosable={false}
        >
          <AddSurgicalHistory onClose={() => handleCloseModal()} />
        </Modal>
      )}
    </div>
  );
}

export default SurgicalHistory;
