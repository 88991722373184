import moment from "moment";
import dayjs from "dayjs";
dayjs.extend(require("dayjs/plugin/utc"));

export const convertUTCDateLocal = (utcDate, format) => {
  if (!format) {
    format = "DD/MM/YYYY, HH:mm:ss";
  }

  // Convert UTC date to local date and time
  const localDate = moment.utc(utcDate).local();

  return localDate.format(format);
};

export const convertUTCDate = (date, format) => {
  if (!format) {
    var d = new Date();
    date = new Date(
      dayjs(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
      " " +
      d.getHours() +
      ":" +
      d.getMinutes()
    );
  }
  format = format || "MM/DD/YYYY";
  return dayjs(date).utc().format(format);
}

export const ConvertTime = (time) => {
  console.log(time);
  const timePart = time.format("HH:mm");
  return `${timePart}`;
}

export const convertDate = (date) => {
  const datePart = dayjs(date, "MM-DD-YYYY").format("MM/DD/YYYY");
  const time = `${datePart}`;
  return time;
};

export const convertTo24HourFormat = (time12h) => {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');
  hours = parseInt(hours, 10);

  if (modifier.toLowerCase() === 'pm' && hours !== 12) {
    hours += 12;
  } else if (modifier.toLowerCase() === 'am' && hours === 12) {
    hours = 0;
  }

  return `${hours.toString().padStart(2, '0')}:${minutes}`;
}

export const addMinutesToTime = (startTime, minutesToAdd) => {
  const [hours, minutes] = startTime.split(':').map(Number);

  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  date.setMinutes(date.getMinutes() + minutesToAdd);

  const resultHours = date.getHours().toString().padStart(2, '0');
  const resultMinutes = date.getMinutes().toString().padStart(2, '0');

  return `${resultHours}:${resultMinutes}`;
}

