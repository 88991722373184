import React, { useState } from "react";
import {  Modal } from "antd";
import { Carousel } from 'antd';
import "./style.css";
import VitalTrends from "./VitalTrends";
import heartRate from "../../assets/images/Vitals/HeartRate.png";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import VitalDashboard from "./VitalDashboard";


const SliderVitals = ({ vitalsData, handleGraph, setViewGraph}) => {
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [viewTrends, setViewTrends] = useState(false);


  const handleVitalClick = (name, vitalCode, vital) => {
    const type = name;
    const code = vitalCode;
    const data = vital;
    handleGraph(type, code, data);
    setViewGraph(true);
  };  

  const bloodPressureSys = vitalsData.find(
    (vital) => vital.vitalCode === "BPS"
  );
  const bloodPressureDia = vitalsData.find(
    (vital) => vital.vitalCode === "BPD"
  );

  const sliderImageUrl = [
    ...vitalsData
      .filter((vital) => vital.vitalCode !== "BPS" && vital.vitalCode !== "BPD" &&  vital.vitalCode !== "HI") // Exclude BPS and BPD from other vitals
      .map((vital) => ({
        title: vital.vitalName,
        value: vital.value,
        units: vital.units,
        code: vital.vitalCode,
        img: vital.vitalCode === "HR" ? heartRate : null, // You can add similar conditions for other vital images
      })),
    bloodPressureSys &&
      bloodPressureDia && {
        title: "Blood Pressure (SYS/DIA)",
        value: `${bloodPressureSys.value}/${bloodPressureDia.value}`,
        units: bloodPressureSys.units,
        code: `${"BP"}`,
        img: null, // You can add a specific image for Blood Pressure if you have one
      },
  ].filter(Boolean);

  return (
    <>
      <div className="carousel-container mt-5">
      <Carousel
        autoplay={false}
        dots={false}
        infinite={true}
        arrows={true}
        prevArrow={
          <button className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-200 text-gray-700 rounded-full p-2 shadow-lg hover:bg-gray-300">
            {/* <LeftOutlined className="text-xl" /> */}
          </button>
        }
        nextArrow={
          <button className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-200 text-gray-700 rounded-full p-2 shadow-lg hover:bg-gray-300">
            {/* <RightOutlined className="text-xl" /> */}
          </button>
        }
        slidesToShow={3}
        swipeToSlide
        className="custom-carousel"

      >
        {sliderImageUrl.map((vital, index) => (
          <div
            key={index}
            onClick={() => handleVitalClick(vital?.title, vital?.code, vital)}
            className="relative border border-gray-300 gap-5  rounded-lg p-5 w-40 h-50 text-center shadow-lg"
            
          >
            <p className="text-base text-gray-700 mb-2 font-medium">{vital.title}</p>
            {/* <img src={heartRate} alt="Vital Icon" className="mx-auto w-30 h-30" /> */}
            <VitalDashboard vitalCode={vital?.code}/>
            <p className="text-lg text-gray-500 mt-4">
              {vital.value} {vital.units}
            </p>
          </div>
        ))}
      </Carousel>
      </div>

      <Modal
        title="Health Trends"
        open={viewTrends}
        // onOk={handleVitalClick}
        // onCancel={handleCancel}
        width={900}
        okText="Submit"
        cancelText="Cancel"
        maskClosable={false}
        centered={true}
        footer={null}
      >
        <VitalTrends />
      </Modal>
    </>
  );
};
export default SliderVitals;
