export const APP_ROUTES = {
    LOGIN :'/',
    HOME:'/dashboard',
    CHECKOUT:'/checkout',
    CONSULTDOCTOR:'/consultdoctor',
    DOCTORDETAILS:'/doctordetail',
    PRIVACYPOLICY:'/privacypolicy',
    TERMSOFUSE:'/termsofuse',
    REFUNDPOLICY:'/refundpolicy',
    // newly added routes from patient app
    MYAPPOINMENTS: "/myappointments",
    MYPROFILE: "/myprofile",
    MEDICALRECORDS: "/medicalrecords",
    MEDICALHISTORY: "/medicalhistory",
    MYPRESCRIPTION: "/myprescription",
    MYVITALS:"/myvitals",
    MYREPORTS:"/myreports",
    MYLABREPORTS:"/mylabreports",
    PAYMENTS: "/payments",
    NOTIFICATIONS: "/notifications",
    SETTINGS: "/settings",
    VIDEOCALL: "/videocall",
    SHARECALL: "/vc/:id/:type",
    SHAREPRSCRIPTION: "/sp/v1/:id",
    LABPRSCRIPTION: "/sp/v1/l/:id",
    PHARMACYPRSCRIPTION: "/sp/v1/p/:id",
    LOGOUT: "/logout",
    PrescriptionTemplate: '/PrescriptionTemplate',
    FORGOTPASSWORD: "/forgotpassword",
    SHARECALLPATIENT: "/sharecallpatient",
    PHONEPEPAYMENT: "/phonepepayment/:transactionId",
    PHONEPEPAYMENT1: "/phonePeResponse1/paymentRedirectDetails/:status"
}