export const SAVE_VIDEO_CALL_DETAILS = "SAVE_VIDEO_CALL_DETAILS"
export const DELETE_VIDEO_URL = "DELETE_VIDEO_URL"
const initialState = {
    callurl: '',
    callType: ''
}

const VideoCallReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case SAVE_VIDEO_CALL_DETAILS:
            return {
                ...state,
                callurl: payload.callurl,
                callType: payload.callType,
                apptDetails: payload.apptDetails
            };
        case DELETE_VIDEO_URL:
            return {
                ...state,
                callurl: null,
                callType: false
            }
        default:
            return state;
    }
}


export default VideoCallReducer