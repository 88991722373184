import http from "./http_common";

class PatientService {
  queryString(payload) {
    return Object.keys(payload)
      .map((key) => key + "=" + payload[key])
      .join("&");
  }

  getPatient(payload) {
    var queryString = this.queryString(payload);
    return http.get(`patient/list/filter?${queryString}`);
  }
  getClinicData(payload) {
    return http.get(`/Clinic_Master/list/filter?clinic_Id=${payload}`);
  }
  patientUpdate(payload) {
    return http.put(`patient/${payload.id}`, payload);
  }
  AddFamilyMember(payload) {
    return http.post(`patient/`, payload);
  }
  getUserName(data){
    return http.get(`/patientAppointment/get-user-name?appointmentId=${data}`);
  }
  updateMedicalRecords(data){
    return http.post(`patientHistoryAccess/save-from-invite-link`, data);
  }
}

export default new PatientService();
