import { Form, Input, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import AuthenticationService from "../Services/AuthenticationService";
import { generateAesKey } from "../utils/encryptPassword";
import { APP_ROUTES } from "../PageRouting/pageRoutes";
import { useLocation, useNavigate } from "react-router";

function ForgotPassword() {
  const navigate = useNavigate();
  const [isOtpButtonClicked, setIsOtpButtonClicked] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [userName, setUserName] = useState("");
  const [isResendEnabled, setIsResendEnabled] = useState(true);
  const [resendBtnClicked, setResendBtnClicked] = useState(false);
  const [timer, setTimer] = useState(120);
  const state = useLocation();
  const userFormRef = useRef();
  const verifyformRef = useRef();
  const changepassRef = useRef();
  const inputRefs = useRef([]);

  useEffect(() => {
    console.log(state);
    if (state?.state?.userName) {
      setUserName(state?.state?.userName);
    }
  }, [state]);

  useEffect(() => {
    if (resendBtnClicked === true) {
      const timerInterval = setInterval(() => {
        setTimer((prevState) => {
          if (prevState === 0) {
            clearInterval(timerInterval);
            setResendBtnClicked(false);
            return 0;
          }
          return prevState - 1;
        });
      }, 1000);
      return () => {
        clearInterval(timerInterval);
        setTimer(120);
      };
    }
  }, [resendBtnClicked]);

  // const handleEnterClick = () => {
  //   history('/resetPassword');
  // }

  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Password is required"));
    }
    if (value.length < 8 || value.length > 16) {
      return Promise.reject(
        new Error("Password must be between 8 and 16 characters")
      );
    }
    if (!/[0-9]/.test(value)) {
      return Promise.reject(
        new Error("Password must include at least one digit (0-9)")
      );
    }
    if (!/[a-z]/.test(value)) {
      return Promise.reject(
        new Error("Password must include at least one lowercase letter (a-z)")
      );
    }
    if (!/[A-Z]/.test(value)) {
      return Promise.reject(
        new Error("Password must include at least one uppercase letter (A-Z)")
      );
    }
    if (!/[!@#$%^&+=]/.test(value)) {
      return Promise.reject(
        new Error(
          "Password must include at least one special character (!@#$%^&+=)"
        )
      );
    }
    return Promise.resolve();
  };

  const handleResend = async (e) => {
    e.preventDefault();
    setIsResendEnabled(false);
    setResendBtnClicked(true);
    setTimeout(() => {
      setIsResendEnabled(true);
    }, 120000);
    let userPayload = {
      patientId: userName,
    };
    try {
      const response = await AuthenticationService.forgetPassword(userPayload);
      if (response.data === 1) {
        setIsOtpButtonClicked(true);
        setIsOtpVerified(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleVal = async (e) => {
    e.preventDefault();
    const currDate = new Date().toISOString();
    const date = currDate.split("T")[0];
    const time = currDate.split("T")[1].slice(0, 8);
    const constructedTime = date + " " + time;
    const { newval, confirmval } = changepassRef.current.getFieldsValue();
    if (newval && confirmval && newval !== confirmval) {
      message.error("Password Mismatch!!!");
      return;
    } else {
      const encryptedVal = generateAesKey(newval);
      let FinalPayload = {
        modifiedBy: userName,
        modifiedDate: constructedTime,
        newVal: encryptedVal,
        patientId: userName,
      };
      const response = await AuthenticationService.updatePassword(FinalPayload);
      if (response.data === 1) {
        message.success("Password Changed Successfully");
        changepassRef.current.resetFields();
        setTimeout(() => {
          navigate(APP_ROUTES.LOGIN);
        }, 1500);
      }
    }
  };

  const generateOTP = async (e) => {
    e.preventDefault();
    const { userCode } = userFormRef.current.getFieldsValue();
    if (userCode && userCode !== userName) {
      setUserName(userCode);
    }
    let userPayload = {
      patientId: userCode ? userCode : userName,
    };
    try {
      const response = await AuthenticationService.forgetPassword(userPayload);
      if (response.data === 1) {
        setIsOtpButtonClicked(true);
        setIsOtpVerified(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleBackArrow = () => {
    setIsOtpButtonClicked(false);
    setIsOtpVerified(false);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    if (!/^[0-9]{0,1}$/.test(value)) {
      return;
    }
  };

  const handleUserName = (e) => {
    setUserName(e.target.value);
  };

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleOtpVerification = async (e) => {
    e.preventDefault();
    const { one, two, three, four, five, six } =
      verifyformRef.current.getFieldsValue();
    let finalNum = Number(one + two + three + four + five + six);
    let verifyPayload = {
      otpNumber: finalNum,
      patientId: userName,
    };
    try {
      const response = await AuthenticationService.verifyPassword(
        verifyPayload
      );
      if (response.data === 1) {
        setIsOtpVerified(true);
      }
    } catch (e) {
      message.error(e.response.data.message);
      return;
    }
  };

  return (
    <>
      <div
        className={`flex-col items-center justify-center h-screen bg-lightBlue p-8 ${
          isOtpButtonClicked ? "hidden" : "flex"
        }`}
      >
        <div className="w-1/3 bg-white rounded-lg px-7 py-4">
          <div className="my-8">
            <h2 className="text-3xl font-semibold">Reset your password</h2>
            <span className="text-brand-textGray">
              Enter your user name to reset password
            </span>
          </div>
          <div>
            <Form
              ref={userFormRef}
              initialValues={{ userCode: state?.state?.userName }}
            >
              <div className="my-8">
                <div className="my-2">
                  <label className="text-lg font-medium">User Name </label>
                  <span style={{ color: "#FF000F" }}>*</span>
                </div>
                <Form.Item name="userCode">
                  <Input
                    placeholder="User Name"
                    className="w-full outline-none border rounded-lg border-borderColor p-3"
                    onChange={handleUserName}
                  />
                </Form.Item>
              </div>
            </Form>
          </div>
          <button
            className="w-full p-3 bg-primaryColor text-white rounded-lg font-semibold"
            onClick={generateOTP}
          >
            Generate OTP
          </button>
        </div>
      </div>
      <div
        className={`flex-col items-center justify-center h-screen bg-lightBlue p-8 ${
          !isOtpButtonClicked || isOtpVerified ? "hidden" : "flex"
        }`}
      >
        <div className="w-1/3 bg-white rounded-lg px-7 py-4">
          <IoArrowBack
            className="text-brand-textGray text-2xl cursor-pointer"
            onClick={handleBackArrow}
          />
          <div className="my-8">
            <h2 className="text-3xl font-semibold">Enter OTP</h2>
            <span className="text-brand-textGray">
              Enter the otp received on your mobile.
            </span>
          </div>
          <div>
            <div className="my-8">
              <div className="my-2">
                <label className="text-lg font-medium">OTP </label>
                <span style={{ color: "#FF000F" }}>*</span>
              </div>
              <Form ref={verifyformRef}>
                <div className="w-full flex gap-2 items-center justify-evenly">
                  {[...Array(6)].map((_, index) => (
                    <Form.Item
                      key={index}
                      name={
                        ["one", "two", "three", "four", "five", "six"][index]
                      }
                      className="w-1/5"
                      rules={[
                        {
                          pattern: /^[0-9]{1}$/,
                        },
                      ]}
                    >
                      <Input
                        maxLength={1}
                        ref={(el) => (inputRefs.current[index] = el)}
                        onChange={(e) => handleInputChange(e, index)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        className="outline-none border rounded-lg border-borderColor p-3 text-center font-medium text-xl"
                      />
                    </Form.Item>
                  ))}
                </div>
              </Form>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <button
              className={`w-full p-3 bg-brand-lightBlue rounded-lg font-semibold disabled:cursor-not-allowed`}
              disabled={!isResendEnabled}
              onClick={handleResend}
            >
              Resend OTP {resendBtnClicked ? `(${timer})` : null}
            </button>
            <button
              className="w-full p-3 bg-primaryColor text-white rounded-lg font-semibold"
              onClick={handleOtpVerification}
            >
              Verify OTP
            </button>
          </div>
        </div>
      </div>
      <div
        className={`flex-col items-center justify-center h-screen bg-lightBlue p-8 ${
          !isOtpVerified ? "hidden" : "flex"
        }`}
      >
        <div className="w-1/3 bg-white rounded-lg px-7 py-4">
          <div className="my-8">
            <h2 className="text-3xl font-semibold">Password</h2>
            <span className="text-brand-textGray text-sm">
              Enter new password.
            </span>
          </div>
          <div>
            <div className="my-8">
              <Form ref={changepassRef}>
                <div className="w-full">
                  <label className="my-2">
                    <label className="text-lg font-medium">New Password </label>
                    <span style={{ color: "#FF000F" }}>*</span>{" "}
                  </label>
                  <Form.Item
                    name="newval"
                    rules={[
                      {
                        validator: validatePassword,
                      },
                    ]}
                  >
                    <Input.Password className="outline-none border rounded-lg border-borderColor p-3 text-center font-medium text-xl" />
                  </Form.Item>
                  <label className="my-2">
                    <label className="text-lg font-medium">
                      Confirm Password{" "}
                    </label>
                    <span style={{ color: "#FF000F" }}>*</span>{" "}
                  </label>
                  <Form.Item name="confirmval">
                    <Input.Password className="outline-none border rounded-lg border-borderColor p-3 text-center font-medium text-xl" />
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
          <button
            className="w-full p-3 bg-primaryColor text-white rounded-lg font-semibold"
            onClick={handleVal}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
