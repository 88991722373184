export const model = {
    intents: {
      greeting: ["hello", "hi", "hai", "hey", "good morning", "good afternoon", "good evening"],
      goodbye: ["goodbye", "bye", "see you", "thank you", "thanks", "good night"],
      affirmative: ["yes", "yeah", "yep", "sure", "correct", "ok", "okay"],
      negative: ["no", "nope", "nah", "not really", "not at all", "never", "I don't think so", "don't think so"],
  
      how_are_you: [
        "How are you?", "How r you", "how r u", "how are u",
        "How do you feel?", "you feel",
        "How’s it going?",
        "What’s up?",
        "Are you okay?",
        "Are you fine?",
        "How are you doing?"
      ],
  
      doxbot_name: [
        "What is your name?", "What's your name?", "what s ur name", "your name?", "ur name",
        "What should I call you?",
        "Can you tell me your name?",
        "Who are you?",
        "What is your name, DoxBot?"
      ],
      doxbot_work: [
        "What do you do?", "what do you can?", "what do u do?", "what do u can?", "what can u do?", "what can you do?",
        "What is your work?", "What's your work?", "your work", "ur work",
        "What can you help me with?", "can you help me", "can you help", "can u help me", "can u help",
        "What services do you provide?", "What do you provide", "What do u provide",
        "What is your role?", "What's your role?", "your role", "ur role"
      ],
  
      about_doxbot: [
        "What is DoxBot?",
        "Tell me about DoxBot.",
        "What does DoxBot do?",
        "Can you explain DoxBot?",
        "What can DoxBot help me with?", "about DoxBot", "explain DoxBot"
      ],
      how_doxbot_works: [
        "How does DoxBot work?", "DoxBot work", "DoxBot works", "your work", "your works", "ur work", "ur works",
        "How do I use DoxBot?",
        "Can you explain how DoxBot works?",
        "How can DoxBot assist me?"
      ],
      doxbot_availability: [
        "Is DoxBot available 24/7?", "DoxBot available", "DoxBot availablity", "your availability", "your availabile",
        "Can I use DoxBot anytime?", "DoxBot anytime", "your available time", "doxbot available time",
        "When is DoxBot available?", "DoxBot available",
        "Does DoxBot work around the clock?"
      ],
      doxbot_services: [
        "What services does DoxBot provide?",
        "What can I do with DoxBot?",
        "Tell me about the services DoxBot offers.", "DoxBot offers", "DoxBot offer", "your offers", "your offer",
        "What can DoxBot help with?", "doxbot services", "doxbot service"
      ],
      doxbot_feedback: [
        "How can I provide feedback about DoxBot?",
        "Where can I give feedback on DoxBot?",
        "I want to leave feedback for DoxBot.",
        "How can I share my experience with DoxBot?", "doxbot feedback"
      ],
      doxbot_technical_support: [
        "I need help with DoxBot.",
        "Is there technical support for DoxBot?",
        "How can I get technical support for DoxBot?",
        "I am facing issues with DoxBot, how can I get help?", "doxbot technical support", "doxbot support"
      ],
      doxbot_updates: [
        "Is DoxBot up to date?",
        "Are there any updates to DoxBot?",
        "When will DoxBot get a new update?",
        "What’s new with DoxBot?", "doxbot updates", "doxbot update",  "update doxbot", "updates to DoxBot"
      ],
      doxbot_personality: [
        "How is DoxBot different from other chatbots?",
        "What is DoxBot’s personality like?",
        "Tell me more about DoxBot’s character.",
        "How does DoxBot respond to queries?", "doxbot personality", "personality of doxbot"
      ],
      doxbot_integration: [
        "Can DoxBot integrate with other systems?",
        "Does DoxBot work with other platforms?",
        "Is DoxBot integrated with any healthcare systems?",
        "Can I link DoxBot with my health account?", "doxbot integration", "integration of doxbot"
      ],
  
      what_is_healthcare: [
        "What is healthcare?", "Healthcare", "Health care",
        "Can you explain healthcare?",
        "Tell me about healthcare",
        "What does healthcare mean?"
      ],
  
      what_is_doxzon: [
        "What is Doxzon?", "Doxzon",
        "Tell me about Doxzon",
        "What does Doxzon do?",
        "Can you explain Doxzon?"
      ],
  
      doxzon_address: [
        "What is Doxzon's address?", "address of doxzon", "doxzon address" ,
        "Where is Doxzon located?", "doxzon location", "location of doxzon",
        "Can you tell me the address of Doxzon?", 
        "Where can I find Doxzon?", "find Doxzon",
        "Where is Doxzon based?", "Doxzon based"
      ],
  
  
      appointmentBooking: [
        "appointment book", "apointment book", "book appointment","book apointment", "book an appointment", "book an apointment", "book a apointment",
        "virtual appointment", "book the appointment", "book the apointment", "book my appointment", "book my apointment",
        "appointment virtual",
        "Can I book an appointment online?",
        "Is online booking available?",
        "How can I book a doctor?",
        "How can I book an appointment?",
        "Can I call to book an appointment?",
        "Can I book an appointment through your website?",
        "Do I need to create an account to book an appointment?",
        "Can I book an appointment for someone else?"
      ],
  
      ePrescription: [
        "how do I download my e-prescription",
        "how can I download my e-prescription",
        "where can I download my e-prescription",
        "is there an option to download my e-prescription",
        "can I download my e-prescription from my account",
        "what steps do I need to follow to download my e-prescription",
        "where is my e-prescription located for download",
        "can I save my e-prescription as a pdf",
        "how long will my e-prescription be available for download",
        "is there a download link for my e-prescription on the website",
        "download e-prescription",
        "how do I get my e-prescription",
        "download prescription",
        "access e-prescription",
        "get my prescription",
        "prescription","eprescription", "e-prescription"
      ],
  
  
      available_doctors: [
        "Which doctors are available for appointments?",
        "How can I find a doctor who specializes in my condition",
         "Who can I book an appointment with?", 
         "List of available doctors",
         "doctors", "doctor", "specialist", "physician"
        ],
  
  
        prescription_validity: [
          "How do I know if my prescription is valid?", "prescription is valid",
          "What makes a prescription valid?",
          "Is my prescription valid?",
          "Valid prescription requirements",
          "Valid prescription", "prescription valid", "Valid", "Validity"
        ],
  
        appointment_types: [
          "What types of appointments can I book?",
          "Types of appointments available",
          "Can I book online or in-person appointments?",
          "Appointment options",
          "appointment types", "types of appointments", "type of appointments", "types of appointment", "type of appointment"
        ],
  
        walk_in_appointments: [
          "Do you offer walk-in appointments?", "walk-in appointments", "walk in appointments", "walk-in appointment", "walk in appointment",
          "Are walk-in appointments available?"
        ],
  
        upload_medical_history: [
          "How can I upload my medical history?", "upload medical history", "medical history upload",
          "How do I upload my health records?", "upload my health records", "upload health records", "health records upload", "health record upload",
          "Upload my medical history",
          "Medical history upload process",
          "How to upload medical history",
          "Medical history", "Medical Records",
        ],

        upload_allergies: [
          "How can I upload my allergies?", "upload my allergy", "upload allergies", "upload allergy", "allergies upload", "allergy upload ",
          "How do I add my allergy details?",
          "Upload allergy information",
          "Add allergy details to my medical history",
          "Allergy upload process",
          "How to upload allergies"
        ],

        upload_past_medical_history: [
          "How can I upload my past medical history?", "upload my past medical history", "upload past medical history", "past medical history upload",
          "How do I upload my previous illnesses?", "upload my previous illnesses", "upload previous illnesses", "my previous illnesses upload",
          "I want to upload my past treatments.", "upload my past treatments", "upload past treatments", "past treatments upload",
          "Where can I upload my past medical history?",
          "How to add my medical history?",
          "Upload previous hospitalizations or surgeries."
        ],

        upload_surgical_history: [
          "How can I upload my surgical history?", "upload my surgical history", "upload surgical history", "surgical history upload",
          "How do I upload my past surgeries?", "upload my past surgeries", "upload past surgeries", "past surgeries upload",
          "I want to upload my surgical history.", 
          "Upload my surgery details.", 
          "Where can I upload my surgical history?", 
          "How to upload surgical information?",  "upload surgical information?", "upload my surgical information?",
          "Upload previous surgeries", "Upload previous surgeries", "Upload my previous surgeries", "previous surgeries upload ", "surgeries upload", "surgery upload", "upload surgery",
          "How to add my surgical history?", "add my surgical history", "add surgical history", "surgical history add ", 
          "How to upload surgery details?", "upload surgery details", "surgery details upload", "upload surgery detail", "surgery detail upload"
        ],

        upload_family_history: [
          "How can I upload my family history?", "upload my family history", "upload family history", "family history upload",
          "How do I upload my family medical history?", 
          "I want to upload my family history.", 
          "Upload my family medical details.", "Upload family medical details", "family medical details upload",
          "Where can I upload my family history?", 
          "How to upload family medical information?", 
          "Upload family health history", 
          "How to add family history?", "add family history", "family history add",
          "How to upload family medical history details?"
        ],

        upload_social_history: [
          "How can I upload my social history?", "upload my social history", "upload social history", "social history upload",
          "How do I upload my social history?", 
          "I want to upload my social history.", 
          "Upload my social details", "Upload social details", "social details upload", "Upload my social detail", "Upload social detail", "social detail upload", 
          "Where can I upload my social history?", 
          "How to upload social information?", "upload my social information", "upload social information", "social information upload",
          "Upload my social history", 
          "How to add social history?", "add social history", "social history add",
          "How to upload social history details?"
        ],  
        
        upload_medications: [
          "How can I upload my medications?", "upload my medications", "upload medications", "medications upload", "upload my medication", "upload medication", "medication upload",
          "How do I upload my medication details?", "upload my medication details", "upload medication details", "medication details upload",
          "I want to upload my medications.",
          "Upload my medication information.", "upload medication information", "medication information upload",
          "Where can I upload my medications?", 
          "How to upload medications?", 
          "Upload medication details", 
          "How to add medication details?", "add my medications", "add medications", "medications add", "add my medication", "add medication", "medication add",
          "How to upload medications to my medical history?"
        ],
        
        upload_chronic_conditions: [
          "How can I upload my chronic conditions?", "upload my chronic conditions", "upload chronic conditions", "chronic conditions upload", "upload my chronic condition", "upload chronic condition", "chronic condition upload", 
          "add my chronic conditions", "add chronic conditions", "chronic conditions add", "add my chronic condition", "add chronic condition", "chronic condition add", 
          "How do I add my chronic conditions?", 
          "Add chronic conditions to medical history", 
          "Upload chronic conditions information", "Upload chronic condition information", "Upload my chronic conditions information", "Upload my chronic condition information",
          "Chronic conditions upload process", 
          "How to upload chronic conditions"
        ],
        
        

        view_medical_history: [
          "How can I view my medical history?", "view my medical history?", "view medical history?", "medical history view?",
          "view my medical history", 
          "view health records", "view health record", "health record view", "health records view",
          "view my health records", "view my health record",  
          "How do I view my health records?",  
          "How to view medical history?", "How to view medical history?",
          "View my medical details", "View medical details", "View my medical detail", "View my medical detail", 
          "How to view my medical information?", "view my medical information", "view medical information", "medical information view",
          "View health details", "health details View", "View health detail", "health detail View",
          "How can I check my medical history?", "check my medical history", "check medical history", "my medical history check", "medical history check",

          "How can I see my medical history?", "see my medical history?", "see medical history?", "medical history see?",
          "see my medical history", 
          "see health records", "see health record", "health record see", "health records see",
          "see my health records", "see my health record",  
          "How do I see my health records?",  
          "How to see medical history?", "How to see medical history?",
          "see my medical details", "see medical details", "see my medical detail", "see my medical detail", 
          "How to see my medical information?", "see my medical information", "see medical information", "medical information see",
          "see health details", "health details see", "see health detail", "health detail see",
        ],

        
        view_allergies: [
          "How can I view my allergies?", "view my allergies", "view allergies", "allergies view", "view my allergy", "view allergy", "allergy view", 
          "view allergy details", "allergy details view", "view allergy detail", "allergy detail view", 
          "How do I view my allergy information?", "view my allergy information", "view allergy information", "allergy information view",
          "How to view allergies?", 
          "How can I check my allergies?", "check my allergies", "my allergies check", "check my allergy", "my allergy check", 
          "How to check my allergies?",

          "How can I see my allergies?", "see my allergies", "see allergies", "allergies see", "see my allergy", "see allergy", "allergy see", 
          "see allergy details", "allergy details see", "see allergy detail", "allergy detail see", 
          "How do I see my allergy information?", "see my allergy information", "see allergy information", "allergy information see",
          "How to see allergies?"
        ],

        
        view_past_medical_history: [
          "How can I view my past medical history?", "view my past medical history", "my past medical history view", "view past medical history", "past medical history view",
          "How do I view my previous illnesses?", "view my previous illnesses", "previous illnesses view",  "previous illnesses view",
          "View my past treatments", "View my past treatment", "my past treatments View", "my past treatment View", 
          "How to view past treatments", 
          "Where can I view my past medical history?", 
          "View previous hospitalizations or surgeries",

          "How can I see my past medical history?", "see my past medical history", "my past medical history see", "see past medical history", "past medical history see",
          "How do I see my previous illnesses?", "see my previous illnesses", "previous illnesses see",  "previous illnesses see",
          "see my past treatments", "see my past treatment", "my past treatments see", "my past treatment see", 
          "How to see past treatments", 
          "Where can I see my past medical history?", 
          "see previous hospitalizations or surgeries"
        ],

        
        view_surgical_history: [
          "How can I view my surgical history?", "view my surgical history", "my surgical history view", "view surgical history", "surgical history view",
          "How do I view my past surgeries?", "view my past surgeries?", "view past surgeries?", "past surgeries view?", "view my past surgery?", "view past surgery?", "past surgery view?",
          "View my surgical details", "surgical details View", "View my surgical detail", "surgical detail View", "View surgical details", "View surgical detail",
          "How to view surgical history", 
          "Where can I view my surgical history?", 
          "View previous surgeries", "View my previous surgeries", "View previous surgery", "View my previous surgery", 
          "How to view surgery details?",

          "How can I see my surgical history?", "see my surgical history", "my surgical history see", "see surgical history", "surgical history see",
          "How do I see my past surgeries?", "see my past surgeries?", "see past surgeries?", "past surgeries see?", "see my past surgery?", "see past surgery?", "past surgery see?",
          "see my surgical details", "surgical details see", "see my surgical detail", "surgical detail see", "see surgical details", "see surgical detail",
          "How to see surgical history", 
          "Where can I see my surgical history?", 
          "see previous surgeries", "see my previous surgeries", "see previous surgery", "see my previous surgery", 
          "How to see surgery details?"
        ],

        
        view_family_history: [
          "How can I view my family history?", "view my family history", "view family history", "my family history view", "family history view", 
          "How do I view my family medical history?", 
          "view my family medical history", "view family medical history", 
          "View my family history details", "View family history details", "family history details View", 
          "How to view family history", 
          "Where can I view my family history?", 
          "View family health history", "View my family health history",
          "How to view family medical details?", "view family medical details", "family medical details view",

          "How can I see my family history?", "see my family history", "see family history", "my family history see", "family history see", 
          "How do I see my family medical history?", 
          "see my family medical history", "see family medical history", 
          "see my family history details", "see family history details", "family history details see", 
          "How to see family history", 
          "Where can I see my family history?", 
          "see family health history", "see my family health history",
          "How to see family medical details?", "see family medical details", "family medical details see"
        ],

        
        view_social_history: [
          "How can I view my social history?", "view my social history", "my social history view", "view social history", "social history view",
          "How do I view my social history?", 
          "view my social details", "view social details", "view my social detail", "view social detail", "social details view", "my social detail view", "social detail view",
          "View my social history details", "my social history details view", "social history detail view", 
          "How to view social history", 
          "Where can I view my social history?", 
          "How to view social history information?",

          "How can I see my social history?", "see my social history", "my social history see", "see social history", "social history see",
          "How do I see my social history?", 
          "see my social details", "see social details", "see my social detail", "see social detail", "social details see", "my social detail see", "social detail see",
          "see my social history details", "my social history details see", "social history detail see", 
          "How to see social history", 
          "Where can I see my social history?", 
          "How to see social history information?"
        ],

        
        view_medications: [
          "How can I view my medications?", "view my medications", "view medications", "view my medication", "view medication", "my medication view", "medication view ",
          "How do I view my medications?", 
          "view my medication details", "view medication details", "view my medication detail", "view medication detail", "my medication detail view", "medication detail view",
          "View my medication details", 
          "How to view medications", 
          "Where can I view my medications?", 
          "How to view medication information?", "view medication information", "view my medication information", "medication information view", "my medication information view",

          "How can I see my medications?", "see my medications", "see medications", "see my medication", "see medication", "my medication see", "medication see ",
          "How do I see my medications?", 
          "see my medication details", "see medication details", "see my medication detail", "see medication detail", "my medication detail see", "medication detail see",
          "see my medication details", 
          "How to see medications", 
          "Where can I see my medications?", 
          "How to see medication information?", "see medication information", "see my medication information", "medication information see", "my medication information see"
        ],

        view_chronic_conditions: [
          "How can I view my chronic conditions?", "view my chronic conditions", "view chronic conditions", "view my chronic condition", "view chronic condition", "view my chronic", "view my chronics",
          "How do I view my chronic conditions?", 
          "view my chronic condition details", "view my chronic condition detail", 
          "View my chronic conditions details", "View my chronic conditions detail", 
          "How to view chronic conditions", 
          "Where can I view my chronic conditions?", 
          "View chronic condition details", "View chronic condition detail", 
          "How to view chronic condition information?", "view chronic condition information", "view chronic conditions information",

          "How can I see my chronic conditions?", "see my chronic conditions", "see chronic conditions", "see my chronic condition", "see chronic condition", "see my chronic", "see my chronics",
          "How do I see my chronic conditions?", 
          "see my chronic condition details", "see my chronic condition detail", 
          "see my chronic conditions details", "see my chronic conditions detail", 
          "How to see chronic conditions", 
          "Where can I see my chronic conditions?", 
          "see chronic condition details", "see chronic condition detail", 
          "How to see chronic condition information?", "see chronic condition information", "see chronic conditions information"
        ],
        

  
        upload_vitals: [
          "How can I upload my vitals?",
          "How do I upload my vitals?",
          "Upload vitals",
          "Add vitals",
          "Vital trends upload", "vitals", "vital"
        ],
  
        upload_reports: [
          "How can I upload my reports?",
          "upload reports", "reports upload", "upload report", "report upload",
          "add new report", "add report", "new report", "report new", 
          "upload medical reports", "medical reports upload"
        ],
  
        view_uploaded_reports: [
          "How can I view my uploaded reports?",
          "view my reports", "my reports", "view reports", "view my report", "my report", "view report",
          "uploaded reports", "reports uploaded"
        ],
  
        update_profile: [
          "How do I update my profile?",
          "What is the process to update my profile information?",
          "How can I change my account details?",
          "Can I update my personal information on the website?",
          "Where can I edit my profile settings?",
          "How do I update my contact information?",
          "Is there an option to modify my profile picture and details?",
          "How do I update my profile?", "update my profile",
          "update profile", "profile update",
          "edit profile", "profile edit", "profile"
        ],
  
        share_medical_records: [
          "how can I give access to my medical records?", "access my medical records", "access medical records", "access my medical record", "access medical record",
          "share medical records", "share medical record", "share my medical records", "share my medical record", "medical records share ", "medical record share ",
          "give access to my records", "share medical reports", "share medical report",
        ],
  
        payment_methods: [
          "What are the payment methods you have?", "payment methods", "payment method", "payment types", "payment type",
          "Which payment methods can I use on your website?",
          "What forms of payment are available?", "forms of payment", "types of payment", "type of payment", "type of payments",
          "Can I pay using digital payment methods?"
        ],
  
        check_payment_status: [
          "how can I check my payment status?",
          "How do I know if my payment was successful?",
          "Where can I view the status of my payment?",
          "Can I track my payment status online?",
          "How do I confirm if my payment went through?",
          "Is there a way to check if my payment has been processed?",
          "How can I verify my payment was received?",
          "payment status",  "status of payment", 
          "check payment status", "my payment"
        ],
  
        change_password: [
          "How can I change my password?",
          "update my password","update password", "password update",
          "change my password",  "change password",  "password change"
        ],
        
        recover_password: [
          "How can I recover my password if I forget it?",
          "What should I do if I can't remember my password?",
          "Is there a way to reset my password if I forgot it?", 
          "forgot password", "forgot my password", "forget password", "forget my password"
        ],
  
  
        check_upcoming_schedule: [
          "How can I check my upcoming schedule?", "upcoming schedule", "upcoming appointment", "upcoming appointments",
          "Where can I see my upcoming appointments?",
          "How do I view my schedule on Doxzon?",
          "Is there a way to check my consultation schedule?",
          "Where can I find details of my upcoming bookings?"
        ],
  
        join_video_call: [
          "How can I join the video call?",
          "What is the process to join a video consultation?",
          "Where can I join my appointment call?",
          "How do I access the video call for my appointment?",
          "Is there a way to join the video consultation from the portal?",
          "video call", "join video call", "video consultation", "join video consultation", "join my appointment call"
        ],
  
        cancel_appointment: [
          "How can I cancel my appointment?",
          "Is it possible to cancel my appointment?",
          "What is the process to cancel an appointment?",
          "Can I cancel a booked appointment?", "cancel the appointment",
          "How do I remove my scheduled appointment?", "cancel appointment", "cancel my appointment"
        ],
  
        appointment_notifications: [
          "Will I get notified about my appointment?",
          "How will I know about my appointment updates?", "appointment updates",
          "Do you send appointment notifications?", "notification of appointment ", "notification of appointments ", "notifications of appointment ", "notifications of appointments ", 
          "Are there notifications for upcoming appointments?",
          "Will I receive alerts about my appointment?", "appointment notifications", "appointment notification"
        ],
  
        payment_failure: [
          "What if my payment fails?", "payment fail", "payment fails", "payment failure", "fails the payment", "fail payment", "fail payments",
          "What happens if my payment doesn't go through?",
          "What should I do if my payment fails?",
          "Can I still book my appointment if my payment fails?",
          "What if my payment is unsuccessful?", "payment unsuccessful", "unsuccessful payment", "payment is unsuccessfull"
        ],
  
        booking_error: [
          "What if I encounter an error while booking my appointment?", "booking error", "appointment booking error",
          "What should I do if I get an error while booking my appointment?",
          "I am facing an issue while booking my appointment, what should I do?", "facing issue while booking", "facing issues in appointment booking",
          "I can't book my appointment, what should I do?", "can't book my appointment", "cannot book my appointment","can't book appointment", "cannot book appointment"
        ],
        
        contact_support: [
          "How do I contact customer support for appointment issues?", "appointment issue", "appointment issues", "contact customer support", "customer support", "support",
          "How can I reach customer support for booking problems?",
          "Where can I get customer support for my appointment?",
          "How do I contact support if I have trouble with my appointment?"
        ],
  
        document_security: [
          "Is my uploaded medical document safe and secure?",
          "Who can access the documents I upload?",
          "How do you ensure my personal information remains private?",
          "What security measures are in place to protect my medical records?",
          "Is my information encrypted when I upload it?",
          "How can I be sure that my data won’t be shared without my permission?",
          "Are my documents stored in a secure, private location?",
          "What happens to my documents if I delete my account?",
          "How often do you update your security protocols?",
          "Will my data be protected if there’s a security breach?",
          "document security", "report security", "records security",
          "document secure", "report secure", "record secure",
          "documents secure", "reports secure", "records secure",
          "documents secured", "reports secured", "records secured"
        ],
  
        refund_policy: [
          "Is there a refund policy if I change my mind or want to cancel my appointment?",
          "Will I get a refund if I cancel my appointment at the last minute?",
          "Do you offer refunds if I miss my appointment?",
          "Can I get a partial refund if I cancel my appointment in advance?",
          "What happens if I need to cancel due to an emergency? Do I get a refund?",
          "Is there a cancellation fee if I decide not to attend my appointment?",
          "How long before the appointment can I cancel without losing my payment?",
          "Do you refund payments for rescheduled appointments?",
          "Is there any refund if the appointment doesn’t meet my expectations?",
          "Can I receive a refund if I accidentally booked the wrong appointment?", "refund"
        ],
  
  
        what_is_telemedicine: [
          "What is Telemedicine?",
          "Can you explain what Telemedicine is?",
          "Tell me about Telemedicine",
          "What does Telemedicine mean?", "Telemedicine", "Telemedicines"
        ],
        how_telemedicine_works: [
          "How does Telemedicine work?", "Telemedicine work",
          "How does Telemedicine function?", "Telemedicine function", 
          "What is the process of Telemedicine?", "Telemedicine process"
        ],
        telemedicine_benefits: [
          "What are the benefits of Telemedicine?", "benefits of Telemedicine", "Telemedicine benefits", "Telemedicine benefit",
          "Why should I use Telemedicine?",
          "What are the advantages of Telemedicine?", "telemedicine benefits", "telemedicine benefit", "advantages of Telemedicine", "advantage of Telemedicine", "Telemedicine advantages", "Telemedicine advantage"
        ],
  
  
        fever: [
          "I have fever, what should I do?", 
          "I’m feeling feverish, what should I do?",
           "feverish", 
          "What should I do if I have a fever?",
          "How do I treat fever?",
          "I feel hot, do I have a fever?", "feel hot", "hot feel ",
          "How do I treat fever?", "fever"
        ],
        cough: [
          "I have a cough, what should I do?",
          "What can I do if I have a cough?",
          "How should I treat my cough?",
          "Is a cough dangerous?", "cough"
        ],
        headache: [
          "I have a headache, what should I do?",
          "My head hurts, what can I do?", "My head hurts", "My head hurt", "head hurts", "head hurt",
          "What to do for a headache?",
          "I’m experiencing a headache, should I see a doctor?", "headache", "head pain"
        ],
        stomach_ache: [
          "I have stomach pain, what should I do?",
          "What should I do if I have a stomach ache?",
          "How can I treat a stomach ache?",
          "What causes stomach pain?", "stomach ache", "stomach pain", "stomach upset"
        ],
        sore_throat: [
          "I have a sore throat, what should I do?",
          "My throat hurts, what can I do?", "My throat hurts", "throat hurts", "throat hurt", "My throat hurt",
          "How should I treat a sore throat?", "sore throat"
        ],
        dizziness: [
          "I feel dizzy, what should I do?", "dizzy",
          "Why am I feeling lightheaded?", "lightheaded",
          "What causes dizziness, and how can I treat it?", "dizziness"
        ],
        nausea: [
          "I feel nauseous, what should I do?", "nauseous",
          "What can I do if I feel like vomiting?", "vomiting", "vomit",
          "How do I stop nausea?", "nausea"
        ],
        general_health_concern: [
          "I'm not feeling well, what should I do?", " not feeling well", "i am not felling well", "i'm not felling well",
          "I'm feeling unwell, what can I do to feel better?", "feeling unwell", "feeling not well",
          "How can I improve my health?", "general health concern", "general health"
        ],
  
  
  
        cold: [
          "I have cold symptoms, what should I do?",
          "What should I do if I have a cold?", "cold",
          "How can I treat a cold?", "cold symptoms"
        ],
        allergy: [
          "I have an allergy, what should I do?",
          "What should I do if I’m allergic to something?", "allergic",
          "How do I treat an allergic reaction?", "allergy"
        ],
        fatigue: [
          "I’m feeling fatigued, what should I do?", "fatigued",
          "Why am I feeling so tired?", "feeling tired", "tired",
          "How can I deal with fatigue?", "fatigue"
        ],
        skin_rash: [
          "I have a rash, what should I do?",
          "What causes a skin rash?", "skin rash",
          "How do I treat a skin rash?",
          "I have a skin rash, what could it be?",
          "Is a skin rash serious?", "When should I be worried about a skin rash?"
        ],
        back_pain: [
          "I have back pain, what should I do?",
          "My back hurts, how can I get relief?", "back pain", "back hurts",
          "How can I treat my back pain?"
        ],
        high_blood_pressure: [
          "I have high blood pressure, what should I do?",
          "What causes high blood pressure?", "high blood pressure",
          "How can I manage high blood pressure?", "high BP", "hypertension"
        ],
        pregnancy: [
          "I’m pregnant, what should I do?",
          "What should I do during pregnancy?", "pregnant",
          "How do I manage my pregnancy?", "pregnancy care", "pregnancy"
        ],
        mental_health: [
          "I’m feeling anxious, what should I do?", "anxious",
          "I feel depressed, what should I do?", "depressed", "feeling low",
          "How can I improve my mental health?", "mental health"
        ],
        weight_loss: [
          "How can I lose weight safely?", "lose weight",
          "What’s the best way to lose weight?", "weight loss",
          "How do I start losing weight?", "weight management"
        ],
  
  
  
  
  
  
        skin_infection: [
          "skin infection", "Skin Infection", "SKIN INFECTION", "skin rash", "rashes", "itching", "skin problem", 
          "skin allergy", "skin irritation", "skin swelling", "skin redness"
        ],
        weakness: [
          "weakness", "feeling weak", "body weakness", "weak body", "weakness and fatigue", "feeling weak and tired"
        ],
        rashes: [
          "rashes", "skin rash", "rashes on skin", "itchy rashes", "rashes on body", "skin rashes", "rash problem"
        ],
        chest_pain: [
          "chest pain", "pain in chest", "sharp chest pain", "tightness in chest", "pain in chest area",
          "I have chest pain", "Pain in my chest", "Pressure in chest", "Sharp pain in chest", "I feel discomfort in my chest", "Chest tightness"
        ],
        stool_not_clear_black_spot: [
          "stool not clear and black spot", "black stool", "stool issues", "stool problems", "black spot in stool"
        ],
        shoulder_joint_pain: [
          "shoulder joint pain", "shoulder pain", "pain in shoulder", "joint pain in shoulder", "shoulder discomfort"
        ],
        diabetes: [
          "diabetes", "diabetic", "diabetes condition", "type 2 diabetes", "high sugar", "diabetes mellitus", 
          "diabetes diagnosis", "sugar problem", "diabetes", "diabete"
        ],
        health_issues: [
          "health issues", "health problems", "medical condition", "health concern", "health problem", "medical issues"
        ],
        hand_pain: [
          "hand pain", "pain in hand", "painful hand", "numbness in hand", "tingling sensation in hand",
          "I have pain in my hand", "Pain in my wrist", "Hand discomfort", "Pain in my fingers", "Wrist pain", "Hand numbness"
        ],
        knee_joint_pain: [
          "knee joint pain", "knee pain", "pain in knee joint", "pain in knees", "joint pain in knee", "knee joint discomfort"
        ],
        face_skin_problem: [
          "face skin problem", "skin issue on face", "facial skin problem", "blemishes on face", "face rash", "face irritation"
        ],
        dizziness_weak: [
          "dizziness and weakness", "feeling dizzy and weak", "weakness and dizziness", "dizzy and weak feeling"
        ],
        abdomen_pain: [
          "abdomen pain", "stomach pain", "pain in abdomen", "abdominal discomfort", "cramps in abdomen",
          "I have abdominal pain", "Belly ache", "Tummy ache", "Pain in my stomach", "Sharp stomach pain", "Abdominal discomfort"
        ],
        hand_tingling_pain: [
          "pain and tingling sensation in hand since 3-4 days", "tingling sensation in hand", "hand pain for several days", 
          "chronic hand tingling"
        ],
        urinary_tract_infection: [
          "urinary tract infection", "UTI", "painful urination", "burning sensation in urine", "frequent urination"
        ],
        cold_cough: [
          "cold and cough", "cough and cold", "cough", "cold", "having cold and cough", "cold symptoms", "cough symptoms"
        ],
        follow_up: [
          "follow up", "follow-up consultation", "follow-up appointment", "follow up on health", "check-up follow up"
        ],
        gastric_problem: [
          "gastric problem", "gastritis", "stomach acidity", "indigestion", "gastric issue", "stomach gas"
        ],
        eye_pain: [
          "eye pain", "pain in eyes", "eye discomfort", "vision issue", "eye irritation"
        ],
        hypothyroidism: [
          "hypothyroidism", "thyroid problem", "low thyroid", "hypothyroid", "underactive thyroid"
        ],
        fever_since_3_days: [
          "fever since 3 days", "high fever", "continuous fever", "fever for 3 days"
        ],
        backache: [
          "backache", "back pain", "pain in back", "lower back pain", "upper back pain", "chronic back pain"
        ],
        respiratory: [
          "respiratory issues", "breathing problems", "respiratory difficulty", "shortness of breath", "breathlessness"
        ],
        psychiatric_consultation: [
          "psychiatrist consultation", "mental health consultation", "psychiatrist", "mental health support"
        ],
        cholesterol: [
          "cholesterol", "high cholesterol", "cholesterol problems", "cholesterol levels", "lipid profile",
          "What is cholesterol?", "How can I manage high cholesterol?", "What causes high cholesterol?", "What are the symptoms of high cholesterol?", "What medications can lower cholesterol?"
        ],
        cold_with_breathlessness: [
          "cold with breathlessness", "cold and shortness of breath", "cold with difficulty breathing", "cold and breathing issue"
        ],
        menstrual_problem: [
          "menstrual problem", "period problem", "irregular periods", "painful periods", "heavy menstruation"
        ],
        hair_fall: [
          "hair fall", "hair loss", "losing hair", "baldness", "thinning hair"
        ],
        scabies: [
          "scabies", "skin infestation", "itching due to scabies", "scabies infection", "scabies skin problem"
        ],
        acidity: [
          "acidity", "acid reflux", "heartburn", "stomach acidity", "gastroesophageal reflux", "GERD"
        ],
        breast_swelling_leg_pain: [
          "breast swelling", "leg pain", "swelling in breasts", "leg swelling", "swelling and pain in legs"
        ],
        high_uric_acid: [
          "high uric acid", "elevated uric acid levels", "gout", "uric acid problems", "high uric acid levels"
        ],
        body_checkup: [
          "body checkup", "general checkup", "health checkup", "full body checkup", "medical checkup"
        ],
        teeth_pain: [
          "teeth pain", "toothache", "pain in teeth", "tooth problem", "tooth pain"
        ],
        sciatica: [
          "sciatica", "nerve pain", "nerve issue", "pain in lower back and legs", "back and leg pain"
        ],
        menstrual_cramps_weakness: [
          "period cramps and weakness", "menstrual cramps and weakness", "weakness with periods", "period pain and weakness"
        ],
        common_cold_body_pain: [
          "common cold, body pain", "cold and body pain", "cold symptoms and body pain", "cold with body ache"
        ],
        cold_and_fever_vomiting: [
          "cold, fever, vomiting", "fever and cold with vomiting", "cold with fever and nausea", "cold, fever and vomiting"
        ],
  
  
  
  
        asthma: [
          "I have asthma, what should I do?", "Asthma symptoms", "I feel short of breath", "Wheezing and coughing", "I have trouble breathing", "How to manage asthma?", "Asthma attack"
        ],
        pelvic_pain: 
        ["I have pelvic pain", "Pelvic discomfort", "Pain in pelvic area", "Lower abdominal pain", "Pain near pelvis", "pelvic pain"
  
        ],
        joint_pain: 
        ["I have joint pain", "Pain in my joints", "Knee pain", "Elbow pain", "Shoulder pain", "Hip pain", "Wrist pain", "joint pain"
  
        ],
        diabetic: [
          "I have diabetes", "Diabetes symptoms", "High blood sugar", "Blood sugar level", "Managing diabetes", "Diabetic consultation"
        ],
        weakness_bodyache: [
          "I feel weak and have body ache", "Weakness and body pain", "I’m tired and sore all over", "Body ache and fatigue", "I feel weak and have body pain", "weakness"
        ],
        blood_report: [
          "I need a blood report", "Blood test results", "Lab report", "Blood work", "Blood analysis", "Complete blood count", "blood report"
        ],
        urine_infection: [
          "I have a urine infection", "I have a UTI", "Painful urination", "Frequent urination", "Burning sensation while urinating", "Blood in urine", "urine infection"
        ],
        thyroid_checkup: [
          "I need a thyroid checkup", "Thyroid problem", "Thyroid test", "Thyroid hormone test", "Hypothyroidism symptoms", "Thyroid function test", "thyroid checkup", "thyroid checkup"
        ],
        period_problem: [
          "I have period problems", "Irregular periods", "Painful periods", "Missed period", "Heavy periods", "Menstrual issues", "period problem"
        ],
        leg_pain: [
          "I have pain in my leg", "Leg pain", "Pain in my knees", "Calf pain", "Thigh pain", "Pain in lower limbs", "leg pain"
        ],
        neck_pain: [
          "I have neck pain", "Pain in my neck", "Stiff neck", "Neck stiffness", "Pain in my cervical area", "neck pain"
        ],
  
        diabetes_management: [
          "How can I manage diabetes?", "What are the best ways to control my diabetes?", "What foods should I avoid with diabetes?", "How to manage blood sugar levels?", "How do I prevent complications from diabetes?"
        ],
        sleep_disorder: [
          "I have trouble sleeping, what should I do?", "How can I improve my sleep?", "I feel tired all the time, what could be causing it?", "What can I do for insomnia?", "How to treat sleep disorders?", "sleep_disorder"
        ],
        stress_management: [
          "I’m feeling stressed, how can I manage it?", "What are some ways to reduce stress?", "How do I cope with stress?", "What are stress-relieving techniques?", "How can I relax when stressed?", "stress management", "stress"
        ],
        nausea_vomiting: [
          "I feel nauseous, what should I do?", "I’m vomiting, what could be the cause?", "How to treat nausea?", "What causes nausea and vomiting?", "When should I see a doctor for vomiting?"
        ],
        cough_with_phlegm: [
          "I have a cough with phlegm, what should I do?", "What does a cough with phlegm mean?", "Why do I have a productive cough?", "How do I treat a cough with phlegm?", "What causes coughing with mucus?"
        ],
        food_allergies: [
          "I think I have a food allergy, what should I do?", "How do I know if I have a food allergy?", "What are common food allergies?", "What should I do if I have an allergic reaction?", "How do I prevent food allergies?", "food allergies", "food allergy"
        ],
        dehydration: [
          "I feel dehydrated, what should I do?", "How do I know if I’m dehydrated?", "What are the symptoms of dehydration?", "How can I stay hydrated?", "How to treat dehydration?", "dehydration"
        ],
        motion_sickness: [
          "I feel sick while traveling, what should I do?", "How to prevent motion sickness?", "What causes motion sickness?", "What can help with motion sickness?", "How can I treat motion sickness?", "motion sickness"
        ],
        sinus_infection: [
          "I think I have a sinus infection, what should I do?", "What causes sinus infections?", "How do I treat a sinus infection?", "What are the symptoms of a sinus infection?", "How long does a sinus infection last?", "sinus infection"
        ],
        kidney_problems: [
          "I think I have kidney problems, what should I do?", "What are the signs of kidney issues?", "How to know if I have kidney disease?", "What causes kidney failure?", "What treatments are available for kidney problems?", "kidney problems"
        ],
        arthritis: [
          "I think I have arthritis, what should I do?", "What are the symptoms of arthritis?", "How do I treat arthritis?", "What are the causes of arthritis?", "What can I do to manage arthritis pain?", "arthritis"
        ],
        migraine: [
          "I have a migraine, what should I do?", "How can I treat my migraine?", "What causes migraines?", "What medications are available for migraines?", "What are the symptoms of a migraine?", "migraine"
        ],
        urinary_retention: [
          "I’m having trouble urinating, what should I do?", "What is urinary retention?", "How can I treat urinary retention?", "What causes difficulty urinating?", "When should I seek medical help for urinary problems?", "urinary retention"
        ],
        eye_infection: [
          "I think I have an eye infection, what should I do?", "How do I treat an eye infection?", "What are the symptoms of an eye infection?", "How do I prevent eye infections?", "What causes eye infections?", "eye infection"
        ],
        tooth_pain: [
          "I have tooth pain, what should I do?", "How do I relieve tooth pain?", "What causes tooth pain?", "When should I see a dentist for tooth pain?", "How can I treat a toothache?", "tooth pain"
        ],
        allergy_reaction: [
          "I’m having an allergic reaction, what should I do?", "What causes allergic reactions?", "How do I treat an allergy reaction?", "When should I seek medical help for allergies?", "What medications can help with allergic reactions?", "allergy reaction"
        ],
        menopause: [
          "I’m going through menopause, what should I expect?", "What are the symptoms of menopause?", "How can I manage menopause symptoms?", "What treatment options are available for menopause?", "How long does menopause last?", "menopause"
        ],
        menopause_symptoms: [
          "I’m experiencing menopause symptoms, what should I do?", "What are the common symptoms of menopause?", "How can I manage hot flashes?", "What is the best way to deal with menopause mood swings?", "When should I consult a doctor about menopause?", "menopause symptoms"
        ],
        pregnancy_checkup: [
          "I’m pregnant, what checkups should I get?", "What are the important pregnancy checkups?", "How often should I see a doctor during pregnancy?", "What tests are done during pregnancy?", "What should I do for a healthy pregnancy?", "pregnancy checkup"
        ],

        for_providers: [
          "What features do you offer for healthcare providers?", "offer for healthcare providers",
          "How can Doxzon help healthcare providers?", "Doxzon help healthcare providers", "Doxzon help providers", "Doxzon help doctors", "Doxzon help provider", "Doxzon help doctor",
          "Do you provide tools for doctors?", "tools for doctors", "tools for doctor",  "tool for doctors", 
          "Benefits for providers using Doxzon?", "Benefits for doctors using Doxzon", "Providers Benefits", "Doctors Benefits", "Provider Benefits", "Doctor Benefits", "Provider Benefit", "Doctor Benefit", "Providers Benefit", "Doctors Benefit",
          "Benefits of doctors using Doxzon", "Benefits of doctors by Doxzon","Benefits of doctor using Doxzon", "Benefit of doctor using Doxzon", "Benefit of doctor by Doxzon", "Benefits for doctors ", "Benefits for doctor ", "Benefit for doctor", "Benefit for doctors ",
          "Benefits for doctors with Doxzon", "Benefits for doctor with Doxzon", "Benefit for doctors with Doxzon", "Benefit for doctor with Doxzon",  "Benefits to doctors ", "Benefits to doctor ", "Benefit to doctor", "Benefit to doctors ",
          "Benefits of providers using Doxzon", "Benefits of provider by Doxzon","Benefits of provider using Doxzon", "Benefit of provider using Doxzon", "Benefit of provider by Doxzon", "Benefits for providers ", "Benefits for provider ", "Benefit for provider", "Benefit for providers ",
          "Benefits for providers with Doxzon", "Benefits for provider with Doxzon", "Benefit for providers with Doxzon", "Benefit for provider with Doxzon",  "Benefits to providers ", "Benefits to provider ", "Benefit to provider", "Benefit to providers ",
          "What does Doxzon offer for doctors?", "Doxzon offer for doctors", "Doxzon offer for providers", "Doxzon offer for doctor", "Doxzon offer for provider",
        ],

        telemedicine: [
          "What is telemedicine?",
          "How does telemedicine work?",
          "Do you offer telemedicine services?",
          "Can I consult a doctor online?",
          "Benefits of telemedicine?",
          "How can telemedicine help patients?"
        ],

        ehr: [
          "What are electronic health records?",
          "How does Doxzon manage patient records?",
          "What is included in an EHR?",
          "Are patient records secure with Doxzon?",
          "Can I access my medical history online?"
        ],

        for_patients: [
          "What does Doxzon offer for patients?", "Doxzon offer for patient", "Doxzon offer for patients", "offer for patients","offer for patient",
          "How can Doxzon help patients?", "Doxzon help patients", "Doxzon help patient", "Doxzon help for patients", "Doxzon help for patient", "Doxzon help to patients", "Doxzon help to patient", "help for patients", "help for patient", "help to patients", "help to patient",
          "Is Doxzon safe for consultations?", "Doxzon safe for consultations", "Doxzon safe for consultation", "safe for consultations", "safe for consultation",
          "Benefits of using Doxzon for patients?", "Benefits for patients using Doxzon", "Benefits for patients by Doxzon","Benefits for patient using Doxzon", "Benefits for patient by Doxzon", "Benefit for patient using Doxzon", "Benefit for patient by Doxzon", "patients Benefits", "patient Benefits", "patient Benefit", "patients Benefit",
          "Benefits of patients using Doxzon", "Benefits of patients by Doxzon","Benefits of patient using Doxzon", "Benefits of patient by Doxzon", "Benefit of patient using Doxzon", "Benefit of patient by Doxzon", "Benefits for patients ", "Benefits for patient ", "Benefit for patient", "Benefit for patients ",
          "Benefits for patients with Doxzon", "Benefits for patient with Doxzon", "Benefit for patients with Doxzon", "Benefit for patient with Doxzon",  "Benefits to patients ", "Benefits to patient ", "Benefit to patient", "Benefit to patients ",
          "How does Doxzon ensure privacy?", "Doxzon ensure privacy"
        ]
        
  
  
  
  
  
    },
  
    
    
    
    responses: {
      greeting: [
        { answer: "Hello! How can I assist you with your healthcare needs today?" },
        { answer: "Hi there! How can I help you with your medical queries?" },
        { answer: "Good day! How can I support you with your health?" },
      ],
      goodbye: [
        { answer: "Goodbye! Take care of your health!" },
        { answer: "See you later! Stay safe!" },
        { answer: "Thanks for reaching out! Feel free to contact me anytime." },
      ],
  
      affirmative: [
        {
        answer: "Thank you for confirming! How can I assist you further?"
          },
      {
        answer: "Great! Let me know if you need anything else."
        },
        {
        answer: "Got it! If you have any other questions, feel free to ask."
        },
        {
        answer: "Awesome! What else can I help you with today?"
        },
        {
        answer: "Perfect! Let me know if you need more information."
        },
        {
          answer: "Thanks for confirming! I'm here if you need anything else."
        },
        {
          answer: "All right! Let me know if you need further assistance."
        }
      ],
  
      negative: [
        {
          answer: "Alright, let me know if you change your mind or need assistance with something else."
        },
        {
          answer: "Okay, if there's anything else you'd like help with, feel free to ask!"
        },
        {
          answer: "No worries! Let me know if you need anything else in the future."
        },
        {
          answer: "Got it! If you need assistance with anything else, I’m here to help."
        },
        {
          answer: "Understood! If you have any other questions or need further support, just let me know."
        },
        {
          answer: "That's okay! If you have other queries, feel free to reach out."
        },
        {
          answer: "I understand. If you change your mind or need anything else, just ask!"
        }
      ],
  
  
      how_are_you: [
        {
          answer: "I'm just a program, but thank you for asking! I'm here to help you with any healthcare-related queries."
        },
        {
          answer: "I'm doing great, thank you! How can I assist you today?"
        },
        {
          answer: "I’m always ready to assist you. Let me know how I can help!"
        }
      ],
  
      doxbot_name: [
        {
          answer: "I am DoxBot, your personal healthcare assistant here to help with any health-related questions or concerns you may have."
        }
      ],
      doxbot_work: [
        {
          answer: "I assist with booking appointments, providing health information, answering your medical queries, and guiding you through various services on the Doxzon Healthcare platform."
        }
      ],
  
      about_doxbot: [
        {
          answer: "DoxBot is a healthcare-focused chatbot designed to provide information, assistance with booking appointments, and support related to health queries. It helps patients and users by offering medical insights, booking consultations, and guiding them through various healthcare services available on Doxzon platform."
        }
      ],
      how_doxbot_works: [
        {
          answer: "DoxBot is an interactive healthcare assistant designed to help you navigate Doxzon Healthcare services. It can assist with scheduling appointments, answering health-related questions, and guiding you through the platform's features. Simply ask your question or describe your needs, and DoxBot will provide the necessary information or assistance."
        }

      ],
      doxbot_availability: [
        {
          answer: "Yes, DoxBot is available 24/7! You can reach out anytime to ask about health-related concerns, book an appointment, or get information about Doxzon services."
        }
      ],
      doxbot_services: [
        {
          answer: "DoxBot offers a variety of services, including helping you book appointments, providing medical advice on symptoms, assisting with prescription information, and answering general health queries related to Doxzon Healthcare."
        }
      ],
      doxbot_appointments: [
        {
          answer: "Yes, DoxBot can help you schedule an appointment with a healthcare provider. Simply let DoxBot know your preferred time, clinic, and doctor, and it will guide you through the process."
        }
      ],
      doxbot_feedback: [
        {
          answer: "You can provide feedback on DoxBot by visiting our 'Feedback' section on the Doxzon platform or simply telling DoxBot what you think. Your feedback helps us improve the services we offer!"
        }
      ],
      doxbot_technical_support: [
        {
          answer: "If you're facing issues with DoxBot, you can contact our technical support team through the Doxzon platform. DoxBot will also direct you to the appropriate channels if needed."
        }
      ],
      doxbot_updates: [
        {
          answer: "DoxBot is regularly updated to provide you with the best service. Any new updates or features will be communicated through the Doxzon platform or via our newsletter."
        }
      ],
      doxbot_personality: [
        {
          answer: "DoxBot is designed to be friendly, professional, and knowledgeable. It provides accurate and helpful information with a focus on empathy and patient care."
        }
      ],
      doxbot_integration: [
        {
          answer: "DoxBot can integrate with various systems to help streamline your healthcare journey. It works seamlessly with Doxzon Healthcare systems, allowing for appointment bookings, medical record access, and more."
        }
      ],
  
      what_is_healthcare: [
        {
          answer: "Healthcare refers to the organized system of medical services and treatment provided to individuals to maintain or improve their health. It includes services such as preventive care, diagnosis, treatment, and rehabilitation provided by medical professionals."
        }
      ],
  
      what_is_doxzon: [
        {
          answer: "Doxzon is a healthcare platform designed to connect patients with healthcare professionals for seamless consultation, appointment booking, and access to medical records. It offers telemedicine services, prescription management, and more, helping individuals manage their health effectively."
        }
      ],
  
      doxzon_address: [
        {
          answer: "Doxzon is part of Cytozon. The address of Cytozon is #179, Kothari Nagar, Singanallur, Coimbatore - 641 005, INDIA."
        }
      ],
  
      appointmentBooking: [
        {
          answer: "To book an appointment, follow these steps:\n" +
                  `1. Visit the page ${process.env.REACT_APP_FRONTEND_URL}consultdoctor \n` +
                  "   or select 'Consult Doctor' from the top-right corner.\n" +
                  "2. Choose your preferred doctor.\n" +
                  "3. Click on 'Book Consultation.'\n" +
                  "4. Select your preferred date and time slot.\n" +
                  "5. Click 'Continue Booking.'\n" +
                  "6. You will be redirected to the checkout page.\n" +
                  "7. Complete your payment to confirm your booking."
        }
      ],

      ePrescription: [
        {
          answer: "To download your e-prescription, follow these steps:\n" +
                  "1. After your consultation, the e-prescription will be sent to your email.\n" +
                  `2. Alternatively, you can download your prescription from ${process.env.REACT_APP_FRONTEND_URL}myprescription \n` +
                  "   or, after logging in, select 'Medical Records' from the top-right corner and then choose 'My Prescription' and click on 'View Prescription' to download your prescription."
        }
      ],

      available_doctors: [
        {
          answer: `To view the list of available doctors, please visit the page ${process.env.REACT_APP_FRONTEND_URL}consultdoctor \n` +
          "You can browse through the available specialists and book your appointment directly through the platform."
        }
      ],
  
  
      prescription_validity: [
        {
          answer: "As per government regulations, any prescription that contains the details of the doctor, patient, medicine, prescription date, and doctor’s sign/stamp is valid."
        }
      ],
  
  
      appointment_types: [
        {
          answer: "You can book two types of appointments: In-person and online."
        }
      ],
  
      walk_in_appointments: [
        {
          answer: "Yes, we do offer walk-in appointments. While booking an appointment, you can select the service type as the 'in-person' option."
        }
      ],
  
      upload_medical_history: [
        {
          answer: "To upload your medical history:\n" +
                  `1. Visit the page ${process.env.REACT_APP_FRONTEND_URL}medicalhistory \n` +
                  "   or, after logging in, select 'Medical Records' from the top-right corner and then choose 'Medical History.'\n" +
                  "3. You can now upload details of your medical history, including allergies, past medical history, surgical history, family history, social history, medications, and chronic conditions."
        }
      ],

      upload_allergies: [
        {
          answer: "To upload your allergy details:\n" +
                  "1. Log in to your Doxzon account.\n" +
                  "2. Go to the 'Medical Records' section from the top-right menu and then choose 'Medical History. \n" +
                  ` or visit directly ${process.env.REACT_APP_FRONTEND_URL}medicalhistory \n` +
                  `3. Choose 'Allergies' \n` +
                  "4. Enter your allergy information, such as type of allergy and reactions, and save the details."
        }
      ],

      upload_past_medical_history: [
        {
          answer: "To upload your past medical history:\n" +
                  "1. Log in to your Doxzon account.\n" +
                  "2. Navigate to the 'Medical Records' section from the top-right menu and then select 'Medical History.'\n" +
                  `   Or visit directly: ${process.env.REACT_APP_FRONTEND_URL}medicalhistory \n` +
                  "3. Choose 'Past Medical History.'\n" +
                  "4. Enter relevant information, such as previous illnesses, treatments, surgeries, or hospitalizations, and save the details."
        }
      ],  
      
      upload_surgical_history: [
        {
          answer: "To upload your surgical history:\n" +
                  "1. Log in to your Doxzon account.\n" +
                  "2. Go to the 'Medical Records' section from the top-right menu and choose 'Medical History'.\n" +
                  `or, visit directly ${process.env.REACT_APP_FRONTEND_URL}medicalhistory\n` +
                  "3. Select 'Surgical History'.\n" +
                  "4. Enter the details of your surgeries, including dates, procedures, and any complications, then save the information."
        }
      ],  
      
      upload_family_history: [
        {
          answer: "To upload your family history:\n" +
                  "1. Log in to your Doxzon account.\n" +
                  "2. Go to the 'Medical Records' section from the top-right menu and select 'Medical History'.\n" +
                  `or, visit directly ${process.env.REACT_APP_FRONTEND_URL}medicalhistory\n` +
                  "3. Select 'Family History'.\n" +
                  "4. Enter details about your family’s medical history, including any hereditary conditions or diseases, and save the information."
        }
      ],   
      
      upload_social_history: [
        {
          answer: "To upload your social history:\n" +
                  "1. Log in to your Doxzon account.\n" +
                  "2. Go to the 'Medical Records' section from the top-right menu and select 'Medical History'.\n" +
                  `or, visit directly ${process.env.REACT_APP_FRONTEND_URL}medicalhistory \n` +
                  "3. Select 'Social History'.\n" +
                  "4. Enter details about your lifestyle, including habits like smoking, alcohol consumption, exercise, and any other social factors, and save the information."
        }
      ],
      
      upload_medications: [
        {
          answer: "To upload your medication details:\n" +
                  "1. Log in to your Doxzon account.\n" +
                  "2. Go to the 'Medical Records' section from the top-right menu and select 'Medical History'.\n" +
                  `or, visit directly ${process.env.REACT_APP_FRONTEND_URL}medicalhistory\n` +
                  "3. Select 'Medications'.\n" +
                  "4. Enter details about your current medications, including the name, dosage, and frequency, and save the information."
        }
      ],
      
      upload_chronic_conditions: [
        {
          answer: "To upload your chronic conditions details:\n" +
                  "1. Log in to your Doxzon account.\n" +
                  "2. Go to the 'Medical Records' section from the top-right menu and select 'Medical History'.\n" +
                  `   Or, visit directly: ${process.env.REACT_APP_FRONTEND_URL}medicalhistory\n` +
                  "3. Select 'Chronic Conditions'.\n" +
                  "4. Enter details about your chronic conditions and save the information."
        }
      ],  
      
      view_medical_history: [
        {
          answer: "To view your medical history:\n" +
                  "1. Log in to your Doxzon account.\n" +
                  "2. Go to the 'Medical Records' section from the top-right menu and select 'Medical History'.\n" +
                  `   Or, visit directly: ${process.env.REACT_APP_FRONTEND_URL}medicalhistory\n` +
                  "3. Here you can view all your medical records, including allergies, past medical history, surgical history, family history, social history, medications, and chronic conditions."
        }
      ],

      view_allergies: [
        {
          answer: "To view your allergy details:\n" +
                  "1. Log in to your Doxzon account.\n" +
                  "2. Go to the 'Medical Records' section from the top-right menu and select 'Medical History'.\n" +
                  `   Or, visit directly: ${process.env.REACT_APP_FRONTEND_URL}medicalhistory\n` +
                  "3. Choose 'Allergies'.\n" +
                  "4. Here, you can view the details of your allergies, including types and reactions."
        }
      ],

      view_past_medical_history: [
        {
          answer: "To view your past medical history:\n" +
                  "1. Log in to your Doxzon account.\n" +
                  "2. Navigate to the 'Medical Records' section from the top-right menu and select 'Medical History'.\n" +
                  `   Or, visit directly: ${process.env.REACT_APP_FRONTEND_URL}medicalhistory\n` +
                  "3. Choose 'Past Medical History'.\n" +
                  "4. You can now view details about your previous illnesses, treatments, surgeries, or hospitalizations."
        }
      ],

      view_surgical_history: [
        {
          answer: "To view your surgical history:\n" +
                  "1. Log in to your Doxzon account.\n" +
                  "2. Go to the 'Medical Records' section from the top-right menu and select 'Medical History'.\n" +
                  `   Or, visit directly: ${process.env.REACT_APP_FRONTEND_URL}medicalhistory\n` +
                  "3. Choose 'Surgical History'.\n" +
                  "4. You can now view details about your surgeries, including dates, procedures, and any complications."
        }
      ],

      view_family_history: [
        {
          answer: "To view your family history:\n" +
                  "1. Log in to your Doxzon account.\n" +
                  "2. Go to the 'Medical Records' section from the top-right menu and select 'Medical History'.\n" +
                  `   Or, visit directly: ${process.env.REACT_APP_FRONTEND_URL}medicalhistory\n` +
                  "3. Choose 'Family History'.\n" +
                  "4. You can now view details about your family’s medical history, including hereditary conditions or diseases."
        }
      ],

      view_social_history: [
        {
          answer: "To view your social history:\n" +
                  "1. Log in to your Doxzon account.\n" +
                  "2. Go to the 'Medical Records' section from the top-right menu and select 'Medical History'.\n" +
                  `   Or, visit directly: ${process.env.REACT_APP_FRONTEND_URL}medicalhistory\n` +
                  "3. Choose 'Social History'.\n" +
                  "4. You can now view your social history details, including lifestyle factors and habits that may affect your health."
        }
      ],

      view_medications: [
        {
          answer: "To view your medication details:\n" +
                  "1. Log in to your Doxzon account.\n" +
                  "2. Go to the 'Medical Records' section from the top-right menu and select 'Medical History'.\n" +
                  `   Or, visit directly: ${process.env.REACT_APP_FRONTEND_URL}medicalhistory\n` +
                  "3. Select 'Medications'.\n" +
                  "4. You can now view your current medications, including the name, dosage, and frequency."
        }
      ],

      view_chronic_conditions: [
        {
          answer: "To view your chronic conditions details:\n" +
                  "1. Log in to your Doxzon account.\n" +
                  "2. Go to the 'Medical Records' section from the top-right menu and select 'Medical History'.\n" +
                  `   Or, visit directly: ${process.env.REACT_APP_FRONTEND_URL}medicalhistory\n` +
                  "3. Select 'Chronic Conditions'.\n" +
                  "4. You can now view your chronic conditions and related details."
        }
      ],      
      
  
      upload_vitals: [
        {
          answer: "To upload your vitals, follow these steps:\n" +
                  `1. Visit the page ${process.env.REACT_APP_FRONTEND_URL}myvitals \n` +
                  "   or, after logging in, select 'Medical Records' from the top-right corner and then choose 'My Vitals.'\n" +
                  "2. Click on 'Add Vitals' to upload your details."
        }
      ],  
  
      upload_reports: [
        {
          answer: "To upload your reports:\n" +
                  "1. Go to the Doxzon patient portal.\n" +
                  "2. Select 'My Records,' then choose 'My Reports.'\n" +
                  "3. Select 'New,' now you can upload your reports."
        }
      ],
      
      view_uploaded_reports: [
        {
          answer: "To view your reports:\n" +
                  "1. Go to the Doxzon patient portal.\n" +
                  "2. Select 'My Records,' then choose 'My reports.'\n" +
                  "3. Select 'View Document.'\n" +
                  "Now you can view your document."
        }
      ],
  
      update_profile: [
        {
          answer: 
            "To update your profile:\n" +
            "1. Go to the Doxzon patient portal.\n" +
            "2. Select 'My Profile,' then choose 'Edit.'\n" +
            "From there, you can modify your personal details, contact information, and profile picture."
        }
      ],
  
      share_medical_records: [
        {
          answer: "To give access to your medical records:\n" +
                  "1. Go to the Doxzon patient portal.\n" +
                  "2. Select 'My Appointments,' then choose 'Share Medical Records.'\n" +
                  "3. Tick the checkbox and click 'Share.'"
        }
      ],
  
      payment_methods: [
        {
          answer: 
            "We accept multiple payment methods, including:\n" +
            "- Cash\n" +
            "- Net banking\n" +
            "- All major digital payment options."
        }
      ],
      
      check_payment_status: [
        {
          answer: "To check your payment status:\n" +
                  "1. Go to the Doxzon patient portal.\n" +
                  "2. Select 'My Payments' to check the payment status.\n" +
                  "3. Alternatively, select 'My Appointment' to check your payment status there as well."
        }
      ],
  
      change_password: [
        {
          answer: "To change your password:\n" +
                  "1. Go to the Doxzon patient portal.\n" +
                  "2. Select 'Settings,' enter your current password, then provide your new password."
        }
      ],
  
      recover_password: [
        {
          answer: 
            "If you forget your password, follow these steps:\n" +
            "1. Click on 'Forgot Password' on the login page.\n" +
            "2. An OTP will be sent to your registered phone number.\n" +
            "3. After successfully entering the OTP, you will be redirected to a page where you can change your password."
        }
      ],
  
      check_upcoming_schedule: [
        {
          answer: 
            "You can view this information on Doxzon's consultation Patient Portal dashboard."
        }
      ],
  
      join_video_call: [
        {
          answer: 
            "To join the video call:\n1. Go to the Doxzon patient portal.\n2. Select 'Appointments,' then click 'Join Call.'"
        }
      ],
  
      cancel_appointment: [
        {
          answer: "No, you can’t cancel the appointment."
        }
      ],
  
      appointment_notifications: [
        {
          answer: "Yes, you will receive notifications about your appointment through the Doxzon patient portal, SMS, and email."
        }
      ],
  
      payment_failure: [
        {
          answer: "If your payment fails, your appointment will not be booked. You can refresh the page or try the payment process again later."
        }
      ],
  
      booking_error: [
        {
          answer: "If you encounter an error while booking your appointment, note the error message and try again. If the issue persists, please reach out to customer support for assistance."
        }
      ],
      contact_support: [
        {
          answer: "You can find the contact information for customer support on our website or app, including phone numbers and email addresses. Feel free to reach out for assistance."
        }
      ],
  
      document_security: [
        {
          answer: "Your privacy and data security are our top priorities. Our website uses advanced encryption and secure servers to ensure that any documents you upload are protected. Only authorized personnel and you will have access to your documents, and we strictly adhere to privacy and security regulations to keep your information safe."
        }
      ],
  
      refund_policy: [
        {
          answer: "Firstly, as a patient, you are able to book an appointment; however, only the doctor can cancel an appointment. Currently, we do not offer refunds for any payments made through our website. We recommend carefully reviewing all information before completing your payment."
        }
      ],
  
      what_is_telemedicine: [
        {
          "answer": "Telemedicine refers to the practice of providing medical care remotely through technology, such as video calls or messaging platforms."
        }
      ],
  
      how_telemedicine_works: [
        {
          "answer": "Telemedicine works by connecting patients with healthcare providers via video conferencing tools, allowing them to discuss symptoms, receive diagnoses, and get prescriptions without an in-person visit."
        }
      ],
      telemedicine_benefits: [
        {
          "answer": "Telemedicine offers convenience, accessibility, and reduced costs. It eliminates the need for travel and waiting times, making it easier to access healthcare from anywhere."
        }
      ],
  
  
  
      fever: [
        {
          answer: "Sorry to hear that you are not feeling well. Fever is usually a sign of infection, and it’s important to consult with a doctor if it persists or gets worse. You can book an appointment with a Doxzon healthcare professional for further guidance."
        }
      ],
      cough: [
        {
          answer: "I'm sorry to hear about your cough. It could be due to various reasons like cold, allergies, or a respiratory infection. Please consult with a doctor to determine the cause and get appropriate treatment."
        }
      ],
      headache: [
        {
          answer: "I’m sorry to hear that you’re experiencing a headache. It could be caused by stress, dehydration, or other factors. If the headache persists or worsens, it’s best to consult with a Doxzon healthcare provider for advice."
        }
      ],
      stomach_ache: [
        {
          answer: "Sorry to hear that you're experiencing stomach pain. This could be caused by several factors such as indigestion, gas, or infections. If it continues, it's advisable to consult with a doctor."
        }
      ],
      sore_throat: [
        {
          answer: "Sorry to hear that your throat is sore. It could be due to a viral or bacterial infection. Rest, hydration, and warm fluids may help, but if the pain persists, please consult with a Doxzon healthcare professional."
        }
      ],
      dizziness: [
        {
          answer: "I’m sorry you’re feeling dizzy. Dizziness can be caused by dehydration, low blood pressure, or other health issues. If it persists, it's important to consult with a doctor."
        }
      ],
      nausea: [
        {
          answer: "Sorry to hear you're feeling nauseous. Nausea can be due to various factors like food intolerance, stress, or an underlying condition. If it continues, consult with a doctor for further guidance."
        }
      ],
      general_health_concern: [
        {
          answer: "Sorry to hear that you're not feeling well. If you’re concerned about your health, it's always a good idea to consult with a Doxzon healthcare provider for personalized advice. You can book an appointment through our website."
        }
      ],
  
      cold: [
      {
        "answer": "Sorry to hear you're dealing with a cold. Rest, hydration, and over-the-counter medications can help. If symptoms worsen or don't improve, it's best to consult with a Doxzon healthcare professional for guidance."
      }
    ],
    allergy: [
      {
        "answer": "I’m sorry you're experiencing allergies. You can try antihistamines or avoid triggers. If your symptoms persist, it's best to consult with a Doxzon healthcare professional for personalized advice."
      }
    ],
    fatigue: [
      {
        "answer": "Sorry to hear you're feeling fatigued. This can be due to lack of sleep, stress, or other health issues. If it continues, it’s a good idea to consult with a Doxzon healthcare professional for a proper diagnosis."
      }
    ],
    skin_rash: [
      {
        "answer": "Sorry to hear about your skin rash. It could be due to an allergy, infection, or other conditions. Please consult with a Doxzon healthcare professional for a proper evaluation and treatment."
      }
    ],
    pregnancy: [
      {
        "answer": "If you're pregnant, it's essential to have regular check-ups with a Doxzon healthcare professional. They can help monitor your health and guide you throughout your pregnancy."
      }
    ],
    mental_health: [
      {
        "answer": "If you’re feeling anxious or depressed, it's important to talk to a Doxzon healthcare professional. They can provide support, coping strategies, and recommend treatment options."
      }
    ],
    weight_loss: [
      {
        "answer": "Losing weight safely requires a balanced diet and regular exercise. A Doxzon healthcare professional can help guide you with a personalized plan to achieve your weight loss goals."
      }
    ],
  
  
  
  
    skin_infection: [
      {
        answer: "A skin infection could be caused by bacteria, viruses, or fungi, leading to symptoms like redness, swelling, or pus. It's important to consult a Doxzon healthcare provider for diagnosis and treatment."
      },
      {
        answer: "If you're experiencing a skin infection, it can lead to discomfort, redness, or irritation. It's best to seek treatment from a Doxzon healthcare professional to avoid complications."
      }
    ],
    high_bp_sugar: [
      {
        answer: "High blood pressure and elevated sugar levels can significantly impact your health. It’s crucial to monitor both conditions regularly and consult a Doxzon healthcare provider for an effective treatment plan."
      },
      {
        answer: "If you have high blood pressure and high sugar levels, it’s important to manage them to prevent complications. Doxzon healthcare professionals can help you with monitoring and treatment."
      }
    ],
    weakness: [
      {
        answer: "Weakness can be caused by various factors, such as dehydration, fatigue, or underlying medical conditions. If this persists, please consult a Doxzon healthcare provider for a thorough evaluation."
      },
      {
        answer: "If you're experiencing persistent weakness, it’s important to identify the underlying cause. A Doxzon healthcare provider can offer a detailed evaluation to determine the cause."
      }
    ],
    rashes: [
      {
        answer: "Rashes can be caused by allergies, infections, or underlying conditions. It's recommended to visit a Doxzon healthcare professional for a proper diagnosis and treatment."
      },
      {
        answer: "If you’re dealing with rashes, it’s important to understand the cause, which could range from allergies to infections. Consult a Doxzon healthcare provider for proper care."
      }
    ],
    chest_pain: [
      {
        answer: "Chest pain should never be ignored as it could indicate a serious condition. Please contact a Doxzon healthcare professional immediately for evaluation."
      },
      {
        answer: "If you’re experiencing chest pain, it could be a sign of a serious health issue. It’s essential to consult a Doxzon healthcare provider as soon as possible for an accurate diagnosis."
      }
    ],
    stool_not_clear_black_spot: [
      {
        answer: "Black stool or changes in stool consistency could indicate digestive issues or other health concerns. It’s important to consult with a Doxzon healthcare provider for an evaluation."
      },
      {
        answer: "If you notice black spots in your stool, it could be a sign of digestive issues. Please reach out to a Doxzon healthcare provider for further investigation."
      }
    ],
    shoulder_joint_pain: [
      {
        answer: "Shoulder joint pain could be caused by strain, arthritis, or other injuries. A visit to a Doxzon healthcare provider can help diagnose the cause and recommend appropriate treatment."
      },
      {
        answer: "Shoulder joint pain can be a sign of overuse or an underlying condition like arthritis. It's best to consult with a Doxzon healthcare professional for a proper diagnosis."
      }
    ],
    diabetes: [
      {
        answer: "Diabetes requires ongoing management, including monitoring blood sugar levels and following a healthy lifestyle. A Doxzon healthcare professional can guide you through managing your diabetes effectively."
      },
      {
        answer: "If you're living with diabetes, it’s important to control blood sugar levels and monitor your health regularly. Doxzon healthcare professionals can help with personalized treatment plans."
      }
    ],
    health_issues: [
      {
        answer: "Health issues can range from minor conditions to more serious diseases. If you're experiencing any symptoms, it’s important to consult with a Doxzon healthcare professional to determine the cause and appropriate treatment."
      },
      {
        answer: "If you're dealing with multiple health issues, it’s best to consult a Doxzon healthcare provider for a thorough assessment and tailored treatment plan."
      }
    ],
    hand_pain: [
      {
        answer: "Hand pain could be related to nerve issues, muscle strain, or joint problems. If you're experiencing ongoing pain, it’s essential to see a Doxzon healthcare provider for an accurate diagnosis."
      },
      {
        answer: "Pain in the hand might indicate nerve compression or other conditions. It’s best to consult with a Doxzon healthcare professional to understand the cause of the pain."
      }
    ],
    knee_joint_pain: [
      {
        answer: "Knee joint pain can be caused by overuse, arthritis, or injuries. It’s important to visit a Doxzon healthcare provider for a proper diagnosis and treatment options."
      },
      {
        answer: "If you’re experiencing knee joint pain, it could be from strain or underlying conditions like arthritis. A Doxzon healthcare professional can guide you through the right treatment plan."
      }
    ],
    face_skin_problem: [
      {
        answer: "Skin issues on the face can be caused by acne, allergies, or other skin conditions. It’s best to consult with a Doxzon healthcare provider to get proper treatment."
      },
      {
        answer: "If you’re experiencing skin problems on your face, it could be related to acne, irritation, or an allergic reaction. A Doxzon healthcare provider can help with appropriate treatment."
      }
    ],
    dizziness_weak: [
      {
        answer: "Dizziness combined with weakness could be caused by dehydration, low blood pressure, or other conditions. It’s essential to visit a Doxzon healthcare provider for a thorough evaluation."
      },
      {
        answer: "Dizziness and weakness can be related to several causes, such as low blood sugar, dehydration, or other medical conditions. Please consult with a Doxzon healthcare provider for proper diagnosis."
      }
    ],
    abdomen_pain: [
      {
        answer: "Abdominal pain can be caused by digestive issues, infections, or other health conditions. It’s important to consult a Doxzon healthcare professional for a proper evaluation."
      },
      {
        answer: "Pain in the abdomen could indicate digestive problems or other conditions. If it persists, please reach out to a Doxzon healthcare provider for further diagnosis and care."
      }
    ],
    hand_tingling_pain: [
      {
        answer: "Tingling or pain in the hand can be caused by nerve compression or poor circulation. It’s important to consult with a Doxzon healthcare provider to determine the cause."
      },
      {
        answer: "Pain and tingling in the hand may suggest a nerve issue. A Doxzon healthcare provider can help assess your symptoms and provide the right treatment."
      }
    ],
  
    urinary_tract_infection: [
      {
        answer: "A urinary tract infection (UTI) causes painful urination, frequent urination, and a burning sensation while urinating. It's important to visit a healthcare professional for proper diagnosis and treatment, including antibiotics if necessary."
      }
    ],
    cold_cough: [
      {
        answer: "Cold and cough are common symptoms, often caused by viral infections. It's important to rest, drink plenty of fluids, and take over-the-counter medications for relief. If symptoms worsen or persist, consult a healthcare professional."
      }
    ],
    follow_up: [
      {
        answer: "A follow-up consultation is important to track your recovery progress. You can schedule a follow-up appointment with a Doxzon healthcare professional to discuss your health and any ongoing concerns."
      }
    ],
    gastric_problem: [
      {
        answer: "Gastric problems like gastritis or acid reflux can cause discomfort and indigestion. It's important to avoid spicy and acidic foods, eat smaller meals, and take prescribed medication. A healthcare professional can provide personalized advice."
      }
    ],
    eye_pain: [
      {
        answer: "Eye pain or discomfort can result from various issues such as dryness, strain, or infection. If the pain persists, it's best to consult an eye specialist for proper diagnosis and treatment."
      }
    ],
    hypothyroidism: [
      {
        answer: "Hypothyroidism, or an underactive thyroid, can lead to symptoms like fatigue, weight gain, and depression. A blood test can confirm thyroid hormone levels, and treatment usually involves hormone replacement therapy."
      }
    ],
    fever_since_3_days: [
      {
        answer: "If you've had a fever for three days or more, it could indicate an underlying infection or illness. It's important to consult a healthcare professional for a proper diagnosis and treatment."
      }
    ],
    backache: [
      {
        answer: "Back pain can result from poor posture, muscle strain, or underlying conditions like arthritis. It's important to practice proper posture, exercise, and consult a healthcare professional if pain persists."
      }
    ],
    respiratory: [
      {
        answer: "Respiratory issues like shortness of breath or breathlessness can be caused by conditions like asthma or lung infections. Consult a healthcare professional to identify the cause and receive appropriate treatment."
      }
    ],
    psychiatric_consultation: [
      {
        answer: "If you're facing mental health challenges, a psychiatric consultation can help. A professional can provide support and treatment options such as therapy or medication to manage your mental well-being."
      }
    ],
    cholesterol: [
      {
        answer: "High cholesterol can lead to heart disease and other complications. Regular check-ups and a healthy diet, along with prescribed medications, can help manage cholesterol levels. Consult a healthcare provider for more information."
      }
    ],
    cold_with_breathlessness: [
      {
        answer: "Cold with breathlessness may indicate a respiratory infection or condition. If you're experiencing difficulty breathing along with cold symptoms, seek immediate medical advice."
      }
    ],
    menstrual_problem: [
      {
        answer: "Menstrual problems like irregular or painful periods can be managed with lifestyle changes and medication. It's best to consult a gynecologist for diagnosis and treatment options."
      }
    ],
    hair_fall: [
      {
        answer: "Hair fall can be caused by various factors like stress, hormonal imbalance, or poor nutrition. A healthcare professional can help identify the cause and recommend treatments such as medication or lifestyle changes."
      }
    ],
    scabies: [
      {
        answer: "Scabies is a skin infestation that causes intense itching. It requires treatment with prescription medication to kill the mites. It's important to consult a doctor for proper treatment and to prevent spreading it."
      }
    ],
    acidity: [
      {
        answer: "Acidity or acid reflux causes a burning sensation in the chest and throat. Avoiding spicy foods, eating smaller meals, and using antacids can help. If symptoms persist, consult a doctor for further advice."
      }
    ],
    breast_swelling_leg_pain: [
      {
        answer: "Swelling in the breasts and legs may be linked to hormonal changes, pregnancy, or other health conditions. It's important to consult a doctor to determine the underlying cause and receive appropriate treatment."
      }
    ],
    high_uric_acid: [
      {
        answer: "High uric acid levels can lead to gout, which causes joint pain and swelling. It's important to manage your diet and consult a healthcare provider for medication to control uric acid levels."
      }
    ],
    body_checkup: [
      {
        answer: "A body checkup is an essential part of preventive healthcare. It helps detect potential health issues early. You can schedule a general health checkup with a Doxzon healthcare professional."
      }
    ],
    teeth_pain: [
      {
        answer: "Tooth pain can be caused by cavities, gum disease, or tooth sensitivity. It's best to visit a dentist for a proper diagnosis and treatment options."
      }
    ],
    sciatica: [
      {
        answer: "Sciatica is pain that radiates from the lower back down the legs. It's often caused by a herniated disc or nerve compression. Physical therapy and pain management can help relieve symptoms."
      }
    ],
    menstrual_cramps_weakness: [
      {
        answer: "Menstrual cramps can cause weakness and discomfort. Over-the-counter pain relievers, heating pads, and proper rest can help manage the pain. If symptoms are severe, consult a doctor."
      }
    ],
    common_cold_body_pain: [
      {
        answer: "Common cold symptoms like body pain can be managed with rest, fluids, and over-the-counter medications. If the symptoms persist, consult a healthcare provider for advice."
      }
    ],
    cold_and_fever_vomiting: [
      {
        answer: "Cold, fever, and vomiting together could indicate a viral infection or flu. It's important to stay hydrated, rest, and consult a healthcare professional if symptoms persist or worsen."
      }
    ],
  
  
  
  
    asthma: [
      {
        answer: "Asthma is a condition that causes difficulty in breathing due to inflammation in the airways. If you have asthma, it's important to follow your healthcare provider’s recommendations. You can book an appointment with a Doxzon healthcare professional for asthma management."
      }
    ],
    pelvic_pain: [
      {
        answer: "Pelvic pain can be caused by various conditions, including menstruation, infections, or even more complex issues. Consult a Doxzon healthcare provider for an accurate diagnosis and treatment options."
      }
    ],
    joint_pain: [
      {
        answer: "Joint pain can occur due to several reasons, including arthritis, injury, or overuse. It’s advisable to visit a Doxzon healthcare provider to determine the exact cause and get the right treatment."
      }
    ],
    back_pain: [
      {
        answer: "Back pain is a common issue that can be caused by muscle strain, poor posture, or underlying conditions. If the pain is severe or persistent, seek advice from a Doxzon healthcare professional."
      }
    ],
    diabetic: [
      {
        answer: "Diabetes is a chronic condition that affects how your body processes blood sugar. It's important to monitor your blood sugar levels and follow a healthy lifestyle. Consult a Doxzon healthcare provider for diabetic management."
      }
    ],
    weakness_bodyache: [
      {
        answer: "Weakness and body aches can be symptoms of various conditions, from viral infections to stress. If the symptoms continue, it’s best to visit a Doxzon healthcare provider for an assessment."
      }
    ],
    blood_report: [
      {
        answer: "Blood tests help in diagnosing a variety of conditions. If you need a blood report or have any questions about your results, you can schedule a consultation with a Doxzon healthcare provider."
      }
    ],
    urine_infection: [
      {
        answer: "Urinary tract infections (UTIs) are common and can cause painful urination and frequent urges to urinate. Consult a Doxzon healthcare provider for proper treatment if you suspect you have a UTI."
      }
    ],
    thyroid_checkup: [
      {
        answer: "Thyroid issues can affect your metabolism and overall health. If you're experiencing symptoms like fatigue, weight gain, or mood changes, it may be a good idea to get a thyroid checkup. Visit a Doxzon healthcare provider for a thorough evaluation."
      }
    ],
    period_problem: [
      {
        answer: "Irregular or painful periods can be due to several factors such as hormonal imbalances or stress. Consult a Doxzon healthcare provider for an accurate diagnosis and treatment."
      }
    ],
    leg_pain: [
      {
        answer: "Leg pain can be due to injuries, muscle strain, or conditions like arthritis. If the pain persists, consult a Doxzon healthcare professional for diagnosis and treatment options."
      }
    ],
    neck_pain: [
      {
        answer: "Neck pain can be caused by muscle strain, poor posture, or underlying conditions. If the pain is persistent or severe, consult a Doxzon healthcare provider for a proper diagnosis and treatment."
      }
    ],
  
  
    high_blood_pressure: [
      {
        answer: "High blood pressure (hypertension) is a serious condition. It's important to monitor your blood pressure regularly and follow the advice of a healthcare provider. You can manage it by adopting a healthy diet, exercising, and taking prescribed medications. Please consult with a Doxzon healthcare professional for personalized recommendations."
      }
    ],
    diabetes_management: [
      {
        answer: "Managing diabetes involves controlling your blood sugar levels through a healthy diet, regular physical activity, and taking your prescribed medications. It's important to monitor your blood sugar regularly. Please consult with a Doxzon healthcare provider for a tailored management plan."
      }
    ],
    sleep_disorder: [
      {
        answer: "If you're having trouble sleeping, consider implementing a sleep routine, reducing caffeine intake, and creating a calm, dark sleep environment. If the problem persists, it’s important to consult a healthcare provider for further evaluation and treatment."
      }
    ],
    stress_management: [
      {
        answer: "To manage stress, practice relaxation techniques such as deep breathing, meditation, and physical exercise. It may also help to talk to a professional counselor or therapist for additional support. If you’re feeling overwhelmed, consult with a Doxzon healthcare professional."
      }
    ],
    nausea_vomiting: [
      {
        answer: "Nausea and vomiting can have many causes, such as infections, digestive issues, or even anxiety. If symptoms persist, it's important to consult with a healthcare professional. You can also try sipping water or ginger tea to soothe your stomach."
      }
    ],
    cough_with_phlegm: [
      {
        answer: "A cough with phlegm could indicate a respiratory infection or other conditions. It's important to stay hydrated and get plenty of rest. If symptoms last for more than a week, consult with a Doxzon healthcare provider for an evaluation."
      }
    ],
    food_allergies: [
      {
        answer: "If you suspect a food allergy, it's important to avoid the trigger food and consult with a healthcare provider for proper testing and treatment. An allergy specialist can guide you in managing your food allergies."
      }
    ],
    dehydration: [
      {
        answer: "Dehydration can occur when you don't drink enough fluids. Drink water regularly, especially in hot weather or after physical activity. If you experience symptoms like dizziness or confusion, seek medical help. Doxzon healthcare can provide further assistance if needed."
      }
    ],
    motion_sickness: [
      {
        answer: "Motion sickness can be managed with medications like antihistamines or natural remedies like ginger. If you experience frequent motion sickness, it’s best to consult with a healthcare provider to determine the underlying cause."
      }
    ],
    sinus_infection: [
      {
        answer: "A sinus infection can cause symptoms like congestion, headaches, and facial pain. It’s often treated with medications or saline solutions. If symptoms persist for more than a week or worsen, consult with a healthcare provider for further evaluation."
      }
    ],
    kidney_problems: [
      {
        answer: "Kidney problems can present with symptoms like swelling, fatigue, and changes in urination. If you're concerned about your kidney health, it’s important to see a healthcare provider who can run tests and recommend treatments if necessary."
      }
    ],
    arthritis: [
      {
        answer: "Arthritis involves inflammation of the joints and can cause pain and stiffness. Treatment can include medications, physical therapy, and lifestyle changes. Consult with a Doxzon healthcare professional for a personalized treatment plan."
      }
    ],
    migraine: [
      {
        answer: "Migraines are intense headaches often accompanied by nausea and sensitivity to light. You can manage migraines with medications, rest, and avoiding triggers. If you experience frequent migraines, it's best to consult a healthcare provider for a more detailed plan."
      }
    ],
    urinary_retention: [
      {
        answer: "Urinary retention can be caused by various issues, including infections, blockages, or nerve problems. It's important to consult with a healthcare provider for an evaluation and proper treatment to avoid complications."
      }
    ],
    eye_infection: [
      {
        answer: "Eye infections can cause symptoms like redness, itching, and discharge. It's important to avoid touching your eyes and to see a healthcare provider for a proper diagnosis and treatment, especially if symptoms persist."
      }
    ],
    tooth_pain: [
      {
        answer: "Tooth pain can be caused by cavities, gum disease, or other dental issues. It's best to see a dentist as soon as possible for an evaluation and treatment to relieve the pain and address the underlying cause."
      }
    ],
    allergy_reaction: [
      {
        answer: "Allergic reactions can range from mild to severe. If you’re experiencing a mild reaction, antihistamines may help. For more severe reactions, such as difficulty breathing or swelling, seek immediate medical attention."
      }
    ],
    menopause: [
      {
        answer: "Menopause marks the end of your menstrual cycles and can bring symptoms like hot flashes and mood swings. It’s important to manage these symptoms with lifestyle changes, medications, or hormone therapy. Consult with a Doxzon healthcare provider for personalized advice."
      }
    ],
    menopause_symptoms: [
      {
        answer: "Symptoms of menopause include hot flashes, night sweats, and mood swings. Managing these symptoms involves lifestyle changes, medications, and sometimes hormone therapy. If you're struggling, it’s a good idea to consult with a healthcare provider for guidance."
      }
    ],
    pregnancy_checkup: [
      {
        answer: "Pregnancy checkups include ultrasounds, blood tests, and screenings to ensure the health of both mother and baby. Regular visits to your healthcare provider are crucial to monitor the progress of the pregnancy. Doxzon healthcare can help guide you through this process."
      }
    ],

    for_providers: [
      {
        answer: "Doxzon provides healthcare providers with advanced tools and a user-friendly interface to deliver high-quality care from anywhere. Join a forward-thinking network to enhance your practice with cutting-edge technology."
      },
      {
        answer: "For providers, Doxzon offers features like electronic health records (EHR), telemedicine capabilities, e-prescription, and a robust appointment scheduling system to revolutionize your practice."
      }
    ],

    telemedicine: [
      {
        answer: "Telemedicine connects patients with healthcare providers through technology for consultations, diagnosis, and treatment, overcoming geographic barriers."
      },
      {
        answer: "Our telemedicine services provide high-definition video calls, allowing patients and healthcare providers to interact face-to-face, ensuring timely care."
      },
      {
        answer: "With Doxzon's telemedicine platform, you can access healthcare services anytime, anywhere, providing convenience and quality care."
      }
    ],

    ehr: [
      {
        answer: "Electronic Health Records (EHR) are a comprehensive digital system for storing and managing patient health information, including medical history, lab results, and treatment plans."
      },
      {
        answer: "With Doxzon, patient records are securely stored and accessible only by authorized personnel. Patients can also view their records and test results online."
      },
      {
        answer: "Our EHR ensures all patient information is centralized, making it easy for healthcare providers to access and update health data seamlessly."
      }
    ],

    for_patients: [
      {
        answer: "Doxzon offers secure and private consultations, easy access to medical records, and the convenience of virtual healthcare tailored to fit your busy lifestyle."
      },
      {
        answer: "With Doxzon, patients can enjoy secure consultations, manage their medical records, and access healthcare services from the comfort of their homes."
      }
    ]
  
  
  
  
  
    },
  };
  