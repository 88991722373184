import React from "react";
import { Line } from "@ant-design/plots";

function VitalTrends() {
  const data = [
    { year: "23/07", value: 3 },
    { year: "24/07", value: 4 },
    { year: "25/07", value: 3.5 },
    { year: "26/07", value: 5 },
    { year: "27/07", value: 4.9 },
    { year: "28/07", value: 6 },
    { year: "29/07", value: 7 },
    { year: "30/07", value: 9 },
    { year: "31/07", value: 13 },
  ];
  const config = {
    data,
    xField: "year",
    yField: "value",
    point: {
      shapeField: "square",
      sizeField: 4,
    },
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    style: {
      lineWidth: 2,
    },
  };
  return (
    <div>
      <Line {...config} />
    </div>
  );
}

export default VitalTrends;
