import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Form, Input, DatePicker, Select, Modal, message } from "antd";
import moment from "moment";
import MedicalService from "../../../Services/MedicalService";
import dayjs from "dayjs";
import { useData } from "../../../Services/Context";

function AddSurgicalHistory(props) {
  const [form] = Form.useForm();
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [surgicalValue, setSurgicalValue] = useState();
  const [manualSurg, setManualSurg] = useState('');
  const { patientLoginData } = useData();
  const InputRef = useRef()
  const patientName = `${activePatient?.salutation || ""}${activePatient?.firstName || ""} ${activePatient?.lastName || ""}`.trim();
  const [disable, setDisable] = useState(false);

  const handleCancel = () => {
    props.onClose();
  };
  const handlehealthChange = (value) => {
    setSurgicalValue(value);
  };

  useEffect(() => {
    if (manualSurg && InputRef.current) InputRef.current.focus();
  }, [manualSurg]);

  const surgicalProcedures = [
    { name: "Cholecystectomy (gall bladder removal)", value: "Cholecystectomy (gall bladder removal)" },
    { name: "Appendectomy (appendix removal)", value: "Appendectomy (appendix removal)" },
    { name: "Vasectomy (vas deferens)", value: "Vasectomy (vas deferens)" },
    { name: "Cardiac surgery (open heart)", value: "Cardiac surgery (open heart)" },
    { name: "Bypass surgery - stent in the heart", value: "Bypass surgery - stent in the heart" },
    { name: "Hysterectomy (uterus removal)", value: "Hysterectomy (uterus removal)" },
    { name: "Hernia surgery", value: "Hernia surgery" },
    { name: "Angioplasty", value: "Angioplasty" },
    { name: "Caesarean section", value: "Caesarean section" },
    { name: "Knee replacement", value: "Knee replacement" },
    { name: "Spine Surgery", value: "Spine Surgery" },
    { name: "Cataract surgery", value: "Cataract surgery" },
    { name: "Laser surgery for retina (eye)", value: "Laser surgery for retina (eye)" },
    { name: "Hip replacement surgery", value: "Hip replacement surgery" },
    { name: "Cancer surgery", value: "Cancer surgery" },
    { name: "Ear surgery", value: "Ear surgery" },
    { name: "Tonsillectomy (tonsil removal)", value: "Tonsillectomy (tonsil removal)" },
    { name: "Others", value: "Others" }
  ];
  
  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const onFinish = (values) => {
    setDisable(true);
    if ( !(surgicalValue || manualSurg) || !values.givenDate) {
      message.error("Please fill all fields");
      setDisable(false);
      return;
    }
   
    let payload = {
      createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      givenDate: dayjs(values.givenDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
      createdBy: patientCode,
      modifiedBy: patientCode,
      status: 1,
      patientId: patientCode,
      surgicalInfo: surgicalValue?surgicalValue:manualSurg,
      description: null,
      patientBloodGroup: patientLoginData?.bloodGroup,
      patientDob: patientLoginData?.dob,
      patientEmail: patientLoginData?.email,
      patientName: patientName,
      account_Id: patientLoginData?.account_Id,
      clinic_Id: patientLoginData?.clinic_Id,
      createdName: patientName,
      modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      fromDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      toDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
    };
    MedicalService.AddSurgicalHistory(payload)
      .then((result) => {
        props.onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSurgSearch = (value) => {
    const isPresent = surgicalProcedures.findIndex((item) => item.name.includes(value));
    if (isPresent === -1) {
      setManualSurg(value);
    }
  }

  const handleManualSurg = (e) => {
    const value = e.target.value;
    if (value) {
      setManualSurg(value);
    }
    else {
      setManualSurg('');
    }
  }

  return (
    <div>
      <Form
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  Surgical History{" "}
                  <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="pastInfo"
            >
               <Select
                style={{ width: "100%" }}
                showSearch
                onSearch={handleSurgSearch}
                onChange={handlehealthChange}
                placeholder="Select"
                // value={surgicalValue}
                options={surgicalProcedures}
                filterOption={(input, option) =>
                  option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                className={`${manualSurg ? 'hidden' : 'block'}`}
                allowClear
              />
               <Input type="text"
                className={`${manualSurg ? 'block' : 'hidden'}`}
                value={manualSurg}
                onChange={(e) => handleManualSurg(e)}
                ref={InputRef}
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item label={<span>Date <span style={{ color: "#FF000F" }}>*</span></span>} name="givenDate">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Select"
                format="MM-DD-YYYY"
                disabledDate={disabledFutureDate}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <div className="flex items-center w-full justify-end my-5">
              <button
                onClick={handleCancel}
                className="border-brand-secondaryColor border rounded text-brand-secondaryColor text-sm px-6 py-1.5"
              >
                Cancel
              </button>
              <button disabled={disable} className="bg-secondaryColor border-brand-secondaryColor border rounded px-5 py-1.5 text-brand-white text-sm ml-3">
                Submit
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default AddSurgicalHistory;
