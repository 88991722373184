import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import plusIcon from "../../../assets/images/Svg/plusIcon.svg";
import AddFamilyHistory from "./AddFamilyHistory";
import MedicalService from "../../../Services/MedicalService";
import dayjs from "dayjs";

function FamilyHistory(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [familyData, setFamliyData] = useState([]);

  const handleFamilyClick = () => {
    setIsModalVisible(true);
  };
  const handleCloseModal = () => {
    setIsModalVisible(false);
    FamilyHistory();
  };

  const FamilyHistory = () => {
    let payload = {
      patientId: patientCode,
    };
    MedicalService.PatientFamilyHistory(payload)
    .then((result) => {
      setFamliyData(result.data);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  useEffect(() => {
    FamilyHistory();
  }, []);

  return (
    <div>
      <div className="card h-[300px] m-5 border rounded-lg">
        <div className="header h-[15%] bg-secondaryColor flex items-center justify-between  rounded-lg p-2">
          <h1 className="font-bold text-normal text-white">Family History</h1>
          <img
            src={plusIcon}
            onClick={() => handleFamilyClick()}
            className="h-10 w-10 cursor-pointer"
            alt=""
          />
        </div>
        <div className="body h-[70%] overflow-y-auto  bg-brand-lightBg p-2" id="unquie-scroll">
        
          {familyData.length > 0
            ? familyData.map((user, i) => (
                <div>
                  <div key={i} className="items-center mt-3 font-light text-sm">
                    <div className="font-medium text-black">Family History : <span className="font-medium text-gray-500">{user?.description}</span></div>
                    <div className="mt-2">
                      <span className="font-medium text-gray-500"> Onset date :
                      {dayjs(user.givenDate).format('YYYY-MM-DD')}</span>                   
                    </div>
                  </div>
                  <hr className="my-4 border-gray-300" />
                </div>
              ))
            : null}
        </div>
        <div className="footer h-[10%]  bg-secondaryColor rounded-md"></div>
      </div>

      {isModalVisible && (
        <Modal
          title={<span className="text-base font-bold font-sans">Add Family History</span>}
          visible={isModalVisible}
          onOk={() => handleCloseModal()}
          centered={true}
          footer={null}
          onCancel={() => handleCloseModal()}
          okText="Submit"
          cancelText="Cancel"
          width={500}
          maskClosable={false}
        >
          <AddFamilyHistory onClose={() => handleCloseModal()} />
        </Modal>
      )}
    </div>
  );
}

export default FamilyHistory;
