import React from "react";

function Clinic() {
  return (
    <div className="clinic py-20">
      <div className="w-[90%] mx-auto">
      <div className="text-center relative z-2">
        <h1 className="headingFont text-[#002f72] sm:text-[30px] md:text-[40px] lg:text-[40px] x:text-[50px]  xl:text-[50px] 2xl:text-[60px]">
          Elevate Your Clinic to New Heights!
        </h1>
        <p className="w-[70%] mx-auto md:text-[18px] lg:text-[14px] x:text-[17px] xl:text-[17px] 2xl:text-[21px] text-[#666]">
          Enhance your clinic's efficiency and patient care with our advanced
          telemedicine solutions. Streamline health records, clinic management,
          and appointment scheduling seamlessly.
        </p>
      </div>

      <div className="flex sm:flex-col sm:mx-0 sm:gap-y-7 mx-5 w-full gap-x-7 mt-10">

        <div className="flex flex-col w-[50%] sm:w-[100%] sm:gap-y-7">
            <div className="healthRecords w-full 2xl:h-[250px] xl:h-[200px] rounded-2xl sm:p-5 md:p-5 2xl:p-10 x:p-7 xl:p-7 lg:p-5 2xl:mb-[54px] x:mb-[30px] xl:mb-[30px] lg:mb-[25px] md:mb-[20px] relative">
              <div className="healthDesc">
              <h1 className="headingFont 2xl:text-[25px] x:text-[20px] xl:text-[20px] lg:text-[17px] md:text-[16px] sm:text-[17px] text-[#002f72]">Health Records</h1>
              <p className="w-[72%] text-[#666] 2xl:text-[20px] x:text-[16px] xl:text-[16px] lg:text-[14px] md:text-[14px] sm:text-[14px]">Streamline patient care with our comprehensive Health Records feature. Easily access, update, and manage patient medical histories, lab results, and treatment plans in a secure, centralized system.</p>
              </div>
            </div>

            <div className="clinicRecords w-full 2xl:h-[250px] xl:h-[200px] rounded-2xl 2xl:p-8 sm:p-5 md:p-5 x:p-7 xl:p-7 lg:p-5 relative">
              <div className="clinicDesc">
              <h1 className="headingFont 2xl:text-[25px] x:text-[20px] xl:text-[20px] md:text-[16px] text-[#002f72] lg:text-[17px] sm:text-[17px]">Clinical Records</h1>
              <p className="w-[72%] text-[#666] 2xl:text-[20px] x:text-[16px] xl:text-[16px] lg:text-[14px] md:text-[14px]  sm:text-[14px]">Maintain a well-organized and efficient clinic with our robust Clinic Records management system. Track clinic operations, including inventory, staff schedules, and billing information, all in one place.</p>
              </div>
            </div>
        </div>
          <div className="appointments w-1/2 sm:w-[100%] mr-10">
          <div className="appointRecords w-full h-full rounded-2xl 2xl:p-10 x:p-7 xl:p-7 lg:p-5 sm:p-5 md:p-5 relative">
              <div className="appointDesc">
              <h1 className="headingFont 2xl:text-[25px] x:text-[20px] xl:text-[20px] lg:text-[17px] sm:text-[17px] text-[#002f72]">Appointments</h1>
              <p className=" text-[#666] 2xl:text-[20px] x:text-[16px] xl:text-[16px] md:text-[14px] lg:text-[14px]  sm:text-[14px]">Optimize your clinic’s appointment scheduling with our intuitive Appointments feature. Allow patients to book, reschedule, or cancel appointments online with ease.</p>
              </div>
            </div>
          </div>
      </div>
      </div>
    </div>
  );
}

export default Clinic;
