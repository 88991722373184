import { Table } from "antd";
import React, { useEffect, useState } from "react";
import VitalService from "../../../Services/VitalService";
import moment from "moment";

function ViewVital(props) {
  const [vitalsData, setVitalsData] = useState([]);
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const vitalName = props.vitalName;
  const [vitalList, setVitalList] = useState([]);

  const vitalalldata = async (value) => {
    let payload = {
      patientId: patientCode,
      status: 1,
    };
    await VitalService.getVitalDateTime(payload)
      .then((result) => {
        setVitalList(result.data);
        if (result.data && result.data.length > 0) {
          const lastDate = result.data[0]?.recordedDateTime; 
          const firstDate = result.data[result.data.length - 1]?.recordedDateTime; 
          MyVitalList(firstDate,lastDate);
        }  
      })
      .catch((error) => {
        console.error("Failed to fetch reports:", error);
        setVitalList([]);
      });
  };

  const MyVitalList = (fromDate, toDate) => {
    let payload = {
      patientId: patientCode,
      vitalCodes: props.selectedVitalCode,
      fromDate: fromDate,
      toDate: toDate,
    };
    VitalService.getVitalsData(payload)
      .then((result) => {
        const data = result.data;
        // Process the data to combine systolic and diastolic values
        const combinedData = [];
        const groupedByDate = {};

        data.forEach((record) => {
          const date = moment(record.createdDate).format("YYYY-MM-DD");
          if (!groupedByDate[date]) {
            groupedByDate[date] = {};
          }
          groupedByDate[date][record.vitalCode] = record.value;
        });

        for (const date in groupedByDate) {
          combinedData.push({
            date,
            bloodPressure: `${groupedByDate[date]["BPS"]}/${groupedByDate[date]["BPD"]} mmHg`,
          });
        }
        const last7Records = result?.data?.slice(0, 6);
        setVitalsData(last7Records);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    // MyVitalList();
    vitalalldata();
  }, []);

  const columns = [
    {
      title: "Date",
      dataIndex: "createdDate",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: vitalName,
      key: "vitalValue",
      render: (text, record) => {
        // Find the corresponding diastolic value for the same date
        const diastolicRecord = vitalsData.find(
          (item) =>
            item.createdDate === record.createdDate &&
            item.vitalCode === "BPD" &&
            item.vitalCode !== record.vitalCode
        );

        if (record.vitalCode === "BPS" && diastolicRecord) {
          return `${record.value}/${diastolicRecord.value} ${record.units}`;
        }

        // If it's a diastolic value or no corresponding systolic value, return the value as is
        return record.vitalCode === "BPD"
          ? null
          : `${record.value} ${record.units}`;
      },
    },
  ];
  const filteredVitalsData = vitalsData.filter(
    (record) => record.vitalCode !== "BPD"
  );

  return (
    <div>
      <Table
        dataSource={filteredVitalsData}
        columns={columns}
        pagination={false}
      />
    </div>
  );
}

export default ViewVital;
