import React, { useEffect, useState } from "react";
import Layouts from "../../Layout";
import { Button, Modal, Space, Select, Spin } from "antd";
import Slider from './SliderVitals'
import AppointmentService from '../../Services/AppointmentService';
import MedicalService from '../../Services/MedicalService';
import ChronicConditionService from '../../Services/ChronicConditionService';
import dayjs from "dayjs";
import TrendIcon from '../../assets/images/trendsicon.png';
import VitalChart from "../MedicalRecords/MyVitals/VitalChart";
import AddVitals from "../MedicalRecords/MyVitals/AddVitals";
import VitalService from "../../Services/VitalService";
import VitalChartBoard from "./VitalChartBoard";
import { APP_ROUTES } from "../../PageRouting/pageRoutes";
import moment from "moment";
import { useNavigate } from "react-router";
import { LoadingOutlined } from "@ant-design/icons";
import AuthService from "../../Services/AuthService";
import LabService from "../../Services/LabService";

const Dashboard = (props) => {
  const navigate = useNavigate();
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [appointmentData, setAppoinmentData] = useState([]);
  const filterValues = props.filterValues;
  const [chronicConditions, setChronicConditions] = useState([]);
  const [pastHistoryData, setPastHistoryData] = useState([]);
  const [patientAlleryData, setPatientAlleryData] = useState([]);
  const [surgicalHistoryData, setSuricalHistoryData] = useState([]);
  const [familyData, setFamliyData] = useState([]);
  const [socialHistoryData, setSocialHistoryData] = useState([]);
  const [medicationData, setMedication] = useState([]);
  const [selectedOption, setSelectedOption] = useState("A");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [trendOpen, setTrendOpen] = useState(false);
  const [VitalOpen, setVitalOpen] = useState(false);
  const [viewGraph, setViewGraph] = useState(false);
  const [selectedVitalType, setSelectedVitalType] = useState(null);
  const [selectedVitalData, setSelectedVitalData] = useState([]);
  const [vitalCode, setVitalCode] = useState(null);
  const [selectedVitalCode, setSelectedVitalCode] = useState("");
  const [vitalsData, setVitalsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [prescriptionsList, setPrescriptionsList] = useState([]);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
  const [labData, setLabData] = useState([]);

  const fetchAllPrescriptions = async () => {

    try {
      let payload = {
        patientId: patientCode,
        detailsYN: "N",
        status: 2,
      };

      const response = await AuthService.getMyPrescriptionView(payload);
      console.log(response.data);
      if(response.data.length > 4){
        setPrescriptionsList(response.data.splice(0,4));
      }
      else{
        setPrescriptionsList(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const showmodal1 = () => {
    setTrendOpen(true)
  }
  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModal2 = () => {
    setVitalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false);
    setTrendOpen(false)
    setVitalOpen(false)
  };

  const handleCancel = (code) => {
    setIsModalOpen(false);
    setTrendOpen(false)
    setVitalOpen(false)
    MyVitalList();
  };

  const handleGraph = (type, code) => {
    setSelectedVitalType(type);
    setVitalCode(code);
    if (code) {
      setViewGraph(true);
    }
  };

  const Appoinment = () => {
    // if (filterValues) {
    //   filterValues.fromDate = dayjs(filterValues.fromDate, "MM/DD/YYYY").format(
    //     "MM/DD/YYYY"
    //   );
    //   filterValues.toDate = dayjs(filterValues.toDate, "MM/DD/YYYY").format(
    //     "MM/DD/YYYY"
    //   );
    // }
    const formattedDate = new Date().toLocaleDateString('en-US');
    let payload = {
      patientId: patientCode,
      status: 1,
      fromDate: formattedDate,
      toDate: formattedDate
    };
    // if (filterValues) {
    //   payload = {
    //     ...payload,
    //     fromDate: filterValues.fromDate,
    //     toDate: filterValues.toDate,
    //   };
    // }
    AppointmentService.getPatientAppointment(payload)
      .then((result) => {
        setAppoinmentData(result.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  const MyVitalList = async () => {
    try {
      setLoading(true);
      const result = await VitalService.getVitalsRecentData(patientCode);
      setVitalsData(result.data);
      // props.onClose(result.data)
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    finally {
      setLoading(false);
    }
  };

  const PatientchronicConditions = () => {
    let payload = {
      patientId: patientCode,
    };
    ChronicConditionService.PatientchronicConditions(payload)
      .then((result) => {
        setChronicConditions(result.data)
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const PastHistory = () => {
    let payload = {
      patientId: patientCode,
      status: 1,
    };
    MedicalService.PastMedicalHistory(payload)
      .then((result) => {
        setPastHistoryData(result.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const PatientAllergies = () => {
    let payload = {
      patientCode: patientCode,
      status: 1,
    };
    MedicalService.PatientAllergiesList(payload)
      .then((result) => {
        setPatientAlleryData(result.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const SurgicalHistory = () => {
    let payload = {
      patientId: patientCode,
      status: 1,
    };
    MedicalService.PatientSurgicalHistory(payload)
      .then((result) => {
        setSuricalHistoryData(result.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const FamilyHistory = () => {
    let payload = {
      patientId: patientCode,
    };
    MedicalService.PatientFamilyHistory(payload)
      .then((result) => {
        setFamliyData(result.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const SocialHistory = () => {
    let payload = {
      patientId: patientCode,
      status: 1,
    };
    MedicalService.PatientSocialHistory(payload)
      .then((result) => {
        setSocialHistoryData(result.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Medication = () => {
    let payload = {
      patientCode: patientCode,
      status: 1,
    };
    MedicalService.PatientMedication(payload)
      .then((result) => {
        setMedication(result.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const labReport = () => {
    let payload = {
      status: 1,
      patientId: patientCode,
    };
    LabService.getLabReportData(payload)
      .then((result) => {
        setLabData(result.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    MyVitalList();
    PatientchronicConditions();
    PastHistory();
    PatientAllergies();
    SurgicalHistory();
    FamilyHistory();
    SocialHistory();
    Medication();
    fetchAllPrescriptions();
    labReport();
  }, []);

  useEffect(() => {
    Appoinment();
  }, [filterValues]);

  const getInitials = (name) => {
    if (!name) return "";
    name = name.replace(/^Mr\.?\s*/, "").trim();
    const initials = name.slice(0, 1).toUpperCase();
    return initials;
  };

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  const handleClick = async (details) => {
    const currentDate = new Date();
    const formattedDate = convertUTCDate(currentDate, "YYYY-MM-DD HH:mm:ss");

    try {
      let payload1 = {
        appointmentId: details.appointmentId,
        modifiedBy: details.userId,
        modifiedDate: formattedDate,
        patientId: details.patientId,
        queueStatus: "Y",
        userId: details.userId,
        joiningDateTime: formattedDate,
      };
      await AppointmentService.callQueueUpdate(payload1);
      navigate(APP_ROUTES.VIDEOCALL, {
        state: { selectedAppDetails: details },
      });

    } catch (error) {
      console.error("Error updating appointment or queue:", error);
    }
  };

  const handleRoute = (route) => {
    navigate(route);
  }

  const formatDate = (dateTime) => {
    const date = new Date(dateTime);
    return date.toISOString().split("T")[0];
  };

  console.log('labData',labData);
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
            width: "100%",
          }}
        >
          <Spin indicator={loadingIcon} size="large" />
        </div>
      ) : vitalsData && vitalsData.length > 0 ? (
        <div>
          <div className="flex MainContent-page p-2">
            <div className="w-2/3 py-4 px-2 firstvital">
              <div className="flex justify-between items-center">
                <h2 className="font-bold text-lg">Vital Trends</h2>
                <div className="flex items-center gap-2">
                  <img src={TrendIcon} onClick={showmodal1} style={{ width: "28px" }} className="cursor-pointer" />
                  <Button className="btn" style={{ backgroundColor: "#4096ff", color: "#FFF" }} onClick={showModal2}>Add</Button>
                </div>
              </div>
              <Slider vitalsData={vitalsData} handleGraph={handleGraph} setViewGraph={setViewGraph} />
            </div>
            <div className="table-container w-1/3 p-4 ml-2">
              <h2 className="font-bold"><p style={{ marginBottom: "12px" }}>Upcoming Appointments</p></h2>

              <div>
                <table className="w-full text-sm text-left rtl:text-right">
                  <thead className="table-header text-xs">
                    <tr>
                      <th scope="col" className="px-4 py-3" style={{ color: "gray" }}>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      </th>
                      <th scope="col" className="px-4 py-3" style={{ color: "gray" }}>
                        Patient Name
                      </th>
                      <th scope="col" className="px-4 py-3" style={{ color: "gray" }}>
                        Appointment Status
                      </th>
                    </tr>
                  </thead>
                </table>
                <div style={{ maxHeight: '18rem', overflowY: 'auto', scrollbarWidth: "1rem" }} id="unquie-scroll">
                  <table className="w-full text-sm text-left rtl:text-right" >
                    <tbody>
                      {appointmentData.length > 0 ? (
                        appointmentData.map((x, index) =>
                          x.paymentConsultation === "BC" && !x.transactionId ? null : (
                            <tr
                              style={{ border: "none" }}
                              key={index}
                              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                            >
                              <td className="px-4 py-4" style={{ fontSize: "small" }}>
                                <div className="h-10 w-10 rounded-full flex items-center justify-center text-white bg-blue-500">
                                  <span>{getInitials(x.patientName)}</span>
                                </div>
                              </td>
                              <td className="px-4 py-4" style={{ fontSize: "small" }}>
                                <div className="patientName" style={{ color: "" }}>
                                  {x.patientName}
                                </div>
                                <div className="appointmentReason" style={{ color: "gray" }}>
                                  {x.appointmentReason}
                                </div>
                              </td>
                              <td className="px-4 py-4">
                                <Space size="middle" className="">
                                  {/* Conditional rendering of the button */}
                                  {x.status === 1 &&
                                    (
                                      ((x.paymentConsultation === "BC" && x.transactionId) || x.paymentConsultation === "AC") &&
                                      !(x.paymentConsultation === "BC" && !x.transactionId)
                                    ) && (
                                      <button
                                        onClick={() => handleClick(x)}
                                        className="p-2 w-20 rounded-md text-white bg-green-500 flex items-center justify-center">
                                        Join Call
                                      </button>
                                    )}
                                </Space>
                              </td>
                            </tr>
                          ))
                      ) : (
                        <div className="flex justify-center items-center h-[40vh]">
                          <p className="text-base">
                            No Upcoming Appointments
                          </p>
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex gap-2 bg-[#f3f4f6] p-2">
            <div className="w-[66.5%] flex gap-2">
              <div className="w-[48%] bg-White rounded-lg p-2">
                <h2 className="medicalhistrory-header font-bold p-2" >My Prescriptions</h2>
                <div className="flex flex-col gap-3 max-h-[18rem] overflow-y-auto mt-3 p-2" id="unquie-scroll">
                  {
                    prescriptionsList?.map((presc) => (
                      <div className="shadow-lg border border-borderColor rounded-lg p-1">
                        <div className="grid grid-cols-2 gap-2">
                          <p className="font-medium text-sm text-textGray">Date  : {presc.consultationDate.slice(0,10)}</p>
                          <p className="font-medium text-sm text-textGray">Appointment Id :  {presc.appointmentId}</p>
                          <p className="font-medium text-sm text-textGray">Doctor : {presc.userName}</p>
                          <p className="font-medium text-sm text-textGray">Reason : {presc.consultationReason}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>
                <p className="text-center font-medium text-lg text-iconBlue cursor-pointer my-2" onClick={() => handleRoute(APP_ROUTES.MYPRESCRIPTION)}>View More</p>
              </div>
              <div className="w-2/4 bg-White rounded-lg p-2">
                <h2 className="medicalhistrory-header font-bold p-2" >My Lab Reports</h2>
                <div className="flex flex-col gap-3 max-h-[20rem] overflow-y-auto scroll mt-3 p-2" id="unquie-scroll">
                  {
                    labData?.map((lab) => (
                      <div className="shadow-lg border border-borderColor rounded-lg p-1">
                        <div className="grid grid-cols-2 gap-2">
                          <p className="font-medium text-sm text-textGray">Date  : {formatDate(lab.uploadedDateTime)}</p>
                          <p className="font-medium text-sm text-textGray">Lab OrderId :  {lab.labTestOrderId}</p>
                          <p className="font-medium text-sm text-textGray">Lab Test : {lab.labTestName}</p>
                          <p className="font-medium text-sm text-textGray">Diagnostics Name	 : {lab.labName}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>
                <p className="text-center font-medium text-lg text-iconBlue cursor-pointer my-2" onClick={() => handleRoute(APP_ROUTES.MYLABREPORTS)}>View More</p>
              </div>

            </div>
            <div className="w-[31.5%] bg-White p-4 rounded-lg mx-2">
              <div className="flex justify-between" >
                <h2 className="medicalhistrory-header font-bold" >Medical History</h2>
                <div className="flex items-center">
                  <Select style={{ width: "11rem" }}
                    defaultValue="A"
                    placeholder="Select"
                    onChange={handleChange}
                    options={[
                      {
                        value: "A",
                        label: "Chronic Conditions",
                      },
                      {
                        value: "B",
                        label: "Past Medical History",
                      },
                      {
                        value: "C",
                        label: "Allergy",
                      },
                      {
                        value: "D",
                        label: "Surgical History",
                      },
                      {
                        value: "E",
                        label: "Family History",
                      },
                      {
                        value: "F",
                        label: "Social History",
                      },
                      {
                        value: "G",
                        label: "Medications",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="medicalhistory p-2 font-medium text-base border border-gray-300 rounded-md" style={{ maxHeight: '18rem', overflowY: 'auto', scrollbarWidth: "1rem" }} id="unquie-scroll">
                {selectedOption === 'A' ? (
                  <h4 className="OptionA p-2 border border-gray-300 rounded-md">Chronic Conditions</h4>
                ) : null}
                <div>
                  {selectedOption === "A" && chronicConditions.length > 0 ? (
                    chronicConditions.map((user, i) => (
                      <>
                        <div key={i} className="items-center mx-2 mt-3 font-light text-sm border-b" >
                          <div className="font-medium text-black">
                            <span className="font-medium text-gray-500" style={{ fontSize: "small" }}>{user.chronicDescription}</span>
                          </div>
                          <div className="font-medium text-gray-500" style={{ fontSize: "small" }}>
                            Check-up date: {dayjs(user.givenDate).format('YYYY-MM-DD')}
                          </div>
                        </div>
                      </>

                    ))
                  ) : null}{" "}
                </div>
                {selectedOption === 'B' ? (
                  <h4 className="OptionA p-2 border border-gray-300 rounded-md">Past Medical History</h4>
                ) : null}
                <div>
                  {selectedOption === "B" && pastHistoryData.length > 0 ? (
                    pastHistoryData.map((user, i) => (
                      <div>
                        <div
                          key={i}
                          className="flex mx-2 justify-between text-sm items-center mt-3 border-b"
                        >
                          <div className="">
                            <span className="font-medium text-gray-500" style={{ fontSize: "small" }}>{user.pastInfo}</span>
                          </div>
                          <div className="ml-auto text-gray-500" style={{ fontSize: "small" }}>
                            check up date :{" "}
                            {dayjs(user.givenDate).format('YYYY-MM-DD')}
                          </div>
                        </div>
                        <div className="my-4" />
                      </div>
                    ))
                  ) : null}{" "}
                </div>
                {selectedOption === 'C' ? (
                  <h4 className="OptionA p-2 border border-gray-300 rounded-md">Allergy</h4>
                ) : null}
                <div>
                  {selectedOption === "C" && patientAlleryData.length > 0 ? (
                    patientAlleryData.map((user, i) => (
                      <div key={i} className="mt-3  border-b">
                        <div className="text-gray-500 font-light text-sm">
                          <span className="font-medium text-gray relative list-dec-before pl-3" style={{ fontSize: "small" }}>
                            Drug Allergies :
                          </span>{" "}
                          <span className="font-medium text-gray-500" style={{ fontSize: "small" }}>
                            {" "}
                            {user.drugAllergy}
                          </span>
                        </div>
                        <div className="text-gray-500  font-light text-sm ">
                          <span className="font-medium text-gray relative list-dec-before pl-3" style={{ fontSize: "small" }}>
                            Food Allergies :
                          </span>{" "}
                          <span className="font-medium text-gray-500" style={{ fontSize: "small" }}>
                            {" "}
                            {user.foodAllergy}{" "}
                          </span>
                        </div>
                        <div className="text-gray-500 text-gray  font-light text-sm">
                          <span className="font-medium text-gray relative list-dec-before pl-3" style={{ fontSize: "small" }}>
                            Chemical Allergies :
                          </span>{" "}
                          <span className="font-medium text-gray-500" style={{ fontSize: "small" }}>
                            {" "}
                            {user.chemicalAllergy}
                          </span>
                        </div>
                        <div className="text-gray-500 text-gray  font-light text-sm">
                          <span className="font-medium text-gray relative list-dec-before pl-3" style={{ fontSize: "small" }}>
                            Other Allergies :
                          </span>{" "}
                          <span className="font-medium text-gray-500" style={{ fontSize: "small" }}>
                            {" "}
                            {user.otherAllergy}
                          </span>
                        </div>
                        <div className="flex ml-3 mt-1">
                          <div className="text-gray-400  font-medium text-sm" style={{ fontSize: "small" }}>
                            check up date :{" "}
                            {dayjs(user.givenDate).format('YYYY-MM-DD')}
                          </div>
                        </div>
                        {i !== patientAlleryData.length - 1 && (
                          <div className="my-4" />
                        )}
                      </div>
                    ))
                  ) : null}{" "}
                </div>
                {selectedOption === 'D' ? (
                  <h4 className="OptionA p-2 border border-gray-300 rounded-md">Surgical History</h4>
                ) : null}
                <div>
                  {selectedOption === "D" && surgicalHistoryData.length > 0
                    ? surgicalHistoryData.map((user, i) => (
                      <div>
                        <div key={i} className="items-center mx-2 text-sm mt-3 border-b">

                          <span className="font-medium text-gray-500" style={{ fontSize: "small" }}>
                            {user.surgicalInfo}
                          </span>

                          <div className="ml-auto text-gray-500" style={{ fontSize: "small" }}>
                            check up date :{" "}
                            {dayjs(user.givenDate).format("YYYY-MM-DD")}
                          </div>
                        </div>
                        <div className="my-4" />
                      </div>
                    ))
                    : null}{" "}
                </div>
                {selectedOption === 'E' ? (
                  <h4 className="OptionA p-2 border border-gray-300 rounded-md">Famliy History</h4>
                ) : null}
                <div>
                  {selectedOption === "E" && familyData.length > 0
                    ? familyData.map((user, i) => (
                      <div>
                        <div key={i} className="items-center mt-3 mx-2 font-light text-sm border-b">
                          <span className="font-medium text-gray-500" style={{ fontSize: "small" }}>{user?.description}</span>
                          <div className="font-medium text-gray-500" style={{ fontSize: "small" }}>
                            check up date :{" "}
                            {dayjs(user.givenDate).format('YYYY-MM-DD')}
                          </div>
                        </div>
                        <div className="my-4" />
                      </div>
                    ))
                    : null}{" "}
                </div>
                {selectedOption === 'F' ? (
                  <h4 className="OptionA p-2 border border-gray-300 rounded-md">Social History</h4>
                ) : null}
                <div>
                  {selectedOption === "F" && socialHistoryData.length > 0
                    ? socialHistoryData.map((user, i) => (
                      <div className="border-b">
                        <div key={i} className="items-center mt-3 mx-2 p-2 text-sm">
                          <h4 className="font-medium text-black"> Social History : <span className="font-medium text-gray-500">{user.description}</span> </h4>
                          <h4 className="font-medium text-black"> Occupation : <span className="font-medium text-gray-500">{user.occupation}</span></h4>
                          <h4 className="font-medium text-black"> Marital Status : <span className="font-medium text-gray-500">{user.maritalStatus}</span></h4>
                          <h4 className="font-medium text-black"> Social relations with family: <span className="font-medium text-gray-500">{user.familyRelation}</span></h4>
                          <h4 className="font-medium text-black"> Social relations with friends:  <span className="font-medium text-gray-500">{user.friendsRelation}</span> </h4>
                          <h4 className="font-medium text-black"> Social relations with community:   <span className="font-medium text-gray-500">{user.communityRelation}</span> </h4>
                          <h4 className="font-medium text-black"> Social relations with people at work :  <span className="font-medium text-gray-500">{user.colleagueRelation}</span> </h4>
                        </div>
                        <div className="ml-2 text-gray-500" style={{ fontSize: "small" }}>
                          check up date :{" "}
                          {dayjs(user.givenDate).format('YYYY-MM-DD')}
                        </div>
                        <div className="my-4" />
                      </div>
                    ))
                    : null}{" "}
                </div>
                {selectedOption === 'G' ? (
                  <h4 className="OptionA p-2 border border-gray-300 rounded-md">Medications</h4>
                ) : null}
                <div>
                  {selectedOption === "G" && medicationData.length > 0
                    ? medicationData.map((user, i) => (
                      <div>
                        <div key={i} className="items-center mt-3 mx-2 text-sm border-b">
                          <p className="font-medium text-gray" style={{ fontSize: "small" }}>Drug Name : <span className="font-medium text-gray-500" style={{ fontSize: "small" }}>{user.drugName}</span></p>
                          <p className="font-medium text-gray" style={{ fontSize: "small" }}>Dosage : <span className="font-medium text-gray-500" style={{ fontSize: "small" }}>{user.dosage} </span></p>
                          <p className="font-medium text-gray" style={{ fontSize: "small" }}>Frequency : <span className="font-medium text-gray-500" style={{ fontSize: "small" }}>{user.frequency}</span> </p>
                          <p className="font-medium text-gray" style={{ fontSize: "small" }}>Duration : <span className="font-medium text-gray-500" style={{ fontSize: "small" }}>{user.duration}</span> </p>
                          <p className="font-medium text-gray" style={{ fontSize: "small" }}>Indication : <span className="font-medium text-gray-500" style={{ fontSize: "small" }}>{user.indication}</span> </p>
                          <p className="font-medium text-gray" style={{ fontSize: "small" }}>Side Effects : <span className="font-medium text-gray-500" style={{ fontSize: "small" }}>{user.sideEffects}</span> </p>
                          <p className="font-medium text-gray" style={{ fontSize: "small" }}>Composition : <span className="font-medium text-gray-500" style={{ fontSize: "small" }}>{user.compliance}</span> </p>
                          <p className="font-medium text-gray" style={{ fontSize: "small" }}>Adverse Effects : <span className="font-medium text-gray-500" style={{ fontSize: "small" }}>{user.adverseEffects}</span> </p>
                          <p className="font-medium text-gray" style={{ fontSize: "small" }}>
                            Advice related to medicines : {user.adviceRelatedMedicine}{" "}
                          </p>

                          <p className="text-gray-500">
                            check up date :{" "}
                            {dayjs(user.givenDate).format('YYYY-MM-DD')}
                          </p>
                        </div>
                        <div className="my-4" />
                      </div>
                    ))
                    : null}{" "}
                </div>
              </div>
            </div>
          </div>


          {trendOpen && (
            <VitalChartBoard
              title={`${selectedVitalType} Graph`}
              data={selectedVitalCode}
              type={selectedVitalType}
              vitalCode={vitalCode}
              closePopup={() => setTrendOpen(false)}
            />
          )}
          {viewGraph && (
            <VitalChart
              title={`${selectedVitalType} Graph`}
              data={selectedVitalCode}
              type={selectedVitalType}
              vitalCode={vitalCode}
              closePopup={() => setViewGraph(false)}
            />
          )}
          {VitalOpen && (
            <Modal
              title="Add Vitals"
              visible={VitalOpen}
              onOk={() => {
                setVitalOpen(false);
              }}
              centered={true}
              footer={null}
              onCancel={() => {
                console.log("Cancel button clicked");
                setVitalOpen(false);
              }}
              okText="Submit"
              cancelText="Cancel"
              width={600}
              maskClosable={false}
            >
              <AddVitals onClose={() => handleCancel()} />
            </Modal>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
            borderRadius: "8px",
            color: "#595959",
            height: "200px",
            textAlign: "center",
            width: "100%",
          }}
        >
          {/* <p style={{ fontSize: "18px", fontWeight: "bold" }}>
            No Vitals found
          </p> */}
        </div>
      )}
    </>
  );
};

export default Dashboard;
