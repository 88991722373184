import React, { useEffect, useState } from "react";
import FilterIcon from "../../../assets/images/Svg/FilterIcon.svg"
import { Button, Col, Modal, Row, Spin } from "antd";
import AuthService from "../../../Services/AuthService";
import AddReport from "./AddReport";
import FilterReport from "./FilterReport";
import dayjs from "dayjs";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { IoMdAdd } from "react-icons/io";
import Layout from "../../../Layout"

const MyReports = () => {
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [myReport, setMyReport] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewDoc, setViewDoc] = useState(null);
  const [pdfView, setpdfView] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [filterValues, setFilterValues] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  const showModal1 = () => {
    if (filterValues) {
      setFilterValues(null); // Clear filter if already applied
    } else {
      setIsModalOpen1(true); // Open modal if no filter is applied
    }
  };

  const handleReportClick = () => {
    setIsModalVisible(true);
  };
  const handleCloseModal = (values) => {
    setIsModalVisible(false);
    setFilterValues(values);
    setIsModalOpen1(false);
    PatientReport();
  };

  const handleViewClick = (record) => {
    window.open(process.env.REACT_APP_IMAGE_URL + record?.documentName);
    // setViewDoc(record);
    // setpdfView(true);
  };

  const PatientReport = (values) => {
    setIsLoading(true);
    if (filterValues) {
      filterValues.fromDate = dayjs(filterValues.fromDate, "MM/DD/YYYY").format(
        "MM/DD/YYYY"
      );
      filterValues.toDate = dayjs(filterValues.toDate, "MM/DD/YYYY").format(
        "MM/DD/YYYY"
      );
    }

    let payload = {
      patientId: patientCode,
      // documentType: "MYREPORT",
      status: 1,
    };
    if (filterValues) {
      payload = {
        ...payload,
        fromDate: filterValues.fromDate,
        toDate: filterValues.toDate,
      };
    }
    AuthService.getMyReport(payload)
      .then((result) => {
        setMyReport(result.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    PatientReport();
  }, [filterValues]);

  return (
    <Layout>
      <div className="p-4">
        <div className="flex justify-between ">
          <div className="font-bold text-xl">My Reports</div>
          <div className="flex  gap-3">
            <button
              onClick={handleReportClick}
              className="rounded-lg h-9 px-2 flex justify-center text-white items-center gap-2 custom_filter"
            >
              <IoMdAdd />
              New
            </button>

            <Button
              className="w-20 h-9 px-2 flex items-center gap-2 custom_filter"
              onClick={showModal1}
            >
              {filterValues ? (
                <div className="flex items-center">
                  <span className="text-white mx-1">Clear</span>
                  <CloseOutlined
                    style={{ color: "white", marginLeft: "4px" }}
                  />
                </div>
              ) : (
                <div className="flex items-center">
                  <span>
                    <img src={FilterIcon} alt="Icon" />
                  </span>
                  <span className="text-white ml-2">Filter</span>
                </div>
              )}
            </Button>
          </div>
        </div>
        <div className="my-6">
          <Row gutter={24} className="">
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60vh", 
                  width: "100%",
                }}
              >
                <Spin indicator={loadingIcon} size="large" />
              </div>
            ) : myReport.length > 0 ? (
              myReport.map((user, i) => (
                <Col key={i} xs={24} sm={24} md={12}>
                  <div className="border shadow-lg p-3 rounded-lg my-2 bg-white">
                    <div className="flex justify-between">
                      <div className="flex flex-col gap-1">
                        <label className="font-bold text-black">Date</label>
                        <p>{user.documentDate}</p>
                      </div>
                      <div className="flex flex-col gap-1">
                        <label className="font-bold text-black">Title</label>
                        <p>{user?.title}</p>
                      </div>
                      <div className="flex flex-col gap-1">
                        <label className="font-bold text-black">Document Type</label>
                        <p>{user?.documentType}</p>
                      </div>
                    </div>
                    <div
                      className="underline cursor-pointer text-brand-primaryColor my-2"
                      onClick={() => handleViewClick(user)}
                    >
                      View Document
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '20px',
                borderRadius: '8px',
                color: '#595959',
                height: '200px',
                textAlign: 'center',
                width: '100%',
              }}
            >
              <p style={{ fontSize: '18px', fontWeight: 'bold' }}>No reports found</p>
            </div>
            )}
          </Row>
        </div>
      </div>

      {isModalVisible && (
        <Modal
        title={<span className="text-lg font-semibold font-sans">Add Report</span>}
        visible={isModalVisible}
          onOk={() => handleCloseModal()}
          centered={true}
          footer={null}
          onCancel={() => handleCloseModal()}
          okText="Submit"
          cancelText="Cancel"
          width={500}
          maskClosable={false}
        >
          <AddReport onClose={() => handleCloseModal()} />
        </Modal>
      )}

      {pdfView && (
        <Modal
          visible={pdfView}
          onOk={() => {
            setpdfView(false);
          }}
          centered={true}
          footer={null}
          onCancel={() => {
            console.log("Cancel button clicked");
            setpdfView(false);
          }}
          okText="Submit"
          cancelText="Cancel"
          maskClosable={false}

          //   width={800}
        >
          {/* <embed
            src={viewDoc}
            type="application/pdf"
            width="100%"
            height="500px"
          /> */}

          {viewDoc?.documentName.endsWith(".jpg") ||
          viewDoc?.documentName.endsWith(".jpeg") ||
          viewDoc?.documentName.endsWith(".png") ? (
            <div className="flex justify-center items-center h-[inherit] w-[inherit]">
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}${viewDoc?.documentName}`}
                alt="report"
              />
            </div>
          ) : (
            <iframe
              src={`${process.env.REACT_APP_IMAGE_URL}${viewDoc?.documentName}`}
              title="report"
              style={{ width: "100%", height: "500px" }} // Adjust height as needed
            />
          )}
        </Modal>
      )}

      {isModalOpen1 && (
        <Modal
        title={<span className="text-lg font-semibold font-sans">Filter Report</span>}
          visible={isModalOpen1}
          onOk={() => handleCloseModal()}
          centered={true}
          footer={null}
          onCancel={() => handleCloseModal()}
          okText="Submit"
          cancelText="Cancel"
          width={500}
          height={350}
          maskClosable={false}
          placement="bottomLeft"
        >
          <FilterReport onClose={(values) => handleCloseModal(values)} />
        </Modal>
      )}
    </Layout>
  );
};

export default MyReports;
