import http from "./http_common";

class ChronicConditionService {
    queryString(payload) {
        return Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&");
    }
    AddChronicConditions(data) {
        return http.post(`patientChronicConditions/save/`, data);
      }
    chronicConditions(payload){
        var queryString = this.queryString(payload)
        return http.get(`chronicConditions/list/filter?${queryString}`);
    }
    PatientchronicConditions(payload){
        var queryString = this.queryString(payload)
        return http.get(`patientChronicConditions/list/filter?${queryString}`);
    }

}

export default new ChronicConditionService();