import http from "./http_common";

class UserService {

   queryString(payload){
      const QueryString =  Object.keys(payload).map((key) => (key && payload[key] && key+"="+payload[key])).join('&');
      return QueryString;
   }

   addDoctor(payload) {
      return http.post('/enrollmentFormDetails/save', payload);
   }

   addPatient(payload) {
      return http.post('/patient/consultation/save', payload);
   }

   getDoctors(payload) {
      const queryString = this.queryString(payload);
      return http.get(`/user/consultation/list/filter?${queryString}`);
   }

   getPatient(payload) {
      const queryString = this.queryString(payload);
      return http.get(`/patient/consultation/list/filter?${queryString}`);
   }

   getDoctorSpeciality(payload) {
      const queryString = this.queryString(payload)
      return http.get(`/Speciality/list?${queryString}`);
   }

}

export default new UserService();