import http from "./http_common";

class CommonService{
    StatesList(){
        return http.get('/stateMaster/list/filter');
     }
    
     CountriesList(payload){
      var queryString = Object.keys(payload).map((key) => key + "=" + payload[key]).join("&");
        return http.get(`/country_Master/list/filter?${queryString}`);
     }

     RelationList(payload){
      var queryString = Object.keys(payload).map((key) => key + "=" + payload[key]).join("&");
      return http.get(`/RelationMaster/list/filter?${queryString}`);
     }
}

export default new CommonService();