import React, { useEffect, useState } from "react";
import { Pagination, Spin, Table } from "antd";
import PaymentService from "../../Services/PaymentService";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";

const PaymentsTable = (props) => {
  const [paymentData, setPaymentData] = useState([]);
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const filterValues = props.filterValues;
  const [isLoading, setIsLoading] = useState(false);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  const paymentHistory = () => {
    setIsLoading(true);
    if (filterValues) {
      if (filterValues.fromDate) {
        filterValues.fromDate = dayjs(
          filterValues.fromDate,
          "MM/DD/YYYY"
        ).format("MM/DD/YYYY");
      }
      if (filterValues.toDate) {
        filterValues.toDate = dayjs(filterValues.toDate, "MM/DD/YYYY").format(
          "MM/DD/YYYY"
        );
      }
    }
    // Initialize base payload with mandatory fields
    let payload = {
      pageNumber: currentPage,
      status: 1,
      patientId: patientCode,
    };
    // Dynamically add filter values to payload if they exist
    if (filterValues) {
      if (filterValues.fromDate) {
        payload.fromDate = filterValues.fromDate;
      }
      if (filterValues.toDate) {
        payload.toDate = filterValues.toDate;
      }
      if (filterValues.transactionId) {
        payload.transactionId = filterValues.transactionId;
      }
    }
    PaymentService.getBillingTransaction(payload)
      .then((result) => {
        setPaymentData(result.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });

    PaymentService.getBillingCount(payload)
      .then((result) => {
        const totalRecords = result.data.TOTAL_COUNT_STATUS;
        setTotalRecords(totalRecords);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    paymentHistory();
  }, [currentPage, filterValues]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patientName",
      // sorter: {
      //   compare: (a, b) => a.patientName.localeCompare(b.patientName),
      // },
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
    },
    {
      title: "Payment Date & Time",
      dataIndex: "paymentDate",
    },
    {
      title: "Service by",
      dataIndex: "serviceName",
    },
    {
      title: "Doctor Name",
      dataIndex: "userName",
    },
    {
      title: "Amount",
      dataIndex: "paidAmount",
      // render: (_, record) => (
      //   <div>₹ {(record?.paidAmount / 100).toFixed(2)}</div>
      // ),
    },
    {
      title: "Payment Status",
      dataIndex: "status",
      render: (_, record) => (
        <div>{record?.status == 1 ? "Success" : "Pending"}</div>
      ),
    },
  ];

  return (
    <div className="flex flex-col  justify-between mt-6">
      <div>
        <Table
          columns={columns}
          dataSource={paymentData}
          loading={{
            spinning: isLoading,
            indicator: <Spin indicator={loadingIcon} size="large" />,
          }}
          pagination={false}
        />
      </div>
      <div className="flex justify-center items-center my-10">
        <span className="mr-2">Page</span>
        <Pagination
          current={currentPage}
          total={totalRecords}
          pageSize={10}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default PaymentsTable;
