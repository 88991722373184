import React, { useEffect, useState } from "react";
import { Col, Row, Form, DatePicker, Select, message } from "antd";
import moment from "moment";
import ChronicConditionService from "../../../Services/ChronicConditionService";
import dayjs from "dayjs";
import { useData } from "../../../Services/Context";

function AddChronicCondition(props) {
  const [form] = Form.useForm();
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [chronicValue, setChronicValue] = useState();
  const [chronicCode, setChronicCode] = useState([]);
  const [chronicDesc, setChronicDesc] = useState();
  const { patientLoginData } = useData();
  const patientName = `${activePatient?.salutation || ""}${
    activePatient?.firstName || ""
  } ${activePatient?.lastName || ""}`.trim();
  const [disable, setDisable] = useState(false);

  const handleCancel = () => {
    props.onClose();
  };
  const handlehealthChange = (value, desc) => {
    setChronicValue(value);
    setChronicDesc(desc.description);
  };

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const healthConditions = [
    { name: "COPD", value: "COPD" },
    { name: "Asthma", value: "Asthma" },
    { name: "Diabetes Mellitus", value: "Diabetes Mellitus" },
    { name: "Hypertension", value: "Hypertension" },
    { name: "Congestive Heart Failure", value: "Congestive Heart Failure" },
    { name: "Cardiac Disease", value: "Cardiac Disease" },
    { name: "Renal Disease", value: "Renal Disease" },
    { name: "Chronic Renal Failure", value: "Chronic Renal Failure" },
    { name: "Rheumatoid Arthritis", value: "Rheumatoid Arthritis" },
    { name: "Organ Transplant", value: "Organ Transplant" },
    { name: "High Cholesterol", value: "High Cholesterol" },
    { name: "Psychiatric Disorder", value: "Psychiatric Disorder" },
    { name: "Neurological Disorder", value: "Neurological Disorder" },
    { name: "Oncological Disorders", value: "Oncological Disorders" },
  ];

  const ChronicCode = () => {
    let payload = {
      status: 1,
    };
    ChronicConditionService.chronicConditions(payload).then((result) => {
      setChronicCode(result.data);
    });
  };

  useEffect(() => {
    ChronicCode();
  }, []);

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  const onFinish = (values) => {
    setDisable(true);
    if (!chronicDesc || !values.givenDate) {
      message.error("Please fill all fields");
      setDisable(false);
      return;
    }
    let payload = {
      createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      givenDate: dayjs(values.givenDate, "YYYY-MM-DD HH:mm:ss").format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      createdBy: patientCode,
      modifiedBy: patientCode,
      status: 1,
      account_Id: patientLoginData?.account_Id,
      clinic_Id: patientLoginData?.clinic_Id,
      patientId: patientCode,
      chronicCode: chronicValue,
      chronicDescription: chronicDesc,
      createdName: patientName,
      patientName: patientName,
      modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
    };
    ChronicConditionService.AddChronicConditions(payload)
      .then((result) => {
        props.onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Form
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        onFinish={onFinish}
      >
        <div className="border rounded-lg p-2">
          <Row gutter={20}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={
                  <span>
                    Chronic Condition{" "}
                    <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
                name="pastInfo"
              >
                <Select
                  style={{ width: "100%" }}
                  onChange={handlehealthChange}
                  placeholder="Select"
                  value={chronicValue}
                  options={chronicCode.map((ele) => ({
                    value: ele.code,
                    description: ele.description,
                    label: ele.description,
                    key: ele.value,
                  }))}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                label={
                  <span>
                    Date <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
                name="givenDate"
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Select"
                  format="MM-DD-YYYY"
                  disabledDate={disabledFutureDate}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <div className="flex items-center w-full justify-end my-5">
                <button
                  onClick={handleCancel}
                  className="border-brand-secondaryColor border rounded text-brand-secondaryColor text-sm px-6 py-1.5"
                >
                  Cancel
                </button>
                <button
                  disabled={disable}
                  className="bg-secondaryColor border-brand-secondaryColor border rounded px-5 py-1.5 text-brand-white text-sm ml-3"
                >
                  Submit
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default AddChronicCondition;
