import React from "react";
import { IoMdMail } from "react-icons/io";
import { IoCall } from "react-icons/io5";

function FooterPrescription({ clinicDetails, doctorData }) {
  return (
    <div>
      {clinicDetails?.prescription_FooterYN === "Y" ? (
        <div className="h-10 my-6"></div>
      ) : clinicDetails?.prescription_FooterYN === "C" ? (
        <div
          dangerouslySetInnerHTML={{
            __html: atob(doctorData.footerDetails).replace(
              /className=/g,
              "class="
            ),
          }}
        />
      ) : clinicDetails?.prescription_FooterYN === "N" ? (
        <div
          style={{
            height: `${clinicDetails.prescription_Footer_Space}px`,
          }}
          className="h-auto"
        ></div>
      ) : (
        ""
      )}
      {/* <div className="p-6 border-t-2 bg-white border-gray-300 mx-auto">
        <div className="text-center mb-6">
          <h2 className="text-lg font-semibold text-[#154A6B] underline">
            Services
          </h2>
          <div className="mt-2 flex justify-center space-x-3 text-[#A7527E]">
            <span>General Medicine</span>
            <span className="text-[#154A6B]">|</span>
            <span>Maternity</span>
            <span className="text-[#154A6B]">|</span>
            <span>Fertility</span>
            <span className="text-[#154A6B]">|</span>
            <span>General & Cosmetic Gynecology</span>
            <span className="text-[#154A6B]">|</span>
            <span>Well Women Services</span>
            <span className="text-[#154A6B]">|</span>
            <span>Lab & Scans</span>
          </div>
        </div>

        <div className="flex justify-between text-gray-600">
          <div>
            <p className="text-[#184B67]">
              Plot no : 1-A-1, Dr.RP Road, Gayathri Nagar,
              <br />
              Hasthinapuram, Chrompet, Chennai - 44
            </p>
          </div>
          <div className="text-[#184B67]">
            <div className="flex items-center cursor-pointer">
              <div className="bg-[#184B67] text-white p-2 rounded-full w-7 h-7">
                <IoCall className="text-[#A7527E]" />
              </div>
              <span className="ml-2">98940 60645</span>
            </div>
            <br />
            <div className="flex items-center justify-center cursor-pointer">
              <div className="bg-[#184B67] text-white p-2 rounded-full w-7 h-7">
                <IoMdMail className="text-[#A7527E]" />
              </div>
              <span className="ml-2">srithailahealthcare@gmail.com</span>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default FooterPrescription;
