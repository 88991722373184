import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Hero from '../DashBoardComponents/Hero';
import Providers from '../DashBoardComponents/Providers';
import Patients from '../DashBoardComponents/Patients';
import Clinic from '../DashBoardComponents/Clinic';
import Plans from '../DashBoardComponents/Plans';
import FAQ from '../DashBoardComponents/FAQ';
import Contact from '../DashBoardComponents/Contact';
import Layout from '../Layout';
import Dashboard from '../Pages/Dashboard';
import { DataContext } from '../Services/Context';
import Footer from '../DashBoardComponents/Footer';


function HomePage() {
  const location = useLocation();
  const state = useContext(DataContext);
  const loggedUserJson = localStorage.getItem("patientData");
  const loggedUser = loggedUserJson ? JSON.parse(loggedUserJson) : null;

  useEffect(() => {
    const hash = location.hash;
    console.log("Current hash:", hash); // Debug log
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        console.log("Element found:", element); // Debug log
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]); // Listen to the entire location change

  return (

    <Layout>
      <div className="App overflow-x-hidden">
        <Hero />
        <main>
          {loggedUser &&
            (<section>
              <Dashboard />
            </section>)
          }
          <section id='providers'>
            <Providers />
          </section>
          <section id='patients'>
            <Patients />
          </section>
          <section id='clinic'>
            <Clinic />
          </section>
          <Plans />
          <FAQ />
          <Contact />
        </main>
      </div>
        <Footer />
    </Layout>
  );
}

export default HomePage;
