import { Col, DatePicker, Form, Input, message, Row } from "antd";
import React from "react";

function LabFilter(props) {
  const [form] = Form.useForm();

  const onFinish = (e) => {
    let values = form.getFieldValue();
    if (!values.fromDate && !values.toDate && !values.labTestOrderId) {
      message.warning("Please enter at least one filter value!");
      return;
    }
    props.onClose(values);
  };

  const handleCancel = () => {
    props.onClose();
  };

  return (
    <div>
      <Form
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col className="gutter-row" span={12}>
            <Form.Item className="mb-0" label="From Date" name="fromDate">
              <DatePicker placeholder="Date" className="w-full h-9" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item className="mb-0" label="To Date" name="toDate">
              <DatePicker placeholder="Date" className="w-full h-9" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              className="mb-0"
              label="Lab OrderId"
              name="labTestOrderId"
            >
              <Input placeholder="lab OrderId" className="w-full h-9" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <div className=" flex justify-end gap-3 mt-5">
              <button
                onClick={handleCancel}
                className="bg-White text-brand-primaryColor border-primaryColor border rounded-lg w-[100px] h-[38px] text-base"
              >
                Cancel
              </button>
              <button
                //   onClick={handleOk}
                className="bg-primaryColor w-[100px] h-[38px] text-base rounded-lg text-white"
              >
                Filter
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default LabFilter;
