import React from "react";
import Navbar from "../DashBoardComponents/Navbar";
import Footer from "../DashBoardComponents/Footer";

function RefundPolicy() {
  return (
    <>
      <Navbar />
      <div className="bg-[#EAF7FE] py-32 ">
        <div className="w-[80%] mx-auto regularFont">
          <h1 className="headingFont font-extrabold text-[#002f72] text-[60px]">
            Return and Refund Policy
          </h1>
          <div>
            <p className="">
              Cytozon provides an easy refund policy, allowing returns within a
              specified period and quick refunds through the original payment
              method or another choice. Customers have access to clear support
              for refund inquiries.
            </p>
            <p className="mt-5">Our Policy for the return and refund shall be as follows:</p>
            <ul className="list-circle px-10 mt-5">
              <li className="mt-5">We strive to deliver correct medicines/items in the right/undamaged condition every time the customer places an order. We strongly recommend that you check the condition of the delivered products at the time of delivery and get back to us if there are any discrepancies.</li>
              <li className="mt-5">If your order is eligible for a return, you can raise a return request within 48 hours from the time of delivery subject to review and verification by the Cytozon team. No return of damaged products is possible once part or whole of the product has been used.</li>
              <li className="mt-5">To raise the return request and return an item, you can call our help desk at customer support numbers and email IDs mentioned in the website.</li>
              <li className="mt-5">Items are eligible for a return exclusively under the following circumstances:</li>
              <li className="mt-5">Products are delayed beyond 48 hours of the estimated date of delivery communicated to the customer at the time of placement of the order.</li>
              <li className="mt-5">Product(s) delivered do not match the order: This would include items that are different from what the customer ordered.</li>
              <li className="mt-5">Product(s) were delivered in damaged/non-working condition.</li>
              <li className="mt-5">Product(s) have missing parts or accessories or are different from their description on the product page.</li>
              <li className="mt-5">Product(s) are returned in original packaging i.e. with labels, barcode, price tags, original serial no. etc.</li>
              <li className="mt-5">The batch number of the product(s) being returned matches with the one(s) mentioned in the invoice.</li>
              <li className="mt-5">Product(s)/medicines(s)/bottle(s) are unused. Opened medicine strip(s)/bottle(s) are not eligible for returns.</li>
            </ul>
            <div>
            <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
            Refunds and timelines:
              </h2>
              <ul className="list-circle px-10 mt-5">
                <li className="mt-5">Refunds for all eligible returns or cancellations are issued through the payment method used at the time of purchase, except for cash payments made under the Pay on Delivery mode of payment.</li>
                <li className="mt-5">Refunds may be processed within 15 working days from the receipt of a request from you.</li>
                <li className="mt-5">The time frame for different payment modes is typically 5-7 business days post the return has been received and verified by Cytozon</li>
                <li className="mt-5">Refund timelines depend on bank turnaround times and RBI guidelines. This may change from time to time. Business days shall mean the working days on which Cytozon corporate office operates.</li>
                <li className="mt-5">For orders placed using Pay on Delivery as the payment method, refunds can be processed to your bank account via National Electronic Funds Transfer (NEFT). There will be no cash refund.</li>
                <li className="mt-5">To receive refunds in NEFT form, you will need to update the following information to enable us to process a refund to your account:</li>
                <li className="mt-5">The Bank Account Number</li>
                <li className="mt-5">IFSC Code</li>
                <li className="mt-5">Account Holder's Name</li>
              </ul>
            </div>
            <div>
            <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
            Cancellation and Refund Policy
              </h2>
              <p className="mt-2">Our Policy for the cancellation and refund shall be as follows:</p>
              <ul className="list-circle px-10 mt-5">
                <li className="mt-5">Customers can cancel orders/services already booked and paid prior to 48 hours of the expected delivery of goods or services. In such a case the customer can get a full refund unless cancellation charges are deducted by the Healthcare Provider (applicable in the case of healthcare services).</li>
                <li className="mt-5">In case where the user, does not show up for the appointment booked with a Healthcare Provider, without cancelling the appointment beforehand, there will not be any refunds.</li>
              </ul>
              <p className="mt-5">If you have any questions about our Return and Refund Policy or your expected refunds, please contact us at our customer support numbers/email mentioned on the website.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default RefundPolicy;
