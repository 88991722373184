import React, { useState } from "react";
import { DatePicker, Form, Input, Modal, Select } from "antd";
import { Button, message, Upload } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import moment from "moment";
import deleteHoverIcon from "../../../assets/images/Svg/hoverdelete.svg";
import Preview from "../../../assets/images/Svg/PreviewIcon.svg";
import MedicalService from "../../../Services/MedicalService";
import dayjs from "dayjs";
import { useData } from "../../../Services/Context";

function AddReport(props) {
  const [form] = Form.useForm();
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [imageData, setImageData] = useState("");
  const [imageType, setImageType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reportPreview, setReportPreview] = useState("");
  const { patientLoginData } = useData();

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };
  const handleCancel = () => {
    props.onClose();
  };
  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      let imageArray = [];
      imageArray = reader.result.split(",");
      const imageDataUrl = imageArray[1];
      const imageType = file.type;
      // Extract file extension from imageType
      const fileExtension = imageType.split("/")[1];
      setImageData(imageDataUrl);
      setImageType(fileExtension);
      setReportPreview(reader.result);
    };
  };

  const props1 = {
    customRequest: ({ file, onSuccess }) => {
      if (file && file.size > 1048576) {
        message.error("Image/file size should be less than or equal to 1 MB!");
        return;
      } else {
        handleFileUpload(file);
        message.success(`${file.name} Uploaded Successfully.`);
        onSuccess("ok");
      }
    },
  };

  const handleRemoveFile = () => {
    setImageData("");
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handlePreviewOk = () => {
    setIsModalOpen(false);
  };
  const handlePreviewClose = () => {
    setIsModalOpen(false);
  };

  const onFinish = (e) => {
    let values = form.getFieldValue();
    let payload = {
      createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      givenDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      createdBy: patientCode,
      modifiedBy: patientCode,
      status: 1,
      patientId: patientCode,
      doctorName: "",
      document: imageData,
      documentDate: dayjs(values.documentDate, "MM/DD/YYYY").format(
        "MM/DD/YYYY"
      ),
      // documentExtansion: null,
      documentName: null,
      documentType: values?.documentType,
      documentsType: imageType,
      title: values.title,
      note: "",
      consultationId: "",
      account_Id: patientLoginData?.account_Id,
      clinic_Id: patientLoginData?.clinic_Id,
      modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      fromDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      toDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
    };
    MedicalService.AddPatientReport(payload)
      .then((result) => {
        props.onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="border rounded-lg p-2">
      <Form
        className="CommonForm grid grid-cols-2 gap-5"
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item className="mb-0" label="Date" name="documentDate">
          <DatePicker placeholder="Date" className="w-full h-8" />
        </Form.Item>
        <Form.Item className="mb-0" label="Title " name="title">
          <Input placeholder="Enter Title" />
        </Form.Item>
        <Form.Item className="mb-0" label="Document Type " name="documentType">
          <Select
            name="reportType"
            className="w-full h-14  p-2  rounded-md"
            placeholder="--Select--"
          >
            <option value="Prescription">Prescription</option>
            <option value="Lab Report">Lab Report</option>
            <option value="Scan Report">Scan Report</option>
            <option value="MRI Scan">MRI Scan</option>
            <option value="Ultrasound Report">Ultrasound Report</option>
            <option value="Clinical Records">Clinical Records</option>
            <option value="Health Records">Health Records</option>
            <option value="Others">Others</option>
          </Select>
        </Form.Item>

        {/* <Form.Item className="mb-0" label="Upload Documents " name="document">
          <Dragger className="h-[30px] p-0">
            <CloudUploadOutlined className="p-0 m-0" />
          </Dragger>
        </Form.Item> */}
        <Form.Item
          name="document"
          label={
            <span>
              Document<span style={{ color: "#FF000F" }}> *</span>
            </span>
          }
        >
          {imageData ? (
            <div className="flex items-center">
              <span>
                {imageType === "application/pdf" ? "PDF" : "Report document"}
              </span>
              <img
                className="ml-3 cursor-pointer"
                onClick={handleRemoveFile}
                src={deleteHoverIcon}
                alt="image"
              />
              <img
                className="ml-3 cursor-pointer"
                src={Preview}
                alt="image"
                onClick={showModal}
              />
            </div>
          ) : (
            <Upload {...props1}>
              <Button className="mt-3" icon={<CloudUploadOutlined />}>
                CHOOSE FILE
              </Button>
            </Upload>
          )}
        </Form.Item>

        {/* <Form.Item
          label={
            <span>
              Notes<span style={{ color: "#FF000F" }}></span>
            </span>
          }
          name="action"
        >
          <>
            <TextArea rows={2} placeholder="Enter" maxLength={30} />
          </>
        </Form.Item> */}
      </Form>
      <div className=" flex justify-end gap-3 mt-3 ">
        <button
          onClick={handleCancel}
          className="bg-White text-brand-primaryColor border-primaryColor border rounded-lg w-[100px] h-[40px] text-base"
        >
          Cancel
        </button>
        <button
          onClick={onFinish}
          className="bg-primaryColor w-[100px] h-[40px] text-base rounded-lg text-white"
        >
          Save
        </button>
      </div>
      <Modal
        title="Preview"
        open={isModalOpen}
        onOk={handlePreviewOk}
        onCancel={handlePreviewClose}
        footer={null}
        width={700}
      >
        {imageType === "pdf" ? (
          <iframe
            src={`data:application/pdf;base64,${imageData}`}
            title="PDF Preview"
            style={{ width: "100%", height: "500px" }}
          />
        ) : (
          <img src={reportPreview} alt="Preview" style={{ width: "100%" }} />
        )}
      </Modal>
    </div>
  );
}

export default AddReport;
