import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Form, Input, DatePicker, Select } from "antd";
import moment from "moment";
import { message } from "antd";
import MedicalService from "../../../Services/MedicalService";
import dayjs from "dayjs";
import { useData } from "../../../Services/Context";

export default function AddAllergy(props) {
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [PatientAlleryData, setPatientAlleryData] = useState([]);
  const [drugValue, setDrugValue] = useState();
  const [foodValue, setFoodValue] = useState();
  const [chemicalValue, setChemicalValue] = useState();
  const [otherValue, setOtherValue] = useState();
  const { patientLoginData } = useData();
  const [manualDrug, setManualDrug] = useState("");
  const [manualFood, setManualFood] = useState("");
  const [manualChemical, setManualChemical] = useState("");
  const [manualOther, setManualOther] = useState("");
  const drugInputRef = useRef(null);
  const foodInputRef = useRef(null);
  const chemicalInputRef = useRef(null);
  const otherInputRef = useRef(null);
  const patientName = `${activePatient?.salutation || ""}${
    activePatient?.firstName || ""
  } ${activePatient?.lastName || ""}`.trim();
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (manualDrug && drugInputRef.current) drugInputRef.current.focus();
  }, [manualDrug]);

  useEffect(() => {
    if (manualFood && foodInputRef.current) foodInputRef.current.focus();
  }, [manualFood]);

  useEffect(() => {
    if (manualChemical && chemicalInputRef.current)
      chemicalInputRef.current.focus();
  }, [manualChemical]);

  useEffect(() => {
    if (manualOther && otherInputRef.current) otherInputRef.current.focus();
  }, [manualOther]);

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const handleDrug = (values) => {
    setDrugValue(values);
  };

  const handleFood = (values) => {
    setFoodValue(values);
  };

  const handleChemical = (values) => {
    setChemicalValue(values);
  };

  const handleOther = (values) => {
    setOtherValue(values);
  };

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY"; // YYYY-MM-DD hh:mm:ss
    return moment(new Date(date)).utc().format(format);
    // return moment(new Date(date)).add(moment(date).utcOffset(), 'm').utc().seconds(30).format(format);
  };

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const DrugOption = [
    { name: "Penicillin", value: "Penicillin" },
    { name: "Sulfonamide", value: "Sulfonamide" },
    { name: "ASA", value: "ASA" },
    { name: "NSAIDs", value: "NSAIDs" },
    { name: "Aspirin", value: "Aspirin" },
    { name: "Ibuprofen", value: "Ibuprofen" },
    { name: "Naproxen", value: "Naproxen" },
  ];

  const ChemicalOption = [
    { name: "Dyes", value: "Dyes" },
    { name: "Colour Additives", value: "Colour Additives" },
    { name: "Cosmetics", value: "Cosmetics" },
    { name: "Soap", value: "Soap" },
    { name: "Moisturiser", value: "Moisturiser" },
    { name: "Perfume", value: "Perfume" },
  ];
  const FoodOption = [
    { name: "Egg", value: "Egg" },
    { name: "Peanut", value: "Peanut" },
    { name: "Peanut butter", value: "Peanut butter" },
    { name: "Milk", value: "Milk" },
    { name: "Nuts", value: "Nuts" },
    { name: "Soy", value: "Soy" },
    { name: "Fish", value: "Fish" },
    { name: "Animal meat", value: "Animal meat" },
    { name: "Wheat", value: "Wheat" },
    { name: "Animal Protein", value: "Animal Protein" },
  ];

  const OtherOption = [
    { name: "Animal Dander", value: "Animal Dander" },
    { name: "Insect Bite", value: "Insect Bite" },
    { name: "Insect Sting", value: "Insect Sting" },
    { name: "Pollen", value: "Pollen" },
    { name: "Insect Faeces", value: "Insect Faeces" },
    { name: "House Dust Mites", value: "House Dust Mites" },
    { name: "Mite Faeces", value: "Mite Faeces" },
    { name: "Natural Rubber Latex", value: "Natural Rubber Latex" },
  ];

  const { TextArea } = Input;
  const onFinish = (values) => {
    setDisable(true);
    if (
      !(drugValue || manualDrug) ||
      !(foodValue || manualFood) ||
      !(chemicalValue || manualChemical) ||
      !(otherValue || manualOther) ||
      !values.givenDate
    ) {
      message.error("Please fill mandatory fields");
      setDisable(false);
      return;
    }
    let payload = values;
    payload.patientCode = patientCode;
    payload.createdBy = patientCode;
    payload.status = 1;
    payload.account_Id = patientLoginData?.account_Id;
    payload.clinic_Id = patientLoginData?.clinic_Id;
    payload.chemicalAllergy = chemicalValue ? chemicalValue : manualChemical;
    payload.foodAllergy = foodValue ? foodValue : manualFood;
    payload.drugAllergy = drugValue ? drugValue : manualDrug;
    payload.otherAllergy = otherValue ? otherValue : manualOther;
    payload.fromDate = null;
    payload.toDate = null;
    payload.createdName = patientName;
    payload.modifiedBy = patientCode;
    payload.modifiedDate = convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss");
    payload.createdDate = convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss");
    payload.givenDate = dayjs(values.givenDate, "YYYY-MM-DD HH:mm:ss").format(
      "YYYY-MM-DD HH:mm:ss"
    );

    MedicalService.AddPatientAllery([payload]).then((result) => {
      setPatientAlleryData(result.data);
      props.onClose();
      message.success({
        content: "Allergy Details added successfully",
        duration: 3,
        className: "custom-toast-success", // Apply your custom CSS class
      });
    });
  };
  const handleCancel = () => {
    props.onClose();
  };

  const handleDrugSearch = (value) => {
    const isPresent = DrugOption.findIndex((item) => item.name.includes(value));
    if (isPresent === -1) {
      setManualDrug(value);
    }
  };

  const handleFoodSearch = (value) => {
    const isPresent = FoodOption.findIndex((item) => item.name.includes(value));
    if (isPresent === -1) {
      setManualFood(value);
    }
  };

  const handleChemSearch = (value) => {
    const isPresent = ChemicalOption.findIndex((item) =>
      item.name.includes(value)
    );
    if (isPresent === -1) {
      setManualChemical(value);
    }
  };

  const handleOthSearch = (value) => {
    const isPresent = OtherOption.findIndex((item) =>
      item.name.includes(value)
    );
    if (isPresent === -1) {
      setManualOther(value);
    }
  };

  const handleManualDurg = (e) => {
    const value = e.target.value;
    if (value) {
      setManualDrug(value);
    } else {
      setManualDrug("");
    }
  };

  const handleManualFood = (e) => {
    const value = e.target.value;
    if (value) {
      setManualFood(value);
    } else {
      setManualFood("");
    }
  };

  const handleManualChem = (e) => {
    const value = e.target.value;
    if (value) {
      setManualChemical(value);
    } else {
      setManualChemical("");
    }
  };

  const handleManualOth = (e) => {
    const value = e.target.value;
    if (value) {
      setManualOther(value);
    } else {
      setManualOther("");
    }
  };

  return (
    <>
      <Form
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
      >
        <div className="border p-2 rounded-lg">
          <Row gutter={20}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={
                  <span>
                    Drug Allergies <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
                name="drugAllergy"
              >
                <Select
                  showSearch
                  onSearch={handleDrugSearch}
                  placeholder="Select"
                  // value={drugValue}
                  onChange={handleDrug}
                  options={DrugOption}
                  className={`${manualDrug ? "hidden" : "block"}`}
                  filterOption={(input, option) =>
                    option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                />
                <Input
                  type="text"
                  className={`${manualDrug ? "block" : "hidden"}`}
                  value={manualDrug}
                  onChange={(e) => handleManualDurg(e)}
                  ref={drugInputRef}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                label={
                  <span>
                    Food Allergies <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
                name="foodAllergy"
              >
                <Select
                  showSearch
                  onSearch={handleFoodSearch}
                  placeholder="Select"
                  // value={foodValue}
                  onChange={handleFood}
                  options={FoodOption}
                  filterOption={(input, option) =>
                    option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  className={`${manualFood ? "hidden" : "block"}`}
                  allowClear
                />
                <Input
                  type="text"
                  className={`${manualFood ? "block" : "hidden"}`}
                  value={manualFood}
                  onChange={(e) => handleManualFood(e)}
                  ref={foodInputRef}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={
                  <span>
                    Chemical Allergies{" "}
                    <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
                name="chemicalAllergy"
              >
                <Select
                  showSearch
                  onSearch={handleChemSearch}
                  placeholder="Select"
                  // value={chemicalValue}
                  onChange={handleChemical}
                  options={ChemicalOption}
                  filterOption={(input, option) =>
                    option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  className={`${manualChemical ? "hidden" : "block"}`}
                  allowClear
                />
                <Input
                  type="text"
                  className={`${manualChemical ? "block" : "hidden"}`}
                  value={manualChemical}
                  onChange={(e) => handleManualChem(e)}
                  ref={chemicalInputRef}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={
                  <span>
                    Other Allergies <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
                name="otherAllergy"
              >
                <Select
                  showSearch
                  onSearch={handleOthSearch}
                  placeholder="Select"
                  // value={otherValue}
                  onChange={handleOther}
                  options={OtherOption}
                  filterOption={(input, option) =>
                    option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  className={`${manualOther ? "hidden" : "block"}`}
                  allowClear
                />
                <Input
                  type="text"
                  className={`${manualOther ? "block" : "hidden"}`}
                  value={manualOther}
                  onChange={(e) => handleManualOth(e)}
                  ref={otherInputRef}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={
                  <span>
                    Date <span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
                name="givenDate"
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Select"
                  format="MM-DD-YYYY"
                  disabledDate={disabledFutureDate}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <div className="flex items-center w-full justify-end my-5">
                <button
                  onClick={handleCancel}
                  className="border-brand-secondaryColor border rounded text-brand-secondaryColor text-sm px-6 py-1.5"
                >
                  Cancel
                </button>
                <button
                  disabled={disable}
                  className="bg-secondaryColor border-brand-secondaryColor border rounded px-5 py-1.5 text-brand-white text-sm ml-3"
                >
                  Submit
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
}
