import React, { useEffect, useState } from "react";
import { Button, Modal, Pagination, Space, Spin, Table } from "antd";
import { APP_ROUTES } from "../../PageRouting/pageRoutes";
import { useNavigate } from "react-router-dom";
import AppointmentService from "../../Services/AppointmentService";
import ViewPrescription from "../MedicalRecords/MyPrescription/ViewPrescription";
import AuthService from "../../Services/AuthService";
import dayjs from "dayjs";
import Sharemedicalrecords from "../VideoConsultation/Sharemedicalrecords";
import axios from "axios";
import sha256 from "crypto-js/sha256";
import { Buffer } from "buffer";
import moment from "moment";
import PaymentService from "../../Services/PaymentService";
import { Base64 } from "js-base64";
import { LoadingOutlined } from "@ant-design/icons";

const AppointmentTable = (props) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [prescriptionsList, setPrescriptionsList] = useState([]);
  const [selectedPrescription, setSelectedPrescription] = useState({});
  const activePatient = JSON.parse(localStorage.getItem("patientData"));
  const patientCode = activePatient?.patientId;
  const [appointmentData, setAppoinmentData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userCode, setUserCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  const filterValues = props.filterValues;
  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };
  const handleClick = async (details) => {
    const currentDate = new Date();
    const formattedDate = convertUTCDate(currentDate, "YYYY-MM-DD HH:mm:ss");

    try {
        let payload1 = {
          appointmentId: details.appointmentId,
          modifiedBy: details.userId,
          modifiedDate: formattedDate,
          patientId: details.patientId,
          queueStatus: "Y",
          userId: details.userId,
          joiningDateTime: formattedDate,
        };
        await AppointmentService.callQueueUpdate(payload1);
        navigate(APP_ROUTES.VIDEOCALL, {
          state: { selectedAppDetails: details },
        });
      
    } catch (error) {
      console.error("Error updating appointment or queue:", error);
    }
  };

  const patientName = `${activePatient?.salutation || ""} ${
    activePatient?.firstName || ""
  } ${activePatient?.lastName || ""}`.trim();

  const Appoinment = () => {
    setIsLoading(true);
    if (filterValues) {
      if (filterValues.fromDate) {
        filterValues.fromDate = dayjs(filterValues.fromDate, "MM/DD/YYYY").format("MM/DD/YYYY");
      }
      if (filterValues.toDate) {
        filterValues.toDate = dayjs(filterValues.toDate, "MM/DD/YYYY").format("MM/DD/YYYY");
      }
    }
    let payload = {
      patientId: patientCode,
    };
    // if (filterValues) {
    //   payload = {
    //     ...payload,
    //     fromDate: filterValues.fromDate,
    //     toDate: filterValues.toDate,
    //   };
    // }
    if (filterValues) {
      if (filterValues.fromDate) {
        payload.fromDate = filterValues.fromDate;
      }
      if (filterValues.toDate) {
        payload.toDate = filterValues.toDate;
      }
      if (filterValues.transactionId) {
        payload.transactionId = filterValues.transactionId;
      }
      if (filterValues.appointmentId) {
        payload.appointmentId = filterValues.appointmentId;
      }
      if (filterValues.userName) {
        payload.userName = filterValues.userName;
      }
    }
    AppointmentService.getPatientAppointment(payload)
      .then((result) => {
        setAppoinmentData(result.data);
        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false)
      });
  };

  const updatedAppointmentData = appointmentData?.map((user) => ({
    ...user,
    PatientName: patientName,
  }));

  const handlePayment = async (record) => {
    let date = moment(new Date()).format("YYYYMMDDHHmmssSSS");
    let transactionId = "MT" + date;
    let appointmentDetails =
      transactionId +
      "|" +
      record?.patientId +
      "|" +
      record?.userId +
      "|" +
      record?.appointmentId +
      "|" +
      record?.account_Id +
      "|" +
      record?.clinic_Id;
    let base64string = Base64.encode(appointmentDetails);
    let paymentPayload = {
      merchantId: "DOXZONUAT",
      merchantTransactionId: transactionId,
      merchantUserId: "MUID123",
      amount: parseFloat(record?.totalAmount) * 100,
      redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}phonepepayment/${base64string}`,
      redirectMode: "POST",
      callbackUrl:
        `${process.env.REACT_APP_BASE_URL}/phonePeResponse1/paymentCallBackDetails`,
      mobileNumber: record?.patientMobile,
      paymentInstrument: {
        type: "PAY_PAGE",
      },
    };
    const SALT_KEY = "58d4bcb6-f046-4e58-8c62-66505c0e32d9";
    const PHONE_PE_HOST_URL =
      "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay";
    let bufferObj = Buffer.from(JSON.stringify(paymentPayload), "utf8");
    let base64EncodedPayload = bufferObj.toString("base64");
    let string = base64EncodedPayload + "/pg/v1/pay" + SALT_KEY;
    let sha256_val = sha256(string);
    let xVerifyChecksum = sha256_val + "###" + 1;

    let phonepeTransaction = {
      account_Id: record?.account_Id,
      checksum: xVerifyChecksum,
      clinic_id: record?.clinic_Id,
      createdBy: record?.patientId,
      createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      paidDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      modifiedBy: record?.patientId,
      patientId: record?.patientId,
      enoCodePayload: base64EncodedPayload,
      providerreferenceId: "",
      serviceCode: "APPT",
      serviceName: "Appoinment Consultation",
      status: 1,
      transactionId: transactionId,
      userId: record?.userId,
      amount: record?.totalAmount,
    };
    try {
      const result = await PaymentService.PhonepeTransaction(
        phonepeTransaction
      );
      // console.log(result.data, "phonepe");
      if (result.data == 1) {
        let billingTransaction = {
          account_Id: record?.account_Id,
          amount: record?.totalAmount,
          clinic_id: record?.clinic_Id,
          createdBy: record?.patientId,
          createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          discountAmount: 0,
          discountPercentage: 0,
          gst: 0,
          modifiedBy: record?.patientId,
          modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          paidAmount: record?.totalAmount,
          patientId: record?.patientId,
          paymentDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          paymode: "PhonePe",
          serviceCode: "APPT",
          serviceCost: record?.totalAmount,
          serviceName: "Appoinment Consultation",
          serviceTransId: record?.appointmentId,
          status: 0,
          transactionId: transactionId,
          userId: record?.userId,
        };
        const billingResult = await PaymentService.BillingTransaction(
          billingTransaction
        );
        if (billingResult.data == 1) {
          const configurationObject = {
            method: "POST",
            url: PHONE_PE_HOST_URL,
            data: { request: base64EncodedPayload },
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-VERIFY": xVerifyChecksum,
            },
          };

          const response = await axios(configurationObject);
          let res = response.data.data.instrumentResponse.redirectInfo;
          window.location.replace(res?.url);
          return response.data;
        }
      }
    } catch (error) {
      console.log("Warning", error);
      return error.response;
    }
  };

  useEffect(() => {
    Appoinment();
    fetchAllPrescriptions();
  }, [filterValues]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchAllPrescriptions = async () => {
    let payload = {
      patientId: patientCode,
      detailsYN: "Y",
    };
    const response = await AuthService.getMyPrescriptionView(payload);
    setPrescriptionsList(response.data);
  };

  const handleShareClick = (userId) => {
    setUserCode(userId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "PatientName",
      sorter: {
        compare: (a, b) => a.PatientName.localeCompare(b.PatientName),
      },
    },
    {
      title: "Patient Id",
      dataIndex: "patientId",
      sorter: {
        compare: (a, b) => a.patientId.localeCompare(b.patientId),
      },
    },
    {
      title: "Doctor Name",
      dataIndex: "userName",
      key: "userName",
      width: 150,
    },
    {
      title: "Appointment ID",
      dataIndex: "appointmentId",
      width: 160,
      sorter: {
        compare: (a, b) => a.appointmentId.localeCompare(b.appointmentId),
      },
    },
    {
      title: "Booked date",
      dataIndex: "createdDate",
      width: 130,
      sorter: {
        compare: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
      },
      render: (text, record) => (
        <span>{new Date(record.createdDate).toLocaleDateString("en-GB")}</span>
      ),
    },
    {
      title: "Appointment Date/Time",
      dataIndex: "startTime",
      width: 205,
      render: (_, record) => {
        const [hours, minutes] = record.startTime.split(":");
        const date = new Date();
        date.setHours(parseInt(hours, 10));
        date.setMinutes(parseInt(minutes, 10));
        const formattedTime = date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        const formattedDate = moment
          .utc(record.appointmentDate)
          .local()
          .format("DD/MM/YYYY");
        return (
          <span>
            {formattedDate}-{formattedTime}
          </span>
        );
      },
    },
    {
      title: "Consultation Reason",
      dataIndex: "appointmentReason",
      width: 180,
    },
    {
      title: "Appointment Status",
      dataIndex: "status",
      width:170,
      render: (_, record) => (
        <Space size="middle" className="">
          {record.status === 0 && (
            <span className=" text-red-500 " disabled>
              Cancelled
            </span>
          )}
          {record.status === 1 && (
            <span className=" text-blue-500 ">Pending</span>
          )}
          {record.status === 2 && (
            <span className=" text-green-500 ">Completed</span>
          )}
        </Space>
      ),
    },
    {
      title: "Payment",
      dataIndex: "payment",
      width: 100,
      render: (_, record) => (
        <div>
          {record.transactionId && (
            <Button className="p-2 w-20 rounded-md text-white bg-blue-500 flex items-center justify-center">
              Paid
            </Button>
          )}
          {!record.transactionId && (
            <Button
              onClick={() => handlePayment(record)}
              className="p-2 w-20 rounded-md text-white bg-green-500 flex items-center justify-center"
            >
              Pay
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      width: 180,
      render: (_, record) => (
        <div>{record.transactionId ? record.transactionId : "N/A"}</div>
      ),
    },
    {
      title: "Action",
      dataIndex: "joinConsultation",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="flex items-center !gap-2">
            <Space size="middle" className="">
              {record.status === 2 && (
                <Button
                  className="p-2 w-20 rounded-md text-white bg-green-500 flex items-center justify-center"
                  disabled
                >
                  Join Call
                </Button>
              )}
              {record.status === 1 && record.paymentConsultation == "AC" && (
                <button
                  className="p-2 w-20 rounded-md text-white bg-green-500 flex items-center justify-center"
                  onClick={() => handleClick(record)}
                >
                  Join Call
                </button>
              )}
              {record.status === 1 && record.paymentConsultation == "BC" && record.transactionId &&(
                <button
                  className="p-2 w-20 rounded-md text-white bg-green-500 flex items-center justify-center"
                  onClick={() => handleClick(record)}
                >
                  Join Call
                </button>
              )}
              {record.status === 1 && record.paymentConsultation == "BC" && !record.transactionId &&(
                <Button
                  className="p-2 w-20 rounded-md text-white bg-green-500 flex items-center justify-center"
                  disabled
                >
                  Join Call
                </Button>
              )}
              {record.status === 0 && ( 
                <Button
                  className="p-2 w-20 rounded-md text-white bg-green-500 flex items-center justify-center"
                  disabled
                >
                  Join Call
                </Button>
              )}
            </Space>
          </div>
        </Space>
      ),
    },
    {
      title: "Share",
      dataIndex: "share",
      key: "share",
      render: (text, record) => (
        <Button
          className="bg-secondaryColor text-white"
          onClick={() => handleShareClick(record.userId)}
        >
          Share Medical Records
        </Button>
      ),
    },
    {
      title: "Prescription",
      dataIndex: "prescription",
      width: 170,
      render: (_, record) => (
        <div className="">
          {record.status === 2 && record.transactionId && (
            <Button
              className="p-2 rounded-md bg-secondaryColor text-white flex items-center justify-center"
              onClick={() => handleViewClick(record)}
            >
              View prescription
            </Button>
          )}
        </div>
      ),
    },
  ];

  const handleViewClick = (record) => {
    console.log(record);
    let payload = {
      patientId: patientCode,
      detailsYN: "Y",
      consultationId: record?.consultationId,
    };
    AuthService.getMyPrescriptionView(payload).then((result) => {
      setSelectedPrescription(result.data[0]);
      setIsModalOpen(true);
    });
  };

  return (
    <div>
      <div className="flex flex-col h-screen justify-between">
        <div className="w-full" id="unquie-scroll">
          <Table
            columns={columns}
            dataSource={updatedAppointmentData}
            loading={{
              spinning: isLoading,
              indicator: <Spin indicator={loadingIcon} size="large" />,
            }}
            pagination={{
              pageSize: 10,
              position: ["bottomCenter"],
            }}
            scroll={{ x: "max-content", y: false }}
          />
        </div>
        {/* <div className="flex justify-center items-center mt-24">
          <span className="mr-2">Page</span>
          <Pagination total={10} showSizeChanger />
        </div> */}
      </div>

      <Modal
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: 20 }}
        width={1000}
        footer={false}
      >
        <ViewPrescription
          prescription={selectedPrescription}
          selectedPatient={patientCode}
        />
      </Modal>

      {showModal && (
        <Sharemedicalrecords userId={userCode} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default AppointmentTable;
