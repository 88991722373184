import React, { useEffect, useRef, useState } from "react";
import {
    Checkbox,
    DatePicker,
    Form,
    Input,
    Modal,
    Progress,
    Row,
    Select,
    message,
} from "antd";
import UserService from "../../Services/UserService";
import CommonService from "../../Services/CommonService";
import { PlusOutlined } from "@ant-design/icons";
import { BsCloudUpload } from "react-icons/bs";
import { encodeBase64File } from "../../utils/encodeBase64";
import deleteHoverIcon from "../../assets/images/Svg/delete.svg";
import View from "../../assets/images/Svg/view.svg";
import { CiWarning } from "react-icons/ci";
import dayjs from "dayjs";
const qualificationList = [
    "MBBS, MD (General Medicine)",
    "MBBS, MD (Family Medicine)",
    "MBBS, MD",
    "MBBS, MS (General Surgery)",
    "MBBS, MS (Orthopaedics)",
    "MBBS, MS, MCh (Neurosurgery)", // Repeated
    "MBBS, MS, MCh (Plastic Surgery)",
    "MBBS, MS, MCh (Cardiothoracic Surgery)",
    "MBBS, MS, MCh (Urology)",
    "MBBS, MD (Paediatrics)",
    "MBBS, MS/MD (Obstetrics and Gynecology)",
    "MBBS, MD (General Medicine), DM (Cardiology)",
    "MBBS, MD (General Medicine), DM (Neurology)",
    "MBBS, MD (Dermatology, Venereology, and Leprosy)",
    "MBBS, MD (Psychiatry)",
    "MBBS, MD (Radiology)",
    "MBBS, MD (Pathology)",
    "MBBS, MS/MD (Ophthalmology)",
    "MBBS, MS (ENT)",
    "MBBS, MD (Anesthesiology)",
    "MBBS, MD (General Medicine), DM (Medical Oncology)",
    "MBBS, MS, MCh (Surgical Oncology)",
    "MBBS, MD (Radiation Oncology)",
    "MBBS, MD (General Medicine), DM (Endocrinology)",
    "MBBS, MD (General Medicine), DM (Gastroenterology)",
    "MBBS, MD, DM (Hepatology)",
    "MBBS, MD (General Medicine), DM (Nephrology)",
    "MBBS, MD (Pulmonary Medicine/Respiratory Medicine)",
    "MBBS, MD (General Medicine), DM (Rheumatology)",
    "MBBS, MD (Community Medicine)",
    "MBBS, MD (Emergency Medicine)",
    "MBBS, MD (Physical Medicine and Rehabilitation)",
    "MBBS, MS (Laparoscopic Surgery)",
    "MBBS, MS, MCh (Paediatric Surgery)",
    "MBBS, MS, MCh (Vascular Surgery)",
    "MBBS, MS, MCh (Burn Surgery)",
    "MBBS, MS, MCh (Spinal Surgery)",
    "MBBS, MS, MCh (Robotic Surgery)",
    "MBBS, MS, MCh (Transplant Surgery)",
    "MBBS, MS, MCh (Trauma Surgery)",
    "MBBS, MS, MCh (Hepato-Pancreato-Biliary Surgery)",
    "MBBS, MS, MCh (Head and Neck Surgery)",
    "MBBS, MD (General Medicine), DM (Clinical Hematology)",
    "MBBS, MD (General Medicine), DM (Metabolic Medicine)",
    "MBBS, MD (General Medicine), DM (Geriatric Cardiology)",
    "MBBS, MD (Paediatrics), DM (Neonatology)",
    "MBBS, MD (Paediatrics), DM (Paediatric Cardiology)",
    "MBBS, MD (Paediatrics), DM (Paediatric Neurology)",
    "MBBS, MD (Paediatrics), DM (Paediatric Endocrinology)",
    "MBBS, MD (Paediatrics), DM (Paediatric Pulmonology)",
    "MBBS, MD (Psychiatry), DM (Child and Adolescent Psychiatry)",
    "MBBS, MD (General Medicine), DM (Genetic Medicine)",
    "MBBS, MD (Nuclear Medicine)",
    "MBBS, MD (Interventional Radiology)",
    "MBBS, MD (Molecular Pathology)",
    "MBBS, MD (Cytogenetics)",
    "MBBS, MD (Forensic Pathology)",
    "MBBS, MD (Occupational Medicine)",
    "MBBS, MD (Epidemiology)",
    "MBBS, MD (Public Health)",
    "MBBS, MD (Preventive Oncology)",
    "MBBS, MD (Industrial Medicine)",
    "MBBS, MD, DM (Critical Care Nephrology)",
    "MBBS, MD, DM (Interventional Pulmonology)",
    "MBBS, MD, DM (Sleep Medicine)",
    "MBBS, MD, DM (Neurocritical Care)",
    "MBBS, MD, DM (Behavioral Neurology)",
    "MBBS, MS, MCh (Cosmetic Surgery)",
    "MBBS, MS, MCh (Oncoplastic Surgery)",
    "MBBS, MD, DM (Inborn Errors of Metabolism)",
    "MBBS, MD (Environmental Health)",
    "MBBS, MD, DM (Cardiac Electrophysiology)",
    "MBBS, MD (Paediatrics), DM (Pediatric Gastroenterology)",
    "MBBS, MD (Paediatrics), DM (Pediatric Hematology-Oncology)",
    "MBBS, MD (Paediatrics), DM (Pediatric Infectious Diseases)",
    "MBBS, MD (Paediatrics), DM (Pediatric Rheumatology)",
    "MBBS, MD (Paediatrics), DM (Pediatric Nephrology)",
    "MBBS, MD (Regenerative Medicine)",
    "MBBS, MD (Hyperbaric Medicine)",
    "MBBS, MD (Pain Medicine)",
    "MBBS, MD (Sleep Disorders Medicine)",
    "MBBS, MD (Telemedicine)",
    "MBBS, MD (General Medicine), DM (Autoimmune Diseases)",
    "MBBS, MD (General Medicine), DM (Thrombosis and Hemostasis)",
    "MBBS, MD (General Medicine), DM (Metabolic Bone Disorders)",
    "MBBS, MD (General Medicine), DM (Tropical Medicine)",
    "MBBS, MD (General Medicine), DM (Precision Medicine)",
    "MBBS, MD (Genomics and Personalized Medicine)",
    "MBBS, MD, PhD (Translational Medicine)",
    "MBBS, MD (Artificial Intelligence in Medicine)",
    "MBBS, MD (General Medicine), DM (Molecular Cardiology)",
    "MBBS, MD (General Medicine), DM (Immuno-Oncology)",
    "MBBS, MD (Clinical Laboratory Medicine)",
    "MBBS, MD (Medical Microbiology)",
    "MBBS, MD (Virology)",
    "MBBS, MD (Parasitology)",
    "MBBS, MD (Bacteriology)",
    "MBBS, MD (Bioinformatics in Medicine)",
    "MBBS, MD (Diagnostic Genomics)",
    "MBBS, MD (Histopathology)",
    "MBBS, MD (Tissue Engineering)",
    "MBBS, MD (Medical Biochemistry)",
    "MBBS, MD (Functional Medicine)",
    "MBBS, MD (Integrative Medicine)",
    "MBBS, MD (Anti-Aging Medicine)",
    "MBBS, MD (Lifestyle Medicine)",
    "MBBS, MD (Climate Change and Health)",
    "MBBS, MD (Medical Informatics)",
    "MBBS, MD (Humanitarian and Disaster Medicine)",
    "MBBS, MD (Geospatial Health)",
    "MBBS, MD (Palliative Medicine)",
    "MBBS, MD (Cannabis-Based Medicine)",
    "MBBS, MS, MCh (Neonatal Surgery)",
    "MBBS, MS, MCh (Reproductive Surgery)",
    "MBBS, MS, MCh (Endocrine Surgery)",
    "MBBS, MS, MCh (Microsurgery)",
    "MBBS, MS, MCh (Abdominal Wall Surgery)",
    "MBBS, MS, MCh (Peripheral Nerve Surgery)",
    "MBBS, MS, MCh (Pelvic Floor Surgery)",
    "MBBS, MS, MCh (Stereotactic Surgery)",
    "MBBS, MS, MCh (Epilepsy Surgery)",
    "MBBS, MS, MCh (Liver Transplant Surgery)",
    "MBBS, MD (Digital Health and Telemedicine)",
    "MBBS, MD (Nanomedicine)",
    "MBBS, MD (Robotic-Assisted Therapies)",
    "MBBS, MD (Biomedical Engineering in Medicine)",
    "MBBS, MD (Space Medicine)",
    "MBBS, MD (Geriatric Oncology)",
    "MBBS, MD (Adolescent Medicine)",
    "MBBS, MD (Military Medicine)",
    "MBBS, MD (Clinical Trial Design)",
    "MBBS, MD (Global Health Diplomacy)",
    "MBBS, MD (Paediatrics), DM (Pediatric Immunology)",
    "MBBS, MD (Paediatrics), DM (Pediatric Allergy)",
    "MBBS, MD (Paediatrics), DM (Pediatric Critical Care)",
    "MBBS, MD (Paediatrics), DM (Pediatric Emergency Medicine)",
    "MBBS, MD (Paediatrics), DM (Pediatric Dermatology)",
    "MBBS, MD (Rare Genetic Disorders)",
    "MBBS, MD (Rare Infectious Diseases)",
    "MBBS, MD (Orphan Drug Development)",
    "MBBS, MD (Dermatologic Surgery)",
    "MBBS, MD (Photomedicine)",
    "MBBS, MD (Critical Care Medicine)",
    "MBBS, MD (Sports Medicine)",
    "MBBS, MD (Geriatrics)",
    "MBBS, MD (Infectious Diseases)",
    "MBBS, MD (Immunology)",
    "MBBS, MD (Allergy and Immunology)",
    "MBBS, MD (Clinical Pharmacology)",
    "MBBS, MD (Hospital Administration)"
];


function DoctorSelfEnrollment({ handleDoctorClose }) {
    const formRef = useRef();
    const [dob, setDob] = useState();
    const [states, setStates] = useState();
    const [selectedAcc, setSelectedAcc] = useState(null);
    const [selectedClinic, setSelectedClinic] = useState(null);
    const [doctorStamp, setDoctorStamp] = useState(null);
    const [doctorSignature, setDoctorSignature] = useState(null);
    const [userImage, setUserImage] = useState(null);
    const [countries, setCountries] = useState([]);
    const [profileImage, setProfileImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [doctorSpecialties, setDoctorSpecialties] = useState([]);
    const fileInputRef = useRef(null);
    const [docSignView, setDocSignView] = useState(null);
    const [stampView, setStampView] = useState(null);
    const [photoView, setphotoView] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
    const [signModal, setSignModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [slots, setSlots] = useState(false);

    useEffect(() => {
        getAllStates();
        getCountriesList();
        getSpecialities();
    }, []);

    const totalPages = 2;
    const progressPercentage = (((currentPage) / totalPages) * 100);

    const handleNext = () => {

        const { firstname, qualification, regnumber, email, mobile, gender} = formRef.current.getFieldValue();
        
        if(!firstname || !email || !mobile){
            message.error("Please fill in mandatory fields");
            return;
        }

        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevious = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const getSpecialities = async () => {
        let payload = {
            status: 1
        }
        try {
            const response = await UserService.getDoctorSpeciality(payload);
            if (response) {
                setDoctorSpecialties(response.data);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const getCountriesList = async () => {
        let payload = {
            status: 1,
        };
        const response = await CommonService.CountriesList(payload);
        setCountries(response.data);
    };

    const getAllStates = async () => {
        const response = await CommonService.StatesList();
        setStates(response.data);
    };

    const handleDob = (value, valueString) => {
        setDob(valueString);
    };


    const handleUserPicChange = (e) => {
        const file = e.target.files[0];
        if (file && file.size <= 1048576) {
            setProfileImage(file);
            setUserImage(file);
            const imageUrl = URL.createObjectURL(file);
            setphotoView(imageUrl);
        } else {
            message.error("Image/file size should be less than or equal to 1 mb !!");
            return;
        }
    };

    const handleSignature = (e) => {
        const file = e.target.files[0];
        if (file && file.size <= 1048576) {
            setDoctorSignature(file);
            const imageUrl = URL.createObjectURL(file);
            setDocSignView(imageUrl);
        } else {
            message.error("Image/file size should be less than or equal to 1 mb !!");
            return;
        }
    };

    const handleStamp = (e) => {
        const file = e.target.files[0];
        if (file && file.size <= 1048576) {
            setDoctorStamp(file);
            const imageUrl = URL.createObjectURL(file);
            setStampView(imageUrl);
        } else {
            message.error("Image/file size should be less than or equal to 1 mb !!");
            return;
        }
    };

    const handleSave = async (e) => {
        e.preventDefault();
        let payload = {};
        setIsLoading(true);
        const currDate = new Date().toISOString();
        const date = currDate.split("T")[0];
        const time = currDate.split("T")[1].slice(0, 8);
        const constructedTime = date + " " + time;

        const {
            salutation,
            firstname,
            lastname,
            gender,
            mobile,
            pincode,
            stateid,
            city,
            countryid,
            email,
            regnumber,
            speciality,
            qualification,
            address,
            fellowship,
            otherqualification,
            clinicname,
            cliniclocation,
            clinicaddress
        } = formRef.current.getFieldValue();

        if (clinicname === undefined || clinicname === "") {
            message.error("Please enter your clinic name!");
            setIsLoading(false);
            return;
        }
        if (cliniclocation === undefined || cliniclocation === "") {
            message.error("Please enter your clinic location!");
            setIsLoading(false);
            return;
        }
        if (clinicaddress === undefined || clinicaddress === "") {
            message.error("Please enter your clinic address!");
            setIsLoading(false);
            return;
        }

        payload = {
            // address1: address,
            // city: city,
            // countryName: countries.find((country) => country.country_Id === countryid)?.country_Name,
            // countryId: countryid,
            createdBy: firstname,
            createdDate: constructedTime,
            // dob: dob,
            email: email,
            firstName: firstname,
            gender: gender,
            // lastName: lastname,
            mobile: mobile,
            modifiedBy: firstname,
            modifiedDate: constructedTime,
            // photo: null,
            // photoName: "",
            // photoType: null,
            // pincode: pincode,
            rolecode: '',
            // salutation: salutation,
            // stateName: states.find((state) => state.stateId === stateid)?.stateName,
            // stateId: stateid,
            status: 1,
            // userName: firstname,
            userType: 'P',
            account_Id: selectedAcc,
            clinic_Id: selectedClinic,
            registrationNumber: regnumber,
            password: '',
            // speciality: speciality ? speciality : '',
            // specialityCode: doctorSpecialties.find(item => item.speciality === speciality)?.code,
            qualification: qualification,
            // fellowship: fellowship,
            // otherQualification: otherqualification,
            refNumber: "",
            clinicAddress: clinicaddress,
            clinicLocation: cliniclocation,
            clinicName: clinicname,
            remarks: ''
        };

        // if (userImage) {
        //     const res = await encodeBase64File(userImage);
        //     payload.imageName = userImage.name;
        //     if (doctorSignature?.type.includes("pdf")) {
        //         payload.imageType = "pdf";
        //         payload.image = res;
        //     } else if (doctorSignature?.type.includes("png")) {
        //         payload.imageType = "png";
        //         payload.image = res;
        //     } else if (doctorSignature?.type.includes("jpg")) {
        //         payload.imageType = "jpg";
        //         payload.image = res;
        //     } else if (doctorSignature?.type.includes("jpeg")) {
        //         payload.imageType = "jpeg";
        //         payload.image = res;
        //     }
        // } else {
        //     payload.imageName = null;
        //     payload.imageType = null;
        //     payload.image = null;
        // }

        // if (doctorStamp) {
        //     const res = await encodeBase64File(doctorStamp);
        //     payload.doctorStampName = doctorStamp.name;
        //     if (doctorStamp?.type.includes("pdf")) {
        //         payload.doctorStampType = "pdf";
        //         payload.doctorStamp = res;
        //     } else if (doctorStamp?.type.includes("png")) {
        //         payload.doctorStampType = "png";
        //         payload.doctorStamp = res;
        //     } else if (doctorStamp?.type.includes("jpg")) {
        //         payload.doctorStampType = "jpg";
        //         payload.doctorStamp = res;
        //     } else if (doctorStamp?.type.includes("jpeg")) {
        //         payload.doctorStampType = "jpeg";
        //         payload.doctorStamp = res;
        //     }
        // } else {
        //     payload.doctorStampName = null;
        //     payload.doctorStampType = null;
        //     payload.doctorStamp = null;
        // }

        // if (doctorSignature) {
        //     const res = await encodeBase64File(doctorSignature);
        //     payload.doctorSignatureName = doctorSignature.name;
        //     if (doctorSignature?.type.includes("pdf")) {
        //         payload.doctorSignatureType = "pdf";
        //         payload.doctorSignature = res;
        //     } else if (doctorSignature?.type.includes("png")) {
        //         payload.doctorSignatureType = "png";
        //         payload.doctorSignature = res;
        //     } else if (doctorSignature?.type.includes("jpg")) {
        //         payload.doctorSignatureType = "jpg";
        //         payload.doctorSignature = res;
        //     } else if (doctorSignature?.type.includes("jpeg")) {
        //         payload.doctorSignatureType = "jpeg";
        //         payload.doctorSignature = res;
        //     }
        // } else {
        //     payload.doctorSignatureName = null;
        //     payload.doctorSignatureType = null;
        //     payload.doctorSignature = null;
        // }

        console.log(payload, ' ....');
        try {
            const response = await UserService.addDoctor(payload);
            setIsLoading(false);
            formRef.current.resetFields();
            setDoctorSignature(null);
            setDoctorStamp(null)
            handleDoctorClose();
            message.success({
                duration: 5,
                content: `Successfully Registered. Please note your your reference no. ${response.data[0].refNumber} and userid ${response.data[0].UserId}.`
            })
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            message.error(e.response?.data.message);
        }

    };

    const handleSlot = () => {
        setSlots(!slots);
    }

    const handleCancel = () => {
        formRef.current.resetFields();
        handleDoctorClose();
    }

    const handleStampDelete = () => {
        setDoctorStamp(null);
    };

    const handlePhotoDelete = () => {
        setProfileImage(null);
    };

    const handleViewStamp = () => {
        setIsModalOpen(true);
    };

    const handleViewPhoto = () => {
        setIsPhotoModalOpen(true);
    };

    const handleStampViewClose = () => {
        setIsModalOpen(false);
    };

    const handeldocSignDel = () => {
        setDoctorSignature(null);
    };

    const handeldocSignView = () => {
        setSignModal(true);
    };

    const closeDocSignView = () => {
        setSignModal(false);
    };

    const closephotoView = () => {
        setIsPhotoModalOpen(false);
    };
    const headingTexts = ["Hello Doctor😊, Could you please help us with some basic details?", "Can we know your current organisation/hospital?", "Hello Doctor😊, Could we know your name?", "Nice to know you, !🙂 Can we know your qualification?", "Could you please help us with some basic details?", "Can we know your current organisation/hospital?", "Can you tell us your specialisation?", "Please upload image files!!"]


    return (
        <>
            <div className="m-6 backgroundImg text-color1">
                <div className="flex items-center justify-between">
                    <h1 className="md:text-xl text-lg font-bold">
                        {headingTexts[currentPage - 1]}
                    </h1>
                    <div className="w-32">
                        <Progress
                            percent={progressPercentage}
                            status="active"
                            strokeColor={{
                                from: '#7FE8EE',
                                to: '#87d068',
                            }}
                        />
                        <p>Page {currentPage} of {totalPages}</p>
                    </div>
                </div>
                <div>
                    <div className=" p-4 rounded-lg">
                        <div>
                            {/* <h2 className='md:text-2xl text-lg font-bold'>Account</h2>
                            <span className='text-textGray md:text-base text-sm'>Fill in the information to create a new user</span>*/}
                            <Form
                                ref={formRef}
                                // className="w-[50%]"
                                className="w-full grid md:grid-cols-2 grid-cols-3 gap-3 md:justify-center items-center"
                                layout="vertical"
                            >


                                {/* Personal Details Fields */}
                                {
                                    currentPage === 1 && (
                                        <>
                                            {/* <div>
                                                <div className="my-2">
                                                    <label className="md:text-lg text-base font-medium">
                                                        First Name
                                                    </label>
                                                </div>
                                                <Form.Item name="firstname">
                                                    <Input
                                                        type="text"
                                                        placeholder="First Name"
                                                        name="firstname"
                                                        className="md:w-4/5 w-full md:h-12 h-10 md:p-2 outline-none border border-[#7FE8EE]  p-1 rounded-lg"
                                                    />
                                                </Form.Item>
                                            </div> */}

                                            <div className="relative">
                                                <span className="w-[inherit] absolute md:text-lg text-base font-medium bg-white px-1 z-10 top-[-0.75rem] left-3">
                                                  Name
                                                  <span className="text-[#ef4444]">*</span>
                                                </span>
                                                <Form.Item name="firstname">
                                                    <Input
                                                        type="text"
                                                        // placeholder="First Name"
                                                        name="firstname"
                                                        className="md:w-4/5 w-full md:h-12 h-10 md:p-2 outline-none border border-[#7FE8EE]  p-1 rounded-lg"
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div className="relative">
                                                {/* <div className="my-2">
                                                    <label className="md:text-lg text-base font-medium">
                                                        Email
                                                    </label>
                                                </div> */}
                                                <span className="w-[inherit] absolute md:text-lg text-base font-medium bg-white px-1 z-10 top-[-0.75rem] left-3">
                                                    Email
                                                    <span className="text-[#ef4444]">*</span>
                                                </span>
                                                <Form.Item
                                                    name="email"
                                                // rules={[
                                                //     {
                                                //         type: "email",
                                                //         message: "Please enter a valid email address!",
                                                //     },
                                                // ]}
                                                >
                                                    <Input
                                                        type="email"
                                                        // placeholder="Enter email"
                                                        name="email"
                                                        className="md:w-4/5 w-full  md:h-12 h-10 border border-[#7FE8EE] outline-none p-2 rounded-lg"
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div className="relative">
                                                {/* <div className="my-2">
                                                    <label className="md:text-lg text-base font-medium">
                                                        Mobile
                                                    </label>
                                                </div> */}
                                                <span className="w-[inherit] absolute md:text-lg text-base font-medium bg-white px-1 z-10 top-[-0.75rem] left-3">
                                                    Mobile
                                                    <span className="text-[#ef4444]">*</span>
                                                </span>
                                                <Form.Item
                                                    name="mobile"
                                                // rules={[
                                                //     {
                                                //         type: "string",
                                                //         pattern: /^\d{10}$/, // Only allow 10 digits
                                                //         message:
                                                //             "Please enter a valid 10-digit mobile number!",
                                                //     },
                                                // ]}
                                                >
                                                    <Input
                                                        // placeholder="Enter mobile No"
                                                        name="mobile"
                                                        className="md:w-4/5 w-full md:h-12 h-10 border border-[#7FE8EE] outline-none md:p-2 p-1 rounded-lg"
                                                        maxLength={10}
                                                        onKeyDown={(event) => {
                                                            const allowedKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight'];

                                                            if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
                                                                event.preventDefault();  // Prevent non-numeric and non-control key input
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div className="relative">
                                                {/* <div className="my-2">
                                                    <label className="md:text-lg text-base font-medium">
                                                        Gender
                                                    </label>
                                                </div> */}
                                                <span className="w-[inherit] absolute md:text-lg text-base font-medium bg-white px-1 z-10 top-[-0.75rem] left-3">
                                                    Gender
                                                </span>
                                                <Form.Item name="gender" className="md:w-4/5 w-full py-2">
                                                    <Select
                                                        // placeholder="Select Gender"
                                                        className="md:h-12 h-10 rounded-lg border border-[#7FE8EE]"
                                                        allowClear
                                                    >
                                                        <Select.Option value="Male">Male</Select.Option>
                                                        <Select.Option value="Female">Female</Select.Option>
                                                        <Select.Option value="Others">Others</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>

                                            <div className="relative">
                                                {/* <div className="my-2">
                                                    <label className="md:text-lg text-base font-medium">
                                                        Qualification
                                                    </label>
                                                </div> */}
                                                <span className="w-[inherit] absolute md:text-lg text-base font-medium bg-white px-1 z-10 top-[-0.75rem] left-3">
                                                    Qualification
                                                </span>
                                                <Form.Item
                                                    name="qualification"
                                                    className="md:w-4/5 w-full"
                                                >
                                                    <Select
                                                        className="bg-lightBlue md:h-12 h-10 rounded-lg border border-[#7FE8EE]"
                                                        // placeholder="Select Qualification"
                                                        allowClear
                                                    >
                                                        {/* {medicalQualifications?.map((qualification) => (
                                                        <Select.Option value={qualification.code}>
                                                            {qualification.description}
                                                        </Select.Option>
                                                    ))} */}
                                                        {/* <Select.Option value={"M.S. (OG) FMAS DMAS FRM"}>M.S. (OG) FMAS DMAS FRM</Select.Option>
                                                        <Select.Option value={"M.B.B.S"}>M.B.B.S</Select.Option>
                                                        <Select.Option value={"M.D. (Gen. Med.)"}>M.D. (Gen. Med.)</Select.Option>
                                                        <Select.Option value={"M.S. (General Surgery)"}>M.S. (General Surgery)</Select.Option>
                                                        <Select.Option value={"M.D. (Cardiology)"}>M.D. (Cardiology)</Select.Option>
                                                        <Select.Option value={"M.D. (Paediatrics)"}>M.D. (Paediatrics)</Select.Option>
                                                        <Select.Option value={"M.D. (Dermatology)"}>M.D. (Dermatology)</Select.Option>
                                                        <Select.Option value={"M.D. (Orthopaedics)"}>M.D. (Orthopaedics)</Select.Option> */}
                                                        {
                                                            qualificationList.map((qualification) => (
                                                                <Select.Option value={qualification}>{qualification}</Select.Option>
                                                            ))
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </div>

                                            <div className="relative">
                                                {/* <div className="my-2">
                                                    <label className="md:text-lg text-base font-medium">
                                                        Reg. No
                                                    </label>
                                                </div> */}
                                                <span className="w-[inherit] absolute md:text-lg text-base font-medium bg-white px-1 z-10 top-[-0.75rem] left-3">
                                                    Reg. No
                                                </span>
                                                <Form.Item name="regnumber" className="w-full">
                                                    <Input
                                                        // placeholder="Registration No"
                                                        name="regnumber"
                                                        className="md:w-4/5 w-full md:h-12 h-10 outline-none border border-[#7FE8EE] md:p-2 p-1 rounded-lg"
                                                        maxLength={10}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </>
                                    )
                                }

                                {/* Educational Details */}

                                {
                                    // currentPage === 2 && (
                                    //     <>
                                    //         <div>
                                    //             <div className="my-2">
                                    //                 <label className="md:text-lg text-base font-medium">
                                    //                     Qualification
                                    //                 </label>
                                    //             </div>
                                    //             <Form.Item
                                    //                 name="qualification"
                                    //                 className="md:w-4/5 w-full"
                                    //             >
                                    //                 <Select
                                    //                     className="bg-lightBlue md:h-12 h-10 rounded-lg border border-[#7FE8EE]"
                                    //                     placeholder="Select Qualification"
                                    //                     allowClear
                                    //                 >
                                    //                     {/* {medicalQualifications?.map((qualification) => (
                                    //                     <Select.Option value={qualification.code}>
                                    //                         {qualification.description}
                                    //                     </Select.Option>
                                    //                 ))} */}
                                    //                     <Select.Option value={"M.S. (OG) FMAS DMAS FRM"}>M.S. (OG) FMAS DMAS FRM</Select.Option>
                                    //                     <Select.Option value={"M.B.B.S"}>M.B.B.S</Select.Option>
                                    //                     <Select.Option value={"M.D. (Gen. Med.)"}>M.D. (Gen. Med.)</Select.Option>
                                    //                     <Select.Option value={"M.S. (General Surgery)"}>M.S. (General Surgery)</Select.Option>
                                    //                     <Select.Option value={"M.D. (Cardiology)"}>M.D. (Cardiology)</Select.Option>
                                    //                     <Select.Option value={"M.D. (Paediatrics)"}>M.D. (Paediatrics)</Select.Option>
                                    //                     <Select.Option value={"M.D. (Dermatology)"}>M.D. (Dermatology)</Select.Option>
                                    //                     <Select.Option value={"M.D. (Orthopaedics)"}>M.D. (Orthopaedics)</Select.Option>

                                    //                 </Select>
                                    //             </Form.Item>
                                    //         </div>

                                    //         <div>
                                    //             <div className="my-2">
                                    //                 <label className="md:text-lg text-base font-medium">
                                    //                     Reg. No
                                    //                 </label>
                                    //             </div>
                                    //             <Form.Item name="regnumber" className="w-full">
                                    //                 <Input
                                    //                     placeholder="Registration No"
                                    //                     name="regnumber"
                                    //                     className="md:w-4/5 w-full md:h-12 h-10 outline-none border border-[#7FE8EE] md:p-2 p-1 rounded-lg"
                                    //                     maxLength={10}
                                    //                 />
                                    //             </Form.Item>
                                    //         </div>
                                    //     </>
                                    // )
                                }

                                {/* Specialisation */}

                                {/* {
                                    currentPage === 3 && (
                                        <>
                                            <div>
                                                <div className="my-2">
                                                    <label className="md:text-lg text-base font-medium">
                                                        Speciality
                                                    </label>
                                                </div>
                                                <Form.Item name="speciality" className="md:w-4/5 w-full">
                                                    <Select
                                                        className="bg-lightBlue md:h-12 h-10 rounded-lg border border-[#7FE8EE]"
                                                        placeholder="Select Speciality"
                                                        allowClear
                                                    >
                                                        {doctorSpecialties?.map((spec) => (
                                                            <Select.Option value={spec.speciality}>
                                                                {spec.speciality}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </>
                                    )
                                } */}



                                {/* Basic Details */}

                                {
                                    // currentPage === 3 && (
                                    //     <>

                                    //         <div>
                                    //             <div className="my-2">
                                    //                 <label className="md:text-lg text-base font-medium">
                                    //                     Email
                                    //                 </label>
                                    //                 {/* <span className="text-red-500">*</span> */}
                                    //             </div>
                                    //             <Form.Item
                                    //                 name="email"
                                    //                 rules={[
                                    //                     {
                                    //                         type: "email",
                                    //                         message: "Please enter a valid email address!",
                                    //                     },
                                    //                 ]}
                                    //             >
                                    //                 <Input
                                    //                     type="email"
                                    //                     placeholder="Enter email"
                                    //                     name="email"
                                    //                     className="md:w-4/5 w-full  md:h-12 h-10 border border-[#7FE8EE] outline-none p-2 rounded-lg"
                                    //                 />
                                    //             </Form.Item>
                                    //         </div>

                                    //         <div>
                                    //             <div className="my-2">
                                    //                 <label className="md:text-lg text-base font-medium">
                                    //                     Mobile
                                    //                 </label>
                                    //                 {/* <span className="text-red-500">*</span> */}
                                    //             </div>
                                    //             <Form.Item
                                    //                 name="mobile"
                                    //                 rules={[
                                    //                     {
                                    //                         type: "string",
                                    //                         pattern: /^\d{10}$/, // Only allow 10 digits
                                    //                         message:
                                    //                             "Please enter a valid 10-digit mobile number!",
                                    //                     },
                                    //                 ]}
                                    //             >
                                    //                 <Input
                                    //                     placeholder="Enter mobile No"
                                    //                     name="mobile"
                                    //                     className="md:w-4/5 w-full md:h-12 h-10 border border-[#7FE8EE] outline-none md:p-2 p-1 rounded-lg"
                                    //                     maxLength={10}
                                    //                 />
                                    //             </Form.Item>
                                    //         </div>

                                    //         <div>
                                    //             <div className="my-2">
                                    //                 <label className="md:text-lg text-base font-medium">
                                    //                     Gender
                                    //                 </label>
                                    //                 {/* <span className="text-red-500">*</span> */}
                                    //             </div>
                                    //             <Form.Item name="gender" className="md:w-4/5 w-full py-2">
                                    //                 <Select
                                    //                     placeholder="Select Gender"
                                    //                     className="md:h-12 h-10 rounded-lg border border-[#7FE8EE]"
                                    //                     allowClear
                                    //                 >
                                    //                     <Select.Option value="Male">Male</Select.Option>
                                    //                     <Select.Option value="Female">Female</Select.Option>
                                    //                     <Select.Option value="Others">Others</Select.Option>
                                    //                 </Select>
                                    //             </Form.Item>
                                    //         </div>

                                    //     </>
                                    // )
                                }

                                {/* Current Hospital */}
                                {
                                    currentPage === 2 && (
                                        <>
                                            <div className="relative">
                                                {/* <div className="my-2">
                                                    <label className="md:text-lg text-base font-medium">
                                                        Clinic Name
                                                    </label>
                                                </div> */}
                                                <span className="w-[inherit] absolute md:text-lg text-base font-medium bg-white z-10 top-[-0.75rem] left-3">
                                                    Clinic Name
                                                </span>
                                                <Form.Item name="clinicname" className="w-full">
                                                    <Input
                                                        // placeholder="Clinic Name"
                                                        name="regnumber"
                                                        className="md:w-4/5 w-full md:h-12 h-10 border border-[#7FE8EE] outline-none md:p-2 p-1 rounded-lg"
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div className="relative">
                                                {/* <div className="my-2">
                                                    <label className="md:text-lg text-base font-medium">
                                                        Clinic Location
                                                    </label>
                                                </div> */}
                                                <span className="w-[inherit] absolute md:text-lg text-base font-medium bg-white z-10 top-[-0.75rem] left-3">
                                                    Clinic Location
                                                </span>
                                                <Form.Item name="cliniclocation" className="w-full">
                                                    <Input
                                                        // placeholder="Clinic Location"
                                                        name="regnumber"
                                                        className="md:w-4/5 w-full md:h-12 h-10 border border-[#7FE8EE] outline-none md:p-2 p-1 rounded-lg"
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div className="relative">
                                                {/* <div className="my-2">
                                                    <label className="md:text-lg text-base font-medium">
                                                        Clinic Address
                                                    </label>
                                                </div> */}
                                                <span className="w-[inherit] absolute md:text-lg text-base font-medium bg-white z-10 top-[-0.75rem] left-3">
                                                    Clinic Address
                                                </span>
                                                <Form.Item name="clinicaddress" className="w-full">
                                                    <Input
                                                        // placeholder="Clinic Address"
                                                        name="regnumber"
                                                        className="md:w-4/5 w-full md:h-12 h-10 border border-[#7FE8EE] outline-none md:p-2 p-1 rounded-lg"
                                                    />
                                                </Form.Item>
                                            </div>
                                        </>
                                    )
                                }

                                {/* image collection */}

                                {
                                    // currentPage === 6 && (
                                    //     <>
                                    //         {/* <Row gutter={24}>
                                    //             <div className="flex flex-col m-auto" onClick={handleClick}>
                                    //                 <div className="Upload h-48 w-48 border-2 border-dashed p-4 rounded-full cursor-pointer relative">
                                    //                     <input
                                    //                         type="file"
                                    //                         style={{ display: "none" }}
                                    //                         onChange={handleUserPicChange}
                                    //                         ref={fileInputRef}
                                    //                     />
                                    //                     <div
                                    //                         className="absolute bottom-0 right-0 rounded-full text-center p-1 bg-slate-300 cursor-pointer"
                                    //                         style={{ width: "24px", height: "24px", zIndex: 1 }}
                                    //                     >
                                    //                         <PlusOutlined style={{}} />
                                    //                     </div>
                                    //                     {profileImage && (
                                    //                         <img
                                    //                             src={URL.createObjectURL(profileImage)}
                                    //                             alt="Profile"
                                    //                             className="absolute top-0 left-0 w-full h-full object-fill rounded-full"
                                    //                         />
                                    //                     )}
                                    //                 </div>
                                    //             </div>
                                    //         </Row> */}

                                    //         <div>
                                    //             <div className="my-2">
                                    //                 <label className="md:text-lg text-base font-medium">
                                    //                     Photo
                                    //                 </label>
                                    //             </div>
                                    //             {!profileImage?.name && (
                                    //                 <Form.Item
                                    //                     className="md:w-4/5 w-full outline-none border-borderColor rounded-lg"
                                    //                     name="stamp"
                                    //                 >
                                    //                     <div className="flex flex-col justify-center relative border border-borderColor md:h-12 h-10 p-2 rounded-lg">
                                    //                         <BsCloudUpload className="mx-auto text-lg  text-primaryColor" />
                                    //                         <input
                                    //                             type="file"
                                    //                             className="w-[inherit] outline-none ml-[2.75rem] border border-[#7FE8EE] absolute left-8  opacity-0"
                                    //                             onChange={handleUserPicChange}
                                    //                         />
                                    //                     </div>
                                    //                 </Form.Item>
                                    //             )}
                                    //             {profileImage?.name && (
                                    //                 <div>
                                    //                     <p>
                                    //                         {profileImage?.name}
                                    //                     </p>
                                    //                     <div className="flex mt-2 gap-4">
                                    //                         <img
                                    //                             role="button"
                                    //                             src={deleteHoverIcon}
                                    //                             alt="Delete"
                                    //                             onClick={handlePhotoDelete}
                                    //                         />
                                    //                         <img
                                    //                             role="button"
                                    //                             src={View}
                                    //                             alt="Preview"
                                    //                             onClick={handleViewPhoto}
                                    //                         />
                                    //                     </div>
                                    //                 </div>
                                    //             )}
                                    //         </div>

                                    //         <div>
                                    //             <div className="my-2">
                                    //                 <label className="md:text-lg text-base font-medium">
                                    //                     Stamp
                                    //                 </label>
                                    //             </div>
                                    //             {!doctorStamp?.name && (
                                    //                 <Form.Item
                                    //                     className="md:w-4/5 w-full outline-none border-borderColor rounded-lg"
                                    //                     name="stamp"
                                    //                 >
                                    //                     <div className="flex flex-col justify-center relative border border-borderColor md:h-12 h-10 p-2 rounded-lg">
                                    //                         <BsCloudUpload className="mx-auto text-lg  text-primaryColor" />
                                    //                         <input
                                    //                             type="file"
                                    //                             className="w-[inherit] outline-none ml-[2.75rem] border border-[#7FE8EE] absolute left-8  opacity-0"
                                    //                             onChange={handleStamp}
                                    //                         />
                                    //                     </div>
                                    //                 </Form.Item>
                                    //             )}
                                    //             {doctorStamp?.name && (
                                    //                 <div>
                                    //                     <p>
                                    //                         {doctorStamp?.name}
                                    //                     </p>
                                    //                     <div className="flex mt-2 gap-4">
                                    //                         <img
                                    //                             role="button"
                                    //                             src={deleteHoverIcon}
                                    //                             alt="Delete"
                                    //                             onClick={handleStampDelete}
                                    //                         />
                                    //                         <img
                                    //                             role="button"
                                    //                             src={View}
                                    //                             alt="Preview"
                                    //                             onClick={handleViewStamp}
                                    //                         />
                                    //                     </div>
                                    //                 </div>
                                    //             )}
                                    //         </div>

                                    //         <div>
                                    //             <div className="my-2">
                                    //                 <label className="md:text-lg text-base font-medium">
                                    //                     E-signature
                                    //                 </label>
                                    //             </div>
                                    //             {!doctorSignature?.name && (
                                    //                 <Form.Item
                                    //                     className="md:w-4/5 w-full outline-none border rounded-lg"
                                    //                     name="signature"
                                    //                 >
                                    //                     <div className="flex flex-col justify-center relative border md:h-12 h-10 border-borderColor p-2 rounded-lg">
                                    //                         <BsCloudUpload className="mx-auto text-lg  text-primaryColor" />
                                    //                         <input
                                    //                             type="file"
                                    //                             className="w-[inherit] outline-none ml-[2.75rem] border border-[#7FE8EE] absolute left-8  opacity-0"
                                    //                             onChange={handleSignature}
                                    //                         />
                                    //                     </div>
                                    //                 </Form.Item>
                                    //             )}
                                    //             {doctorSignature?.name && (
                                    //                 <div>
                                    //                     <p>
                                    //                         {doctorSignature.name}
                                    //                     </p>
                                    //                     <div className="flex mt-2 gap-4">
                                    //                         <img
                                    //                             role="button"
                                    //                             src={deleteHoverIcon}
                                    //                             alt="Delete"
                                    //                             onClick={handeldocSignDel}
                                    //                         />
                                    //                         <img
                                    //                             role="button"
                                    //                             src={View}
                                    //                             alt="Preview"
                                    //                             onClick={handeldocSignView}
                                    //                         />
                                    //                     </div>
                                    //                 </div>
                                    //             )}
                                    //         </div>

                                    //     </>
                                    // )
                                }

                            </Form>
                        </div>
                    </div>
                    <div className="rounded-lg">
                        <div className="flex md:flex-row md:items-center items-end justify-end gap-3">
                            <button
                                className="md:min-w-28 w-[6rem] md:w-1/6 md:p-3 p-1 bg-White rounded-lg border text-primaryColor border-primaryColor font-semibold custom-button-shadow"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            {currentPage > 1 && (
                                <button type="button" onClick={handlePrevious} className="md:min-w-28 w-[6rem] md:w-1/6 md:p-3 p-1 bg-primaryColor text-White rounded-lg font-semibold disabled:cursor-not-allowed disabled:opacity-50">
                                    Previous
                                </button>
                            )}
                            {currentPage < totalPages ? (
                                <button type="button" onClick={handleNext} className="md:min-w-28 w-[6rem] md:w-1/6 md:p-3 p-1 bg-primaryColor text-White rounded-lg font-semibold disabled:cursor-not-allowed disabled:opacity-50">
                                    Next
                                </button>
                            ) : (
                                <button
                                    disabled={isLoading}
                                    className="md:min-w-28 w-[6rem] md:w-1/6 md:p-3 p-1 bg-primaryColor text-White rounded-lg font-semibold disabled:cursor-not-allowed disabled:opacity-50"
                                    onClick={handleSave}
                                >
                                    Save
                                </button>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title="Doctor Stamp"
                open={isModalOpen}
                onCancel={handleStampViewClose}
                footer={[]}
            >
                <img src={stampView} alt="Preview" style={{ width: "100%" }} />
            </Modal>
            <Modal
                title="Doctor Signature"
                open={signModal}
                onCancel={closeDocSignView}
                footer={[]}
            >
                <img src={docSignView} alt="Preview" style={{ width: "100%" }} />
            </Modal>
            <Modal
                title="Photo"
                open={isPhotoModalOpen}
                onCancel={closephotoView}
                footer={[]}
            >
                <img src={photoView} alt="Preview" style={{ width: "100%" }} />
            </Modal>
        </>
    );
}

export default DoctorSelfEnrollment;
