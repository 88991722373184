import React, { useEffect, useRef, useState } from "react";
import Navbar from "../DashBoardComponents/Navbar";
import doctor from "../assets/images/Doctor1.png";
import doctorIcon from "../assets/images/Svg/doctorIcon.svg";
import { IoVideocam } from "react-icons/io5";
import { FaCalendarAlt, FaGift, FaPlusCircle } from "react-icons/fa";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import Footer from "../DashBoardComponents/Footer";
import { Checkbox, DatePicker, Form, InputNumber, message, Modal, Radio, Spin } from "antd";
import { useLocation } from "react-router-dom";
import { addMinutesToTime, ConvertTime, convertUTCDate } from "../utils/convertionFunc";
import dayjs from "dayjs";
import enrollmentServices from "../Services/enrollmentServices";
import UserService from "../Services/UserService";
import { NewPatientPayment } from "../utils/Appointments";
import PatientRegistration from "./PatientRegistration";

const data = [
  {
    title: "Apply Coupon",
    content:
      "Telemedicine refers to the practice of providing medical care remotely through technology, such as video calls or messaging platforms.",
  },
];


const Checkout = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [doctor, setDoctor] = useState(null);
  const [dob, setDob] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPatientCreated, setIsPatientCreated] = useState(false);
  const [slotChecked, setSlotChecked] = useState(false);
  const [patientDetails, setPatientDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const formRef = useRef();

  console.log(state);

  useEffect(() => {
    setDoctor(state?.appointmentPayload.selectedDoctor);
    const patJson = localStorage.getItem('patientData');
    const patObj = patJson ? JSON.parse(patJson) : null;
    setPatientDetails(patObj);
  }, [state]);

  const handleClick = async () => {
    setIsLoading(true);
    const payload = {
      account_Id: doctor?.account_Id,
      appointmentDate: convertUTCDate(
        state?.appointmentPayload.selectedDate
      ),
      clinic_Id: doctor?.clinic_Id,
      consultationReason: "",
      createdBy: doctor?.userId,
      createdDate: convertUTCDate(
        new Date(),
        "YYYY-MM-DD HH:mm:ss"
      ),
      dob: "",
      email: patientDetails?.email,
      endTime: addMinutesToTime(state?.appointmentPayload.selectedSlot, 15),
      firstName: patientDetails?.firstName,
      gender: patientDetails?.gender,
      lastName: patientDetails?.lastName,
      meetingLink: "",
      mobile: patientDetails?.mobile,
      modifiedBy: doctor?.userId,
      modifiedDate: convertUTCDate(
        new Date(),
        "YYYY-MM-DD HH:mm:ss"
      ),
      patientId: patientDetails?.patientId,
      patientRegFee: 0,
      patientRegFeeYN: "N",
      patientType: "E",
      paymentConsultation: "BC",
      paymentReceived: "N",
      paymentType: "Digital",
      salutation: "",
      serviceCode: "APPT_VIDEO",
      serviceName: "Video Consultation",
      specialityCode: doctor.specialityCode,
      startTime: state?.appointmentPayload.selectedSlot,
      status: 0,
      totalAmount: doctor?.videoFee,
      // totalAmount:parseFloat(payload.patientRegFee) + parseFloat(payload.consultationFee),
      consultationFee: doctor?.videoFee,
      userId: doctor?.userId
    };




    // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // if (emailRegex.test(payload.email)) {
    //   console.log("Valid email format");
    // } else {
    //   message.error("enter valid email in the send invite");
    //   return;
    // }
    NewPatientPayment(payload, doctor)
      .catch((error) => {
        console.error("An error occurred:", error);
        message.error("An Error occurred, try again");
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleSlot = () => {
    setSlotChecked(!slotChecked);
  }

  const handlePostPatientCreation = (patientData) => {
    setPatientDetails(patientData);
    setIsPatientCreated(true);
    setIsModalOpen(false);
  }

  const handlePatientCreate = async () => {
    const { firstname, lastname, gender, email } = formRef.current.getFieldValue();
    if (!firstname || !lastname || !dob) {
      message.error('Please fill in required fields.');
    }

    const payload = {
      account_Id: doctor?.account_Id,
      account_Name: "",
      address1: "",
      address2: "",
      bloodGroup: "",
      city: "",
      clinic_Id: doctor?.clinic_Id,
      clinic_Name: "",
      country: "",
      createdBy: doctor?.userId,
      createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      dob: dob,
      email: email,
      firstName: firstname,
      gender,
      lastName: lastname,
      loginId: "",
      loginIdAvailableYN: "",
      maritalStatus: "",
      meetingLink: "",
      mobile: "",
      modifiedBy: doctor?.userId,
      modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      parentPatientId: "",
      parentPatientName: "",
      patientId: "",
      patientRegFee: slotChecked ? 500 : 0,
      patientRegFeeYN: slotChecked ? 'Y' : 'N',
      photo: "",
      photoName: "",
      photoType: "",
      pinCode: "",
      relationCode: "",
      relationDescription: "",
      salutation: "",
      state: "",
      status: 1,
      userId: doctor?.userId,
      userName: doctor?.userName
    }
    console.log(payload, ' .... ');
    try {
      const response = await UserService.addPatient(payload);
      const patientPayload = {
        patientId: response.data?.Patientid,
        status: 1
      }
      const patientResponse = await UserService.getPatient(patientPayload);
      console.log(patientResponse.data);
      setPatientDetails(patientResponse.data);
      localStorage.setItem('patient', JSON.stringify(patientResponse.data));
      setIsPatientCreated(true);
      setIsModalOpen(false);
      setSlotChecked(false);
      formRef.current.resetFields();
    }
    catch (e) {
      console.log(e)
    }
  }

  const handleDob = (value, valueString) => {
    setDob(valueString);
  };

  const handlePatientSave = () => {
    const patientjson = localStorage.getItem('patient');
    const patientObj = patientjson ? JSON.parse(patientjson) : null;
    if (patientObj) {
      handleClick();
    }
    else {
      setIsModalOpen(true);
    }

  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Navbar />
      <div className="flex gap-6 p-6 bg-gray-50 min-h-screen mt-16">
        <div className="w-1/3 bg-white shadow-md rounded-lg p-4">
          {
            doctor?.imageName ? (
              <img
                src={process.env.REACT_APP_IMAGE_URL + doctor?.imageName}
                alt="DoctorPic"
                className="w-40 h-28 mx-auto rounded-sm object-contain"
              />
            ) : (
              <img
                src={doctorIcon}
                alt="DoctorPic"
                className="w-40 h-28 mx-auto rounded-sm"
              />
            )
          }
          <h2 className="text-xl font-bold mt-4 text-center text-[#346078]">
            {`${doctor?.salutation}${doctor?.firstName} ${doctor?.lastName}`}
          </h2>
          <hr className="text-[#346078]" />
          {/* <p className="text-[#002F72] text-center text-sm">
            {doctor?.speciality} | 8 Yrs. Exp
          </p> */}

          <div className="mt-6 rounded-md p-3 bg-[#F5F5F3]">
            <h3 className="text-base text-[#346078] font-semibold mb-2">
              Appointment Details
            </h3>
            <hr />
            <div className="flex gap-4 items-center text-gray-600 mb-1 mt-3">
              <IoVideocam className="text-[#346078]" />
              <p className="text-sm text-[#002F72]">Video Consultation</p>
            </div>
            <div className="flex gap-4 items-center text-gray-600 nt-1">
              <FaCalendarAlt className="text-[#346078]" />
              <p className="text-sm text-[#002F72]">{state?.appointmentPayload.selectedDate}, {state?.appointmentPayload.selectedSlot}</p>
            </div>
          </div>
        </div>

        <div className="w-full bg-white shadow-md rounded-lg p-6 space-y-6">
          <div className="bg-[#EFF6FF] h-16 p-4 rounded-lg items-center">
            <h1 className="text-xl font-semibold text-[#002f72]">Checkout</h1>
          </div>


          <p className="text-lg font-semibold uppercase text-[#002f72]">
            Total Charges
          </p>
          <hr />
          <div className="bg-gray-50 p-4 rounded-lg">
            <div className="flex justify-between">
              <p className="text-[#002f72] font-medium">Consultation Fee</p>
              <p className="text-[#002f72] font-medium">₹{doctor?.videoFee}</p>
            </div>
            <div className="flex justify-between mt-1">
              <p className="text-[#002f72] font-medium">Registration Fee</p>
              <p className="text-[#002f72] font-medium">₹ 0</p>
            </div>
            <hr className="mt-2 border-[1px]" />
            <div className="flex justify-between font-bold mt-2">
              <p className="text-[#002f72]">To Pay</p>
              <p className="text-[#002f72]">₹{doctor?.videoFee}</p>
            </div>
          </div>
          {/* <div className="border-2 border-dashed border-[#DAFCF5] p-3 bg-[#F9FAFB]">
            <p>
              You <span className="text-[#4fe2c5]">could get 0.00</span> on your
              consult
            </p>
          </div> */}
          {/* <label className="text-sm mt-1 text-[#002f72] text-center">
            By booking appointment, you are agreeing to Terms and Conditions of
            the usage of 24/7,Privacy Policy,Refund Policy
          </label> */}
          <div className="flex justify-center">
            <button disabled={isLoading} className="w-[60%] bg-[#002f72] text-white py-3 rounded-lg font-semibold disabled:opacity-75 disabled:cursor-not-allowed" onClick={handleClick}>
              Pay Rs.{doctor?.videoFee} {isLoading ? <Spin /> : ''}
            </button>
          </div>
        </div>
      </div>
      {/* <Modal
        title={
          <span className="text-lg font-bold text-center flex justify-center text-[#002f72]">
            Add New Member
          </span>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={400}
        bodyStyle={{
          scrollbarWidth: "none",
          maxHeight: "400px",
          overflowY: "auto",
          padding: 0,
        }}
        footer={[]}

      >
        <PatientRegistration
          doctor={doctor}
          handlePostPatientCreation={handlePostPatientCreation}
          handleCancel={handleCancel} />
      </Modal> */}

      <Footer />
    </>
  );
};

export default Checkout;
