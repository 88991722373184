export const SAVE_VIDEO_CALL_DETAILS = "SAVE_VIDEO_CALL_DETAILS"
export const DELETE_VIDEO_URL = "DELETE_VIDEO_URL"
export const SaveVideoCall = (data) =>{
    return {
        type: SAVE_VIDEO_CALL_DETAILS,
        payload: data
    }
}

export const DeleteVideoUrl = () =>{
    return {
        type: DELETE_VIDEO_URL
    }
}
