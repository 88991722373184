import React from "react";
import Navbar from "../DashBoardComponents/Navbar";
import Footer from "../DashBoardComponents/Footer";

function TermsOfUse() {
  return (
    <>
      <Navbar />
      <div className="bg-[#EAF7FE] py-32 ">
        <div className="w-[80%] mx-auto regularFont">
          <h1 className="headingFont font-extrabold text-[#002f72] text-[50px]">
            Terms Of Use
          </h1>

          <div>
            <div className=" text-[17px]">
              <p className="mt-2">
                The domain name https://doxzon.com/ and its related sub-domains,
                sites, services and tools collectively (
                <span className="font-extrabold">“Website”</span> or{" "}
                <span className="font-extrabold">“Portal”</span>) is owned and
                managed by Cytozon Technologies Pvt Ltd, a company incorporated
                under the Companies Act, 2013 (hereinafter referred as{" "}
                <span className="font-extrabold">“Company”, "We", "Us"</span> or{" "}
                <span className="font-extrabold">“Our”</span>). Your access or
                use of this Portal, any transactions undertaken, and any
                services availed through the Portal are governed by the terms of
                use provided herein (the{" "}
                <span className="font-extrabold">“Terms”</span>). Alongside, our
                privacy policy forms an integral part of the Terms. The Terms
                are intended to create a binding contract between you and
                doxzon.
              </p>
              <p className="mt-5">
                You are required to express your consent to these Terms before
                you access or use the Portal, directly or indirectly through
                social media Portals like Facebook, Twitter, Google+ and other
                similar social forums (the “Social Media”).
              </p>

              <p className="mt-5">
                We reserve the right to amend the Terms herein, in whole or in
                part, from time to time and provide notice to you upon such
                amendment. Your continued use of the Portal, post any update to
                the Terms would mean your acceptance. If you do not agree to
                such amendments, you must contact us at info@doxzon.com (The
                amended Terms shall be made available on the Portal promptly,
                and hence, you are required to review the Terms periodically to
                keep yourself aware and up to date).
              </p>

              <p className="mt-5">
                PLEASE READ THE TERMS OF USE AND REFERRED POLICIES CAREFULLY
                BEFORE YOU CONTINUE USING PORTAL IN ANY MANNER. YOUR CONSENT IS
                REQUIRED TO THESE TERMS OF USE, WHICH UPON CONSENT WILL BE A
                BINDING CONTRACT BETWEEN YOU AND CYTOZON TECHNOLOGIES PVT LTD.
                IF YOU DO NOT WISH TO CONSENT, YOU ARE ADVISED NOT TO USE THE
                PORTAL. IF YOU DO NOT CONSENT BUT CONTINUE TO USE THE PORTAL, AN
                IMPLIED CONSENT SHALL BE INFERRED FROM YOUR ACTION OF USING THE
                PORTAL.
              </p>

              <p className="mt-5">
                I UNDERSTAND THAT THE TERMS OF USE AND THE PRIVACY POLICY GOVERN
                MY USE AND/OR ACCESS OF THE PORTAL AS A LAWFUL CONTRACT, BINDING
                AND ENFORCEABLE ON ME, AND I HEREBY ACCEPT AND CONSENT TO BE
                GOVERNED BY THESE TERMS OF USE.
              </p>
            </div>
            <div>
              <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
                Eligibility of Users
              </h2>

              <p className="mt-5">
                In order to access, use, browse, view or perform any activities
                on the Portal, you must be above 18 years of age, and competent
                to contract in terms of the Indian Contract Act, 1872. By
                accessing, using, browsing, or performing any other activity on
                the Portal, you hereby agree and represent that you are of a
                legal age and competent to enter into a contract under the
                Indian Contracts Act, 1872 or you have the permission of your
                parent and/or guardian to do so.
              </p>

              <p className="mt-5">
                You are responsible for all activities that occur by you on the
                Portal and consequence thereof. If there is reason to believe
                that there is likely to be breach of security or misuse or
                breach of your privacy, we have the right to suspend your
                services/request for services without any liability for such
                duration as we deem fit.
              </p>

              <p className="mt-5">
                You undertake that you will provide accurate and complete
                information for registration, maintain and promptly update your
                information, maintain security of your account, and promptly
                write to us if you anticipate or become aware of a security
                breach.
              </p>
            </div>
            <div>
              <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
                Accessing through Social Media?
              </h2>
              <p className="mt-5">
                You may access the Portal and avail some Services (as defined
                below) using Social Media accounts, in which case these Terms
                along with terms of use and privacy policy of respective Social
                Media shall also be applicable to you. Further, we shall not be
                responsible for any kind of transmission, publication of any of
                your information on any such Social Media.
              </p>
            </div>
            <div>
              <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
                Services
              </h2>
              <ul className="list-circle px-10">
                <li className="mt-5">
                  The Portal enables you to avail certain services, some of
                  which are provided by CYTOZON TECHNOLOGIES PVT LTD and some by
                  third parties (“Services”).
                </li>

                <li className="mt-5">
                  The Portal provides an intermediary Portal for interaction and
                  commercial transactions between you and third party service
                  providers in healthcare ecosystem
                </li>
              </ul>

              <p className="mt-5">
                Third parties referred above who are providing services using
                Portal are hereinafter referred to as “Third Party Service
                Providers”.
              </p>

              <p className="mt-5">
                You understand and agree that doxzon apart from providing
                certain services on its own, it is merely providing hosting and
                intermediary services from certain third parties. Except as
                otherwise provided in these Terms, we have no control over the
                accuracy, sufficiency, fitness, professionalism, competency and
                quality of services provided by Third Party Service Providers,
                which at all times be the sole responsibility of the Third Party
                Service Providers. They are solely responsible for obtaining
                necessary permits, providing correct information about
                themselves and services offered on the Portal, maintaining
                resources and infrastructure required, comply with applicable
                laws, and perform the offered Services upon conclusion of a
                transaction. You agree that we shall have no liability on this
                count.
              </p>

              <p className="mt-5">
                We act as a technology enabled service provider and as such when
                you avail Services from Third Party Service Providers, you agree
                that a separate contractual relationship is created between you
                and such Third Party Service Provider. Such contract by conduct
                does not supersede the Terms contained herein as the same is
                enabled through the Portal, and will apply to you as well as the
                Third Party Service Provider. We do not make any representation
                or warranty, express or implied for the Services provided by
                Third Party Service Provider, and are not responsible for
                non-performance of deficient performance of Services.
              </p>
              <p className="mt-5">
                Listing of the Services on the Portal is an invitation to an
                offer for sale. When you choose to avail a Service, it
                constitutes an offer from your end to enter into a contract with
                Third Party Service Provider as and when applicable. Thereafter,
                the Third Party Service Provider must accept your offer for the
                transaction to proceed further. For this acceptance, Third Party
                Service Provider will abide by its own internal processes and
                applicable law. Until such time, there is no contract between
                you and the Third Party Service Provider. Reference to “offer
                for sale” or “offer and sale” in these Terms will only mean
                “invitation to an offer”. No liability can be ascribed to doxzon
                for refusal by the Third Party Service Provider to enter into a
                contract with you.
              </p>
            </div>
            <div>
              <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
                Why do we collect and process some information?
              </h2>
              <p className="mt-5">
                When you avail the Services or access/use the Portal, we may
                need to access your specific data including personal data,
                identity information, contacts, device ID, payment details,
                financial, etc. Information provided by you during the
                registration process and thereafter, helps rendering of
                Services, offer relevant content, customer service and network
                management, and all such information shall be used and accessed
                as per the terms of our Privacy Policy. We store personal
                information including sensitive personal information such as
                your health and financial information by employing adequate
                security measures as required under applicable law.
              </p>
            </div>
            <div>
              <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
                Data protection & privacy
              </h2>
              <p className="mt-5">
                Your personal information supplied to us while using the Portal
                or availing the Services shall be used by us in accordance with
                our Privacy Policy. You must review our Privacy Policy which
                forms an integral part of these Terms before you continue to use
                or access the Portal.
              </p>
            </div>
            <div>
              <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
                Payment
              </h2>
              <p className="mt-5">
                We may charge a subscription or facilitation fee for services
                delivered by us through the Portal, which must be paid online
                through the payment facility on the Portal or as mutually agreed
                between us from time to time. These fees are non-refundable.
              </p>
              <p className="mt-5">
                Any payment made for availing services must be in Indian Rupees.
              </p>
              <p className="mt-5">
                You understand and agree that the payment facility provided on
                the Portal is not banking or financial service, and doxzon is
                merely a facilitator to provide an online payment facility for
                the transaction on the Portal using existing third parties such
                as authorized banking infrastructure, credit card payment
                gateway networks, wallets, etc.
              </p>
              <p className="mt-5">
                For Third Party Services, we shall collect payment for the
                services rendered to you and all such fees and payments are
                exclusive of applicable taxes, and you and the Third Party
                Service Provider shall be solely responsible for payment, legal
                compliances, and reporting under applicable law. We are not
                responsible for those taxes which are your legal obligation.
              </p>
            </div>
            <div>
              <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
                User conduct
              </h2>
              <p className="mt-5">
                You agree that you have reviewed and understood the Terms
                including the Privacy Policy and refund and return policy and
                expressly consented to the terms therein;
              </p>
              <p className="mt-1">
                all information provided by you is true, accurate and complete
                in all aspects;
              </p>
              <p className="mt-5">
                you will not allow any third party to use the Portal or Services
                in any manner on your behalf;
              </p>
              <p className="mt-5">
                you will immediately notify us of any unauthorized use of the
                Portal by any person using your device or your details or any
                other breach of security;
              </p>
              <p className="mt-5">
                you shall not use the Portal to use or share any information on
                the Portal that:
              </p>

              <ul className="list-circle px-10 mt-5">
                <li className="mt-5">
                  belongs to another person and to which you do not have any
                  right to access or use;
                </li>
                <li className="mt-5">
                  is obscene, pornographic, paedophilic, invasive of another’s
                  privacy including bodily privacy, insulting or harassing on
                  the basis of gender, racially or ethnically objectionable,
                  relating or encouraging money laundering or gambling,2 [or an
                  online game that causes user harm,] or promoting enmity
                  between different groups on the grounds of religion or caste
                  with the intent to incite violence;
                </li>
                <li className="mt-5">is harmful to children;</li>
                <li className="mt-5">
                  infringes any patent, trademark, copyright or other
                  proprietary rights;
                </li>
                <li className="mt-5">
                  deceives or misleads the addressee about the origin of the
                  message or knowingly and intentionally communicates any
                  misinformation or information which is patently false and
                  untrue or misleading in nature or, in respect of any business
                  of the Central Government, is identified as fake or false or
                  misleading by any fact check unit of the Central Government
                </li>
                <li className="mt-5">impersonates another person;</li>
                <li className="mt-5">
                  threatens the unity, integrity, defence, security or
                  sovereignty of India, friendly relations with foreign States,
                  or public order, or causes incitement to the commission of any
                  cognisable offence, or prevents investigation of any offence,
                  or is insulting other nation;
                </li>
                <li className="mt-5">
                  contains software virus or any other computer code, file or
                  program designed to interrupt, destroy or limit the
                  functionality of any computer resource;
                </li>
                <li className="mt-5">
                  is in the nature of an online game that is not verified as a
                  permissible online game;
                </li>
                <li className="mt-5">
                  is in the nature of advertisement or surrogate advertisement
                  or promotion of an online game that is not a permissible
                  online game, or of any online gaming intermediary offering
                  such an online game;
                </li>
                <li className="mt-5">
                  violates any law for the time being in force.
                </li>
              </ul>
              <p className="mt-5">
                you are accessing and using the Portal and/or the Services at
                your sole risk and are using your best and prudent judgment
                before accessing and using the Services and/or the Portal;
                <br />
                the terms of agreement with your respective mobile network
                provider or internet service provider shall apply while
                accessing the Portal; as a result of this, you may be charged by
                the concerned mobile network provider or internet service
                provider for using network connection, and you agree to pay such
                charges, as applicable, when you access the Portal;
              </p>
              <p className="mt-5">
                we may update, revise, modify, delete, amend, or withdraw any
                feature, content, options, page, data hosted on the Portal
                without any prior notification to you, and you hereby consent to
                the same;
                <br />
                you are availing the Services with your free consent and with
                full knowledge of the inherent limitations; you have consented
                with your free will and with all information that your personal
                information will be shared with third parties, which consent
                also allows transfer and storage of your sensitive personal
                information outside the country.
              </p>
            </div>
            <div>
            <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
            Proprietary rights and license
              </h2>
              <p className="mt-5">All content and materials, including, but not limited to description of Services, images, text, illustrations, designs, icons, photographs, names, logos, design marks, slogans, programs, software, music clips or downloads, video clips and written and other proprietary information (including page layout, or form) that are part of the Portal (the “Content”) are intended solely for personal, non-commercial use</p>
              <p className="mt-5">The Content is provided by us and you acknowledge that we and/or our affiliates and Third Party Service Providers are the sole and absolute owners of any proprietary right in the Content; and except as expressly authorized in these Terms, you shall not use, access, distribute, modify, copy, transmit, display, reproduce, license, create derivative works from, transfer, reverse engineer, or deal otherwise with the Content.</p>
              <p className="mt-5">Any attempt to decompile, or reverse engineer, or to remove any proprietary declarations such as copyright, trademark from the Content, is strictly prohibited and will be considered as unauthorized use of the Content resulting in deregistration and debarment from accessing the Portal.</p>
              
            </div>
            <div>
            <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
            Availability of Portal
              </h2>
              <p className="mt-5">We will use reasonable efforts to make the Portal available at all times.<br/>
              However, you acknowledge that the Portal and/or the Services may not be available due to reasons outside our control such as, without limitation, while under maintenance, limited internet or connectivity due to the services rendered by mobile service provider, etc. In such an event, you shall not hold us liable, including but not limited for any direct or indirect loss.</p>
            </div>
            <div>
            <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
            Reviews & feedback
              </h2>
              <p className="mt-5">We shall have proprietary rights over any review, comment, feedback, suggestion, complaint and other submission disclosed and submitted by you on the Portal (except any personal information), or otherwise disclosed and submitted in relation to the Services on any media including Social Media (the “Feedback”); and we shall have worldwide rights, titles and interests in all copyrights and other intellectual properties in such Feedback.</p>
              <p className="mt-5">You expressly assign all proprietary rights including copyright in Feedback to us without any limitation and restriction as to use or ownership, without payment of any compensation. Thus, we shall exclusively own all such rights and titles, and use, reproduce, disclose, modify, adapt, create derivative works from, monitor, remove, edit, delete, publish, share in the Feedback and shall be entitled to use such Feedback in any way, commercial or otherwise.</p>
              <p className="mt-5">You agree that we are under no obligation to maintain confidentiality of any Feedback, to respond to any Feedback, nor shall your Feedback cause violate the Terms or any right of any third party (including copyright, trademark, privacy or other personal or proprietary right(s)) and not cause injury to any person or entity.</p>
            </div>
            <div>
            <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
            Disclaimer of warranties
              </h2>
              <p className="mt-5">
              The Services provided on the Portal are provided on “as is” and “as available” basis. We do not make any representation or warranties in respect of the Third Party Service Provider or the intermediary services or the Portal whatsoever, and absolutely none for the Services provided by Third Party Service Providers.
              </p>
            </div>
            <div>
            <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
            Limitation of liability
              </h2>
              <p className="mt-5">
              In no event shall we be liable for any direct, indirect, punitive, incidental, special, consequential damages or any other damages resulting from the use or the inability to use the Portal and/or the Services and/or the Content;
              </p>
              <p className="mt-5">unauthorized access to or alteration of your transmissions or data;</p>
              <p className="mt-5">any unauthorized, indecent, defamatory, false, seditious content uploaded or posted by anybody else, including audio-visual content infringing any third party’s intellectual property rights;

</p>
              <p className="mt-5">any non-performance of deficit performance of Services provided or availed by you from Third Party Service Provider;</p>
              <p className="mt-5">any consequence which is not caused by any action or omission on our part.</p>
              <p className="mt-5">This will survive termination of these Terms.</p>
            </div>
            <div>
            <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
            Indemnification
              </h2>
              <p className="mt-5">
              You agree to indemnify and hold us and (as applicable) our affiliates, partner organizations, officers, directors, agents, and employees, harmless from all losses, liabilities, claims, demands or expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon), asserted against or incurred by us that arise out of, as a result from, or may be payable by virtue of, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed by you pursuant to these Terms, your violation of any law, or violation of the rights of a third party, including the infringement by you of any intellectual property or other right of any person or entity. These obligations will survive any termination of these Terms. Accordingly, we hereby disclaim any warranties of Services or otherwise provided by the Third Party Service Providers and any issues of deficiency of Services of Third Party Service Providers shall be taken up by you directly with the Third Party Service Provider.
              </p>
            </div>
            <div>
            <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
            Termination
              </h2>
              <p className="mt-5">
              We reserve the right to suspend or terminate your access and use of the Portal, or any Service if we believe, in our sole and absolute discretion that you have breached, violated, abused, or unethically manipulated or exploited any term of these Terms or anyway otherwise acted unethically.
              </p>
              <p className="mt-5">
              If we terminate access to the Portal, or any Service, we may, at our sole discretion, delete any and all of your content or other related data, information and materials and we will have no liability to you or any third party for doing so.
              </p>
            </div>
            <div>
            <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
            Governing law and dispute resolution
              </h2>
              <p className="mt-5">
              The present Terms shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions. All disputes shall be decided by mediation under the courts of Chennai High Court. If the parties fail to mediate within 3 months from the date of a dispute as notified by a party in writing to the other, the dispute shall be adjudicated by competent courts in Chennai, Tamil Nadu, India.
              </p>
            </div>
            <div>
            <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
            Severability & waiver
              </h2>
              <p className="mt-5">
              In case of non-compliance of these Terms, we reserve the right to take necessary action including but not limiting to termination of the Terms, and appropriate legal actions. Our failure to enforce any right or provision under these Terms will not be considered as waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable, the remaining clauses of these Terms will continue to be binding and remain in effect. These Terms constitute the entire agreement between you and us regarding use of the Portal and the Services, and supersede and replace any prior agreements we might have with you.
              </p>
            </div>
            <div>
            <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
            Grievance Redressal Mechanism
              </h2>
              <p className="mt-5">
              In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below: 
              </p>
              <p className="mt-5">Name: Chandra Sekhar</p>
              <p className="mt-5">Address: #179, Kothari Nagar, Singanallur, Coimbatore - 641 005. INDIA</p>
              <p className="mt-5">E-mail: <a className="text-sky-500" href="mailto:admin@cytozon.com">info@doxzon.com</a></p>
            </div>
            <div>
            <h2 className="mt-10 headingFont text-[#002f72] text-[30px]">
            Contact us
              </h2>
              <p className="mt-5">If you have any questions about these Terms, please contact us at info@doxzon.com or write to us at #179, Kothari Nagar, Singanallur, Coimbatore - 641 005. INDIA</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermsOfUse;
